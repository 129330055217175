import React, { Component } from 'react'; //different
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
// import './style.css';
import Main from './main';
import Casino from './casino';
import NavMenu from './navmenu';
import Slot from './slot';
import Arcade from './arcade';
import Event from './event';
import Sport from './sport';
import Fising from './fising';
import Signin from './signin';
import Profile from './profile';
import Promotion from './promotionx';
import Login from './login';
import Logout from './logout';
import Statement from './statement';
import Deposit from './deposit';
import Withdraw from './withdraw';
import Affiliate from './affiliate';
import Banner from './banner';
import Loss from './loss';
import Linklistall from './linklistall';
import Linklistalldemo from './linklistaldemo';
import instance from "./axios-instance";
import Systems from "./SystemFunction";
import SweetAlert from 'react-bootstrap-sweetalert';
import jwt_decode from "jwt-decode";
import Swal from 'sweetalert2'
import Freegame from './slotfree';
import Register from './register';
import io from "socket.io-client";
import MoblieMenu from "./mobilemenu";
import moment from 'moment';
import Spinwheels from './spinweel';
import $ from "jquery";
import Paoyingchub from './game/paoyingchub';
import { Flag } from '@mui/icons-material';
import md5 from 'md5';
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      mainsetting: [],
      rewardlist: [],
      activePage: 1,
      ThmeseV: null,
      checkboxcookie: false,
      isActive: false,
      formData: {}, // Contains login form data
      errors: {}, // Contains login field errors
      formSubmitted: false, // Indicates submit status of login form
      loading: true, // Indicates in progress state of login form
      pagecount: 1,
      username: null,
      settingwheel: "",
      settingcheckin: "",
      userid: null,
      phoneNumber: "",
      balance: 0,
      mainacc: null,
      pathname: "",
      qrcode: null,
      stateregis: 1,
      checkboxacc: 0,
      products_page_count: 1,
      loginstate: false,
      fullname: null,
      lname: null,
      link: "",
      alert: null,
      depositstate: 0,
      stateforget: 1,
      depositamount: 0,
      stateopterror: "",
      btnisActive: true,
      otpphone: "",
      token: window.localStorage.getItem("token"),
      accnumber: null,
      accnumbertxt: null,
      withdrawamount: 0,
      rankpoint: 0,
      changepassword: false,
      linelink: "",
      errortext: "",
      trueaccount: null,
      datas: null,
      otpphoneforget: "",
      affpercent: 0,
      userrecomend: "",
      historylist: [],
      useradvice: [],
      cashoutlist: [],
      tblnextrank: [],
      tblrankall: [],
      maxwithdrawstatus: 0,
      tblprofile: [],
      prodetail: [],
      oldpassword: "",
      linkaff: "",
      webnamex: "",
      inviteSet: "",
      truegiftlink: "",
      proderedeem: [],
      proderedeemlist: [],
      buttoncliam: false,
      limit: null,
      rankpic: "1.jpeg",
      rankname: null,
      rankob: null,
      cashoutSet: [],
      tblPopupMenu1: [],
      tblPopupMenu2: [],
      tblPopupMenu: null,
      termAndCondition: false,
      errorstatus: false,
    }
    this.handleClick = this.handleClick.bind(this);
    this.forgetotpandregister = this.forgetotpandregister.bind(this);
  }
  copyCode = () => {
    navigator.clipboard
      .writeText(this.state.linkaff)
      .then(() => {
        setTimeout(function () {
        }, 3000);
      })
      .catch((err) => {
        // console.log(err.message);
      });
  };

  openAccout(evt, cityName) {
    var i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("js-profile-account-modal");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }
    tablinks = document.getElementsByClassName("tabaccount");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }
    document.getElementById(cityName).style.display = "block";
    evt.currentTarget.className += " active";
  }
  showClockRealTime = (datestart) => {
    // var days = ['อาทิตย์', 'จันทร์', 'อังคาร', 'พุธ', 'พฤหัส', 'ศุกร์', 'เสาร์'];

    var months_th_mini = ["ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.", "พ.ค.", "มิ.ย.", "ก.ค.", "ส.ค.", "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค.",];
    var today = new Date(datestart);
    var endday = months_th_mini[today.getMonth()];

    return endday;
  }
  handleClick = async (e, price) => {
    if (price <= this.state.balance) {
      this.setState({
        withdrawamount: price
      });
    }
    else {
      await Swal.fire({
        title: 'Warning!',
        text: 'ยอดถอนของท่านไม่เพียงพอในการถอน',
        icon: 'warning',
        confirmButtonText: 'ตกลง'
      })
    }
  }


  checkpro = async (e, proid) => {
    var token = localStorage.getItem('auth_token');
    var decoded = jwt_decode(token);
    var Username = decoded.message.playerid;
    await instance.post("/api/v1/checkpromotion", {
      userid: Username,
      System: Systems,
      Proid: proid,
    }, {
      headers: { Authorization: `Bearer ${token}` }
    }).then(async (res) => {
      if (res.data.status === 200) {
        console.log(209, res.data);
        if (res.data.type === 0) {
          this.setState({
            prodetail: res.data.message.tblpromotions[0],
          });
        }
        else if (res.data.type === 1) {
          this.setState({
            proderedeem: res.data.message.tblpromotionredeems,
            proderedeemlist: res.data.message.tblpromotionredeems,
          });
        }

      }
      else if (res.data.status === 201) {
        this.setState({
          prodetail: [],
          picnonpro: "/media/cache/strip/202109/promotion/7AB3F352-6DE2-44CC-A5FC-D4359FCB2122.png",
        });

      }
      else if (res.data.status === 202) {
        await Swal.fire({
          title: 'error!',
          text: 'ท่านใช้โปรโมชั่นค้างไว้ สามารถเติมเงินได้เลย',
          icon: 'error',
          confirmButtonText: 'ตกลง'
        })
        return false;
      }

    }).catch(async (err) => {
      // console.log(err);
      if (err.response.status === 401 || err.response.status === 403) {
        localStorage.clear();
        await Swal.fire({
          title: 'Warning!',
          text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
          icon: 'warning',
          confirmButtonText: 'ตกลง'
        })
        window.location.href = '/';
      }
    });
  }

  redeempro = async (e, proid) => {
    e.preventDefault();
    var token = localStorage.getItem('auth_token');
    var decoded = jwt_decode(token);
    var Username = decoded.message.playerid;
    // console.log(Username, proid);
    await instance.post("/api/v1/redeempromotion", {
      userid: Username,
      System: Systems,
      Proid: proid,
    }, {
      headers: { Authorization: `Bearer ${token}` }
    }).then(async (res) => {
      //  console.log(res.data.status)
      if (res.data.status === 200) {
        document.getElementById("opendeposit").click()
        // await Swal.fire({
        //   title: 'success!',
        //   text: 'm',
        //   icon: 'success',
        //   confirmButtonText: 'ตกลง'
        // })
      }
      else if (res.data.status === 201) {
        await Swal.fire({
          title: 'error!',
          text: 'ท่านไม่เข้าเงื่อนใขโปรโมชั่นนี้',
          icon: 'error',
          confirmButtonText: 'ตกลง'
        })
        e.preventDefault();
        return false;
      }
      else if (res.data.status === 204) {
        await Swal.fire({
          title: 'error!',
          text: 'ท่านใช้สิทธวันนี้ครบแล้ว',
          icon: 'error',
          confirmButtonText: 'ตกลง'
        })
        e.preventDefault();
        return false;
      }
      else if (res.data.status === 202) {
        await Swal.fire({
          title: 'error!',
          text: 'ท่านใช้โปรมั่นชั่นนี้ไปแล้ว',
          icon: 'error',
          confirmButtonText: 'ตกลง'
        })
        e.preventDefault();
        return false;
      }

    }).catch(async (err) => {
      // console.log(err);
      if (err.response.status === 401 || err.response.status === 403) {
        localStorage.clear();
        await Swal.fire({
          title: 'Warning!',
          text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
          icon: 'warning',
          confirmButtonText: 'ตกลง'
        })
        window.location.href = '/';
      }
    });
  }

  async recheccredit(evt) {

    evt.currentTarget.className += " fa-spin";
    var token = localStorage.getItem('auth_token');
    var decoded = jwt_decode(token);
    var Username = decoded.message.playerid;
    //this.rechecklogin(Username, token);
    this.setState({
      userid: decoded.message.playerid,
      username: decoded.message.playerid,
      firstname: decoded.message.firstname,
      lastname: decoded.message.lastname,
      loginstate: true,
    });
    try {
      // console.log(2);
      await instance.post("/api/v1/checkpoint", {
        userid: Username,
        System: Systems,
        Function: 'recheccredit-App.js'
      }, {
        headers: { Authorization: `Bearer ${token}` }
      }).then(async (res) => {
        if (res.data.status === 200) {
          localStorage.setItem('auth_token', res.data.token)
          this.setState({
            accnumber: res.data.message.accnumber,
            userid: res.data.message.member_id,
            username: res.data.message.playerid,
            fullname: res.data.message.firstname,
            point: res.data.message.Point,
            balance: res.data.databalance,
            loginstate: true,
          });
          var i, tablinks;
          tablinks = document.getElementsByClassName("btn btn-link -reload-btn p-0");
          for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace(" fa-spin", "");
          }
        }
        else if (res.data.status === 401) {
          //   console.log(res.data);
          localStorage.clear();
          await Swal.fire({
            title: 'Warning!',
            text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
            icon: 'warning',
            confirmButtonText: 'ตกลง'
          })
          window.location.href = '/';
        }

      }).catch(async (err) => {
        // console.log(err);
        if (err.response.status === 401 || err.response.status === 403) {
          localStorage.clear();
          await Swal.fire({
            title: 'Warning!',
            text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
            icon: 'warning',
            confirmButtonText: 'ตกลง'
          })
          window.location.href = '/';
        }
      });
    }
    catch (ex) {
      ////////console.log(ex);
    }
  }

  handleClickdeposit = async (e, price) => {
    this.setState({
      depositamount: price

    });
  }
  onChangexdep = event => {
    ////console.log(event.target.value);
    if (event.target !== undefined) {

      this.setState({ depositamount: event.target.value });
    }
  };

  onChangexgiftlink = event => {
    ////console.log(event.target.value);
    if (event.target !== undefined) {

      this.setState({ truegiftlink: event.target.value });
    }
  };

  checkpromotions = async (e, num) => {
    e.preventDefault();
  }


  confirmdeposittrue = async (e, num) => {
    e.preventDefault();

    var token = localStorage.getItem('auth_token');
    var decoded = jwt_decode(token);
    var Username = decoded.message.playerid;
    if (token != null && this.state.btnisActive === true) {
      if (this.state.truegiftlink !== "") {
        this.setState({ statetrue: "โปรดรอซักครุ่กำลังดำเนินการ", btnisActive: false });
        Username = decoded.message.playerid;
        await instance.post("/api/v1/redeemtrue", {
          userid: Username,
          System: Systems,
          link: this.state.truegiftlink
        }, {
          headers: { Authorization: `Bearer ${token}` }
        }).then(async (res) => {
          if (res.data.status == 200) {
            console.log(res.data.amountx);
            this.setState({ btnisActive: true, statetrue: "โปรดใส่ลิ้งค์ ของขวัญด้านล่าง ขั้นต่ำ 10 บาท" });
            await Swal.fire({
              title: 'Success!',
              text: "เติมเงินสำเร็จจำนวน " + res.data.amountx + "  บาท ",
              icon: 'success',
              confirmButtonText: 'ตกลง'
            })
          }
          else if (res.data.status == 202) {
            this.setState({ btnisActive: true, statetrue: "โปรดใส่ลิ้งค์ ของขวัญด้านล่าง ขั้นต่ำ 10 บาท" });
            await Swal.fire({
              title: 'Warning!',
              text: 'ลิ้งค์ของขวัญถูกใช้งานไปแล้วไม่สามารถไช้ได้',
              icon: 'warning',
              confirmButtonText: 'ตกลง'
            })
          }
          else if (res.data.status == 203) {
            this.setState({ btnisActive: true, statetrue: "โปรดใส่ลิ้งค์ ของขวัญด้านล่าง ขั้นต่ำ 10 บาท" });
            await Swal.fire({
              title: 'Warning!',
              text: 'ลิงค์เติมเงิน ไม่ใช่ชื่อบัญชีของคุณ โปรดตรวจสอบ',
              icon: 'warning',
              confirmButtonText: 'ตกลง'
            })
          }
          else if (res.data.status == 205) {
            this.setState({ btnisActive: true, statetrue: "โปรดใส่ลิ้งค์ ของขวัญด้านล่าง ขั้นต่ำ 10 บาท" });
            await Swal.fire({
              title: 'Warning!',
              text: 'ปิดระบบเติมเงินทรู รออัพเดท',
              icon: 'warning',
              confirmButtonText: 'ตกลง'
            })
          }
          else {
            this.setState({ btnisActive: true, statetrue: "โปรดใส่ลิ้งค์ ของขวัญด้านล่าง ขั้นต่ำ 10 บาท" });
            await Swal.fire({
              title: 'Warning!',
              text: 'ทำรายการไม่สำเร็จหรือลิงค์ไม่สามารถใช้ได้ ',
              icon: 'warning',
              confirmButtonText: 'ตกลง'
            })
          }
        });
      }
      else if (this.state.truegiftlink === "") {
        await Swal.fire({
          title: 'Warning!',
          text: 'คุณไม่ได้ไส่ลิ้งค์ของขวัญ ',
          icon: 'warning',
          confirmButtonText: 'ตกลง'
        })
      }
    }
    else {
      await Swal.fire({
        title: 'Warning!',
        text: 'กำลังดำเนินการโปรดรอสักครู่',
        icon: 'warning',
        confirmButtonText: 'ตกลง'
      })
    }
  }
  confirmdeposit = async (e, num) => {
    // console.log(493,num)
    e.preventDefault();
    var token = localStorage.getItem('auth_token');
    var decoded = jwt_decode(token);
    var Username = decoded.message.playerid;
    if (token != null) {
      await instance.post("/api/v1/getredeempromotion", {
        userid: Username,
        System: Systems,
      }, {
        headers: { Authorization: `Bearer ${token}` }
      }).then(async (res) => {
        if (res.data.status === 200) {
          this.setState({
            proderedeem: res.data.message.tblpromotionredeems,
          });

        }
      }).catch(async (err) => {
      });
    }

    if (num === 0) {
      if ((this.state.depositamount >= this.state.limit.min) && (this.state.depositamount <= this.state.limit.max)) {
        const { depositstate } = this.state
        //////console.log(depositstate, num);
        if (num === 0) {
          if (token != null) {
            Username = decoded.message.playerid;
            instance.post("/api/v1/getqrcode", {
              username: Username,
              System: Systems,
              amount: this.state.depositamount
            }, {
              headers: { Authorization: `Bearer ${token}` }
            }).then(async (res) => {
              //////console.log(res.data.picture)
              this.setState({
                mainacc: res.data.mainacc,
                qrcode: res.data.picture
              });
            });
          }

          this.setState({
            depositstate: 1
          });
        }
      }
      else {
        await Swal.fire({
          title: 'Warning!',
          text: 'เติมขั้นต่ำ ' + this.state.limit.min + ' บาท สูงสุด ' + this.state.limit.max + ' ',
          icon: 'warning',
          confirmButtonText: 'ตกลง'
        })
      }
    }
    else if (num === 1) {
      this.setState({
        depositstate: 1
      });
    }

    else if (num === 5) {
      document.getElementById("truemonney").click()
      //deposittruemoneyModal
    }
    else {
      this.setState({
        depositstate: 0
      });
    }



  }

  redeemrank = async (e, index) => {
    e.preventDefault();
    //console.log(index);

    var token = localStorage.getItem('auth_token');
    if (token != null) {
      var decoded = jwt_decode(token);
      var Username = decoded.message.playerid;
      Swal.fire({
        icon: 'warning',
        title: 'คุณต้องการเคลม Rank หรือ ไม่?',
        showCancelButton: true,
        confirmButtonText: 'Save',
      }).then(async (result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          await instance.post("/api/v1/userredeemrank", {
            User_id: Username,
            rankno: index.no,
            System: Systems,
            amount: index.claimamount
          }, {
            headers: { Authorization: `Bearer ${token}` }
          }).then(async (res) => {
            if (res.data.status === 200) {
              const datas = res.data.message;
              this.calllisthistory(Username);
              this.checklogin();
              Swal.fire('ดำเนินการเรียบร้อย', '', 'success')
            }
            else {
              Swal.fire('ไม่สามารถดำเนินการได้ โปรดรอสักครู่', '', 'error')
            }
          });

        }
      })
    }

  }
  async opencliamcashout(evt, cityName) {
    var token = localStorage.getItem('auth_token');
    var decoded = jwt_decode(token);
    var Username = decoded.message.playerid;
    await instance.post("/api/v1/cliamcashout", {
      userid: Username,
      System: Systems,
    }, {
      headers: { Authorization: `Bearer ${token}` }
    }).then(async (res) => {
      if (res.data.status === 200) {
        var decoded = jwt_decode(token);
        var Username = decoded.message.playerid;
        this.rechecklogin(Username, token);
        await Swal.fire({
          title: 'Success!',
          text: 'ทำรายการรับเงินเรียบร้อย',
          icon: 'success',
          confirmButtonText: 'ตกลง'
        })

      }
      else if (res.data.status === 201) {
        await Swal.fire({
          title: 'error!',
          text: 'ไม่สามารถทำรายการได้ ไม่มียอดเงิน',
          icon: 'error',
          confirmButtonText: 'ตกลง'
        })
        return false;
      }
      else if (res.data.status === 202) {
        await Swal.fire({
          title: 'error!',
          text: 'ไม่สามารถทำรายการได้',
          icon: 'error',
          confirmButtonText: 'ตกลง'
        })
        return false;
      }

    }).catch(async (err) => {
      // console.log(err);
      if (err.response.status === 401 || err.response.status === 403) {
        localStorage.clear();
        await Swal.fire({
          title: 'Warning!',
          text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
          icon: 'warning',
          confirmButtonText: 'ตกลง'
        })
        window.location.href = '/';
      }
    });
  }

  resetpassword = async (e, type) => {
    e.preventDefault();
    const { phoneNumber, fname, password, oldpassword, passwordcf, checkboxacc, accnumbertxt } = this.state;
    console.log(type, password, oldpassword, passwordcf);
    var token = localStorage.getItem('auth_token');
    if (password === passwordcf) {
      var Username = null;
      var decoded = null;
      if (type === 1 && token != null) {
        decoded = jwt_decode(token);
        Username = decoded.message.playerid;
        instance.post("/api/v1/resetpassword", {
          username: Username,
          oldpass: oldpassword,
          System: Systems,
          newpass: password,
          type: type
        }, {
          headers: { Authorization: `Bearer ${token}` }
        }).then(async (res) => {
          if (res.data.status === 200) {
            await Swal.fire({
              title: 'Success!',
              text: 'เปลี่ยนพาสเวิดเรียบร้อย',
              icon: 'Success',
              confirmButtonText: 'ตกลง'
            })
          }
          else if (res.data.status === 201) {
            await Swal.fire({
              title: 'error!',
              text: 'คุณใส่รหัสเก่าผิดพลาด',
              icon: 'error',
              confirmButtonText: 'ตกลง'
            })
          }
          else if (res.data.status === 203) {
            await Swal.fire({
              title: 'error!',
              text: 'มีข้อผิดพลาด โปรดทำรายการอีกครั้ง',
              icon: 'error',
              confirmButtonText: 'ตกลง'
            })
          }
          else if (res.data.status === 202) {
            await Swal.fire({
              title: 'error!',
              text: 'คุณใช้พาสเวิดเดิม',
              icon: 'error',
              confirmButtonText: 'ตกลง'
            })
          }
        });
      }
      else if (type === 2) {
        instance.post("/api/v1/resetpassword", {
          username: phoneNumber,
          oldpass: oldpassword,
          newpass: password,
          System: Systems,
          type: type
        }).then(async (res) => {
          if (res.data.status === 200) {
            await Swal.fire({
              title: 'success!',
              text: 'เปลี่ยนพาสเวิดเรียบร้อย',
              icon: 'success',
              confirmButtonText: 'ตกลง'
            })
          }
          else if (res.data.status === 201) {
            await Swal.fire({
              title: 'error!',
              text: 'คุณใส่รหัสเก่าผิดพลาด',
              icon: 'error',
              confirmButtonText: 'ตกลง'
            })
          }
          else if (res.data.status === 203) {
            await Swal.fire({
              title: 'error!',
              text: 'มีข้อผิดพลาด โปรดทำรายการอีกครั้ง',
              icon: 'error',
              confirmButtonText: 'ตกลง'
            })
          }
          else if (res.data.status === 202) {
            await Swal.fire({
              title: 'error!',
              text: 'คุณใช้พาสเวิดเดิม',
              icon: 'error',
              confirmButtonText: 'ตกลง'
            })
          }
        });
      }
      else if (type === 3) {
        instance.post("/api/v1/resetpassword", {
          username: phoneNumber,
          oldpass: oldpassword,
          newpass: password,
          System: Systems,
          type: type
        }).then(async (res) => {
          if (res.data.status === 200) {
            await Swal.fire({
              title: 'success!',
              text: 'เปลี่ยนพาสเวิดเรียบร้อย',
              icon: 'success',
              confirmButtonText: 'ตกลง'
            })
          }
          else if (res.data.status === 201) {
            await Swal.fire({
              title: 'error!',
              text: 'คุณใส่รหัสเก่าผิดพลาด',
              icon: 'error',
              confirmButtonText: 'ตกลง'
            })
          }
          else if (res.data.status === 203) {
            await Swal.fire({
              title: 'error!',
              text: 'มีข้อผิดพลาด โปรดทำรายการอีกครั้ง',
              icon: 'error',
              confirmButtonText: 'ตกลง'
            })
          }
          else if (res.data.status === 202) {
            await Swal.fire({
              title: 'error!',
              text: 'คุณใช้พาสเวิดเดิม',
              icon: 'error',
              confirmButtonText: 'ตกลง'
            })
          }
        });
      }
    }
    else {
      await Swal.fire({
        title: 'error!',
        text: 'คุณใส่พาสเวิดไม่ตรงกัน',
        icon: 'error',
        confirmButtonText: 'ตกลง'
      })
    }
  }


  nextstep = async (e, num) => {
    e.preventDefault();
    const { stateregis, phoneNumber, checkboxacc, fname, otpphone, accnumbertxt } = this.state

    console.log(num);
    if (num === 1) {
      const check = await this.forgetotpandregister(8);
      console.log(1, phoneNumber.length, check.checked);
      if (phoneNumber.length === 10 && check.checked) {
        this.setState({
          stateregis: 2
        });
      }
      else {
        if (phoneNumber.length !== 10) {
          this.setState({
            stateopterror: "คุณใส่เบอร์ผิดพลาด"
          });
        }
        else if (!check.checked && check.status === 201) {
          this.setState({
            stateopterror: "เบอร์นี้มีคนใช้แล้ว"
          });
        }
        else {
          this.setState({
            stateopterror: "คุณทำรายการถี่เกินไป โปรดรอสักครู่"
          });
        }

        document.getElementById("phoneNumber").style = "border-color: #ef4444;";
        document.getElementById("checkotpforgetphonenormal").style.display = "block";
      }
    }
    else if (num === 2) {

      if (checkboxacc !== 0 && fname !== "" && accnumbertxt !== "") {
        this.setState({
          stateregis: 3
        });
      }
      else {
        await Swal.fire({
          title: 'Warning!',
          text: 'กรุณาเลือกธนาคาร หรือใส่ข้อมูลให้ครบถ้วน !!',
          icon: 'warning',
          confirmButtonText: 'ตกลง'
        })
        //////console.log(2, stateregis, checkboxacc);
      }
    }
    else if (num === 3) {
      //////console.log(1, stateregis, num);
      this.setState({
        stateregis: 4
      });
    }
    else if (num === 5) {
      var check = await this.forgetotpandregister(7);
      if (otpphone !== "" && check.checked) {
        this.setState({
          stateregis: 2
        });
      }
      else if (otpphone === "" || otpphone.length < 4) {
        this.setState({
          stateopterror: "คุณใส่ตัวเลขไม่ครบ"
        });
        document.getElementById("otpphone").style = "border-color: #ef4444;";
        document.getElementById("checkotp").style.display = "block";
      }

      else {
        this.setState({
          stateopterror: "รหัสผิดพลาด"
        });
        document.getElementById("otpphone").style = "border-color: #ef4444;";
        document.getElementById("checkotp").style.display = "block";
      }



    }
    else if (num === 11) {
      //////console.log(1, stateregis, num);
      this.setState({
        stateregis: 1
      });
    }
    else if (num === 22) {
      //////console.log(1, stateregis, num);
      this.setState({
        stateregis: 2
      });
    }
  }

  forgetotpandregister = async (type) => {
    const { phoneNumber, otpphoneforget, otpphone } = this.state;
    var recheck = false;
    if (type === 4) {
      await instance.post("/api/v1/resetpassword", {
        username: phoneNumber,
        System: Systems,
        type: type
      }).then(async (res) => {
        recheck = res.data.message;
      });
    }
    else if (type === 5) {
      await instance.post("/api/v1/resetpassword", {
        username: phoneNumber,
        pin: otpphoneforget,
        System: Systems,
        type: type
      }).then(async (res) => {
        recheck = res.data.message;

      });
    }
    else if (type === 6) {
      await instance.post("/api/v1/resetpassword", {
        username: phoneNumber,
        System: Systems,
        type: type
      }).then(async (res) => {
        recheck = res.data.message;
      });
    }
    else if (type === 7) {
      await instance.post("/api/v1/resetpassword", {
        username: phoneNumber,
        pin: otpphone,
        System: Systems,
        type: type
      }).then(async (res) => {
        recheck = res.data.message;

      });
    }
    return recheck
  }

  test = async () => {
    await Swal.fire({
      title: 'Warning!',
      text: 'ยอดถอนของท่านไม่เพียงพอในการถอน',
      icon: 'warning',
      confirmButtonText: 'ตกลง'
    })
  }

  nextstepforget = async (e, num) => {
    e.preventDefault();
    console.log(num);
    const { phoneNumber, otpphoneforget } = this.state
    if (num === 1) {
      const check = await this.forgetotpandregister(4);
      if (phoneNumber.length === 10 && check.checked) {
        this.setState({
          stateforget: 2
        });
      }
      else {
        if (phoneNumber.length !== 10) {
          this.setState({
            stateopterror: "คุณใส่เบอร์ผิดพลาด"
          });
        }
        else if (!check.checked && check.status === 201) {
          this.setState({
            stateopterror: "ไม่มีเบอร์นี้ในระบบ"
          });
        }
        else {
          this.setState({
            stateopterror: "คุณทำรายการถี่เกินไป โปรดรอสักครู่"
          });
        }
        document.getElementById("phoneNumberforget").style = "border-color: #ef4444;";
        document.getElementById("checkotpforgetphone").style.display = "block";
      }
    }
    else if (num === 2) {
      const check = await this.forgetotpandregister(5)
      console.log(check)
      if (otpphoneforget !== "" && check.checked) {
        this.setState({
          stateforget: 3
        });
      }
      else if (otpphoneforget === "" || otpphoneforget.length < 4) {
        this.setState({
          stateopterror: "คุณใส่ตัวเลขไม่ครบ"
        });
        document.getElementById("otpphoneforget").style = "border-color: #ef4444;";
        document.getElementById("checkotpforget").style.display = "block";
      }
      else {
        this.setState({
          stateopterror: "รหัสผิดพลาด"
        });
        document.getElementById("otpphoneforget").style = "border-color: #ef4444;";
        document.getElementById("checkotpforget").style.display = "block";
      }

    }
    else if (num === 11) {
      this.setState({
        stateforget: 1
      });
    }
  }

  submitwithdraw = async (e) => {
    //////console.log(this.state.withdrawamount, this.state.balance);
    if (parseFloat(this.state.withdrawamount) <= parseFloat(this.state.balance)) {
      if (parseFloat(this.state.withdrawamount) >= 100) {
        var token = localStorage.getItem('auth_token');
        if (token != null) {
          var decoded = jwt_decode(token);
          var Username = decoded.message.playerid;
          instance.post("/api/v1/userwithdraw", {
            User_id: Username,
            System: Systems,
            amount: this.state.withdrawamount
          }, {
            headers: { Authorization: `Bearer ${token}` }
          }).then(async (res) => {
            if (res.data.status === 200) {
              await Swal.fire({
                title: 'Success!',
                text: 'รายการถอนเรียบร้อย',
                icon: 'success',
                confirmButtonText: 'ตกลง'
              })
              window.location.reload(false);
            }
            else if (res.data.status === 206) {
              await Swal.fire({
                title: 'Success!',
                text: 'ทำรายการสำเร็จ คุณสามารถถอนได้ไม่เกิน ' + res.data.amountturnover + " บาทเนื่องจากคุณรับโบนัส เครดิตส่วนที่เหลือเราจะทำการตัดออก",
                icon: 'success',
                confirmButtonText: 'ตกลง'
              })
            }
            else if (res.data.status === 203) {
              await Swal.fire({
                title: 'Error!',
                text: 'ท่านติด Turnover อีก ' + res.data.amountturnover + " บาท",
                icon: 'error',
                confirmButtonText: 'ตกลง'
              })
            }
          });
        }
        else {
          await Swal.fire({
            title: 'error!',
            text: 'รายการถอนมีปัญหา โปรดติดต่อพนักงาน',
            icon: 'error',
            confirmButtonText: 'ตกลง'
          })
        }
      }
      else {
        await Swal.fire({
          title: 'Warning!',
          text: 'ขั้นต่ำในการถอน 100 บาท',
          icon: 'warning',
          confirmButtonText: 'ตกลง'
        })
      }
    }
    else {
      await Swal.fire({
        title: 'Warning!',
        text: 'ยอดถอนของท่านไม่เพียงพอในการถอน',
        icon: 'warning',
        confirmButtonText: 'ตกลง'
      })
    }

  }

  onChangex = event => {
    // console.log("event", event.target.name);


    if (event.target !== undefined) {
      let nam;
      let val;
      if (event.target.name === "otpphone") {
        document.getElementById("otpphone").style = "";
        document.getElementById("checkotp").style.display = "none";
      }
      else if (event.target.name === "phoneNumber") {
        document.getElementById("phoneNumberforget").style = "";
        document.getElementById("checkotpforgetphone").style.display = "none";
      }
      else if (event.target.name === "otpphoneforget") {
        document.getElementById("otpphoneforget").style = "";
        document.getElementById("checkotpforget").style.display = "none";
      }
      else if (event.target.name === "otpphoneforget") {
        document.getElementById("phoneNumber").style = "";
        document.getElementById("checkotpforgetphonenormal").style.display = "none";
      }

      if (event.target.name === undefined) {
        nam = event.name;
        val = event.value;
      } else {
        nam = event.target.name;
        val = event.target.value;
      }
      this.setState({ [nam]: val });
    }
  };

  logout = (e) => {
    ////////console.log(e);
    // window.location.href = '/logout';

  }

  openchangepassword = (e) => {
    e.preventDefault();
    const { changepassword } = this.state
    ////////console.log(changepassword);
    if (changepassword)
      this.setState({
        changepassword: false
      });
    else
      this.setState({
        changepassword: true
      });

  }

  cancelwithdraw = async (e, index) => {
    e.preventDefault();
    var token = localStorage.getItem('auth_token');
    if (token != null) {
      var decoded = jwt_decode(token);
      var Username = decoded.message.playerid;
      Swal.fire({
        icon: 'warning',
        title: 'คุณต้องการยกเลิกการถอนเงินใช่ หรือ ไม่?',
        showCancelButton: true,
        confirmButtonText: 'Save',
      }).then(async (result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          await instance.post("/api/v1/userwithdrawcancel", {
            User_id: Username,
            tid: index.tid,
            System: Systems,
            amount: index.amount
          }, {
            headers: { Authorization: `Bearer ${token}` }
          }).then(async (res) => {
            if (res.data.status === 200) {
              const datas = res.data.message;
              this.calllisthistory(Username);
              Swal.fire('ดำเนินการเรียบร้อย', '', 'success')
            }
            else {
              Swal.fire('ไม่สามารถดำเนินการได้ โปรดรอสักครู่', '', 'error')
            }
          });

        }
      })
    }

  }

  cancelredeempromotion = async (e, index) => {
    e.preventDefault();
    var token = localStorage.getItem('auth_token');
    if (token != null) {
      var decoded = jwt_decode(token);
      var Username = decoded.message.playerid;
      Swal.fire({
        icon: 'warning',
        title: 'คุณต้องการยกเลิกการใช้โปรใช่ หรือ ไม่?',
        showCancelButton: true,
        confirmButtonText: 'Save',
      }).then(async (result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          await instance.post("/api/v1/usercancelpromotion", {
            User_id: Username,
            no: index.no,
            System: Systems,
          }, {
            headers: { Authorization: `Bearer ${token}` }
          }).then(async (res) => {
            if (res.data.status === 200) {
              this.checkpro(null, 999);
              Swal.fire('ดำเนินการเรียบร้อย', '', 'success')
            }
            else {
              Swal.fire('ไม่สามารถดำเนินการได้ โปรดรอสักครู่', '', 'error')
            }
          });

        }
      })
    }

  }
  couponredeem = async (e, index) => {
    e.preventDefault();
    var token = localStorage.getItem('auth_token');
    if (token != null) {
      var decoded = jwt_decode(token);
      var Username = decoded.message.playerid;
      await instance.post("/api/v1/usercoupon", {
        User_id: Username,
        System: Systems,
        couponkey: this.state.couponkey,
        type: 1
      }).then(async (res) => {
        if (res.data.status === 200) {
          const datas = res.data.message;
          await Swal.fire({
            title: 'Success!',
            text: 'ดำเนินการใช้คูปองเรียบร้อย',
            icon: 'success',
            confirmButtonText: 'ตกลง'
          })
        }
        else {
          await Swal.fire({
            title: 'Warning!',
            text: 'ไม่มี Key นี้ในระบบ',
            icon: 'warning',
            confirmButtonText: 'ตกลง'
          })
        }
      });
    }

  }

  async calllisthistory(userid) {
    await instance.post("/api/v1/userwithdrawlist", {
      userid: userid,
      Cat_ID: 0,
      System: Systems,
      page: 1,
      type: 1
    }).then((res) => {
      if (res.data.status === 200) {
        const datas = res.data.message;
        //  //console.log(datas.withdrawlist);
        this.setState({
          historylist: datas.withdrawlist
        });

      }
      else {
        // this.CheckThisGoalError();
      }
    });

  }

  // async saveclick(linkid) {
  //   await instance.post("/api/v1/saveclick", {
  //     linkid: linkid,
  //     System: Systems,
  //   }).then((res) => {
  //   });

  // }
  async callgetthemesv(Systems) {
    await instance.post("/api/v1/getthemesv", {
      System: Systems,
    }).then((res) => {
      if (res.data.status === 200) {
        document.body.setAttribute("data-theme", res.data.ThmeseV)
        this.setState({
          ThmeseV: res.data.ThmeseV
        });

      }
      else {
        // this.CheckThisGoalError();
      }
    });

  }
  async saveclickaff(linkid) {
    await instance.post("/api/v1/saveclickaff", {
      linkid: linkid,
      System: Systems,
    }).then((res) => {
      if (res.data.status == 200) {
        var date = new Date()
        this.setState({
          userrecomend: res.data.username,
          link: linkid
        });
        var username = res.data.username
        var object = { date, username }
        localStorage.setItem('aff', JSON.stringify(object))
      }
    }).catch(async (err) => {
    });

  }
  async componentDidMount() {
    // await this.callgetthemesv(Systems);
    this.setState({
      pathname: window.location.pathname.replace("/", "").trim()
    });
    //  const endpoint = "https://betner.co:9000";
    const endpoint = "https://betner.co:9000";
    const socket = io.connect(endpoint);
    var roomid = Systems;
    var obj = { roomid }
    var json = JSON.stringify(obj);
    socket.on('connect', function (data) {
      var obj = { roomid }
      var json = JSON.stringify(obj);
      socket.emit('new', json);
    });
    // const space = window.location.host
    // //console.log(space);
    // const search = window.location.href;
    // var test = search.split("aff=")[1]
    // //console.log(test.split("&")[0]);
    // if (test != undefined) {
    //   this.saveclickaff(test.split("&")[0]);
    // }

    // var params = search.split("reg=")[1]
    // //  console.log(params);
    // if (params != undefined) {
    //   this.saveclick(params);
    //   this.setState({
    //     userrecomend: params.split("&")[0],
    //   });
    // }


    // var token = localStorage.getItem('register');
    // if (token != null) {

    //   var codev = JSON.parse(token)
    //   console.log(1359, codev);
    //   var Username = codev.username;

    //   this.setState({
    //     userrecomend: Username,
    //   });
    // }


    // var token = localStorage.getItem('aff');
    // if (token != null) {
    //   var codev = JSON.parse(token)
    //   console.log(1370, codev);
    //   var Username = codev.username;
    //   this.setState({
    //     userrecomend: Username,
    //   });
    // }


    try {

      // await instance.post("/api/v1/mainsetting", {
      //   System: Systems,
      // }).then((res) => {
      //   if (res.data.status === 200) {


      //     const datas = res.data.message;
      //     // console.log( datas[7].value)
      //     var min = res.data.tblcatagoryx[0].limitdeposit;
      //     var max = res.data.tblcatagoryx[0].maxdeposit;
      //     var maxwithdrawstatus = res.data.tblcatagoryx[0].withdrawmax
      //     var limit = { min, max }
      //     this.setState({
      //       maxwithdrawstatus: maxwithdrawstatus,
      //       linelink: datas[1].value,
      //       webnamex: datas[7].value,
      //       System: res.data.tblcatagoryx[0].System,
      //       cashoutSet: res.data.tblcashoutsetting[0],
      //       inviteSet: res.data.tblinvitedsetting[0],
      //       datas: datas,
      //       limit: limit,
      //     });
      //   }
      //   else {
      //     // this.CheckThisGoalError();
      //   }
      // });
    }
    catch (ex) {
      console.log(ex);
    }

    //await this.checklogin();
    // if (!this.state.checkboxcookie) {
    //   setTimeout(function () {
    //     document.getElementById("openpopup").click()
    //   }, 500);
    // }

    //await this.getwheel();    
    //await this.getcheckin();
    // await this.getpopup();
  }

  onChange = async event => {

    if (event.target !== undefined) {
      let nam;
      let val;
      if (event.target.name === undefined) {
        nam = event.name;
        val = event.value;
      } else {
        nam = event.target.name;
        val = event.target.value;
      }
      if (nam === "withdrawamount") {

        if (this.state.maxwithdrawstatus === 1) {

          this.setState({ [nam]: this.state.balance });
          await Swal.fire({
            title: 'Warning!',
            text: 'บังคับถอนยอดทั้งหมด',
            icon: 'warning',
            confirmButtonText: 'ตกลง'
          })
        }
        else if (Number(val) <= Number(this.state.balance)) {
          this.setState({ [nam]: val });
        }
        else {
          await Swal.fire({
            title: 'Warning!',
            text: 'ยอดถอนของท่านไม่เพียงพอในการถอน',
            icon: 'warning',
            confirmButtonText: 'ตกลง'
          })
        }
      } else {
        this.setState({ [nam]: val });
      }

    }
  };

  async checkuseradvice() {
    instance.post("/api/v1/checkuseradvice", {
      User_id: this.state.username,
      page: 1,
      System: Systems,
    }).then((res) => {
      if (res.data.status === 200) {
        console.log(res.data.message.useradvicelist);
        this.setState({
          useradvice: res.data.message.useradvicelist
        });
      }
      else {
        // console.log(res.data);
        // this.CheckThisGoalError();
      }
    });
  }

  closemodal = async (e) => {

    console.log(1159, this.state.checkboxcookie);
    if (this.state.checkboxcookie) {
      var date = new Date()
      var checked = this.state.checkboxcookie
      var object = { date, checked }
      console.log(object);
      localStorage.setItem('cookie', JSON.stringify(object))
    }
  }

  async recheckbox() {
    var checked = false;
    if (this.state.checkboxcookie) checked = false;
    else checked = true;
    this.setState({
      checkboxcookie: checked,
    });

  }

  async checklogin() {

    var token = localStorage.getItem('auth_token');
    //console.log(token);
    if (token != null) {
      var cookie = localStorage.getItem('cookie');
      console.log(1188, cookie);
      if (cookie != 'null' && cookie != null) {
        var persons = JSON.parse(cookie);
        if (persons.checked != null) {
          this.setState({

            checkboxcookie: persons.checked,
          });
        }
      }

      var decoded = jwt_decode(token);
      var Username = decoded.message.playerid;
      // console.log(33);
      await this.rechecklogin(Username, token);
    }
    else {
      this.setState({
        loginstate: false,
      });
    }
  }

  redeem = (e) => {
    e.preventDefault();
    //this.checklogin();
    console.log('tag', this.state.loginstate);
    if (!this.state.loginstate) {
      var title = 'error'
      var text = ' คุณไม่ได้ทำการ Login'
      var object = { title, text }
      this.modalerror(object);
    }
    else {
      window.location.href = '/promotions';
    }
  }

  redeemlink = (e, link) => {
    e.preventDefault();
    //this.checklogin();
    console.log('tag', this.state.loginstate);
    if (!this.state.loginstate) {
      var title = 'error'
      var text = ' คุณไม่ได้ทำการ Login'
      var object = { title, text }
      this.modalerror(object);
    }
    else {
      window.location.href = link;
    }
  }


  async modalerror(object) {
    await Swal.fire({
      title: object.title + "!",
      text: object.text,
      icon: object.title,
      confirmButtonText: 'ตกลง'
    })
  }
  validatergisterForm = async (e) => {
    e.preventDefault();
    const { phoneNumber, fname, password, passwordcf, checkboxacc, accnumbertxt } = this.state;

    //////console.log(checkboxacc);
    //////////console.log(this.state)
    if (password.length < 6) {
      ////////console.log(password);
      var title = 'Error!'
      var text = 'Password คุณไม่ครบ 6 ตัวโปรดตรวจสอบ'
      var object = { title, text }
      this.modalerror(object);
      // this.CheckThisGoalError(object);
    }

    else if (phoneNumber === "") {

      title = 'Error!'
      text = 'คุณไม่ทำการใส่ เบอร์โทรศัพท์'
      object = { title, text }
      this.modalerror(object);

    }
    else if (accnumbertxt === "") {
      title = 'Error!'
      text = 'คุณไม่ทำการใส่เลขบัญชีธนาคาร'
      object = { title, text }
      this.modalerror(object);

    }
    else if (fname === "") {

      title = 'Error!'
      text = 'คุณไม่ทำการใส่ ชื่อ-นามสกุล'
      object = { title, text }
      this.modalerror(object);

    }
    else if (checkboxacc === 0) {
      title = 'Error!'
      text = 'คุณยังไม่ได้เลือกธนาคาร'
      object = { title, text }
      this.modalerror(object);
    }
    else if (password !== passwordcf) {
      title = 'Error!'
      text = 'คุณไส่พาสเวิดไม่ตรงกัน'
      object = { title, text }
      this.modalerror(object);
    }

    else {
      instance.post("/api/v1/register", {
        User_id: phoneNumber,
        name: fname,
        password: password,
        accbank: checkboxacc,
        accno: accnumbertxt,
        System: Systems,
        Useradvise: this.state.userrecomend
      })
        .then(async (res) => {
          if (res.data.status === 200) {
            await Swal.fire({
              title: 'Success!',
              text: 'ทำการสมัครเรียบร้อยโปรด Log in อีกครั้ง',
              icon: 'success',
              confirmButtonText: 'ตกลง'
            })
            window.location.href = '/';
          }
          else if (res.data.status === 202) {
            this.setState({
              errorstatus: 1,
              errortext: 'Username นี้ทำการสมัครไปแล้ว'
            });
          }
          else {
            this.setState({
              errorstatus: 1,
              errortext: 'Username หรือ ชื่อในการสมัครไม่ถูกต้อง'
            });
          }
        });
    }


  }

  rechecklogin = async (Username, token) => {
    try {

      //const { webnamex } = this.state
      console.log(1406, Username);
      //console.log(3);
      const search = window.location.href;
      const paramx = new URLSearchParams(search);
      // const space = search.split("/")
      const space = window.location.host
      var userhash = md5(Username);
      //  const linkaff = "https://pgline88.com/?register=" + userhash;
      const linkaff = "https://" + space + "/register?reg=" + userhash;
      await instance.post("/api/v1/checkpoint", {
        userid: Username,
        System: Systems,
        Update: userhash,
        Function: 'rechecklogin-App.js'
      }, {
        headers: { Authorization: `Bearer ${token}` }
      }).then(async (res) => {
        console.log(1751, res.data);
        if (res.data.status === 200) {


          this.calllisthistory(Username);
          console.log(443, res.data);
          localStorage.setItem('auth_token', res.data.token)
          var rankall = res.data.tblrankall;
          const found = rankall.find(obj => {
            return obj.checked === 1;
          });

          this.setState({
            tblrankall: res.data.tblrankall,
            linkaff: linkaff,
            buttoncliam: (found === undefined) ? true : false,
            tblnextrank: res.data.tblnextrank,
            rankpic: (res.data.tblrank.image == null || res.data.tblrank.image == "") ? "https://betner.co/build/rank/1.jpeg" : (res.data.tblrank.picrack == null || res.data.tblrank.picrack == "") ? "https://betner.co/build/rank/" + res.data.tblrank.image : res.data.tblrank.picrack,
            rankname: res.data.tblrank.name,
            rankob: res.data.tblrank,
            tblprofile: res.data.message,
            rankpoint: res.data.rankpoint,
            accnumber: res.data.message.accnumber,
            userid: res.data.message.member_id,
            username: res.data.message.playerid,
            fullname: res.data.message.firstname,
            point: res.data.message.Point,
            balance: res.data.databalance,
            loginstate: true,
          });
        }
        else if (res.data.status === 401) {
          localStorage.clear();
          await Swal.fire({
            title: 'Warning!',
            text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
            icon: 'warning',
            confirmButtonText: 'ตกลง'
          })
          window.location.href = '/';
        }

      }).catch(async (err) => {
        // console.log(err);
        if (err.response.status === 401 || err.response.status === 403) {
          localStorage.clear();
          await Swal.fire({
            title: 'Warning!',
            text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
            icon: 'warning',
            confirmButtonText: 'ตกลง'
          })
          window.location.href = '/';
        }
      });
    }
    catch (ex) {
      ////////console.log(ex);
    }

  }


  render() {
    //////////console.log(this.state)this.state.userrecomend
    const pathname = this.state.pathname
    const { loginstate, cashoutSet, stateopterror, changepassword, username, balance, accnumber, fullname, mainacc, limit, rankob, rankpoint, tblnextrank, userrecomend, linkaff, tblprofile, proderedeem, proderedeemlist, prodetail } = this.state

    let stype = false;
    if (pathname !== "event" || pathname !== "loss" || pathname !== "promotion" || pathname !== "affiliate" || pathname !== "deposit" || pathname !== "withdraw" || pathname == "signin" || pathname !== "register") {
      stype = true
    }
    var accnumberx;
    var limits = limit
    if (accnumber != null) {
      accnumberx = accnumber[0];
    }

    return (
      < >



        <BrowserRouter >
          <Routes>
            <Route path="/*" element={<Main />} />
            <Route path="/logout" element={<Logout />} />
            {/* <Route path="/slot" element={<Slot />} />
              <Route path="/sport" element={<Sport />} /> */}
            {/* <Route path="/loss" element={<Loss />} /> */}
            {/* <Route path="/deposit" element={<Deposit />} /> */}
            {/* <Route path="/signin" element={<Signin />} /> */}
            {/* <Route path="/profile" element={<Profile />} /> */}
            {/* <Route path="/arcade" element={<Arcade />} /> */}
            {/* <Route path="/event" element={<Event />} /> */}
            {/* <Route path="/statement" element={<Statement />} /> */}
            {/* <Route path="/fishing" element={<Fising />} /> */}
            {/* <Route path="/withdraw" element={<Withdraw />} /> */}
            {/* <Route path="/promotion" element={<Promotion />} /> */}
            {/* <Route path="/freegame" element={<Freegame />} /> */}
            {/* <Route path="/listgameall/*" element={<Linklistall />} /> */}
            {/* <Route path="/listgamealldemo/*" element={<Linklistalldemo />} />
              <Route path="/logout" element={<Logout />} /> */}
            {/* <Route path="/affiliate/overview" element={<Affiliate />} /> */}
            {/* <Route path="/register/*" element={<Register />} />
              <Route path='/Paoyingchub' element={<Promotion />} /> */}
          </Routes>
        </BrowserRouter>
        <ToastContainer />
      </>
    );
  }
}

export default App;
