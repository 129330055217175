import React, { Component, createRef } from 'react'; //different
import './App.css';
import jwt_decode from "jwt-decode";
import Swal from 'sweetalert2'
import instance from "./axios-instance";
import Systems from "./SystemFunction";
import Banner from "./banner";
import Luckywhell from "./luckyfortune";
import Popup from "./popup";
import moment from 'moment';
import md5 from 'md5';
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import NProgress from "nprogress";
import axios from 'axios';

let isMobile = false;
if (window.innerWidth <= 760) isMobile = true;
class Main extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ios: true,
            finish: 0,
            popupprofile: false,
            webname: "",
            categorylist: [],
            tabprofile: 1,
            isPopupOpen: false,
            selectedItem: null, // Store the selected item
            luckywheeldisplay: false,
            listitallgame: [],
            slotlist: [],
            sportlist: [],
            logoimg: '',
            describemain: "",
            fishinglist: [],
            gamehitlist: [],
            files: [],
            providorlist: [],
            filterlist: [],
            stats: {},
            reports: {},
            qrstep: 1,
            listacc: [],
            addhome: true,
            whellsetting: [],
            pglist: [],
            tabselectdeposit: 0,
            rewardlist: [],
            loader: false,
            slotlist: [],
            recentlist: [],
            activePage: 1,
            ThmeseV: null,
            pagecount: 1,
            trueaccount: true,
            products_page_count: 1,
            isActive: false,
            activeTab: 1,
            resetpass: 0,
            paymentlist: null,
            checkcredit: false,
            refer: 1,
            showmodal: false,
            historylist: [],
            formData: {}, // Contains login form data
            errors: {}, // Contains login field errors
            formSubmitted: false, // Indicates submit status of login form
            loading: false, // Indicates in progress state of login form
            tblprofile: [],
            userid: null,
            afficiate: [],
            cashoutSet: [],
            modal: false,
            modalpromotion: false,
            linkaff: "",
            point: 0,
            maxwithdrawstatus: 0,
            useradvice: [],
            afficiatetoday: [],
            afficiateall: [],
            balance: 0,
            counter: 300,
            partner: null,
            qrcodecheck: false,
            loginstate: false,
            firstname: null,
            lastname: null,
            activeTab: 1,
            alert: null,
            modalOpen: false,
            handleClose: false,
            proderedeemlist: [],
            token: window.localStorage.getItem("token"),
            type: "",
            prolistx: [],
            prodetail: [],
            username: "",
            tabselect: null,
            showpass: false,
            amount: "",
            remark: "",

            rankpic: "1.jpeg",
            rankname: null,
            rankob: null,
            rankpoint: 0,
            limit: null,
            name: "",
            password: "",
            oldpassword: "",
            passwordcf: "",
            left: false,
            pathname: "",
            linelink: "",
            mainsetting: [],
            rewardlist: [],
            activePage: 1,
            checkboxcookie: false,
            isActive: false,
            formData: {}, // Contains login form data
            errors: {}, // Contains login field errors
            formSubmitted: false, // Indicates submit status of login form
            pagecount: 1,
            username: null,
            picture: null,
            settingwheel: "",
            settingcheckin: "",
            userid: null,
            phoneNumber: "",
            mainacc: null,
            paymentgateway: null,
            tabselectsec: 0,
            checkcashout: false,
            stateregis: 1,
            checkboxacc: 0,
            products_page_count: 1,
            fullname: null,
            lname: null,
            link: "",
            alert: null,
            depositstate: 0,
            stateforget: 1,
            depositamount: 0,
            stateopterror: "",
            btnisActive: true,
            otpphone: "",
            token: window.localStorage.getItem("token"),
            accnumber: null,
            accnumbertxt: null,
            withdrawamount: 0,
            rankpoint: 0,
            changepassword: false,
            linelink: "",
            errortext: "",
            datas: null,
            otpphoneforget: "",
            affpercent: 0,
            counter: 300,
            userrecomend: "",
            historylist: [],
            useradvice: [],
            currentIndexA: 0,
            currentIndex: 0,
            cashoutlist: [],
            tblnextrank: [],
            tblrankall: [],
            maxwithdrawstatus: 0,
            tblprofile: [],
            prodetail: [],
            oldpassword: "",
            linkaff: "",
            webnamex: "",
            inviteSet: "",
            truegiftlink: "",
            proderedeem: [],
            qrcode: null,
            proderedeemlist: [],
            buttoncliam: false,
            limit: null,
            rankpic: "1.jpeg",
            rankname: null,
            rankob: null,
            cashoutSet: [],
            tblPopupMenu1: [],
            tblPopupMenu2: [],
            tblPopupMenu: null,
            termAndCondition: false,
            errorstatus: false,
            modalx: false,
            hours: 0,
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
            minutes: 0,
            seconds: 0
        }
        this.androidSlides = [
            {
                src: "https://d12lxcfs2l5om8.cloudfront.net/new_webimages/manager/download/android/download_1.png",
                alt: "กดปุ่มติดตั้ง",
                text: "กดปุ่มติดตั้งตามภาพด้านบน",
            },
            {
                src: "https://d12lxcfs2l5om8.cloudfront.net/new_webimages/manager/download/android/download_2.png",
                alt: "เลือกติดตั้ง",
                text: "เลือก 'ติดตั้ง'",
            },
            {
                src: "https://d12lxcfs2l5om8.cloudfront.net/new_webimages/manager/download/android/download_3.png",
                alt: "ตรวจสอบหน้าโฮม",
                text: "ตรวจสอบหน้าโฮม ว่ามีไอคอนขึ้นแล้ว",
            },
        ];
        this.iosSlides = [
            {
                src: "https://d12lxcfs2l5om8.cloudfront.net/new_webimages/manager/download/ios/download_1.png",
                alt: "กดปุ่มด้านล่าง",
                text: "กดปุ่มด้านล่างตามภาพ\nเพื่อเพิ่มแอปพลิเคชั่น",
            },
            {
                src: "https://d12lxcfs2l5om8.cloudfront.net/new_webimages/manager/download/ios/download_2.png",
                alt: "เลือก Add to Home Screen",
                text: "เลือก Add to Home Screen / เพิ่มลงไปยังหน้าจอโฮม",
            },
            {
                src: "https://d12lxcfs2l5om8.cloudfront.net/new_webimages/manager/download/ios/download_3.png",
                alt: "เพิ่มทางลัด",
                text: "กด 'เพิ่ม' ทางลัด\nเข้าสู่เกมส์ลงในหน้าโฮม",
            },
            {
                src: "https://d12lxcfs2l5om8.cloudfront.net/new_webimages/manager/download/ios/download_4.png",
                alt: "ตรวจสอบหน้าโฮม",
                text: "ตรวจสอบหน้าโฮม ว่ามีไอคอนขึ้นแล้ว",
            },
        ];
        this.timer = null; // เก็บตัวนับเวลา
        this.popupRef = createRef();
    }
    handleClickOutside = (event) => {
        const isOutside =
            !event.target.closest('[role="dialog"]'); // ตรวจสอบว่า target อยู่นอก role="presentation"
        if (isOutside) {

            this.setState({
                popupprofile: false,
                modal: false,
            });
            this.closeModal();
        }
    };
    handleChange = (event) => {
        console.log(event.target.value);
        const { name, value } = event.target;
        this.setState({
            [name]: value,
        });
    };
    nextSlideA = () => {
        this.setState((prevState) => ({
            currentIndexA: (prevState.currentIndexA + 1) % this.androidSlides.length,
        }));
    };

    // ฟังก์ชันไปยังสไลด์ก่อนหน้า
    prevSlideA = () => {
        this.setState((prevState) => ({
            currentIndexA:
                (prevState.currentIndexA - 1 + this.androidSlides.length) %
                this.androidSlides.length,
        }));
    };

    nextSlide = () => {
        this.setState((prevState) => ({
            currentIndex: (prevState.currentIndex + 1) % this.iosSlides.length,
        }));
    };

    prevSlide = () => {
        this.setState((prevState) => ({
            currentIndex:
                (prevState.currentIndex - 1 + this.iosSlides.length) %
                this.iosSlides.length,
        }));
    };

    handleSubmit = async (event) => {
        event.preventDefault();
        const { oldPassword, newPassword, confirmPassword } = this.state;
        // console.log(type, newPassword, oldPassword, confirmPassword);
        var token = localStorage.getItem('auth_token');
        if (newPassword === confirmPassword) {
            var Username = null;
            var decoded = null;
            if (newPassword != oldPassword) {
                if (token != null) {
                    decoded = jwt_decode(token);
                    Username = decoded.message.playerid;
                    await instance.post("/api/v1/resetpassword", {
                        username: Username,
                        oldpass: oldPassword,
                        System: Systems,
                        newpass: newPassword,
                        type: 1
                    }, {
                        headers: { Authorization: `Bearer ${token}` }
                    }).then(async (res) => {
                        if (res.data.status === 200) {
                            this.setState({
                                tabprofile: 1,
                            });
                            await this.notify("success", 'เปลี่ยนพาสเวิดเรียบร้อย');
                        }
                        else if (res.data.status === 201) {
                            await this.notify("error", 'คุณใส่รหัสเก่าผิดพลาด');
                        }
                        else if (res.data.status === 203) {
                            await this.notify("error", 'มีข้อผิดพลาด โปรดทำรายการอีกครั้ง');
                        }
                        else if (res.data.status === 202) {
                            await this.notify("error", 'คุณใช้พาสเวิดเดิม');
                        }
                    });
                }
                else {
                    await this.notify("error", 'ทำรายการไม่สำเร็จ');
                }
            }
            else {
                await this.notify("error", 'คุณใส่รหัสผ่านเดิม');
            }
        }
        else {
            await this.notify("error", 'คุณใส่พาสเวิดไม่ตรงกัน');
        }

    }
    validatergisterForm = async (e, fname) => {
        e.preventDefault();
        const { phoneNumber, password, passwordcf, bankselect, accnumbertxt } = this.state;
        console.log("fname", fname);
        if (accnumbertxt === "" || typeof accnumbertxt == "undefined") {
            var title = 'error'
            var text = 'คุณไม่ทำการใส่เลขบัญชีธนาคาร'
            var object = { title, text }
            this.modalerror(object);

        }
        else if (fname === "" || fname === null || fname === undefined) {

            var title = 'error'
            var text = 'คุณไม่ทำการใส่ ชื่อ-นามสกุล'
            var object = { title, text }
            this.modalerror(object);

        }
        else if (password.length > 15) {
            var title = 'error'
            var text = 'Password สูงสุดไม่เกิน 15 ตัว'
            var object = { title, text }
            this.modalerror(object);
        }
        else if (password.length < 6) {
            var title = 'error'
            var text = 'พาสเวิดต่ำไม่เกิน 15 ตัว'
            var object = { title, text }
            this.modalerror(object);
        }
        else if (password.length < 6) {
            var title = 'error'
            var text = 'Password มากกว่า 6 ตัว'
            var object = { title, text }
            this.modalerror(object);
        }
        else {
            await instance.post("/api/v1/register", {
                User_id: phoneNumber,
                name: fname,
                password: password,
                accbank: bankselect,
                accno: accnumbertxt,
                System: Systems,
                Useradvise: this.state.userrecomend
            }).then(async (res) => {
                console.log(res.data);
                if (res.data.status === 200) {
                    var title = 'success'
                    var text = 'ทำการสมัครเรียบร้อย กรุณารอสักครู่'
                    var object = { title, text }
                    await this.modalerror(object);

                    this.setState({
                        username: phoneNumber,
                        password: password,
                        finish: 1,
                    });


                }
                else if (res.data.status === 203) {
                    var title = 'error'
                    var text = 'เลขบัญชี นี้ทำการสมัครไปแล้ว'
                    var object = { title, text }
                    this.modalerror(object);
                }
                else if (res.data.status === 202) {
                    var title = 'error'
                    var text = 'Username นี้ทำการสมัครไปแล้ว'
                    var object = { title, text }
                    this.modalerror(object);
                }
                else {
                    var title = 'error'
                    var text = 'Username หรือ ชื่อในการสมัครไม่ถูกต้อง'
                    var object = { title, text }
                    this.modalerror(object);
                }
            });
        }


    };
    handleClickmodal = () => {
        // console.log(this.state.modalx);
        // if (!this.state.modalx) {
        //     //document.addEventListener("click", this.handleOutsideClick, false);
        // } else {
        //     document.removeEventListener("click", this.handleOutsideClick, false);
        // }

        // this.setState(prevState => ({
        //     modalx: !prevState.modalx
        // }));
    };
    closeModal = () => {
        this.setState({ modalpopup: false });
    };
    handleIosClick = () => {
        this.setState({ ios: true });
    };
    handleAndroidClick = () => {
        this.setState({ ios: false });
    };
    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
        clearInterval(this.timer); // ล้างตัวนับเมื่อคอมโพเนนต์ถูกถอดออก
    }
    getNextMondayTarget() {
        const now = new Date();
        let target = new Date();

        // คำนวณวันจันทร์ถัดไป
        const daysUntilMonday = (8 - now.getDay()) % 7; // คำนวณจำนวนวันที่เหลือถึงวันจันทร์
        target.setDate(now.getDate() + daysUntilMonday);
        target.setHours(1, 0, 0, 0); // ตั้งเป้าหมายเป็น 01:00 AM ของวันจันทร์

        // ถ้าขณะนี้เลยเวลาเป้าหมายไปแล้ว ให้เลื่อนเป้าหมายไปวันจันทร์ถัดไป
        if (now >= target) {
            target.setDate(target.getDate() + 7);
        }

        return target;
    }

    startCountdown() {
        let targetTime = this.getNextMondayTarget();

        this.timer = setInterval(() => {
            const now = new Date();
            const diff = targetTime - now;

            // ถ้าถึงเวลาเป้าหมายแล้ว ให้ตั้งเป้าหมายใหม่
            if (diff <= 0) {
                targetTime = this.getNextMondayTarget();
            }

            // คำนวณวัน ชั่วโมง นาที และวินาทีที่เหลือ
            const days = Math.floor(diff / (1000 * 60 * 60 * 24));
            const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
            const minutes = Math.floor((diff / (1000 * 60)) % 60);
            const seconds = Math.floor((diff / 1000) % 60);

            // อัปเดตสถานะ (state)
            this.setState({
                days: days >= 0 ? days : 0,
                hours: hours >= 0 ? hours : 0,
                minutes: minutes >= 0 ? minutes : 0,
                seconds: seconds >= 0 ? seconds : 0
            });
        }, 1000);
    }
    redeemrank = async (e, index) => {
        e.preventDefault();
        //console.log(index);

        var token = localStorage.getItem('auth_token');
        if (token != null) {
            var decoded = jwt_decode(token);
            var Username = decoded.message.playerid;
            Swal.fire({
                icon: 'warning',
                title: 'คุณต้องการเคลม Rank หรือ ไม่?',
                showCancelButton: true,
                confirmButtonText: 'Save',
            }).then(async (result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    await instance.post("/api/v1/userredeemrank", {
                        User_id: Username,
                        rankno: index.no,
                        System: Systems,
                        amount: index.claimamount
                    }, {
                        headers: { Authorization: `Bearer ${token}` }
                    }).then(async (res) => {
                        if (res.data.status === 200) {
                            const datas = res.data.message;
                            this.calllisthistory(Username);
                            this.checklogin();
                            Swal.fire('ดำเนินการเรียบร้อย', '', 'success')
                        }
                        else {
                            Swal.fire('ไม่สามารถดำเนินการได้ โปรดรอซักครู่', '', 'error')
                        }
                    });

                }
            })
        }

    }
    handleFileSelected = async (e) => {
        const files = Array.from(e.target.files)
        this.setState({
            files: files,
            depositAmount: 1,
            dateselect: moment(new Date).format('YYYY/MM/DD'),
            inputTimeHour: moment(new Date).format('HH'),
            inputTimeMin: moment(new Date).format('mm'),
        });
        this.uploadslip(files)
        console.log(212, files);
    }

    uploadslip = async (files) => {


        var token = localStorage.getItem('auth_token');
        if (token != null) {

            try {
                if (true) {
                    var decoded = jwt_decode(token);
                    var Username = decoded.message.playerid;
                    const formData = new FormData();
                    formData.append('System', "javisauto");
                    formData.append('image', files[0]);
                    let headerss = {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': 'Bearer langter',
                    };
                    const slip = await axios.post('https://apixgen.javisx.com:8801/api/v1/uploadslip', formData, { headers: headerss })
                        .then((res) => {
                            console.log(48, res.data);
                            return res.data.link
                        }).catch((error) => {
                            console.log(4855, error);
                        });
                    let object =
                    {
                        playerid: Username,
                        amount: this.state.depositAmount,
                        date: this.state.dateselect,
                        minute: this.state.inputTimeMin,
                        hour: this.state.inputTimeHour,
                        bank: this.state.mainacc,
                        slipimg: slip,
                        System: Systems,
                        type: 0,
                    }
                    await instance.post("/api/v1/updateslip",
                        object).then(async (res) => {
                            console.log(269, object);
                            if (res.data.status == 200) {
                                await this.notify("success", 'success');
                            }
                            else {
                                await this.notify("error", res.data.message);
                            }
                        });


                }
            }
            catch (ex) {
                await this.notify("error", ex);
            }
        }
        else {
            await this.notify("error", 'not login');
        }

        // console.log(slip);
    }
    confirmqrcode = async () => {
        await Swal.fire({
            title: 'success!',
            text: 'ยืนยันการเติมเงิน',
            icon: 'success',
            confirmButtonText: 'ตกลง'
        })
        this.setState({
            tabselect: 0,
            qrcode: null,
            tabselectdeposit: 0,
        });
    };
    cancleqrcode = () => {
        Swal.fire({
            title: "ยกเลิก QR code",
            html: 'คุณต้องการยกเลิกการใช้ Qr code',
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#81d539",
            cancelButtonColor: "#ea133d",
            confirmButtonText: "ยืนยัน",
            cancelButtonText: "ยกเลิก"
        }).then(async (result) => {
            if (result.isConfirmed) {
                this.setState({
                    qrstep: 0,
                    qrcode: null,
                    counter: 0,
                    tabselectdeposit: 2,
                });
            }
        });
    };
    promotionlist = () => {
        instance.post("/api/v1/getpromotion", {
            System: Systems,
        }).then(async (res) => {
            if (res.data.status === 200) {
                this.setState({
                    prolistx: res.data.message,
                });
            }
        }).catch(async (err) => {
            console.log(err);

            if (err.response.status === 401 || err.response.status === 403) {
                localStorage.clear();
                await this.notify("warning", 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง');
                window.location.href = '/';
            }
        });
    }
    login = (e) => {
        e.preventDefault();
        let errors = this.validateLoginForm();
    }
    handleClickdeposit = async (e, price) => {
        this.setState({
            depositamount: price

        });
    }
    validateLoginForm = async (e) => {
        const { username, password } = this.state;
        console.log(username, password);
        await instance.post("/api/v1/userlogin", {
            userid: username,
            password: password,
            System: Systems,
            Function: 'validateLoginForm-navbar.js'
        })
            .then((res) => {
                console.log(res);
                if (res.status === 200) {
                    //////console.log(res.data.status);
                    if (res.data.status === 200) {
                        localStorage.setItem('auth_token', res.data.token)
                        localStorage.removeItem('cookie', '')
                        this.setState({
                            userid: res.data.message.member_id,
                            username: res.data.message.playerid,
                            firstname: res.data.message.firstname,
                            lastname: res.data.message.lastname,
                            point: res.data.message.Point,
                            balance: res.databalance,
                            loginstate: true,
                        });
                        window.location.href = '/';
                    }
                    else if (res.data.status === 401) {
                        Swal.fire({
                            title: 'error!',
                            text: 'ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง',
                            icon: 'error',
                            confirmButtonText: 'ตกลง'
                        })
                    }
                    else {
                        Swal.fire({
                            title: 'error!',
                            text: 'ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง',
                            icon: 'error',
                            confirmButtonText: 'ตกลง'
                        })
                        //   Bonn.alert("ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง")
                    }
                }
                else {
                    this.setState({
                        firstname: null,
                        lastname: null,
                        point: null,
                        loginstate: false,
                    });
                    var title = 'Error!'
                    var text = 'Username นี้ทำการสมัครไปแล้ว'
                    var object = { title, text }
                    this.CheckThisGoalError(object);
                }
            });
    }
    checklogin() {
        var token = localStorage.getItem('auth_token');
        console.log(11111111, token);
        if (token != null) {
            var decoded = jwt_decode(token);
            var Username = decoded.message.playerid;
            this.rechecklogin(Username, token);
        }
        else {
            this.setState({
                loginstate: false,
            });
        }
    }
    radiocheck(input) {
        console.log(input);
        this.setState({
            bankselect: input
        });
        if (input == 99) {
            this.setState({
                trueaccount: false
            });
        }
        else {
            this.setState({
                trueaccount: true,
                fname: ""
            });
        }
    }

    async recheccredit(evt) {

        evt.currentTarget.className += " fa-spin";
        var token = localStorage.getItem('auth_token');
        var decoded = jwt_decode(token);
        var Username = decoded.message.playerid;
        this.setState({
            checkcredit: true,
            userid: decoded.message.playerid,
            username: decoded.message.playerid,
            firstname: decoded.message.firstname,
            lastname: decoded.message.lastname,
            loginstate: true,
        });
        try {
            // console.log(2);
            await instance.post("/api/v1/checkpoint", {
                userid: Username,
                System: Systems,
                Function: 'recheccredit-App.js'
            }, {
                headers: { Authorization: `Bearer ${token}` }
            }).then(async (res) => {
                if (res.data.status === 200) {
                    localStorage.setItem('auth_token', res.data.token)
                    this.setState({
                        accnumber: res.data.message.accnumber,
                        userid: res.data.message.member_id,
                        username: res.data.message.playerid,
                        fullname: res.data.message.firstname,
                        point: res.data.message.Point,
                        balance: res.data.databalance,
                        loginstate: true,
                        checkcredit: false,
                    });
                }
                else if (res.data.status === 401) {
                    //   console.log(res.data);
                    localStorage.clear();
                    await this.notify("warning", 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง');
                    window.location.href = '/';
                }

            }).catch(async (err) => {
                // console.log(err);
                if (err.response.status === 401 || err.response.status === 403) {
                    localStorage.clear();
                    await this.notify("warning", 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง');
                    window.location.href = '/';
                }
            });
        }
        catch (ex) {
            ////////console.log(ex);
        }
    }
    changepass = (e) => {
        e.preventDefault();
        this.setState({
            showpass: this.state.showpass ? false : true,
        });
    }
    onChangex = event => {
        // console.log("event", event.target.name);


        if (event.target !== undefined) {
            let nam;
            let val;
            if (event.target.name === undefined) {
                nam = event.name;
                val = event.value;
            } else {
                nam = event.target.name;
                val = event.target.value;
            }
            this.setState({ [nam]: val });
        }
    };
    handleOutsideClick = e => {
        if (!this.node.contains(e.target)) this.handleClickmodal();
    };
    claerclass = async (e, url) => {
        var i, tabcontent, tablinks;
        tabcontent = document.querySelectorAll('[id*="game"]');
        Array.prototype.forEach.call(tabcontent, callback);
        function callback(element, iterator) {
            if (!isMobile) {
                element.className = "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-luxecs";
            }
            else if (isMobile) {
                element.className = "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-5oh6yz";
            }
        }
    }
    async saveclickaff(linkid) {
        await instance.post("/api/v1/saveclickaff", {
            linkid: linkid,
            System: Systems,
        }).then((res) => {
            if (res.data.status == 200) {
                var date = new Date()
                this.setState({
                    userrecomend: res.data.username,
                    link: linkid
                });
                var username = res.data.username
                var object = { date, username }
                localStorage.setItem('aff', JSON.stringify(object))
            }
        }).catch(async (err) => {
        });

    }
    async saveclick(linkid) {
        await instance.post("/api/v1/saveclickaff", {
            linkid: linkid,
            System: Systems,
        }).then((res) => {
            if (res.data.status == 200) {
                var date = new Date()
                var username = res.data.username
                this.setState({
                    userrecomend: username,
                });
                var object = { date, username }
                localStorage.setItem('aff', JSON.stringify(object))
            }
        }).catch(async (err) => {
        });

    }
    handleSelectItem = (item) => {
        if (item.id == 99) {
            this.setState({
                trueaccount: false,
                selectedItem: item,
                isPopupOpen: false,
                bankselect: item.id,
            });
        }
        else {
            this.setState({
                trueaccount: true,
                selectedItem: item,
                isPopupOpen: false,
                bankselect: item.id,
                fname: ""
            });
        }
        //  this.setState({}); // Store selected item and close menu
    };
    closewheel(e) {
        e.preventDefault(); // ป้องกันพฤติกรรมเริ่มต้น
        this.setState({ luckywheeldisplay: false }); // ซ่อน Lucky Wheel
    }
    checkurl = async (e, url, listitem = null) => {
        window.history.pushState(null, null, url);
        NProgress.start();
        this.setState({
            modalx: false,
            modal: false,
            listitallgame: []
        });
        const pathname = window.location.pathname.split("/")
        console.log(210, pathname);
        if (pathname.length > 1) {
            NProgress.set(0.4);
            if (pathname[1] == "") {
                console.log(250, pathname[1]);
                this.setState({
                    tabselect: 0,
                });
            }
            else if (pathname[1] + "/" + pathname[2] == "game/casino") {
                this.claerclass();
                var i, tabcontent, tablinks;
                if (!isMobile) {
                    tabcontent = document.getElementById("gamecasino");
                    tabcontent.className = "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-1fh7av9";
                }
                else if (isMobile) {
                    tabcontent = document.getElementById("gamecasino");
                    tabcontent.className = "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-17axtqp";
                }
                await this.getgame('casino', pathname[3], "993", true)
                this.setState({
                    tabselect: 10,
                });
            }
            else if (pathname[1] + "/" + pathname[2] == "game/hit") {
                this.claerclass();
                var i, tabcontent, tablinks;
                if (!isMobile) {
                    tabcontent = document.getElementById("gamehit");
                    tabcontent.className = "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-1fh7av9";
                }
                else if (isMobile) {
                    tabcontent = document.getElementById("gamehit");
                    tabcontent.className = "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-17axtqp";
                }
                await this.getgame('slot', pathname[3], "992")
                await this.getprovidor('slot', pathname[3], "2255")
                this.setState({
                    tabselect: 11,
                });
            }
            else if (pathname[1] + "/" + pathname[2] == "game/providor") {
                this.claerclass();
                var i, tabcontent, tablinks;
                if (!isMobile) {
                    tabcontent = document.getElementById("gameprovidor");
                    tabcontent.className = "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-1fh7av9";
                }
                else if (isMobile) {
                    tabcontent = document.getElementById("gameprovidor");
                    tabcontent.className = "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-17axtqp";
                }
                await this.getgame('casino', pathname[3], "994", true)
                this.setState({
                    tabselect: 18,
                });
            }
            else if (pathname[1] + "/" + pathname[2] == "game/fishing") {
                this.claerclass();
                var i, tabcontent, tablinks;
                if (!isMobile) {
                    tabcontent = document.getElementById("gamefishing");
                    tabcontent.className = "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-1fh7av9";
                }
                else if (isMobile) {
                    tabcontent = document.getElementById("gamefishing");
                    tabcontent.className = "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-17axtqp";
                }
                await this.getgame('fishing', pathname[3], "995")
                this.setState({
                    tabselect: 12,
                });
            }
            else if (pathname[1] + "/" + pathname[2] == "game/slot") {
                this.claerclass();
                var i, tabcontent, tablinks;
                if (!isMobile) {
                    tabcontent = document.getElementById("gameslot");
                    tabcontent.className = "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-1fh7av9";
                }
                else if (isMobile) {
                    tabcontent = document.getElementById("gameslot");
                    tabcontent.className = "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-17axtqp";
                }
                console.log(101, pathname);
                await this.getgame('slot', pathname[3], "995")
                this.setState({
                    tabselect: 13,
                });
            }
            else if (pathname[1] + "/" + pathname[2] == "game/sport") {
                this.claerclass();
                var i, tabcontent, tablinks;
                if (!isMobile) {
                    tabcontent = document.getElementById("gamesport");
                    tabcontent.className = "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-1fh7av9";
                }
                else if (isMobile) {
                    tabcontent = document.getElementById("gamesport");
                    tabcontent.className = "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-17axtqp";
                }
                await this.getgame('sport', pathname[3], "996", true)
                //await this.getprovidor('sport', pathname[3],"996")
                this.setState({
                    tabselect: 14,
                });
            }
            else if (pathname[1] + "/" + pathname[2] == "game/list") {
                this.claerclass();
                await this.getgame('slot', pathname[3])
                this.setState({
                    tabselect: 20,
                    partner: pathname[3]
                });
            }
            else if (pathname[1] == "loss") {
                this.setState({
                    tabselect: 25,
                });
            }
            else if (pathname[1] + "/" + pathname[2] == "manager/ranking") {
                this.setState({
                    tabselect: 27,
                });
            }
            else if (pathname[1] + "/" + pathname[2] == "minigame/luckywheel") {
                this.setState({
                    tabselect: 28,
                });
            }


            else if (pathname[1] == "event") {
                this.setState({
                    tabselect: 16,
                });
            }
            else if (pathname[1] + "/" + pathname[2] == "game/lotto") {
                this.claerclass();
                var i, tabcontent, tablinks;
                if (!isMobile) {
                    tabcontent = document.getElementById("gamelotto");
                    tabcontent.className = "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-1fh7av9";
                }
                else if (isMobile) {
                    tabcontent = document.getElementById("gamelotto");
                    tabcontent.className = "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-17axtqp";
                }
                this.setState({
                    tabselect: 17,
                });
            }
            else if (pathname[1] + "/" + pathname[2] == "manager/withdraw") {
                if (this.state.loginstate) {
                    this.setState({
                        tabselect: 2,
                    });
                }
                else {
                    await this.notify("error", 'คุณยังไม่ได้เข้าสู่ระบบ');
                    this.setState({
                        tabselect: 0,
                    });
                }
            }
            else if (pathname[1] + "/" + pathname[2] == "manager/deposit") {
                if (this.state.loginstate) {
                    this.setState({
                        tabselect: 1,
                    });
                }
                else {
                    await this.notify("error", 'คุณยังไม่ได้เข้าสู่ระบบ');
                    this.setState({
                        tabselect: 0,
                    });
                }
            }
            else if (pathname[1] + "/" + pathname[2] == "manager/statement") {
                if (this.state.loginstate) {
                    this.setState({
                        tabselect: 3,
                    });
                }
                else {
                    await this.notify("error", 'คุณยังไม่ได้เข้าสู่ระบบ');
                    this.setState({
                        tabselect: 0,
                    });
                }
            }
            else if (pathname[1] == "profile") {
                this.setState({
                    tabselect: 8,
                });
            }
            else if (pathname[1] == "register") {
                this.setState({
                    tabselect: 22,
                });
            }
            else if (pathname[1] == "signin") {
                this.setState({
                    tabselect: 23,
                });
            }
            else if (pathname[1] + "/" + pathname[2] == "refer/overview") {
                this.setState({
                    tabselect: 4,
                    refer: 1
                });
            }
            else if (pathname[1] + "/" + pathname[2] == "refer/member") {
                this.setState({
                    tabselect: 4,
                    refer: 2
                });
            }
            else if (pathname[1] + "/" + pathname[2] == "refer/income-summary") {
                this.setState({
                    tabselect: 4,
                    refer: 3
                });
            }
            else if (pathname[1] + "/" + pathname[2] == "refer/share-network") {
                this.setState({
                    tabselect: 4,
                    refer: 4
                });
            }
            else if (pathname[1] == "rebate") {
                this.setState({
                    tabselect: 5,
                });
            }
            else if (pathname[1] + "/" + pathname[2] == "manager/promotion") {
                this.setState({
                    tabselect: 7,
                });
            }
            else if (pathname[1] == "listgameall") {
                await this.getgame(pathname[2], pathname[3]);
                this.setState({
                    tabselect: 15,
                });
            }
        }
        else {
            this.setState({
                tabselect: 0,
            });
        }
        NProgress.done();
        window.scrollTo(0, 0)
    }

    calllisthistory(userid) {
        const cacheKey = "userwithdrawlist_data"; // กำหนด Key ของ Cache
        const cachedData = this.getCache(cacheKey);

        if (cachedData) {
            console.log("Using cached data:", cachedData);
            this.setState({
                historylist: cachedData
            });
        } else {
            // หากไม่มีข้อมูลใน Cache หรือ Cache หมดอายุ ให้เรียก API
            instance.post("/api/v1/userwithdrawlist", {
                userid: userid,
                Cat_ID: 0,
                System: Systems,
                page: 1,
                type: 1
            }).then((res) => {
                console.log(864, res.data);
                if (res.data.status === 200) {
                    const datas = res.data.message;

                    // เก็บข้อมูลใน Cache เป็นเวลา 2 นาที
                    this.setCache(cacheKey, datas.withdrawlist, 2);

                    this.setState({
                        historylist: datas.withdrawlist
                    });
                } else {
                    this.setState({
                        historylist: []
                    });
                }
            });
        }


    }
    forgetotpandregister = async (type) => {
        const { phoneNumber, otpphoneforget, bankselect, otpphone, accnumbertxt } = this.state;
        var recheck = false;
        if (type === 4) {
            await instance.post("/api/v1/resetpassword", {
                username: phoneNumber,
                System: Systems,
                type: type
            }).then(async (res) => {
                recheck = res.data.message;
            });
        }
        else if (type === 5) {
            await instance.post("/api/v1/resetpassword", {
                username: phoneNumber,
                pin: otpphoneforget,
                System: Systems,
                type: type
            }).then(async (res) => {
                recheck = res.data.message;

            });
        }
        else if (type === 6) {
            await instance.post("/api/v1/resetpassword", {
                username: phoneNumber,
                System: Systems,
                type: type
            }).then(async (res) => {
                recheck = res.data.message;
            });
        }
        else if (type === 7) {
            await instance.post("/api/v1/resetpassword", {
                username: phoneNumber,
                pin: otpphone,
                System: Systems,
                type: type
            }).then(async (res) => {
                recheck = res.data.message;

            });
        }
        else if (type === 10) {
            return await instance.post("/api/v1/resetpassword", {
                accountnumber: accnumbertxt,
                bankid: bankselect,
                System: Systems,
                type: type
            }).then(async (res) => {
                return recheck = res.data.message;

            });
        }
        return recheck
    }
    async modalerror(object) {
        await this.notify(object.title, object.text);
    }
    nextstep = async (e, num) => {
        e.preventDefault();
        const { stateregis, password, bankselect, passwordcf, phoneNumber, checkboxacc, fname, otpphone, accnumbertxt } = this.state
        console.log(num);
        if (num === 1) {
            const check = await this.forgetotpandregister(6);
            if (phoneNumber.length === 10 && check.checked) {
                this.setState({
                    stateregis: 2
                });
            }
            else {
                if (phoneNumber.length !== 10) {
                    await this.notify("warning", 'คุณใส่เบอร์ผิดพลาด');
                }
                else if (!check.checked && check.status === 201) {
                    await this.notify("warning", 'เบอร์นี้มีคนใช้แล้ว');
                }
                else {
                    await this.notify("warning", 'คุณทำรายการถี่เกินไป โปรดรอสักครู่');
                }
            }
        }
        else if (num === 2) {

            if (password === "" || password === null) {
                await this.notify("warning", 'คุณไม่ได้ไส่พาสเวิด');
            }
            else if (password.length < 6) {
                await this.notify("warning", 'โปรดใส่พาสเวิดมากกว่า 6 ตัว');
            }
            else {
                this.setState({
                    stateregis: 3
                });
            }

        }
        else if (num === 3) {
            if (checkboxacc !== 0 && fname !== "" && accnumbertxt !== "") {
                this.setState({
                    stateregis: 3
                });
            }
            else {
                await this.notify("warning", 'กรุณาเลือกธนาคาร หรือใส่ข้อมูลให้ครบถ้วน 6 ตัว');
                //////console.log(2, stateregis, checkboxacc);
            }
        }
        else if (num === 5) {
            var check = await this.forgetotpandregister(7);
            if (otpphone !== "" && check.checked) {
                this.setState({
                    stateregis: 2
                });
            }
            else if (otpphone === "" || otpphone.length < 4) {
                await this.notify("warning", 'คุณใส่ตัวเลขไม่ครบ');
                document.getElementById("otpphone").style = "border-color: #ef4444;";
                document.getElementById("checkotp").style.display = "block";
            }

            else {
                this.setState({
                    stateopterror: "รหัสผิดพลาด"
                });
                await this.notify("warning", 'รหัสผิดพลาด');
                document.getElementById("otpphone").style = "border-color: #ef4444;";
                document.getElementById("checkotp").style.display = "block";
            }



        }
        else if (num === 11) {

            console.log(1, stateregis, num);

            this.setState({
                stateregis: 1
            });
        }
        else if (num === 22) {
            //////console.log(1, stateregis, num);
            this.setState({
                stateregis: 2
            });
        }
        else if (num === 25) {
            console.log("accnumbertxt", accnumbertxt);

            if (accnumbertxt === null || accnumbertxt === "" || typeof accnumbertxt == "undefined") {
                var title = 'error'
                var text = 'คุณไม่ทำการใส่เลขบัญชีธนาคาร'
                var object = { title, text }
                this.modalerror(object);

            }
            else if (accnumbertxt.length < 10) {
                var title = 'error'
                var text = 'คุณยังไม่ได้เลือกธนาคาร'
                var object = { title, text }
                this.modalerror(object);
            }
            else if (bankselect === 0) {
                var title = 'error'
                var text = 'คุณยังไม่ได้เลือกธนาคาร'
                var object = { title, text }
                this.modalerror(object);
            }
            else {
                this.setState({
                    loading: true,
                });
                if (fname === '') {
                    let check = await this.forgetotpandregister(10)
                    if (check.checked) {
                        console.log(302, check.verifications);
                        console.log(302, check.verifications.name);
                        if (check.verifications.status == 0) {
                            this.setState({
                                trueaccount: false,
                            });
                        }
                        else if (check.verifications.status == 1) {
                            this.setState({
                                fname: check.verifications.name,
                            });
                            await this.validatergisterForm(e, check.verifications.name);
                        }
                    }
                    else {
                        var title = 'error'
                        var text = 'เลขบัญชีไม่ถูกต้อง'
                        var object = { title, text }
                        this.modalerror(object);
                    }
                }
                else {
                    await this.validatergisterForm(e, fname)
                }
                this.setState({
                    loading: false,
                });
                //console.log(302, check.verifications);
            }



        }
    };
    confirmdeposit = async (e, num) => {
        console.log(493, num)
        e.preventDefault();
        var token = localStorage.getItem('auth_token');
        var decoded = jwt_decode(token);
        var Username = decoded.message.playerid;
        if (num === 0) {
            if ((this.state.depositamount >= this.state.limit.min) && (this.state.depositamount <= this.state.limit.max)) {
                if (num === 0) {
                    if (token != null) {
                        Username = decoded.message.playerid;
                        instance.post("/api/v1/getqrcode", {
                            username: Username,
                            System: Systems,
                            amount: this.state.depositamount,
                            status: 1
                        }, {
                            headers: { Authorization: `Bearer ${token}` }
                        }).then(async (res) => {
                            console.log(1067, res.data)
                            if (res.data.tblpaymentgatewayx != null) {
                                if (res.data?.tblpaymentgatewayx?.link) {
                                    window.location.href = res.data.tblpaymentgatewayx.link;
                                }
                                else {
                                    this.setState({
                                        mainacc: res.data.mainacc,
                                        qrcode: res.data.tblpaymentgatewayx.QRcode,
                                        paymentlist: res.data.tblpaymentgatewayx,
                                        counter: 300,
                                        qrcodecheck: true,
                                    });
                                }
                            } else {
                                console.log(1067, res.data);
                                this.setState({
                                    mainacc: res.data.mainacc,
                                    qrcode: res.data.picture,
                                    paymentlist: res.data.tblpaymentgatewayx,
                                    counter: 300,
                                    qrcodecheck: true,
                                });
                            }
                            await this.notify("success", 'กรุณาสแกน qrcode ภายใน 5 นาที');
                        });

                    }

                    this.setState({
                        depositstate: 1
                    });
                }
            }
            else {
                await this.notify("warning", 'เติมขั้นต่ำ ' + this.state.limit.min + ' บาท สูงสุด ' + this.state.limit.max + ' ');

            }
        }
        else if (num === 1) {
            this.setState({
                depositstate: 1
            });
        }

        else if (num === 5) {
            document.getElementById("truemonney").click()
            //deposittruemoneyModal
        }
        else {
            this.setState({
                depositstate: 0
            });
        }



    }
    togglePopup = () => {
        this.setState((prevState) => ({
            isPopupOpen: !prevState.isPopupOpen,
        }));
    };

    onChange = async (event) => {
        console.log(1163, event);
        if (event.target !== undefined) {
            let nam;
            let val;
            if (event.target.name === undefined) {
                nam = event.name;
                val = event.value;
            } else {
                nam = event.target.name;
                val = event.target.value;
            }
            if (nam === "withdrawamount") {
                if (this.state.maxwithdrawstatus === 1) {
                    this.setState({ [nam]: this.state.balance });
                    await this.notify("warning", 'บังคับถอนยอดทั้งหมด');
                }
                else if (Number(val) <= Number(this.state.balance)) {
                    this.setState({ [nam]: val });
                }
                else {
                    await this.notify("warning", 'ยอดถอนของท่านไม่เพียงพอในการถอน');

                }
            } else {
                this.setState({ [nam]: val });
            }
            if (nam === "txtsech" || nam === "txtsech2") {
                const tabselect = this.state.tabselect
                let list = []
                let step = 1
                if (this.state.filterlist.length == 0) {
                    if (tabselect == 16 || tabselect == 20) {
                        list = this.state.listitallgame
                        step = 1
                    }
                    else if (tabselect == 13) {
                        list = this.state.slotlist
                        step = 2
                    }
                    else if (tabselect == 10) {
                        list = this.state.categorylist
                        step = 3
                    }
                    else if (tabselect == 14) {
                        list = this.state.sportlist
                        step = 4
                    }
                    else if (tabselect == 12) {
                        list = this.state.fishinglist
                        step = 5
                    }
                    else if (tabselect == 11) {
                        list = this.state.gamehitlist
                        step = 6
                    }
                    else if (tabselect == 18) {
                        list = this.state.providorlist
                        step = 7
                    }
                    this.setState({ filterlist: list });
                }
                else {
                    list = this.state.filterlist
                }
                let retailArrays = [];
                list.forEach(
                    array => {
                        console.log(1231, tabselect, step, array)
                        if (tabselect == 18 || tabselect == 10 || tabselect == 14) {
                            if (array.providername.toLowerCase().includes(val.toLowerCase())) {
                                retailArrays.push(array);
                            };
                        }
                        else {
                            if (array.description.toLowerCase().includes(val.toLowerCase())) {
                                retailArrays.push(array);
                            };
                        }
                    }
                )
                if (step == 1) this.setState({ listitallgame: retailArrays });
                else if (step == 2) this.setState({ slotlist: retailArrays });
                else if (step == 3) this.setState({ categorylist: retailArrays });
                else if (step == 4) this.setState({ sportlist: retailArrays });
                else if (step == 5) this.setState({ fishinglist: retailArrays });
                else if (step == 6) this.setState({ gamehitlist: retailArrays });
                else if (step == 7) this.setState({ providorlist: retailArrays });
            }

        }
    };
    submitwithdraw = async (e) => {

        console.log(this.state.withdrawamount, this.state.balance);
        if (parseFloat(this.state.withdrawamount) <= parseFloat(this.state.balance)) {
            if (parseFloat(this.state.withdrawamount) >= 10) {
                var token = localStorage.getItem('auth_token');
                if (token != null) {
                    var decoded = jwt_decode(token);
                    var Username = decoded.message.playerid;
                    await instance.post("/api/v1/userwithdraw", {
                        User_id: Username,
                        System: Systems,
                        amount: this.state.withdrawamount
                    }, {
                        headers: { Authorization: `Bearer ${token}` }
                    }).then(async (res) => {
                        console.log(res.data);
                        if (res.data.status === 200) {
                            // await this.notify("success", 'รายการถอนเรียบร้อย');

                            await Swal.fire({
                                html: `
                                  <div style="display: flex; flex-direction: column; align-items: center;">
                                    <div style="margin-bottom: 20px;">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" fill="#28a745" viewBox="0 0 16 16">
                                        <path d="M16 8a8 8 0 1 1-16 0 8 8 0 0 1 16 0zM6.82 10.93a.75.75 0 0 0 1.06 0l4.72-4.72a.75.75 0 1 0-1.06-1.06L7.5 9.378 5.47 7.348a.75.75 0 0 0-1.06 1.061l2.41 2.521z"/>
                                      </svg>
                                    </div>
                                    <h3 style="margin: 0; font-size: 1.5rem; color: #333;">กำลังดำเนินการถอนเงิน</h3>
                                    <div style="margin-top: 15px; text-align: left; font-size: 1rem; color: #555;">
                                      <p><strong>จาก:</strong>  กระเป๋าสตางค์</p>
                                      <p><strong>ยอดเงิน:</strong> ${this.state.withdrawamount} THB</p>
                                      <p><strong>วิธีการชำระเงิน:</strong> Thailand Local Internet Banking</p>
                                    </div>
                                  </div>
                                `,
                                confirmButtonText: 'ตกลง',
                                confirmButtonColor: '#007bff',
                                width: 400,
                                showCloseButton: true,
                            });


                            this.checkurl(e, "/manager/statement")

                        }
                        else if (res.data.status === 203) {
                            await this.notify("error", 'ท่านติด Turnover อีก ' + res.data.amountturnover + " บาท");

                        }
                        else if (res.data.status === 207) {
                            await this.notify("error", 'ขั้นต่ำในการถอน ' + res.data.maxwithdraw + " บาท");

                        }
                        else {
                            await this.notify("error", "ไม่สามารถทำรายการถอนได้ โปรดแจ้งพนักงาน");
                        }
                    });
                }
                else {
                    await this.notify("error", 'รายการถอนมีปัญหา โปรดติดต่อพนักงาน');
                }
            }
            else {
                await this.notify("warning", 'ขั้นต่ำในการถอน 100 บาท');
            }
        }
        else {
            await this.notify("warning", 'ยอดถอนของท่านไม่เพียงพอในการถอน');
        }

    }
    resetpassword = async (e, type) => {
        e.preventDefault();
        const { phoneNumber, fname, password, oldpassword, passwordcf } = this.state;
        console.log(type, password, oldpassword, passwordcf);
        var token = localStorage.getItem('auth_token');
        if (password === passwordcf) {
            var Username = null;
            var decoded = null;
            if (type === 1 && token != null) {
                decoded = jwt_decode(token);
                Username = decoded.message.playerid;
                instance.post("/api/v1/resetpassword", {
                    username: Username,
                    oldpass: oldpassword,
                    System: Systems,
                    newpass: password,
                    type: type
                }, {
                    headers: { Authorization: `Bearer ${token}` }
                }).then(async (res) => {
                    if (res.data.status === 200) {
                        await this.notify("success", 'เปลี่ยนพาสเวิดเรียบร้อย');
                    }
                    else if (res.data.status === 201) {
                        await this.notify("error", 'คุณใส่รหัสเก่าผิดพลาด');
                    }
                    else if (res.data.status === 203) {
                        await this.notify("error", 'มีข้อผิดพลาด โปรดทำรายการอีกครั้ง');
                    }
                    else if (res.data.status === 202) {
                        await this.notify("error", 'คุณใช้พาสเวิดเดิม');
                    }
                });
            }
            else if (type === 2) {
                instance.post("/api/v1/resetpassword", {
                    username: phoneNumber,
                    oldpass: oldpassword,
                    newpass: password,
                    System: Systems,
                    type: type
                }).then(async (res) => {
                    if (res.data.status === 200) {
                        await this.notify("success", 'เปลี่ยนพาสเวิดเรียบร้อย');
                    }
                    else if (res.data.status === 201) {
                        await this.notify("error", 'คุณใส่รหัสเก่าผิดพลาด');
                    }
                    else if (res.data.status === 203) {
                        await this.notify("error", 'มีข้อผิดพลาด โปรดทำรายการอีกครั้ง');
                    }
                    else if (res.data.status === 202) {
                        await this.notify("error", 'คุณใช้พาสเวิดเดิม');
                    }
                });
            }
            else if (type === 3) {
                instance.post("/api/v1/resetpassword", {
                    username: phoneNumber,
                    oldpass: oldpassword,
                    newpass: password,
                    System: Systems,
                    type: type
                }).then(async (res) => {
                    if (res.data.status === 200) {
                        await this.notify("success", 'เปลี่ยนพาสเวิดเรียบร้อย');
                    }
                    else if (res.data.status === 201) {
                        await this.notify("error", 'คุณใส่รหัสเก่าผิดพลาด');
                    }
                    else if (res.data.status === 203) {
                        await this.notify("error", 'มีข้อผิดพลาด โปรดทำรายการอีกครั้ง');
                    }
                    else if (res.data.status === 202) {
                        await this.notify("error", 'คุณใช้พาสเวิดเดิม');
                    }
                });
            }
        }
        else {
            await this.notify("error", 'คุณใส่พาสเวิดไม่ตรงกัน');
        }
    }
    async opencliaminvite(evt, cityName) {
        // var str = "ต้องการถอนโบนัสจำนวน 0 เครดิต ใช่หรือไม่?\n" +
        //     "คุณจะได้รับเครดิตคืนจาก (สล็อต: 0 คาสิโนสด: 0 กีฬา: 0)\n";
        // Swal.fire({
        //     title: "ยืนยันการถอนโบนัส",
        //     html: '<pre>' + str + '</pre>',
        //     icon: "warning",
        //     showCancelButton: true,
        //     confirmButtonColor: "#81d539",
        //     cancelButtonColor: "#ea133d",
        //     confirmButtonText: "ยืนยัน",
        //     cancelButtonText: "ยกเลิก"
        // }).then(async (result) => {
        console.log(cityName);
        var token = localStorage.getItem('auth_token');
        if (token != null) {
            var decoded = jwt_decode(token);
            var Username = decoded.message.playerid;
            await instance.post("/api/v1/cliaminvite", {
                userid: Username,
                System: Systems,
            }, {
                headers: { Authorization: `Bearer ${token}` }
            }).then(async (res) => {
                if (res.data.status === 200) {
                    var decoded = jwt_decode(token);
                    var Username = decoded.message.playerid;
                    this.rechecklogin(Username, token);
                    await this.notify("success", 'ทำรายการรับเงินเรียบร้อย');
                }
                else if (res.data.status === 201) {
                    await this.notify("error", 'ไม่สามารถทำรายการได้ ไม่มียอดเงิน');
                    return false;
                }
                else if (res.data.status === 202) {
                    await this.notify("error", 'ไม่สามารถทำรายการได้');
                    return false;
                }

            }).catch(async (err) => {
                // console.log(err);
                if (err.response.status === 401 || err.response.status === 403) {
                    localStorage.clear();
                    await this.notify("warning", 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง');
                    window.location.href = '/';
                }
            });
        } else {
            await this.notify("error", 'คุณยังไม่ได้ล๊อคอิน');
            return false;
        }
        // });
    }
    handleClick = async (e, price) => {
        console.log(price);
        if (price <= this.state.balance) {
            this.setState({
                withdrawamount: price
            });
        }
        else {
            await this.notify("warning", 'ยอดถอนของท่านไม่เพียงพอในการถอน');
        }
    }
    handleClickreposit = async (e, price) => {
        this.setState({
            depositamount: price
        });
    }
    redeempro = async (e, proid) => {
        e.preventDefault();
        var token = localStorage.getItem('auth_token');
        var decoded = jwt_decode(token);
        var Username = decoded.message.playerid;
        //console.log(Username, proid);
        if (token != null) {
            this.setState({
                username: Username,
            });
            await instance.post("/api/v1/redeempromotion", {
                userid: Username,
                System: Systems,
                Proid: proid,
            }, {
                headers: { Authorization: `Bearer ${token}` }
            }).then(async (res) => {
                //console.log(res.data.status)
                if (res.data.status === 200) {
                    await this.notify("success", 'รับโปรโมชั่นเรียบร้อย โปรดทำการเติมเงิน');
                    this.setState({
                        modalpromotion: false,
                    })
                }
                else if (res.data.status === 201) {
                    await this.notify("error", 'ท่านไม่เข้าเงื่อนใขโปรโมชั่นนี้');
                    e.preventDefault();
                    this.setState({
                        modalpromotion: false,
                    })
                    return false;
                }
                else if (res.data.status === 204) {
                    await this.notify("error", 'ท่านใช้สิทธวันนี้ครบแล้ว');
                    e.preventDefault();
                    this.setState({
                        modalpromotion: false,
                    })
                    return false;
                }
                else if (res.data.status === 202) {
                    await this.notify("error", 'ท่านมีการรับโปรโมชั่น ค้างไว้');
                    e.preventDefault();
                    this.setState({
                        modalpromotion: false,
                    })
                    return false;
                }

            }).catch(async (err) => {
                // console.log(err);
                if (err.response.status === 401 || err.response.status === 403) {
                    localStorage.clear();
                    await this.notify("error", 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง');
                    window.location.href = '/';
                }
            });
        }
    }
    redeem = async (e, gameIdx, partner) => {
        var userAgent = navigator.userAgent
        await this.checklogin();
        console.log(this.state.loginstate, 55555555)
        if (!this.state.loginstate) {
            await this.notify("error", 'คุณยังไม่ได้เข้าสู่ระบบ');
            return;
            //  return document.getElementById("loginmode").click();
        }
        if (this.state.username !== null) {
            instance.post("/api/v1/tablauncher", {
                gameId: gameIdx,
                partner: partner,
                playerUsername: this.state.username,
                isMobile: isMobile,
                System: Systems,
                demo: false
            })
                .then((res) => {
                    if (res.data.status === 200) {
                        //////console.log(res.data.message);
                        var test = res.data.message.launcher
                        if (test !== undefined) {
                            if (/iPad|iPhone|Macintosh|iPod/.test(userAgent) && !window.MSStream) {
                                window.location.href = test.mobile;
                            }
                            else {
                                if (!isMobile) window.open(test.desktop, '_blank', 'height=1000,width=1000');
                                else window.open(test.mobile);
                            }
                        }
                        else if (test === undefined) {
                            this.notify("error", 'ขณะนี้เกมมีปัญหากรุณารอสักครู่');
                        }
                    }
                    else {
                        //  this.CheckThisGoalError();
                    }
                });
        }
        else {

        }


    }
    redeemcheck = async (e, ListItem) => {
        console.log(ListItem)
        var userAgent = navigator.userAgent
        this.checklogin();
        if (!this.state.loginstate) {
            await this.notify("error", 'คุณยังไม่ได้เข้าสู่ระบบ');
            return;
            //  return document.getElementById("loginmode").click();
        }
        if (this.state.username !== null) {

            if (ListItem.gameId != undefined) {
                instance.post("/api/v1/tablauncher", {
                    gameId: ListItem.gameId,
                    partner: ListItem.partner,
                    playerUsername: this.state.username,
                    isMobile: isMobile,
                    System: Systems,
                    demo: false
                })
                    .then((res) => {
                        if (res.data.status === 200) {
                            //////console.log(res.data.message);
                            var test = res.data.message.launcher
                            if (test !== undefined) {
                                if (/iPad|iPhone|Macintosh|iPod/.test(userAgent) && !window.MSStream) {
                                    window.location.href = test.mobile;
                                }
                                else {
                                    if (!isMobile) window.open(test.desktop, '_blank', 'height=1000,width=1000');
                                    else window.open(test.mobile);
                                }
                            }
                            else if (test === undefined) {
                                this.notify("error", 'ขณะนี้เกมมีปัญหากรุณารอสักครู่');
                            }
                        }
                        else {
                            //  this.CheckThisGoalError();
                        }
                    });
            }
            else {
                this.checkurl(e, "/game/list/" + ListItem.partner)
            }
        }
        else {

        }


    }

    couponredeem = async (e, index) => {
        e.preventDefault();
        var token = localStorage.getItem('auth_token');
        if (token != null) {
            var decoded = jwt_decode(token);
            var Username = decoded.message.playerid;
            await instance.post("/api/v1/usercoupon", {
                User_id: Username,
                System: Systems,
                couponkey: this.state.couponkey,
                type: 1
            }).then(async (res) => {
                if (res.data.status === 200) {
                    const datas = res.data.message;
                    await this.notify("success", 'ดำเนินการใช้คูปองเรียบร้อย');
                }
                else {
                    await this.notify("warning", 'ไม่มี Key นี้ในระบบ');
                }
            });
        }
        else {
            await this.notify("warning", 'คุณไม่ได้ล๊อคอิน');
        }

    }

    copy = async (e, accnumber) => {
        toast.success("คัดลอกเรียบร้อย", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });

        navigator.clipboard.writeText(accnumber)
    }
    copyCode = (text, type = 0) => {
        navigator.clipboard
            .writeText(text)
            .then(() => {
                setTimeout(function () {
                }, 3000);
            })
            .catch((err) => {
                // console.log(err.message);
            });
        if (type == 0) {
            this.notify("success", 'คัดลอกสำเร็จ !!');
        }
        else {
            this.setState({
                modaldeposit: true
            });

        }
    };
    async callgetthemesv(Systems) {
        await instance.post("/api/v1/getthemesv", {
            System: Systems,
        }).then((res) => {
            if (res.data.status === 200) {
                document.body.setAttribute("data-theme", res.data.ThmeseV)
                this.setState({
                    ThmeseV: res.data.ThmeseV
                });

            }
            else {
                // this.CheckThisGoalError();
            }
        });

    }
    async getgame(typepage, partner, state = "991", cheked = false) {
        await instance.post("/api/v1/tabgamelist", {
            state: state,
            System: Systems,
            partner: partner,
            game: typepage,
        }).then((res) => {
            console.log(803, res.data.message[0].list);
            if (res.data.status === 200) {
                if (cheked) {
                    this.setState({
                        listitallgame: res.data.message,
                        providorlist: res.data.message
                    });
                } else {

                    if (res.data.message[0].list != undefined) {
                        this.setState({
                            listitallgame: res.data.message,
                            providorlist: res.data.message[0].list


                        });
                    }
                    else {
                        this.setState({
                            listitallgame: res.data.message,
                        });
                    }

                }
            }
        });
    }
    async getprovidor(typepage, partner, state = "991") {
        await instance.post("/api/v1/tabgamelist", {
            state: state,
            System: Systems,
            partner: partner,
            game: typepage,
        }).then((res) => {
            console.log(803, res.data);
            if (res.data.status === 200) {
                this.setState({
                    providorlist: res.data.message
                });

            }
        });
    }

    async getcallprovidor() {
        const cacheTTL = 3 * 60 * 60 * 1000; // 3 ชั่วโมงในมิลลิวินาที

        const fetchData = async (state, key) => {
            const cacheKey = `tabGameListCache_${state}`;
            const cachedData = JSON.parse(localStorage.getItem(cacheKey));

            if (cachedData && (Date.now() - cachedData.timestamp < cacheTTL)) {
                console.log(`Using cached data for state: ${state}`);
                this.setState({ [key]: cachedData.data });
            } else {
                // Fetch new data and cache it
                await instance.post("/api/v1/tabgamelist", {
                    state: state,
                    System: Systems,
                }).then((res) => {
                    if (res.data.status === 200) {
                        this.setState({ [key]: res.data.message });

                        // Save to cache
                        localStorage.setItem(cacheKey, JSON.stringify({
                            timestamp: Date.now(),
                            data: res.data.message,
                        }));
                    }
                }).catch((err) => {
                    console.error(`Error fetching data for state: ${state}`, err);
                });
            }
        };

        // Fetch data for each state and cache it
        await fetchData("2", "categorylist");
        await fetchData("6666", "slotlist");
        await fetchData("4444", "gamehitlist");
        await fetchData("5555", "pglist");
        await fetchData("2255", "providorlist");
        await fetchData("5", "sportlist");
        await fetchData("7777", "fishinglist");
    }

    getCache = (key, ttl) => {
        const cachedDataStr = localStorage.getItem(key);
        console.log("Raw cached data:", cachedDataStr);

        const cachedData = cachedDataStr ? JSON.parse(cachedDataStr) : null;
        if (cachedData) {
            console.log("Cache timestamp:", cachedData.expiry);
            console.log("Current time:", Date.now());
            console.log("TTL:", ttl);

            if (Date.now() < cachedData.expiry) {
                console.log("Using valid cached data:", cachedData.value);
                return cachedData.value;
            }
        }

        console.log("Cache expired or not found.");
        return null;
    };
    setCache = (key, data, ttl) => {
        const now = new Date();
        const item = {
            value: data,
            expiry: now.getTime() + ttl * 60000, // TTL ในหน่วยนาที
        };
        console.log("Saving data to cache:", key, item);
        localStorage.setItem(key, JSON.stringify(item));
        console.log("Cached data after save:", localStorage.getItem(key));
    };
    async componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
        NProgress.start();
        this.checklogin();
        this.startCountdown();
        const search = window.location.href;
        var test = search.split("aff=")[1]
        if (test != undefined) {
            this.saveclickaff(test.split("&")[0]);
        }
        var params = search.split("reg=")[1]
        if (params != undefined) {
            this.saveclick(params);
            this.setState({
                userrecomend: params.split("&")[0],
            });
        }

        var tokenf = localStorage.getItem('aff');
        if (tokenf != null) {
            var codev = JSON.parse(tokenf)
            //   console.log(codev);
            var Username = codev.username;
            this.setState({
                userrecomend: Username,
            });
        }

        var tokenx = localStorage.getItem('register');
        if (tokenx != null) {
            var codev = JSON.parse(tokenx)
            // console.log(codev);
            var Username = codev.username;
            this.setState({
                userrecomend: Username,
            });
        }

        this.getcallprovidor();
        this.promotionlist();
        try {
            const pathname = window.location.pathname.split("/");
            const mainSettingKey = "mainSettingCache";
            const mainSettingTTL = 30 * 60 * 1000; // 30 นาที
            var token = localStorage.getItem('auth_token');
            var Playerid = null
            if (token != null) {
                var decoded = jwt_decode(token);
                Playerid = decoded.message.playerid;
            }

            // ตรวจสอบแคชสำหรับ main setting
            const mainSettingCache = this.getCache(mainSettingKey, mainSettingTTL);

            if (mainSettingCache) {
                console.log("Using cached data for main setting");
                const data = mainSettingCache;

                document.title = data.tblsetting[0].title;
                const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
                link.type = 'image/svg+xml';
                link.rel = 'icon';
                link.href = data.tblsetting[0].imglogo;
                document.getElementsByTagName('head')[0].appendChild(link);

                this.setState({
                    webname: data.tblsetting[0].Description,
                    logoimg: data.tblsetting[0].imglogo,
                    whellsetting: data.tblwheelsetting,
                    maxwithdrawstatus: data.tblcatagoryx[0].withdrawmax,
                    luckywheeldisplay: data.tblwheelsetting[0].status === 1,
                    linelink: data.message[1].value,
                    webnamex: data.message[7].value,
                    describemain: data.tblsetting[0].title,
                    System: data.tblcatagoryx[0].System,
                    cashoutSet: data.tblcashoutsetting[0],
                    inviteSet: data.tblinvitedsetting[0],
                    datas: data.message,
                    limit: { min: data.tblcatagoryx[0].limitdeposit, max: data.tblcatagoryx[0].maxdeposit },
                });
            } else {
                instance.post("/api/v1/mainsetting", {
                    System: Systems,
                    Playerid: Playerid,
                }).then((res) => {
                    if (res.data.status === 200) {
                        console.log("luckywheeldisplay", res.data);
                        const datas = res.data.message;
                        const min = res.data.tblcatagoryx[0].limitdeposit;
                        const max = res.data.tblcatagoryx[0].maxdeposit;
                        const maxwithdrawstatus = res.data.tblcatagoryx[0].withdrawmax;
                        const limit = { min, max };
                        const luckywheeldisplay = res.data.tblwheelsetting[0].status === 1;

                        document.title = res.data.tblsetting[0].title;
                        const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
                        link.type = 'image/svg+xml';
                        link.rel = 'icon';
                        link.href = res.data.tblsetting[0].imglogo;
                        document.getElementsByTagName('head')[0].appendChild(link);

                        this.setState({
                            webname: res.data.tblsetting[0].Description,
                            logoimg: res.data.tblsetting[0].imglogo,
                            whellsetting: res.data.tblwheelsetting,
                            maxwithdrawstatus: maxwithdrawstatus,
                            luckywheeldisplay: luckywheeldisplay,
                            linelink: datas[1].value,
                            webnamex: datas[7].value,
                            describemain: res.data.tblsetting[0].title,
                            System: res.data.tblcatagoryx[0].System,
                            cashoutSet: res.data.tblcashoutsetting[0],
                            inviteSet: res.data.tblinvitedsetting[0],
                            datas: datas,
                            limit: limit,
                        });

                        this.setCache(mainSettingKey, res.data);
                    }
                });
            }

            // ตรวจสอบแคชสำหรับ getTrue
            const getTrueKey = `getTrueCache_${Username}_${Systems}`;
            const getTrueTTL = 30 * 60 * 1000; // 30 นาที
            const getTrueCache = this.getCache(getTrueKey, getTrueTTL);

            if (getTrueCache && getTrueCache.length !== 0 && getTrueCache.length != undefined) {
                console.log("Using cached data for getTrue", getTrueCache.length);
                this.setState({
                    trueaccount: getTrueCache,
                });
            } else {
                // ไม่มี Cache หรือ Cache มี length = 0
                instance
                    .post(
                        "/api/v1/gettrue",
                        {
                            userid: Username,
                            System: Systems,
                        },
                        {
                            headers: { Authorization: `Bearer ${token}` },
                        }
                    )
                    .then((res) => {
                        if (res.data.status === 200) {
                            console.log(res);
                            const trueAccount = res.data.message.tbltruewallet[0];

                            // อัปเดต State และเก็บข้อมูลลงใน Cache
                            this.setState({
                                trueaccount: trueAccount,
                            });
                            this.setCache(getTrueKey, trueAccount);
                        }
                    })
                    .catch((err) => {
                        console.error("Error fetching getTrue data:", err);
                    });
            }


            if (pathname.length > 1) {
                console.log(pathname);
                if (pathname[1] == "") {
                    console.log(250, pathname[1]);
                    this.setState({
                        tabselect: 0,
                    });
                }
                else if (pathname[1] + "/" + pathname[2] == "game/casino") {
                    this.claerclass();
                    var i, tabcontent, tablinks;
                    if (!isMobile) {
                        tabcontent = document.getElementById("gamecasino");
                        tabcontent.className = "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-1fh7av9";
                    }
                    else if (isMobile) {
                        tabcontent = document.getElementById("gamecasino");
                        tabcontent.className = "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-17axtqp";
                    }

                    await this.getgame('casino', pathname[3], "993", true)
                    this.setState({
                        tabselect: 10,
                    });
                }
                else if (pathname[1] + "/" + pathname[2] == "game/hit") {
                    this.claerclass();
                    var i, tabcontent, tablinks;
                    if (!isMobile) {
                        tabcontent = document.getElementById("gamehit");
                        tabcontent.className = "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-1fh7av9";
                    }
                    else if (isMobile) {
                        tabcontent = document.getElementById("gamehit");
                        tabcontent.className = "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-17axtqp";
                    }
                    await this.getgame('slot', pathname[3], "992")
                    await this.getprovidor('slot', pathname[3], "2255")
                    this.setState({
                        tabselect: 11,
                    });
                }
                else if (pathname[1] + "/" + pathname[2] == "game/providor") {
                    this.claerclass();
                    var i, tabcontent, tablinks;
                    if (!isMobile) {
                        tabcontent = document.getElementById("gameprovidor");
                        tabcontent.className = "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-1fh7av9";
                    }
                    else if (isMobile) {
                        tabcontent = document.getElementById("gameprovidor");
                        tabcontent.className = "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-17axtqp";
                    }
                    await this.getgame('casino', pathname[3], "994", true)
                    this.setState({
                        tabselect: 18,
                    });
                }
                else if (pathname[1] + "/" + pathname[2] == "game/fishing") {
                    this.claerclass();
                    var i, tabcontent, tablinks;
                    if (!isMobile) {
                        tabcontent = document.getElementById("gamefishing");
                        tabcontent.className = "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-1fh7av9";
                    }
                    else if (isMobile) {
                        tabcontent = document.getElementById("gamefishing");
                        tabcontent.className = "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-17axtqp";
                    }
                    await this.getgame('fishing', pathname[3], "995")
                    this.setState({
                        tabselect: 12,
                    });
                }
                else if (pathname[1] == "loss") {
                    this.setState({
                        tabselect: 25,
                    });
                }
                else if (pathname[1] + "/" + pathname[2] == "game/slot") {
                    this.claerclass();
                    var i, tabcontent, tablinks;
                    if (!isMobile) {
                        tabcontent = document.getElementById("gameslot");
                        tabcontent.className = "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-1fh7av9";
                    }
                    else if (isMobile) {
                        tabcontent = document.getElementById("gameslot");
                        tabcontent.className = "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-17axtqp";
                    }
                    await this.getgame('slot', pathname[3], "995")
                    this.setState({
                        tabselect: 13,
                    });
                }
                else if (pathname[1] == "register") {
                    this.setState({
                        tabselect: 22,
                    });
                }
                else if (pathname[1] == "signin") {
                    this.setState({
                        tabselect: 23,
                    });
                }
                else if (pathname[1] + "/" + pathname[2] == "game/sport") {
                    this.claerclass();
                    var i, tabcontent, tablinks;
                    if (!isMobile) {
                        tabcontent = document.getElementById("gamesport");
                        tabcontent.className = "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-1fh7av9";
                    }
                    else if (isMobile) {
                        tabcontent = document.getElementById("gamesport");
                        tabcontent.className = "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-17axtqp";
                    }
                    await this.getgame('sport', pathname[3], "996", true)
                    // await this.getprovidor('sport', pathname[3],"996")
                    this.setState({
                        tabselect: 14,
                    });
                }
                else if (pathname[1] + "/" + pathname[2] == "game/list") {
                    console.log(672, pathname[3]);
                    this.claerclass();
                    this.getgame('slot', pathname[3])
                    this.setState({
                        tabselect: 20,
                        partner: pathname[3]
                    });
                }
                else if (pathname[1] == "event") {
                    this.setState({
                        tabselect: 16,
                    });
                }
                else if (pathname[1] + "/" + pathname[2] == "game/lotto") {
                    this.claerclass();
                    var i, tabcontent, tablinks;
                    if (!isMobile) {
                        tabcontent = document.getElementById("gamelotto");
                        tabcontent.className = "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-1fh7av9";
                    }
                    else if (isMobile) {
                        tabcontent = document.getElementById("gamelotto");
                        tabcontent.className = "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-17axtqp";
                    }
                    this.setState({
                        tabselect: 17,
                    });
                }
                else if (pathname[1] + "/" + pathname[2] == "manager/withdraw") {
                    // console.log(1232323)
                    if (this.state.loginstate) {
                        this.setState({
                            tabselect: 2,
                        });
                    }
                    else {
                        this.setState({
                            tabselect: 0,
                        });
                    }
                }
                else if (pathname[1] + "/" + pathname[2] == "manager/deposit") {
                    if (this.state.loginstate) {
                        this.setState({
                            tabselect: 1,
                        });
                    }
                    else {
                        this.setState({
                            tabselect: 0,
                        });
                    }
                }
                else if (pathname[1] + "/" + pathname[2] == "manager/statement") {
                    if (this.state.loginstate) {
                        this.setState({
                            tabselect: 3,
                        });
                    }
                    else {
                        this.setState({
                            tabselect: 0,
                        });
                    }
                }
                else if (pathname[1] == "profile") {
                    if (this.state.loginstate) {
                        this.setState({
                            tabselect: 8,
                        });
                    }
                    else {
                        this.setState({
                            tabselect: 0,
                        });
                    }
                }
                else if (pathname[1] + "/" + pathname[2] == "refer/overview") {
                    this.setState({
                        tabselect: 4,
                        refer: 1
                    });
                }
                else if (pathname[1] + "/" + pathname[2] == "refer/member") {
                    console.log(63855555);
                    this.setState({
                        tabselect: 4,
                        refer: 2
                    });
                }
                else if (pathname[1] + "/" + pathname[2] == "refer/income-summary") {
                    this.setState({
                        tabselect: 4,
                        refer: 3
                    });
                }
                else if (pathname[1] + "/" + pathname[2] == "refer/share-network") {
                    this.setState({
                        tabselect: 4,
                        refer: 4
                    });
                }
                else if (pathname[1] == "rebate") {
                    this.setState({
                        tabselect: 5,
                    });
                }
                else if (pathname[1] + "/" + pathname[2] == "manager/promotion") {
                    this.setState({
                        tabselect: 7,
                    });
                }
                else if (pathname[1] + "/" + pathname[2] == "manager/ranking") {
                    this.setState({
                        tabselect: 27,
                    });
                }
                else if (pathname[1] + "/" + pathname[2] == "minigame/luckywheel") {
                    this.setState({
                        tabselect: 28,
                    });
                }
                else if (pathname[1] == "listgameall") {
                    await this.getgame(pathname[2], pathname[3]);
                    this.setState({
                        tabselect: 15,
                    });
                }
            }
            try {
                var token = localStorage.getItem('auth_token');
                if (token != null) {
                    var decoded = jwt_decode(token);
                    var Username = decoded.message.playerid;
                    this.rechecklogin(Username, token);
                    this.checkuseradvice(Username);
                    this.setState({
                        userid: decoded.message.playerid,
                        username: decoded.message.playerid,
                        firstname: decoded.message.firstname,
                        lastname: decoded.message.lastname,
                        loginstate: true,
                    });
                }
                else {
                    this.setState({
                        loginstate: false,
                    });
                }


            }
            catch (error) {
                console.log(185222, error);
            }
        }
        catch (error) {
            console.log(185222, error);
        }
        NProgress.set(0.4);
        if (token != null) {
            var decoded = jwt_decode(token);
            let Username = decoded.message.playerid;
            instance.post("/api/v1/getqrcode", {
                username: Username,
                System: Systems,
                amount: 50
            }, {
                headers: { Authorization: `Bearer ${token}` }
            }).then(async (res) => {
                //////console.log(res.data.picture)
                console.log(16888, res.data.tblmain)
                this.setState({
                    mainacc: res.data.mainacc,
                    listacc: res.data.tblmain,
                    paymentgateway: res.data.tblpaymentgatewayx,
                });
            });
        }

        await this.getgame('casino', null, "994");
        NProgress.done();


    }


    async checktab(e, index) {
        console.log(11111, index);
        if (index == 2) {
            //   await this.notify("error", 'ปิดระบบ QRcode ขัวคราว');
            this.setState({
                tabselectdeposit: index,
                tabselect: 1,
            });

        }
        else {
            this.setState({
                tabselectdeposit: index,
                tabselect: 1,
            });

            if (index == 3) {
                this.confirmdeposit(e, 0)
            }
        }
    }
    openAccout(evt, cityName) {
        var i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("x-menu-account-list-sidebar");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].className += " -open";
        }
    }
    async checkuseradvice(username) {
        instance.post("/api/v1/checkuseradvice", {
            User_id: username,
            page: 1,
            System: Systems,
        }).then((res) => {
            if (res.data.status === 200) {
                console.log("statsstatsstatsstats", res.data.message);
                this.setState({
                    stats: res.data.message.stats,
                    reports: res.data.message.reports,
                    useradvice: res.data.message.useradvicelist
                });
            }
            else {
                // console.log(res.data);
                // this.CheckThisGoalError();
            }
        });
    }


    openAccoutcan(evt, cityName) {
        var i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("x-menu-account-list-sidebar");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].className = "x-menu-account-list-sidebar";
        }
    }

    async rechecklogin(Username, token) {

        try {
            console.log(4555555555555);
            const search = window.location.href;
            const paramx = new URLSearchParams(search);
            // const space = search.split("/")
            const space = window.location.host
            var userhash = md5(Username);
            //  const linkaff = "https://pgline88.com/?register=" + userhash;
            const linkaff = "https://" + space + "/register?reg=" + userhash;
            instance.post("/api/v1/checkpoint", {
                userid: Username,
                System: Systems,
                Function: 'rechecklogin-navbar.js'
            }, {
                headers: { Authorization: `Bearer ${token}` }
            }).then(async (res) => {

                if (res.data.status === 200) {
                    console.log(2142, res.data.tblrankall);
                    localStorage.setItem('auth_token', res.data.token)
                    console.log(1067, res.data.userafftoday, res.data.useraffall);
                    var rankall = res.data.tblrankall;
                    const found = rankall.find(obj => {
                        return obj.checked === 1;
                    });
                    this.setState({
                        tblrankall: res.data.tblrankall,
                        rankpic: res.data.tblrank?.image,
                        rankname: res.data.tblrank?.name,
                        linkaff: linkaff,
                        rankob: res.data.tblrank,
                        rankpoint: res.data.rankpoint,
                        fullname: res.data.message.firstname,
                        accnumber: res.data.message.accnumber,
                        userid: res.data.message.member_id,
                        tblprofile: res.data.message,
                        username: res.data.message.playerid,
                        fname: res.data.message.firstname,
                        point: res.data.message.Point,
                        balance: res.data.databalance,
                        afficiatetoday: res.data.userafftoday,
                        afficiateall: res.data.useraffall,
                        buttoncliam: (found === undefined) ? true : false,
                        loginstate: true,
                    });
                    this.calllisthistory(Username);
                }
                else if (res.data.status === 401) {
                    localStorage.clear();
                    await this.notify("error", 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง');
                    window.location.href = '/';
                }

            }).catch(async (err) => {
                console.log(err)
                if (err.response.status === 401 || err.response.status === 403) {
                    localStorage.clear();
                    await this.notify("error", 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง');
                    window.location.href = '/';
                }
            });
        }
        catch (ex) {
            //////console.log(ex);
        }

    }
    checkpro = async (e, proid) => {
        console.log(248, proid);
        var Username = "";
        var token = localStorage.getItem('auth_token');
        if (token != null) {
            var decoded = jwt_decode(token);
            Username = decoded.message.playerid;
        }
        await instance.post("/api/v1/checkpromotion", {
            userid: Username,
            System: Systems,
            Proid: proid,
        }, {
            headers: { Authorization: `Bearer ${token}` }
        }).then(async (res) => {
            console.log(res.data)
            if (res.data.status === 200) {
                //console.log(res.data);
                if (res.data.type === 0) {
                    this.setState({
                        modalpromotion: true,
                        prodetail: res.data.message.tblpromotions[0],
                    });
                }
                else if (res.data.type === 1) {
                    this.setState({
                        modalpromotion: true,
                        proderedeem: res.data.message.tblpromotionredeems,
                    });
                }



            }
            else if (res.data.status === 201) {
                this.setState({
                    showmodal: true,
                    prodetail: [],
                    picnonpro: "/media/cache/strip/202109/promotion/7AB3F352-6DE2-44CC-A5FC-D4359FCB2122.png",
                });

            }
            else if (res.data.status === 202) {
                await this.notify("success", 'ท่านใช้โปรโมชั่นค้างไว้ สามารถเติมเงินได้เลย');
                return false;
            }

        }).catch(async (err) => {
            //   console.log(err);
            if (err.response.status === 401 || err.response.status === 403) {
                localStorage.clear();
                await this.notify("error", 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง');
                window.location.href = '/';
            }
        });
    }


    validateLogout = (e) => {
        localStorage.removeItem('auth_token', '')
        this.setState({
            userid: null,
            username: null,
            password: null,
            firstname: null,
            lastname: null,
            point: null,
            loginstate: false,
        });
    }
    logout = (e) => {
        // e.preventDefault();
        let errors = this.validateLogout();
        if (errors === true) {
            // alert("You are successfully signed in...");
            window.location.reload()
        } else {

            this.setState({
                errors: errors,
                formSubmitted: true
            });
            window.location.reload()
        }
    }
    waitnotify = async () => {
        setTimeout(() => { console.log('World!') }, 5000)
    }
    notify = async (mode, msg) => {
        console.log(mode, msg);
        if (mode == "success") {
            await toast.success(msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
        else if (mode == "warning") {
            await toast.warning(msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
        else if (mode == "error") {
            await toast.error(msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
        else if (mode == "info") {
            await toast.info(msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
        await this.waitnotify();
    }
    async opencliamcashout(evt, cityName) {
        var str = "ต้องการถอนโบนัสจำนวน 0 เครดิต ใช่หรือไม่?\n" +
            "คุณจะได้รับเครดิตคืนจาก (สล็อต: 0 คาสิโนสด: 0 กีฬา: 0)\n";
        Swal.fire({
            title: "ยืนยันการถอนโบนัส",
            html: '<pre>' + str + '</pre>',
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#81d539",
            cancelButtonColor: "#ea133d",
            confirmButtonText: "ยืนยัน",
            cancelButtonText: "ยกเลิก"
        }).then(async (result) => {
            if (result.isConfirmed) {
                var token = localStorage.getItem('auth_token');
                if (token != null) {
                    var decoded = jwt_decode(token);

                    var Username = decoded.message.playerid;
                    await instance.post("/api/v1/cliamcashout", {
                        userid: Username,
                        System: Systems,
                    }, {
                        headers: { Authorization: `Bearer ${token}` }
                    }).then(async (res) => {
                        if (res.data.status === 200) {
                            var decoded = jwt_decode(token);
                            var Username = decoded.message.playerid;
                            this.rechecklogin(Username, token);
                            this.notify("success", 'ทำรายการรับเงินเรียบร้อย');

                        }
                        else if (res.data.status === 201) {
                            await this.notify("error", 'ไม่สามารถทำรายการได้ ไม่มียอดเงิน');
                            return false;
                        }
                        else if (res.data.status === 202) {
                            await this.notify("error", 'ไม่สามารถทำรายการได้');
                            return false;
                        }

                    }).catch(async (err) => {
                        // console.log(err);
                        if (err.response.status === 401 || err.response.status === 403) {
                            localStorage.clear();
                            await this.notify("error", 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง');
                            window.location.href = '/';
                        }
                    });
                }
                else {
                    await this.notify("error", 'คุณยังไม่ได้ล๊อคอิน');
                }
            }
        });

    }
    render() {
        const { ios } = this.state;
        const { oldPassword, newPassword, confirmPassword, currentIndexA, currentIndex } = this.state;
        const { isPopupOpen, selectedItem } = this.state;
        const { prodetail, pathname, prolistx, categorylist, fishinglist, sportlist, slotlist, listitallgame } = this.state
        const { loginstate, fullname, loading, loader, userid, username, point, firstname, lastname, balance, proderedeemlist, linkaff, tblprofile } = this.state
        const { accnumber, qrstep, paymentgateway, listacc, mainacc, trueaccount, tabselect, tabselectdeposit } = this.state
        const { tblnextrank, rankob, rankpoint } = this.state
        var accnumberx;
        if (accnumber != null) {
            accnumberx = accnumber[0];
        }
        //  console.log("listacc", listacc)
        const menuItems = [
            { id: "6", name: "กสิกรไทย KBANK", imgSrc: "https://tensugame.com/_next/image?url=https%3A%2F%2Fd12lxcfs2l5om8.cloudfront.net%2Fnew_webimages%2Fbank%2Fkbank.webp&w=64&q=75", classes: "MuiButtonBase-root MuiMenuItem-root MuiMenuItem-gutters css-1sno93h" },
            { id: "5", name: "ไทยพาณิชย์ SCB", imgSrc: "https://tensugame.com/_next/image?url=https%3A%2F%2Fd12lxcfs2l5om8.cloudfront.net%2Fnew_webimages%2Fbank%2Fscb.webp&w=64&q=75", classes: "MuiButtonBase-root MuiMenuItem-root MuiMenuItem-gutters css-atderz" },
            { id: "7", name: "กรุงเทพ BBL", imgSrc: "https://tensugame.com/_next/image?url=https%3A%2F%2Fd12lxcfs2l5om8.cloudfront.net%2Fnew_webimages%2Fbank%2Fbbl.webp&w=64&q=75", classes: "MuiButtonBase-root MuiMenuItem-root MuiMenuItem-gutters css-11y6xcv" },
            { id: "14", name: "กรุงไทย KTB", imgSrc: "https://tensugame.com/_next/image?url=https%3A%2F%2Fd12lxcfs2l5om8.cloudfront.net%2Fnew_webimages%2Fbank%2Fktb.webp&w=64&q=75", classes: "MuiButtonBase-root MuiMenuItem-root MuiMenuItem-gutters css-9xnm3b" },
            { id: "8", name: "กรุงศรี BAY", imgSrc: "https://tensugame.com/_next/image?url=https%3A%2F%2Fd12lxcfs2l5om8.cloudfront.net%2Fnew_webimages%2Fbank%2Fbay.webp&w=64&q=75", classes: "MuiButtonBase-root MuiMenuItem-root MuiMenuItem-gutters css-qjyshk" },
            { id: "11", name: "ทหารไทยธนชาต ttb", imgSrc: "https://tensugame.com/_next/image?url=https%3A%2F%2Fd12lxcfs2l5om8.cloudfront.net%2Fnew_webimages%2Fbank%2Fttb.webp&w=64&q=75", classes: "MuiButtonBase-root MuiMenuItem-root MuiMenuItem-gutters css-1xo111q" },
            { id: "15", name: "ออมสิน GSB", imgSrc: "https://tensugame.com/_next/image?url=https%3A%2F%2Fd12lxcfs2l5om8.cloudfront.net%2Fnew_webimages%2Fbank%2Fgsb.webp&w=64&q=75", classes: "MuiButtonBase-root MuiMenuItem-root MuiMenuItem-gutters css-u90vr8" },
            { id: "16", name: "ธ.ก.ส. BAAC", imgSrc: "https://tensugame.com/_next/image?url=https%3A%2F%2Fd12lxcfs2l5om8.cloudfront.net%2Fnew_webimages%2Fbank%2Fbaac.webp&w=64&q=75", classes: "MuiButtonBase-root MuiMenuItem-root MuiMenuItem-gutters css-1tacmq9" },
            //  { id: "067", name: "ทิสโก้ TISCO", imgSrc: "https://tensugame.com/_next/image?url=https%3A%2F%2Fd12lxcfs2l5om8.cloudfront.net%2Fnew_webimages%2Fbank%2Ftsco.webp&w=64&q=75", classes: "MuiButtonBase-root MuiMenuItem-root MuiMenuItem-gutters css-10llw9i" },
            { id: "9", name: "เกียรตินาคิน KK", imgSrc: "https://tensugame.com/_next/image?url=https%3A%2F%2Fd12lxcfs2l5om8.cloudfront.net%2Fnew_webimages%2Fbank%2Fkk.webp&w=64&q=75", classes: "MuiButtonBase-root MuiMenuItem-root MuiMenuItem-gutters css-1txbj9z" },
            //  { id: "022", name: "ซีไอเอ็มบี CIMB", imgSrc: "https://tensugame.com/_next/image?url=https%3A%2F%2Fd12lxcfs2l5om8.cloudfront.net%2Fnew_webimages%2Fbank%2Fcimb.webp&w=64&q=75", classes: "MuiButtonBase-root MuiMenuItem-root MuiMenuItem-gutters css-1uculzh" },
            //  { id: "024", name: "ยูโอบี UOB", imgSrc: "https://tensugame.com/_next/image?url=https%3A%2F%2Fd12lxcfs2l5om8.cloudfront.net%2Fnew_webimages%2Fbank%2Fuob.webp&w=64&q=75", classes: "MuiButtonBase-root MuiMenuItem-root MuiMenuItem-gutters css-mnuwuu" },
            //  { id: "073", name: "แลนด์ แอนด์ เฮ้าส์ LH", imgSrc: "https://tensugame.com/_next/image?url=https%3A%2F%2Fd12lxcfs2l5om8.cloudfront.net%2Fnew_webimages%2Fbank%2Flhbank.webp&w=64&q=75", classes: "MuiButtonBase-root MuiMenuItem-root MuiMenuItem-gutters css-9um8mf" },
            //  { id: "031", name: "เอชเอสบีซี HSBC", imgSrc: "https://tensugame.com/_next/image?url=https%3A%2F%2Fd12lxcfs2l5om8.cloudfront.net%2Fnew_webimages%2Fbank%2Fhsbc.webp&w=64&q=75", classes: "MuiButtonBase-root MuiMenuItem-root MuiMenuItem-gutters css-b0b9u5" },
            { id: "53", name: "อาคารสงเคราะห์ GHB", imgSrc: "https://tensugame.com/_next/image?url=https%3A%2F%2Fd12lxcfs2l5om8.cloudfront.net%2Fnew_webimages%2Fbank%2Fghba.webp&w=64&q=75", classes: "MuiButtonBase-root MuiMenuItem-root MuiMenuItem-gutters css-4xpz60" },
            { id: "54", name: "ธนาคารอิสลามแห่งประเทศไทย", imgSrc: "https://cdn.javisx.com/image/77e99108-dd75-4d15-a8b1-8c11685448f4.PNG", classes: "MuiButtonBase-root MuiMenuItem-root MuiMenuItem-gutters css-4xpz60" },
            //     { id: "070", name: "ไอซีบีซี ICBC", imgSrc: "https://tensugame.com/_next/image?url=https%3A%2F%2Fd12lxcfs2l5om8.cloudfront.net%2Fnew_webimages%2Fbank%2Ficbc.webp&w=64&q=75", classes: "MuiButtonBase-root MuiMenuItem-root MuiMenuItem-gutters css-jdfhpn" },
            { id: "99", name: "ทรูวอลเลท True", imgSrc: "https://cdn.javisx.com/image/ca805e12-cad1-41b7-84bf-3fb2c637143f.png", classes: "MuiButtonBase-root MuiMenuItem-root MuiMenuItem-gutters css-jdfhpyy" },
        ];
        // const isMobile = true;
        return (
            <>

                <div aria-hidden="true" className="MuiBackdrop-root css-xuaqpw"
                    style={{ opacity: 0, visibility: "hidden" }}
                >
                    <span
                        className="MuiCircularProgress-root MuiCircularProgress-indeterminate MuiCircularProgress-colorInherit css-8yzpf7"
                        style={{ width: 40, height: 40 }}
                        role="progressbar"
                    >
                        <svg className="MuiCircularProgress-svg css-13o7eu2" viewBox="22 22 44 44">
                            <circle
                                className="MuiCircularProgress-circle MuiCircularProgress-circleIndeterminate css-14891ef"
                                cx={44}
                                cy={44}
                                r="20.2"
                                fill="none"
                                strokeWidth="3.6"
                            />
                        </svg>
                    </span>
                </div>
                <div aria-hidden="false">
                    <main >
                        <header style={{ display: loginstate && tabselect !== 22 ? "" : "none" }} className="MuiPaper-root MuiPaper-elevation MuiPaper-elevation0 MuiAppBar-root MuiAppBar-colorPrimary MuiAppBar-positionFixed mui-fixed css-11y9v2g"  >
                            <div className="MuiToolbar-root MuiToolbar-gutters MuiToolbar-regular css-i6s8oy">
                                <div className={isMobile ? "MuiTypography-root MuiTypography-body1 css-hsnrgc" : "MuiTypography-root MuiTypography-body1 css-156ekru"}>
                                    <div className="MuiGrid-root MuiGrid-container css-16a96b4" style={{ display: isMobile ? "none" : "" }}>
                                        <div className="MuiBox-root css-1gl14dv" style={{ display: loginstate ? "" : "none" }} aria-label="คลิกเพื่อเปิดเมนู">
                                            <button
                                                onClick={(e) => {
                                                    this.setState({
                                                        modal: true,
                                                    });
                                                }}
                                                className="MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeSmall css-i29csa"
                                                tabIndex={0}
                                                type="button"
                                            >
                                                <div className="loader"
                                                    style={{ color: "white", marginLeft: 0, marginRight: 0 }}
                                                />
                                                <span className="MuiTouchRipple-root css-w0pj6f" />
                                            </button>
                                        </div>
                                        <div className="MuiBox-root css-0" />
                                        <div className="MuiBox-root css-0" />
                                        <div className="MuiBox-root css-s8vbhp">
                                            <h1 className="MuiTypography-root MuiTypography-body1 css-1dd7od3">
                                                รหัสสมาชิก
                                            </h1>
                                            <h1 className="MuiTypography-root MuiTypography-body1 css-xf2lnq">
                                                {username}
                                            </h1>
                                        </div>
                                    </div>
                                    <span
                                        style={{
                                            boxSizing: "border-box",
                                            display: isMobile ? "inline-block" : "none",
                                            overflow: "hidden",
                                            width: "initial",
                                            height: "initial",
                                            background: "none",
                                            opacity: 1,
                                            border: 0,
                                            margin: 0,
                                            padding: 0,
                                            position: "relative",
                                            maxWidth: "100%"
                                        }}
                                    >
                                        <span
                                            style={{
                                                boxSizing: "border-box",
                                                display: "block",
                                                width: "initial",
                                                height: "initial",
                                                background: "none",
                                                opacity: 1,
                                                border: 0,
                                                margin: 0,
                                                padding: 0,
                                                maxWidth: "100%"
                                            }}
                                        >
                                            <img
                                                alt=""
                                                aria-hidden="true"
                                                src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2783%27%20height=%2732%27/%3e"
                                                style={{
                                                    display: "block",
                                                    maxWidth: "100%",
                                                    width: "initial",
                                                    height: "initial",
                                                    background: "none",
                                                    opacity: 1,
                                                    border: 0,
                                                    margin: 0,
                                                    padding: 0
                                                }}
                                            />
                                        </span>
                                        <a role="button" onClick={(e) => {
                                            this.checkurl(e, "/")
                                        }}>
                                            <img
                                                alt="logo"
                                                src={this.state.logoimg}
                                                decoding="async"
                                                data-nimg="intrinsic"
                                                style={{
                                                    position: "absolute",
                                                    inset: 0,
                                                    boxSizing: "border-box",
                                                    padding: 0,
                                                    border: "none",
                                                    margin: "auto",
                                                    display: "block",
                                                    width: 0,
                                                    height: 0,
                                                    minWidth: "100%",
                                                    maxWidth: "100%",
                                                    minHeight: "150%",
                                                    maxHeight: "100%",
                                                    objectFit: "contain"
                                                }}

                                            />
                                        </a>
                                    </span>

                                </div>
                                <div className="MuiTypography-root MuiTypography-body1 css-4z09sj" style={{ display: isMobile ? "none" : "" }}>
                                    <span
                                        style={{
                                            boxSizing: "border-box",
                                            display: "inline-block",
                                            overflow: "hidden",
                                            width: "initial",
                                            height: "initial",
                                            background: "none",
                                            opacity: 1,
                                            border: 0,
                                            margin: 0,
                                            padding: 0,
                                            position: "relative",
                                            maxWidth: "100%"
                                        }}
                                    >
                                        <span
                                            style={{
                                                boxSizing: "border-box",
                                                display: "block",
                                                width: "initial",
                                                height: "initial",
                                                background: "none",
                                                opacity: 1,
                                                border: 0,
                                                margin: 0,
                                                padding: 0,
                                                maxWidth: "100%"
                                            }}
                                        >
                                            <img
                                                alt=""
                                                aria-hidden="true"
                                                src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%27140%27%20height=%2750%27/%3e"
                                                style={{
                                                    display: "block",
                                                    maxWidth: "100%",
                                                    width: "initial",
                                                    height: "initial",
                                                    background: "none",
                                                    opacity: 1,
                                                    border: 0,
                                                    margin: 0,
                                                    padding: 0
                                                }}
                                            />
                                        </span>
                                        <a role="button" onClick={(e) => {
                                            this.checkurl(e, "/")
                                        }}>
                                            <img
                                                alt="logo"
                                                src={this.state.logoimg}
                                                decoding="async"
                                                data-nimg="intrinsic"
                                                style={{
                                                    position: "absolute",
                                                    inset: 0,
                                                    boxSizing: "border-box",
                                                    padding: 0,
                                                    border: "none",
                                                    margin: "auto",
                                                    display: "block",
                                                    width: 0,
                                                    height: 0,
                                                    minWidth: "100%",
                                                    maxWidth: "100%",
                                                    minHeight: "150%",
                                                    maxHeight: "100%",
                                                    objectFit: "contain"
                                                }}
                                            />
                                        </a>
                                    </span>
                                </div>
                                <div className="MuiTypography-root MuiTypography-body1 css-7cpncs">
                                    <div className="MuiBox-root css-vxcmzt">
                                        <div style={{ display: "flex", position: "relative", left: 20 }}>
                                            <span
                                                style={{
                                                    boxSizing: "border-box",
                                                    display: "inline-block",
                                                    overflow: "hidden",
                                                    width: "initial",
                                                    height: "initial",
                                                    background: "none",
                                                    opacity: 1,
                                                    border: 0,
                                                    margin: 0,
                                                    padding: 0,
                                                    position: "relative",
                                                    maxWidth: "100%"
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        boxSizing: "border-box",
                                                        display: "block",
                                                        width: "initial",
                                                        height: "initial",
                                                        background: "none",
                                                        opacity: 1,
                                                        border: 0,
                                                        margin: 0,
                                                        padding: 0,
                                                        maxWidth: "100%"
                                                    }}
                                                >
                                                    <img
                                                        alt=""
                                                        aria-hidden="true"
                                                        src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2745%27%20height=%2745%27/%3e"
                                                        style={{
                                                            display: isMobile ? "none" : "block",
                                                            maxWidth: "100%",
                                                            width: "initial",
                                                            height: "initial",
                                                            background: "none",
                                                            opacity: 1,
                                                            border: 0,
                                                            margin: 0,
                                                            padding: 0
                                                        }}
                                                    />
                                                    <img
                                                        alt=""
                                                        aria-hidden="true"
                                                        src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2725%27%20height=%2725%27/%3e"
                                                        style={{
                                                            display: isMobile ? "block" : "none",
                                                            maxWidth: "100%",
                                                            width: "initial",
                                                            height: "initial",
                                                            background: "none",
                                                            opacity: 1,
                                                            border: 0,
                                                            margin: 0,
                                                            padding: 0
                                                        }}
                                                    />
                                                </span>
                                                <img
                                                    alt="coin"
                                                    srcSet="/build/web/igame-index-lobby-wm/img/coin.webp 1x, /build/web/igame-index-lobby-wm/img/coin.webp 2x"
                                                    src="/build/web/igame-index-lobby-wm/img/coin.webp"
                                                    decoding="async"
                                                    data-nimg="intrinsic"
                                                    style={{
                                                        zIndex: 1,
                                                        position: "absolute",
                                                        inset: 0,
                                                        boxSizing: "border-box",
                                                        padding: 0,
                                                        border: "none",
                                                        margin: "auto",
                                                        display: "block",
                                                        width: 0,
                                                        height: 0,
                                                        minWidth: "100%",
                                                        maxWidth: "100%",
                                                        minHeight: "100%",
                                                        maxHeight: "100%",
                                                        objectFit: "contain"
                                                    }}
                                                />
                                            </span>
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                margin: "auto",
                                                borderRadius: 14,
                                                background: "rgb(255, 255, 255)",
                                                padding: "0px 10px"
                                            }}
                                        >
                                            <h1 className={isMobile ? "MuiTypography-root MuiTypography-body1 css-1b2wo20" : "MuiTypography-root MuiTypography-body1 css-1wecvnv"}>
                                                <div>{balance}</div>
                                            </h1>
                                        </div>
                                        <div style={{ display: isMobile ? "none" : "flex", margin: "auto" }}>
                                            <button
                                                className="MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeMedium css-1yxmbwk"
                                                tabIndex={0}
                                                type="button"
                                            >
                                                <svg
                                                    className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1okqa6k"
                                                    focusable="false"
                                                    aria-hidden="true"
                                                    viewBox="0 0 24 24"
                                                    data-testid="RefreshIcon"
                                                >
                                                    <path d="M17.65 6.35C16.2 4.9 14.21 4 12 4c-4.42 0-7.99 3.58-7.99 8s3.57 8 7.99 8c3.73 0 6.84-2.55 7.73-6h-2.08c-.82 2.33-3.04 4-5.65 4-3.31 0-6-2.69-6-6s2.69-6 6-6c1.66 0 3.14.69 4.22 1.78L13 11h7V4l-2.35 2.35z" />
                                                </svg>
                                                <span className="MuiTouchRipple-root css-w0pj6f" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="MuiBox-root css-k008qs">
                                    <a
                                        onClick={(e) => {
                                            this.checkurl(e, "/manager/deposit")
                                        }}
                                        className={isMobile ? "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium zoom css-bbt4aw" : "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium zoom css-1i8ehsn"}
                                        tabIndex={0}
                                        type="button"
                                    >
                                        <h1 className={isMobile ? "MuiTypography-root MuiTypography-body1 css-16w2zn8" : "MuiTypography-root MuiTypography-body1 css-59czqk"}>
                                            ฝากเงิน
                                        </h1>
                                        <span className="MuiTouchRipple-root css-w0pj6f" />
                                    </a>
                                    <a
                                        onClick={(e) => {
                                            this.checkurl(e, "/manager/withdraw")
                                        }}
                                        className={isMobile ? "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium zoom css-1pjtl5z" : "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium zoom css-1tgme2y"}
                                        tabIndex={0}
                                        type="button"
                                    >
                                        <h1 className={isMobile ? "MuiTypography-root MuiTypography-body1 css-16w2zn8" : "MuiTypography-root MuiTypography-body1 css-59czqk"}>
                                            ถอนเงิน
                                        </h1>
                                        <span className="MuiTouchRipple-root css-w0pj6f" />
                                    </a>
                                </div>
                                <div className="MuiBox-root css-1gl14dv" style={{ display: isMobile && loginstate ? "" : "none" }} aria-label="คลิกเพื่อเปิดเมนู">
                                    <button
                                        onClick={(e) => {
                                            this.setState({
                                                modal: true,
                                            });
                                        }}
                                        className="MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeSmall css-17303pi"
                                        tabIndex={0}
                                        type="button"
                                    >
                                        <div
                                            className="loader"
                                            style={{ color: "white", marginLeft: 30, marginRight: 10 }}
                                        />
                                        <span className="MuiTouchRipple-root css-w0pj6f" />
                                    </button>
                                </div>
                                <div style={{ display: isMobile ? "" : "none" }} className="MuiBox-root css-0" />
                                <div style={{ display: isMobile ? "" : "none" }} className="MuiBox-root css-0" />

                            </div>
                        </header>
                        <header style={{ display: !loginstate && tabselect !== 22 ? "" : "none" }} className="MuiPaper-root MuiPaper-elevation MuiPaper-elevation0 MuiAppBar-root MuiAppBar-colorPrimary MuiAppBar-positionFixed mui-fixed css-11y9v2g"  >
                            <div className="MuiToolbar-root MuiToolbar-gutters MuiToolbar-regular css-i6s8oy">
                                <div className={isMobile ? "MuiTypography-root MuiTypography-body1 css-hsnrgc" : "MuiTypography-root MuiTypography-body1 css-156ekru"}>
                                    <div className="MuiGrid-root MuiGrid-container css-16a96b4" style={{ display: isMobile ? "none" : "" }}>
                                        <div className="MuiBox-root css-1gl14dv" aria-label="คลิกเพื่อเปิดเมนู">
                                            <button
                                                className="MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeSmall css-i29csa"
                                                tabIndex={0}
                                                type="button"
                                            >
                                                <div
                                                    className="loader"
                                                    style={{ color: "white", marginLeft: 0, marginRight: 0 }}
                                                />
                                                <span className="MuiTouchRipple-root css-w0pj6f" />
                                            </button>
                                        </div>
                                        <div className="MuiBox-root css-0" />
                                        <div className="MuiBox-root css-0" />
                                        <div className="MuiBox-root css-s8vbhp">
                                        </div>
                                    </div>
                                    <span
                                        style={{
                                            boxSizing: "border-box",
                                            display: isMobile ? "inline-block" : "none",
                                            overflow: "hidden",
                                            width: "initial",
                                            height: "initial",
                                            background: "none",
                                            opacity: 1,
                                            border: 0,
                                            margin: 0,
                                            padding: 0,
                                            position: "relative",
                                            maxWidth: "100%"
                                        }}
                                    >
                                        <span
                                            style={{
                                                boxSizing: "border-box",
                                                display: "block",
                                                width: "initial",
                                                height: "initial",
                                                background: "none",
                                                opacity: 1,
                                                border: 0,
                                                margin: 0,
                                                padding: 0,
                                                maxWidth: "100%"
                                            }}
                                        >
                                            <img
                                                alt=""
                                                aria-hidden="true"
                                                src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2783%27%20height=%2732%27/%3e"
                                                style={{
                                                    display: "block",
                                                    maxWidth: "100%",
                                                    width: "initial",
                                                    height: "initial",
                                                    background: "none",
                                                    opacity: 1,
                                                    border: 0,
                                                    margin: 0,
                                                    padding: 0
                                                }}
                                            />
                                        </span>
                                        <a role="button" onClick={(e) => {
                                            this.checkurl(e, "/")
                                        }}>
                                            <img
                                                alt="logo"
                                                src={this.state.logoimg}
                                                decoding="async"
                                                data-nimg="intrinsic"
                                                style={{
                                                    position: "absolute",
                                                    inset: 0,
                                                    boxSizing: "border-box",
                                                    padding: 0,
                                                    border: "none",
                                                    margin: "auto",
                                                    display: "block",
                                                    width: 0,
                                                    height: 0,
                                                    minWidth: "100%",
                                                    maxWidth: "100%",
                                                    minHeight: "150%",
                                                    maxHeight: "100%",
                                                    objectFit: "contain"
                                                }}

                                            />
                                        </a>
                                    </span>

                                </div>
                                <div className="MuiTypography-root MuiTypography-body1 css-4z09sj" style={{ display: isMobile ? "none" : "" }}>
                                    <span
                                        style={{
                                            boxSizing: "border-box",
                                            display: "inline-block",
                                            overflow: "hidden",
                                            width: "initial",
                                            height: "initial",
                                            background: "none",
                                            opacity: 1,
                                            border: 0,
                                            margin: 0,
                                            padding: 0,
                                            position: "relative",
                                            maxWidth: "100%"
                                        }}
                                    >
                                        <span
                                            style={{
                                                boxSizing: "border-box",
                                                display: "block",
                                                width: "initial",
                                                height: "initial",
                                                background: "none",
                                                opacity: 1,
                                                border: 0,
                                                margin: 0,
                                                padding: 0,
                                                maxWidth: "100%"
                                            }}
                                        >
                                            <img
                                                alt=""
                                                aria-hidden="true"
                                                src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%27140%27%20height=%2750%27/%3e"
                                                style={{
                                                    display: "block",
                                                    maxWidth: "100%",
                                                    width: "initial",
                                                    height: "initial",
                                                    background: "none",
                                                    opacity: 1,
                                                    border: 0,
                                                    margin: 0,
                                                    padding: 0
                                                }}
                                            />
                                        </span>
                                        <a role="button" onClick={(e) => {
                                            this.checkurl(e, "/")
                                        }}>
                                            <img
                                                alt="logo"
                                                src={this.state.logoimg}
                                                decoding="async"
                                                data-nimg="intrinsic"
                                                style={{
                                                    position: "absolute",
                                                    inset: 0,
                                                    boxSizing: "border-box",
                                                    padding: 0,
                                                    border: "none",
                                                    margin: "auto",
                                                    display: "block",
                                                    width: 0,
                                                    height: 0,
                                                    minWidth: "100%",
                                                    maxWidth: "100%",
                                                    minHeight: "150%",
                                                    maxHeight: "100%",
                                                    objectFit: "contain"
                                                }}
                                            />
                                        </a>
                                    </span>
                                </div>
                                <div className="MuiBox-root css-k008qs">
                                    <a
                                        role="button"
                                        onClick={(e) => {
                                            this.checkurl(e, "/register")
                                        }}
                                        className="MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium zoom css-hbiykz"
                                        tabIndex={0}
                                        type="button"
                                    >
                                        <h1 className="MuiTypography-root MuiTypography-body1 css-1rpabqk">
                                            สมัครสมาชิก
                                        </h1>
                                        <span className="MuiTouchRipple-root css-w0pj6f" />
                                    </a>
                                    <a
                                        onClick={(e) => {
                                            this.setState({
                                                modalx: true
                                            });
                                        }}
                                        //  onClick={() => this.handleClickmodal()}
                                        className="MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium zoom css-1dyr3on"
                                        tabIndex={0}
                                        type="button"
                                    >
                                        <h1 className="MuiTypography-root MuiTypography-body1 css-1rpabqk">
                                            เข้าสู่ระบบ
                                        </h1>
                                        <span className="MuiTouchRipple-root css-w0pj6f" />
                                    </a>
                                </div>
                                <div className="MuiBox-root css-1gl14dv" style={{ display: isMobile && loginstate ? "" : "none" }} aria-label="คลิกเพื่อเปิดเมนู">
                                    <button
                                        onClick={(e) => {
                                            this.setState({
                                                modal: true,
                                            });
                                        }}
                                        className="MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeSmall css-17303pi"
                                        tabIndex={0}
                                        type="button"
                                    >
                                        <div
                                            className="loader"
                                            style={{ color: "white", marginLeft: 30, marginRight: 10 }}
                                        />
                                        <span className="MuiTouchRipple-root css-w0pj6f" />
                                    </button>
                                </div>
                                <div style={{ display: isMobile ? "" : "none" }} className="MuiBox-root css-0" />
                                <div style={{ display: isMobile ? "" : "none" }} className="MuiBox-root css-0" />

                            </div>
                        </header>
                        <div style={{ display: tabselect == 0 || tabselect == 1 || tabselect == 2 || tabselect == 3 || tabselect == 28 || tabselect == 27 || tabselect == 7 || tabselect == 4 ? "" : "none" }} className="MuiBox-root css-0" >
                            <div style={{ display: tabselect == 0 || tabselect == 1 || tabselect == 2 || tabselect == 3 || tabselect == 28 || tabselect == 27 || tabselect == 7 || tabselect == 4 ? "" : "none" }} className="MuiToolbar-root MuiToolbar-gutters MuiToolbar-regular css-j7e4n7" />
                            <div className="MuiToolbar-root MuiToolbar-gutters MuiToolbar-regular css-115s37g" style={{ display: (tabselect !== 0 && tabselect !== 1 && tabselect !== 2 && tabselect != 3 && tabselect != 7 && tabselect != 4) && !isMobile ? "" : "none" }} />
                            <div style={{ display: tabselect == 0 && tabselect != 1 && tabselect != 2 && tabselect != 3 ? "" : "none" }} className={isMobile ? "MuiBox-root css-r7q7qr" : "MuiBox-root css-12595yo"}>
                                <div
                                    className="flicking-viewport"
                                    style={{
                                        userSelect: "none",
                                        WebkitUserDrag: "none",
                                        touchAction: "pan-y"
                                    }}
                                >
                                    <Banner />
                                </div>
                            </div>
                            <div className="MuiBox-root css-1xzsyzi" style={{ display: isMobile || tabselect !== 0 ? "none" : "" }} />

                            <div className="MuiBox-root css-0" style={{ display: tabselect == 0 || tabselect == 1 || tabselect == 2 || tabselect == 3 || tabselect == 7 || tabselect == 28 || tabselect == 27 || tabselect == 4 ? "" : "none" }}>
                                <div className={isMobile ? "MuiBox-root css-1l4w6pd" : "MuiBox-root css-reqtdo"}>
                                    <div className="MuiBox-root css-18hsx6q" style={{ display: isMobile ? "none" : "" }} >
                                        <div className="MuiBox-root css-vxcmzt">
                                            <div className="MuiBox-root css-xu1x1p">
                                                <div className="MuiBox-root css-fub1ud">
                                                    <div className="MuiBox-root css-1wx63vi">
                                                        <p className="MuiTypography-root MuiTypography-body1 css-1k48bpp">
                                                            ยอดเงินคงเหลือ
                                                        </p>
                                                        <div className="MuiBox-root css-1i6xgxf">
                                                            <div>฿{parseFloat(balance).toFixed(2)}</div>
                                                        </div>
                                                    </div>
                                                    <div className="MuiBox-root css-1gy2zie">
                                                        <a role="button" onClick={(e) => {
                                                            this.checkurl(e, "/manager/deposit")
                                                        }}> <div className="zoom MuiBox-root css-1vgqyv9">ฝากเงิน</div></a>
                                                        <a role="button" onClick={(e) => {
                                                            this.checkurl(e, "/manager/withdraw")
                                                        }} >  <div className="zoom MuiBox-root css-qtyvg">ถอนเงิน</div></a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="MuiBox-root css-19jpzii">
                                                <div className="MuiBox-root css-lbb20b">
                                                    <div className="MuiBox-root css-1s8b3q1">
                                                        <div
                                                            className="base-timer"
                                                            style={{ marginRight: 5, marginLeft: 5, width: 40, height: 40 }}
                                                        >
                                                            <svg
                                                                className="base-timer__svg"
                                                                viewBox="0 0 100 100"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <g className="base-timer__circle">
                                                                    <circle
                                                                        className="base-timer__path-elapsed"
                                                                        cx={50}
                                                                        cy={50}
                                                                        r={45}
                                                                    />
                                                                    <path
                                                                        id="base-timer-path-remaining"
                                                                        strokeDasharray="NaN 283"
                                                                        className="base-timer__path-remaining base-timer__path-remaining-red"
                                                                        d=" M 50, 50 m -45, 0 a 45,45 0 1,0 90,0 a 45,45 0 1,0 -90,0 "
                                                                    />
                                                                </g>
                                                            </svg>
                                                            <span
                                                                id="base-timer-label"
                                                                className="base-timer__label"
                                                                style={{
                                                                    width: 40,
                                                                    height: 40,
                                                                    fontSize: 22,
                                                                    color: "black"
                                                                }}
                                                            >

                                                                {this.state.days}
                                                            </span>
                                                            <span
                                                                className="base-timer__text"
                                                                style={{
                                                                    width: 40,
                                                                    height: 30,
                                                                    top: 35,
                                                                    fontSize: 10,
                                                                    color: "black"
                                                                }}
                                                            >
                                                                วัน
                                                            </span>
                                                        </div>
                                                        <div
                                                            className="base-timer"
                                                            style={{ marginRight: 5, marginLeft: 5, width: 40, height: 40 }}
                                                        >
                                                            <svg
                                                                className="base-timer__svg"
                                                                viewBox="0 0 100 100"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <g className="base-timer__circle">
                                                                    <circle
                                                                        className="base-timer__path-elapsed"
                                                                        cx={50}
                                                                        cy={50}
                                                                        r={45}
                                                                    />
                                                                    <path
                                                                        id="base-timer-path-remaining"
                                                                        strokeDasharray="59 283"
                                                                        className="base-timer__path-remaining base-timer__path-remaining-purple"
                                                                        d=" M 50, 50 m -45, 0 a 45,45 0 1,0 90,0 a 45,45 0 1,0 -90,0 "
                                                                    />
                                                                </g>
                                                            </svg>
                                                            <span
                                                                id="base-timer-label"
                                                                className="base-timer__label"
                                                                style={{
                                                                    width: 40,
                                                                    height: 40,
                                                                    fontSize: 22,
                                                                    color: "black"
                                                                }}
                                                            >
                                                                {this.state.hours}
                                                            </span>
                                                            <span
                                                                className="base-timer__text"
                                                                style={{
                                                                    width: 40,
                                                                    height: 30,
                                                                    top: 35,
                                                                    fontSize: 10,
                                                                    color: "black"
                                                                }}
                                                            >
                                                                ชั่วโมง
                                                            </span>
                                                        </div>
                                                        <div
                                                            className="base-timer"
                                                            style={{ marginRight: 5, marginLeft: 5, width: 40, height: 40 }}
                                                        >
                                                            <svg
                                                                className="base-timer__svg"
                                                                viewBox="0 0 100 100"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <g className="base-timer__circle">
                                                                    <circle
                                                                        className="base-timer__path-elapsed"
                                                                        cx={50}
                                                                        cy={50}
                                                                        r={45}
                                                                    />
                                                                    <path
                                                                        id="base-timer-path-remaining"
                                                                        strokeDasharray="255 283"
                                                                        className="base-timer__path-remaining base-timer__path-remaining-yellow"
                                                                        d=" M 50, 50 m -45, 0 a 45,45 0 1,0 90,0 a 45,45 0 1,0 -90,0 "
                                                                    />
                                                                </g>
                                                            </svg>
                                                            <span
                                                                id="base-timer-label"
                                                                className="base-timer__label"
                                                                style={{
                                                                    width: 40,
                                                                    height: 40,
                                                                    fontSize: 22,
                                                                    color: "black"
                                                                }}
                                                            >
                                                                {this.state.minutes}
                                                            </span>
                                                            <span
                                                                className="base-timer__text"
                                                                style={{
                                                                    width: 40,
                                                                    height: 30,
                                                                    top: 35,
                                                                    fontSize: 10,
                                                                    color: "black"
                                                                }}
                                                            >
                                                                นาที
                                                            </span>
                                                        </div>
                                                        <div
                                                            className="base-timer"
                                                            style={{ marginRight: 5, marginLeft: 5, width: 40, height: 40 }}
                                                        >
                                                            <svg
                                                                className="base-timer__svg"
                                                                viewBox="0 0 100 100"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <g className="base-timer__circle">
                                                                    <circle
                                                                        className="base-timer__path-elapsed"
                                                                        cx={50}
                                                                        cy={50}
                                                                        r={45}
                                                                    />
                                                                    <path
                                                                        id="base-timer-path-remaining"
                                                                        strokeDasharray="156 283"
                                                                        className="base-timer__path-remaining base-timer__path-remaining-green"
                                                                        d=" M 50, 50 m -45, 0 a 45,45 0 1,0 90,0 a 45,45 0 1,0 -90,0 "
                                                                    />
                                                                </g>
                                                            </svg>
                                                            <span
                                                                id="base-timer-label"
                                                                className="base-timer__label"
                                                                style={{
                                                                    width: 40,
                                                                    height: 40,
                                                                    fontSize: 22,
                                                                    color: "black"
                                                                }}
                                                            >
                                                                {this.state.seconds}
                                                            </span>
                                                            <span
                                                                className="base-timer__text"
                                                                style={{
                                                                    width: 40,
                                                                    height: 30,
                                                                    top: 35,
                                                                    fontSize: 10,
                                                                    color: "black"
                                                                }}
                                                            >
                                                                วินาที
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="MuiBox-root css-1iv5rld">
                                                        <div className="MuiBox-root css-qfdd9t">
                                                            <p className="MuiTypography-root MuiTypography-body1 css-1rr0gz9">
                                                                เล่นเสียให้คืน
                                                            </p>
                                                            <p style={{ display: this.state.checkcashout ? "" : "none" }} class="MuiTypography-root MuiTypography-body1 css-vw5nql">฿{(tblprofile != null) ? tblprofile.Cashtotal : 0}</p>
                                                            <div onClick={(e) => {
                                                                this.setState({
                                                                    checkcashout: true
                                                                });
                                                            }} className="MuiBox-root css-p590ja" style={{ display: this.state.checkcashout ? "none" : "" }} >
                                                                <div className="MuiBox-root css-gur5g7">
                                                                    <p className="MuiTypography-root MuiTypography-body1 css-1ea94c7">
                                                                        คลิกเพื่อ
                                                                    </p>
                                                                    <p className="MuiTypography-root MuiTypography-body1 css-1ea94c7">
                                                                        แสดงยอด
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="MuiBox-root css-1bvc4cc">
                                                            <a onClick={(e) => {
                                                                this.opencliamcashout();
                                                            }}>
                                                                <div className="zoom MuiBox-root css-aob0g4">ถอนโบนัส</div>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="MuiBox-root css-2pgxi8">
                                                <div className="MuiBox-root css-gj706f">
                                                    <div className="MuiBox-root css-1w1ks0k">
                                                        <div className="MuiBox-root css-0">
                                                            <p className="MuiTypography-root MuiTypography-body1 css-by2zv0">
                                                                โบนัสชวนเพื่อน
                                                            </p>
                                                            <div className="MuiBox-root css-1vlw42c">
                                                                <div className="MuiBox-root css-1byehwt">
                                                                    <span
                                                                        style={{
                                                                            boxSizing: "border-box",
                                                                            display: "inline-block",
                                                                            overflow: "hidden",
                                                                            width: "initial",
                                                                            height: "initial",
                                                                            background: "none",
                                                                            opacity: 1,
                                                                            border: 0,
                                                                            margin: 0,
                                                                            padding: 0,
                                                                            position: "relative",
                                                                            maxWidth: "100%"
                                                                        }}
                                                                    >
                                                                        <span
                                                                            style={{
                                                                                boxSizing: "border-box",
                                                                                display: "block",
                                                                                width: "initial",
                                                                                height: "initial",
                                                                                background: "none",
                                                                                opacity: 1,
                                                                                border: 0,
                                                                                margin: 0,
                                                                                padding: 0,
                                                                                maxWidth: "100%"
                                                                            }}
                                                                        >
                                                                            <img
                                                                                alt=""
                                                                                aria-hidden="true"
                                                                                src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%27150%27%20height=%27150%27/%3e"
                                                                                style={{
                                                                                    display: "block",
                                                                                    maxWidth: "100%",
                                                                                    width: "initial",
                                                                                    height: "initial",
                                                                                    background: "none",
                                                                                    opacity: 1,
                                                                                    border: 0,
                                                                                    margin: 0,
                                                                                    padding: 0
                                                                                }}
                                                                            />
                                                                        </span>
                                                                        <img
                                                                            alt="Bonus"
                                                                            srcSet="/build/web/igame-index-lobby-wm/img/icon_bonus.webp 1x, /build/web/igame-index-lobby-wm/img/icon_bonus.webp 2x"
                                                                            src="/build/web/igame-index-lobby-wm/img/icon_bonus.webp"
                                                                            decoding="async"
                                                                            data-nimg="intrinsic"
                                                                            style={{
                                                                                position: "absolute",
                                                                                inset: 0,
                                                                                boxSizing: "border-box",
                                                                                padding: 0,
                                                                                border: "none",
                                                                                margin: "auto",
                                                                                display: "block",
                                                                                width: 0,
                                                                                height: 0,
                                                                                minWidth: "100%",
                                                                                maxWidth: "100%",
                                                                                minHeight: "100%",
                                                                                maxHeight: "100%"
                                                                            }}
                                                                        />
                                                                    </span>
                                                                </div>
                                                                <p className="MuiTypography-root MuiTypography-body1 css-cldbm6">
                                                                    ฿0
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="MuiBox-root css-1bvc4cc">
                                                            <a onClick={(e) => {
                                                                this.opencliaminvite();
                                                            }}>
                                                                <div className="zoom MuiBox-root css-343epc">ถอนโบนัส</div>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="MuiBox-root css-1fi59mn" style={{ display: isMobile ? "" : "none" }} >
                                        <div className="MuiBox-root css-2s42x6">
                                            <div className="MuiBox-root css-ygqgmp">
                                                <div className="MuiBox-root css-1vdtsuo">
                                                    <p className="MuiTypography-root MuiTypography-body1 css-e1exce">
                                                        ยอดเงินคงเหลือ
                                                    </p>
                                                    <div className="MuiBox-root css-1uu04x0">
                                                        <div>฿{balance}</div>
                                                    </div>
                                                </div>
                                                <div className="MuiBox-root css-11ggvod">
                                                    <div onClick={(e) => {
                                                        this.checkurl(e, "/manager/deposit")
                                                    }} className="zoom MuiBox-root css-52y8uw">ฝากเงิน</div>
                                                    <div onClick={(e) => {
                                                        this.checkurl(e, "/manager/deposit")
                                                    }} className="zoom MuiBox-root css-6ea5xg">ถอนเงิน</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="MuiBox-root css-nfp9ly">
                                            <div className="MuiBox-root css-huonai">
                                                <div className="MuiBox-root css-1s8b3q1">
                                                    <div
                                                        className="base-timer"
                                                        style={{ marginRight: 5, marginLeft: 5, width: 12, height: 12 }}
                                                    >
                                                        <svg
                                                            className="base-timer__svg"
                                                            viewBox="0 0 100 100"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <g className="base-timer__circle">
                                                                <circle
                                                                    className="base-timer__path-elapsed"
                                                                    cx={50}
                                                                    cy={50}
                                                                    r={45}
                                                                />
                                                                <path
                                                                    id="base-timer-path-remaining"
                                                                    strokeDasharray="NaN 283"
                                                                    className="base-timer__path-remaining base-timer__path-remaining-red"
                                                                    d=" M 50, 50 m -45, 0 a 45,45 0 1,0 90,0 a 45,45 0 1,0 -90,0 "
                                                                />
                                                            </g>
                                                        </svg>
                                                        <span
                                                            id="base-timer-label"
                                                            className="base-timer__label"
                                                            style={{ width: 12, height: 18, fontSize: 6, color: "black" }}
                                                        >
                                                            0
                                                        </span>
                                                        <span
                                                            className="base-timer__text"
                                                            style={{
                                                                width: 12,
                                                                height: 12,
                                                                top: 18,
                                                                fontSize: 6,
                                                                color: "black"
                                                            }}
                                                        >
                                                            วัน
                                                        </span>
                                                    </div>
                                                    <div
                                                        className="base-timer"
                                                        style={{ marginRight: 5, marginLeft: 5, width: 12, height: 12 }}
                                                    >
                                                        <svg
                                                            className="base-timer__svg"
                                                            viewBox="0 0 100 100"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <g className="base-timer__circle">
                                                                <circle
                                                                    className="base-timer__path-elapsed"
                                                                    cx={50}
                                                                    cy={50}
                                                                    r={45}
                                                                />
                                                                <path
                                                                    id="base-timer-path-remaining"
                                                                    strokeDasharray="0 283"
                                                                    className="base-timer__path-remaining base-timer__path-remaining-purple"
                                                                    d=" M 50, 50 m -45, 0 a 45,45 0 1,0 90,0 a 45,45 0 1,0 -90,0 "
                                                                />
                                                            </g>
                                                        </svg>
                                                        <span
                                                            id="base-timer-label"
                                                            className="base-timer__label"
                                                            style={{ width: 12, height: 18, fontSize: 6, color: "black" }}
                                                        >
                                                            0
                                                        </span>
                                                        <span
                                                            className="base-timer__text"
                                                            style={{
                                                                width: 12,
                                                                height: 12,
                                                                top: 18,
                                                                fontSize: 6,
                                                                color: "black"
                                                            }}
                                                        >
                                                            ชั่วโมง
                                                        </span>
                                                    </div>
                                                    <div
                                                        className="base-timer"
                                                        style={{ marginRight: 5, marginLeft: 5, width: 12, height: 12 }}
                                                    >
                                                        <svg
                                                            className="base-timer__svg"
                                                            viewBox="0 0 100 100"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <g className="base-timer__circle">
                                                                <circle
                                                                    className="base-timer__path-elapsed"
                                                                    cx={50}
                                                                    cy={50}
                                                                    r={45}
                                                                />
                                                                <path
                                                                    id="base-timer-path-remaining"
                                                                    strokeDasharray="0 283"
                                                                    className="base-timer__path-remaining base-timer__path-remaining-yellow"
                                                                    d=" M 50, 50 m -45, 0 a 45,45 0 1,0 90,0 a 45,45 0 1,0 -90,0 "
                                                                />
                                                            </g>
                                                        </svg>
                                                        <span
                                                            id="base-timer-label"
                                                            className="base-timer__label"
                                                            style={{ width: 12, height: 18, fontSize: 6, color: "black" }}
                                                        >
                                                            0
                                                        </span>
                                                        <span
                                                            className="base-timer__text"
                                                            style={{
                                                                width: 12,
                                                                height: 12,
                                                                top: 18,
                                                                fontSize: 6,
                                                                color: "black"
                                                            }}
                                                        >
                                                            นาที
                                                        </span>
                                                    </div>
                                                    <div
                                                        className="base-timer"
                                                        style={{ marginRight: 5, marginLeft: 5, width: 12, height: 12 }}
                                                    >
                                                        <svg
                                                            className="base-timer__svg"
                                                            viewBox="0 0 100 100"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <g className="base-timer__circle">
                                                                <circle
                                                                    className="base-timer__path-elapsed"
                                                                    cx={50}
                                                                    cy={50}
                                                                    r={45}
                                                                />
                                                                <path
                                                                    id="base-timer-path-remaining"
                                                                    strokeDasharray="0 283"
                                                                    className="base-timer__path-remaining base-timer__path-remaining-green"
                                                                    d=" M 50, 50 m -45, 0 a 45,45 0 1,0 90,0 a 45,45 0 1,0 -90,0 "
                                                                />
                                                            </g>
                                                        </svg>
                                                        <span
                                                            id="base-timer-label"
                                                            className="base-timer__label"
                                                            style={{ width: 12, height: 18, fontSize: 6, color: "black" }}
                                                        >
                                                            0
                                                        </span>
                                                        <span
                                                            className="base-timer__text"
                                                            style={{
                                                                width: 12,
                                                                height: 12,
                                                                top: 18,
                                                                fontSize: 6,
                                                                color: "black"
                                                            }}
                                                        >
                                                            วินาที
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="MuiBox-root css-mypd7u">
                                                    <div className="MuiBox-root css-qfdd9t">
                                                        <p className="MuiTypography-root MuiTypography-body1 css-gkiase">
                                                            เล่นเสียให้คืน
                                                        </p>
                                                        <p style={{ display: this.state.checkcashout ? "" : "none" }} class="MuiTypography-root MuiTypography-body1 css-hldd0s">฿{(tblprofile != null) ? tblprofile.Cashtotal : 0}</p>
                                                        <div onClick={(e) => {
                                                            this.setState({
                                                                checkcashout: true
                                                            });
                                                        }} className="MuiBox-root css-1evwcao" style={{ display: this.state.checkcashout ? "none" : "" }}>
                                                            <div className="MuiBox-root css-gur5g7">
                                                                <p className="MuiTypography-root MuiTypography-body1 css-9huz2">
                                                                    คลิกเพื่อ
                                                                </p>
                                                                <p className="MuiTypography-root MuiTypography-body1 css-9huz2">
                                                                    แสดงยอด
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="MuiBox-root css-1bvc4cc">
                                                        <a onClick={(e) => {
                                                            this.opencliamcashout();
                                                        }}>
                                                            <div className="zoom MuiBox-root css-xdjh4b">ถอนโบนัส</div>
                                                        </a>
                                                        {/* <div className="zoom MuiBox-root css-xdjh4b">ถอนโบนัส</div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="MuiBox-root css-yzg11a">
                                            <div className="MuiBox-root css-gj706f">
                                                <div className="MuiBox-root css-1gx78w6">
                                                    <div className="MuiBox-root css-0">
                                                        <p className="MuiTypography-root MuiTypography-body1 css-1l89ba3">
                                                            โบนัสชวนเพื่อน
                                                        </p>
                                                        <div className="MuiBox-root css-1vlw42c">
                                                            <div className="MuiBox-root css-y4tms">
                                                                <span
                                                                    style={{
                                                                        boxSizing: "border-box",
                                                                        display: "inline-block",
                                                                        overflow: "hidden",
                                                                        width: "initial",
                                                                        height: "initial",
                                                                        background: "none",
                                                                        opacity: 1,
                                                                        border: 0,
                                                                        margin: 0,
                                                                        padding: 0,
                                                                        position: "relative",
                                                                        maxWidth: "100%"
                                                                    }}
                                                                >
                                                                    <span
                                                                        style={{
                                                                            boxSizing: "border-box",
                                                                            display: "block",
                                                                            width: "initial",
                                                                            height: "initial",
                                                                            background: "none",
                                                                            opacity: 1,
                                                                            border: 0,
                                                                            margin: 0,
                                                                            padding: 0,
                                                                            maxWidth: "100%"
                                                                        }}
                                                                    >
                                                                        <img
                                                                            alt=""
                                                                            aria-hidden="true"
                                                                            src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%27150%27%20height=%27150%27/%3e"
                                                                            style={{
                                                                                display: "block",
                                                                                maxWidth: "100%",
                                                                                width: "initial",
                                                                                height: "initial",
                                                                                background: "none",
                                                                                opacity: 1,
                                                                                border: 0,
                                                                                margin: 0,
                                                                                padding: 0
                                                                            }}
                                                                        />
                                                                    </span>
                                                                    <img
                                                                        alt="Bonus"
                                                                        src="/build/web/igame-index-lobby-wm/img/icon_bonus.webp"
                                                                        decoding="async"
                                                                        data-nimg="intrinsic"
                                                                        style={{
                                                                            position: "absolute",
                                                                            inset: 0,
                                                                            boxSizing: "border-box",
                                                                            padding: 0,
                                                                            border: "none",
                                                                            margin: "auto",
                                                                            display: "block",
                                                                            width: 0,
                                                                            height: 0,
                                                                            minWidth: "100%",
                                                                            maxWidth: "100%",
                                                                            minHeight: "100%",
                                                                            maxHeight: "100%"
                                                                        }}
                                                                    />
                                                                </span>
                                                            </div>
                                                            <p className="MuiTypography-root MuiTypography-body1 css-kfhguq">
                                                                ฿0
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div onClick={(e) => {
                                                        this.opencliaminvite(e, 'bonusrankaccount')
                                                    }} className="MuiBox-root css-1bvc4cc">
                                                        <div className="zoom MuiBox-root css-167m4ed">ถอนโบนัส</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="MuiContainer-root css-10ur324" style={{ display: tabselect == 1 || tabselect == 2 ? "" : "none" }}>
                                <div className="MuiBox-root css-1ln9osj">
                                    <div role="group" className="MuiButtonGroup-root MuiButtonGroup-outlined css-l3ihcd" >
                                        <button
                                            role="button" onClick={(e) => {
                                                this.checkurl(e, "/manager/deposit")
                                            }}
                                            className={tabselect == 1 ? "MuiButtonBase-root MuiButtonGroup-grouped MuiButtonGroup-groupedHorizontal MuiButtonGroup-groupedOutlined MuiButtonGroup-groupedOutlinedHorizontal MuiButtonGroup-groupedOutlinedPrimary MuiButton-root MuiButton-outlined MuiButton-outlinedPrimary MuiButton-sizeMedium MuiButton-outlinedSizeMedium MuiButtonGroup-grouped MuiButtonGroup-groupedHorizontal MuiButtonGroup-groupedOutlined MuiButtonGroup-groupedOutlinedHorizontal MuiButtonGroup-groupedOutlinedPrimary css-kxk28c" : "MuiButtonBase-root MuiButtonGroup-grouped MuiButtonGroup-groupedHorizontal MuiButtonGroup-groupedOutlined MuiButtonGroup-groupedOutlinedHorizontal MuiButtonGroup-groupedOutlinedPrimary MuiButton-root MuiButton-outlined MuiButton-outlinedPrimary MuiButton-sizeMedium MuiButton-outlinedSizeMedium MuiButtonGroup-grouped MuiButtonGroup-groupedHorizontal MuiButtonGroup-groupedOutlined MuiButtonGroup-groupedOutlinedHorizontal MuiButtonGroup-groupedOutlinedPrimary css-8fvcow"}
                                            tabIndex={0}
                                            type="button"
                                        >
                                            ฝากเงิน
                                            <span className="MuiTouchRipple-root css-w0pj6f" />
                                        </button>
                                        <button
                                            role="button" onClick={(e) => {
                                                this.checkurl(e, "/manager/withdraw")
                                            }}
                                            className={tabselect == 2 ? "MuiButtonBase-root MuiButtonGroup-grouped MuiButtonGroup-groupedHorizontal MuiButtonGroup-groupedOutlined MuiButtonGroup-groupedOutlinedHorizontal MuiButtonGroup-groupedOutlinedPrimary MuiButton-root MuiButton-outlined MuiButton-outlinedPrimary MuiButton-sizeMedium MuiButton-outlinedSizeMedium MuiButtonGroup-grouped MuiButtonGroup-groupedHorizontal MuiButtonGroup-groupedOutlined MuiButtonGroup-groupedOutlinedHorizontal MuiButtonGroup-groupedOutlinedPrimary css-10bkud5" : "MuiButtonBase-root MuiButtonGroup-grouped MuiButtonGroup-groupedHorizontal MuiButtonGroup-groupedOutlined MuiButtonGroup-groupedOutlinedHorizontal MuiButtonGroup-groupedOutlinedPrimary MuiButton-root MuiButton-outlined MuiButton-outlinedPrimary MuiButton-sizeMedium MuiButton-outlinedSizeMedium MuiButtonGroup-grouped MuiButtonGroup-groupedHorizontal MuiButtonGroup-groupedOutlined MuiButtonGroup-groupedOutlinedHorizontal MuiButtonGroup-groupedOutlinedPrimary css-vlenph"}
                                            tabIndex={0}
                                            type="button"
                                        >
                                            ถอนเงิน
                                            <span className="MuiTouchRipple-root css-w0pj6f" />
                                        </button>
                                    </div>
                                    <div className={isMobile ? "MuiBox-root css-10n284" : "MuiBox-root css-1idkcu9"} style={{ display: tabselect == 1 && tabselectdeposit == 0 ? "" : "none" }}>
                                        <p className={isMobile ? "MuiTypography-root MuiTypography-body1 css-p19i6o" : "MuiTypography-root MuiTypography-body1 css-gatbua"}>
                                            กรุณาเลือกวิธีการเติมเงิน
                                        </p>
                                        <div className={isMobile ? "MuiBox-root css-wkpw2c" : "MuiBox-root css-1vd84sn"}>
                                            <div style={{
                                                display: accnumber !== null && accnumberx.accbank !== 99 ? "" : "none",
                                            }} onClick={(e) => {
                                                this.checktab(e, 1)
                                            }} className={isMobile ? "MuiBox-root css-6s61hh" : "MuiBox-root css-18bbvfw"}>

                                                <div className={isMobile ? "MuiBox-root css-1g360fb" : "MuiBox-root css-old1by"}>
                                                    <span
                                                        style={{
                                                            boxSizing: "border-box",
                                                            display: "inline-block",
                                                            overflow: "hidden",
                                                            width: "initial",
                                                            height: "initial",
                                                            background: "none",
                                                            opacity: 1,
                                                            border: 0,
                                                            margin: 0,
                                                            padding: 0,
                                                            position: "relative",
                                                            maxWidth: "100%"
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                boxSizing: "border-box",
                                                                display: "block",
                                                                width: "initial",
                                                                height: "initial",
                                                                background: "none",
                                                                opacity: 1,
                                                                border: 0,
                                                                margin: 0,
                                                                padding: 0,
                                                                maxWidth: "100%"
                                                            }}
                                                        >
                                                            <img
                                                                alt=""
                                                                aria-hidden="true"
                                                                src={isMobile ? "data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2780%27%20height=%2780%27/%3e" : "data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%27150%27%20height=%27150%27/%3e"}
                                                                style={{
                                                                    display: "block",
                                                                    maxWidth: "100%",
                                                                    width: "initial",
                                                                    height: "initial",
                                                                    background: "none",
                                                                    opacity: 1,
                                                                    border: 0,
                                                                    margin: 0,
                                                                    padding: 0
                                                                }}
                                                            />
                                                        </span>
                                                        <img
                                                            alt="transfer"
                                                            src="/build/web/igame-index-lobby-wm/img/transfer.webp"
                                                            decoding="async"
                                                            data-nimg="intrinsic"
                                                            style={{
                                                                position: "absolute",
                                                                inset: 0,
                                                                boxSizing: "border-box",
                                                                padding: 0,
                                                                border: "none",
                                                                margin: "auto",
                                                                display: "block",
                                                                width: 0,
                                                                height: 0,
                                                                minWidth: "100%",
                                                                maxWidth: "100%",
                                                                minHeight: "100%",
                                                                maxHeight: "100%"
                                                            }}
                                                        />
                                                    </span>
                                                </div>
                                                <div className={isMobile ? "MuiBox-root css-13brihr" : "MuiBox-root css-xi606m"}>
                                                    <p className={isMobile ? "MuiTypography-root MuiTypography-body1 css-16du02u" : "MuiTypography-root MuiTypography-body1 css-nur4ax"}>
                                                        โอนผ่านธนาคาร
                                                    </p>
                                                    <p className={isMobile ? "MuiTypography-root MuiTypography-body1 css-im09bx" : "MuiTypography-root MuiTypography-body1 css-hu468x"}>
                                                        ใช้บัญชีธนาคารที่ทำการสมัครโอนเท่านั้น
                                                    </p>
                                                </div>

                                            </div>
                                            <div style={{
                                                display: accnumber !== null && accnumberx.accbank === 99 ? "" : "none",
                                            }}
                                                onClick={(e) => {
                                                    this.checktab(e, 1)
                                                }} className={isMobile ? "MuiBox-root css-6s61hh" : "MuiBox-root css-18bbvfw"}>

                                                <div className={isMobile ? "MuiBox-root css-1g360fb" : "MuiBox-root css-old1by"}>
                                                    <span
                                                        style={{
                                                            boxSizing: "border-box",
                                                            display: "inline-block",
                                                            overflow: "hidden",
                                                            width: "initial",
                                                            height: "initial",
                                                            background: "none",
                                                            opacity: 1,
                                                            border: 0,
                                                            margin: 0,
                                                            padding: 0,
                                                            position: "relative",
                                                            maxWidth: "100%"
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                boxSizing: "border-box",
                                                                display: "block",
                                                                width: "initial",
                                                                height: "initial",
                                                                background: "none",
                                                                opacity: 1,
                                                                border: 0,
                                                                margin: 0,
                                                                padding: 0,
                                                                maxWidth: "100%"
                                                            }}
                                                        >
                                                            <img
                                                                alt=""
                                                                aria-hidden="true"
                                                                src={isMobile ? "data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2780%27%20height=%2780%27/%3e" : "data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%27150%27%20height=%27150%27/%3e"}
                                                                style={{
                                                                    display: "block",
                                                                    maxWidth: "100%",
                                                                    width: "initial",
                                                                    height: "initial",
                                                                    background: "none",
                                                                    opacity: 1,
                                                                    border: 0,
                                                                    margin: 0,
                                                                    padding: 0
                                                                }}
                                                            />
                                                        </span>
                                                        <img
                                                            alt="transfer"
                                                            src="https://i.pinimg.com/originals/ab/0e/c0/ab0ec0235e2d2cd198b573f754df2690.png"
                                                            decoding="async"
                                                            data-nimg="intrinsic"
                                                            style={{
                                                                position: "absolute",
                                                                inset: 0,
                                                                boxSizing: "border-box",
                                                                padding: 0,
                                                                border: "none",
                                                                margin: "auto",
                                                                display: "block",
                                                                width: 0,
                                                                height: 0,
                                                                minWidth: "100%",
                                                                maxWidth: "100%",
                                                                minHeight: "100%",
                                                                maxHeight: "100%"
                                                            }}
                                                        />
                                                    </span>
                                                </div>
                                                <div className={isMobile ? "MuiBox-root css-13brihr" : "MuiBox-root css-xi606m"}>
                                                    <p className={isMobile ? "MuiTypography-root MuiTypography-body1 css-16du02u" : "MuiTypography-root MuiTypography-body1 css-nur4ax"}>
                                                        โอนเงินผ่านวอลเลท
                                                    </p>
                                                    <p className={isMobile ? "MuiTypography-root MuiTypography-body1 css-im09bx" : "MuiTypography-root MuiTypography-body1 css-hu468x"}>
                                                        ใช้บัญชีวอลเลทที่ทำการสมัครโอนเท่านั้น
                                                    </p>
                                                </div>

                                            </div>
                                            <div
                                             style={{
                                                display: accnumber !== null && accnumberx.accbank !== 99 ? "" : "none",
                                            }} 
                                            
                                             onClick={(e) => {
                                                this.checktab(e, 2)
                                            }} className={isMobile ? "MuiBox-root css-k41sl2" : "MuiBox-root css-eyqf97"}>

                                                <div className={isMobile ? "MuiBox-root css-1g360fb" : "MuiBox-root css-old1by"}>
                                                    <span
                                                        style={{
                                                            boxSizing: "border-box",
                                                            display: "inline-block",
                                                            overflow: "hidden",
                                                            width: "initial",
                                                            height: "initial",
                                                            background: "none",
                                                            opacity: 1,
                                                            border: 0,
                                                            margin: 0,
                                                            padding: 0,
                                                            position: "relative",
                                                            maxWidth: "100%"
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                boxSizing: "border-box",
                                                                display: "block",
                                                                width: "initial",
                                                                height: "initial",
                                                                background: "none",
                                                                opacity: 1,
                                                                border: 0,
                                                                margin: 0,
                                                                padding: 0,
                                                                maxWidth: "100%"
                                                            }}
                                                        >
                                                            <img
                                                                alt=""
                                                                aria-hidden="true"
                                                                src={isMobile ? "data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2780%27%20height=%2780%27/%3e" : "data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%27150%27%20height=%27150%27/%3e"}
                                                                style={{
                                                                    display: "block",
                                                                    maxWidth: "100%",
                                                                    width: "initial",
                                                                    height: "initial",
                                                                    background: "none",
                                                                    opacity: 1,
                                                                    border: 0,
                                                                    margin: 0,
                                                                    padding: 0
                                                                }}
                                                            />
                                                        </span>
                                                        <img
                                                            alt="promptpay"

                                                            src="/build/web/igame-index-lobby-wm/img/promptpay_logo.webp"
                                                            decoding="async"
                                                            data-nimg="intrinsic"
                                                            style={{
                                                                position: "absolute",
                                                                inset: 0,
                                                                boxSizing: "border-box",
                                                                padding: 0,
                                                                border: "none",
                                                                margin: "auto",
                                                                display: "block",
                                                                width: 0,
                                                                height: 0,
                                                                minWidth: "100%",
                                                                maxWidth: "100%",
                                                                minHeight: "100%",
                                                                maxHeight: "100%"
                                                            }}
                                                        />
                                                    </span>
                                                </div>
                                                <div className={isMobile ? "MuiBox-root css-13brihr" : "MuiBox-root css-xi606m"}>
                                                    <p className={isMobile ? "MuiTypography-root MuiTypography-body1 css-hko6q5" : "MuiTypography-root MuiTypography-body1 css-14g2wlq"}>
                                                        โอนผ่าน PromptPay QR
                                                    </p>
                                                    <p className={isMobile ? "MuiTypography-root MuiTypography-body1 css-se2rbz" : "MuiTypography-root MuiTypography-body1 css-se2rbz"}>
                                                        สแกนจ่ายผ่านแอพธนาคารของท่าน
                                                    </p>
                                                </div>

                                            </div>
                                            <div onClick={(e) => {
                                                this.checktab(e, 4)
                                            }} className={isMobile ? "MuiBox-root css-h8aqdy" : "MuiBox-root css-ptq0yi"}>

                                                <div className={isMobile ? "MuiBox-root css-1g360fb" : "MuiBox-root css-old1by"}>
                                                    <span
                                                        style={{
                                                            boxSizing: "border-box",
                                                            display: "inline-block",
                                                            overflow: "hidden",
                                                            width: "initial",
                                                            height: "initial",
                                                            background: "none",
                                                            opacity: 1,
                                                            border: 0,
                                                            margin: 0,
                                                            padding: 0,
                                                            position: "relative",
                                                            maxWidth: "100%"
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                boxSizing: "border-box",
                                                                display: "block",
                                                                width: "initial",
                                                                height: "initial",
                                                                background: "none",
                                                                opacity: 1,
                                                                border: 0,
                                                                margin: 0,
                                                                padding: 0,
                                                                maxWidth: "100%"
                                                            }}
                                                        >
                                                            <img
                                                                alt=""
                                                                aria-hidden="true"
                                                                src={isMobile ? "data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2780%27%20height=%2780%27/%3e" : "data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%27150%27%20height=%27150%27/%3e"}
                                                                style={{
                                                                    display: "block",
                                                                    maxWidth: "100%",
                                                                    width: "initial",
                                                                    height: "initial",
                                                                    background: "none",
                                                                    opacity: 1,
                                                                    border: 0,
                                                                    margin: 0,
                                                                    padding: 0
                                                                }}
                                                            />
                                                        </span>
                                                        <img
                                                            alt="transfer"
                                                            src="https://cdn.javisx.com/image/e9e975c7-f1b3-42dc-9e62-b2c5980c1196.png"
                                                            decoding="async"
                                                            data-nimg="intrinsic"
                                                            style={{
                                                                position: "absolute",
                                                                inset: 0,
                                                                boxSizing: "border-box",
                                                                padding: 0,
                                                                border: "none",
                                                                margin: "auto",
                                                                display: "block",
                                                                width: 0,
                                                                height: 0,
                                                                minWidth: "100%",
                                                                maxWidth: "100%",
                                                                minHeight: "100%",
                                                                maxHeight: "100%"
                                                            }}
                                                        />
                                                    </span>
                                                </div>
                                                <div className={isMobile ? "MuiBox-root css-13brihr" : "MuiBox-root css-xi606m"}>
                                                    <p className={isMobile ? "MuiTypography-root MuiTypography-body1 css-nur4ax" : "MuiTypography-root MuiTypography-body1 css-nur4ax"}>
                                                        อัพโหลดสลิป
                                                    </p>
                                                    <p className={isMobile ? "MuiTypography-root MuiTypography-body1 css-im09bx" : "MuiTypography-root MuiTypography-body1 css-hu468x"}>
                                                        สำหรับผู้ที่โอนเงินแล้วยังไม่เข้าระบบ
                                                    </p>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="x-modal-separator-container x-login-form pb-3 animated animatedFadeInUp fadeInUp bg-signup">
                                            <div className="-img-container text-white text-center">
                                                <div className="MuiFormControl-root css-1fab3uj">
                                                    <p className="MuiTypography-root MuiTypography-body1 css-m73dwr">
                                                        คูปอง
                                                    </p>
                                                    <div className="MuiFormControl-root MuiTextField-root css-1ihe2hn">
                                                        <label
                                                            className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-outlined MuiFormLabel-colorPrimary css-u9osun"
                                                            data-shrink="true"
                                                            htmlFor=":r16:"
                                                            id=":r16:-label"
                                                        >
                                                            Code Coupon
                                                        </label>
                                                        <div className="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-formControl MuiInputBase-adornedStart css-1ufn0jl">

                                                            <input
                                                                onChange={this.onChangex}
                                                                id="couponkey"
                                                                name="couponkey"
                                                                type="text"
                                                                autoComplete="off"
                                                                aria-invalid="false"
                                                                placeholder="รหัสคูปอง"
                                                                className="MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputAdornedStart css-1ixds2g"
                                                                defaultValue=""
                                                            />
                                                            <fieldset
                                                                aria-hidden="true"
                                                                className="MuiOutlinedInput-notchedOutline css-igs3ac"
                                                            >
                                                                <legend className="css-1in441m">
                                                                    <span>รหัสคูปอง</span>
                                                                </legend>
                                                            </fieldset>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-center couponx">
                                            <div className="MuiBox-root css-k1z727" >
                                                <a
                                                    onClick={(e) => {
                                                        this.couponredeem(e)
                                                    }}
                                                    className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeLarge MuiButton-containedSizeLarge css-18sq5gr"
                                                    tabIndex={0}
                                                    type="submit"
                                                >
                                                    <span>
                                                        <p className="MuiTypography-root MuiTypography-body1 css-1o9b57b">
                                                            ดำเนินการต่อ
                                                        </p>
                                                    </span>
                                                    <span className="MuiTouchRipple-root css-w0pj6f" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="MuiBox-root css-1idkcu9" style={{ display: tabselect == 2 ? "" : "none" }}> */}
                                    <div style={{ display: tabselect == 2 ? "" : "none" }} className="MuiContainer-root MuiContainer-maxWidthLg css-7z3b4">

                                        <div style={{ display: isMobile ? "none" : "" }} className={"MuiBox-root css-bwno58"} >
                                            <div className="MuiBox-root css-aq0ghh">
                                                <div className="MuiBox-root css-9zfuus">
                                                    <p className="MuiTypography-root MuiTypography-body1 css-1acbrzs">
                                                        บัญชีธนาคารของท่าน
                                                    </p>
                                                    <div className="MuiBox-root css-1r2f04i">
                                                        <span
                                                            style={{
                                                                boxSizing: "border-box",
                                                                display: "inline-block",
                                                                overflow: "hidden",
                                                                width: "initial",
                                                                height: "initial",
                                                                background: "none",
                                                                opacity: 1,
                                                                border: 0,
                                                                margin: 0,
                                                                padding: 0,
                                                                position: "relative",
                                                                maxWidth: "100%"
                                                            }}
                                                        >
                                                            <span
                                                                style={{
                                                                    boxSizing: "border-box",
                                                                    display: "block",
                                                                    width: "initial",
                                                                    height: "initial",
                                                                    background: "none",
                                                                    opacity: 1,
                                                                    border: 0,
                                                                    margin: 0,
                                                                    padding: 0,
                                                                    maxWidth: "100%"
                                                                }}
                                                            >
                                                                <img
                                                                    alt=""
                                                                    aria-hidden="true"
                                                                    src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2780%27%20height=%2780%27/%3e"
                                                                    style={{
                                                                        display: "block",
                                                                        maxWidth: "100%",
                                                                        width: "initial",
                                                                        height: "initial",
                                                                        background: "none",
                                                                        opacity: 1,
                                                                        border: 0,
                                                                        margin: 0,
                                                                        padding: 0
                                                                    }}
                                                                />
                                                            </span>
                                                            <img
                                                                alt="BANK LOGO"
                                                                src={accnumber !== null ? accnumberx.img_path : ""}
                                                                decoding="async"
                                                                data-nimg="intrinsic"
                                                                style={{
                                                                    position: "absolute",
                                                                    inset: 0,
                                                                    boxSizing: "border-box",
                                                                    padding: 0,
                                                                    border: "none",
                                                                    margin: "auto",
                                                                    display: "block",
                                                                    width: 0,
                                                                    height: 0,
                                                                    minWidth: "100%",
                                                                    maxWidth: "100%",
                                                                    minHeight: "100%",
                                                                    maxHeight: "100%"
                                                                }}
                                                            />
                                                        </span>
                                                    </div>
                                                    <div className="MuiBox-root css-0">
                                                        <p className="MuiTypography-root MuiTypography-body1 css-xwuunc">
                                                            {fullname}
                                                        </p>
                                                        <p className="MuiTypography-root MuiTypography-body1 css-989l4c">
                                                            {accnumber !== null ? accnumberx.accno.substring(0, 3) + "-" + accnumberx.accno.substring(3, 9) + "-" + accnumberx.accno.substring(9, 12) : ""}
                                                        </p>
                                                        <p className="MuiTypography-root MuiTypography-body1 css-xwuunc">
                                                            {fullname}
                                                        </p>
                                                    </div>
                                                </div>
                                                {/* <button
                                                    className="MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-6t4wih"
                                                    tabIndex={0}
                                                    type="button"
                                                    onClick={(e) => {
                                                        this.checkurl(e, "/manager/ranking")
                                                    }}

                                                >
                                                    <p className="MuiTypography-root MuiTypography-body1 css-9gjvdr">
                                                        <svg
                                                            className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1nrhc3k"
                                                            focusable="false"
                                                            aria-hidden="true"
                                                            viewBox="0 0 24 24"
                                                            data-testid="HistoryIcon"
                                                        >
                                                            <path d="M13 3c-4.97 0-9 4.03-9 9H1l3.89 3.89.07.14L9 12H6c0-3.87 3.13-7 7-7s7 3.13 7 7-3.13 7-7 7c-1.93 0-3.68-.79-4.94-2.06l-1.42 1.42C8.27 19.99 10.51 21 13 21c4.97 0 9-4.03 9-9s-4.03-9-9-9zm-1 5v5l4.28 2.54.72-1.21-3.5-2.08V8H12z" />
                                                        </svg>
                                                        <span style={{ marginLeft: 6 }}>ตรวจสอบ Rank</span>
                                                    </p>
                                                    <span className="MuiTouchRipple-root css-w0pj6f" />
                                                </button> */}
                                                <div className="MuiBox-root css-1ewciyh">
                                                    <div className="MuiBox-root css-1uc8f8z">
                                                        <p className="MuiTypography-root MuiTypography-body1 css-1i9awav">
                                                            สถานะการถอน: ไม่พบรายการถอน
                                                        </p>
                                                    </div>
                                                    <div className="MuiBox-root css-1w0fp6k">
                                                        <p className="MuiTypography-root MuiTypography-body1 css-1i9awav">
                                                            <span style={{ color: "rgb(160, 160, 160)" }}>TURNOVER: ไม่มี</span>
                                                        </p>
                                                    </div>
                                                    <div className="MuiBox-root css-1fzqpqw">
                                                        <p className="MuiTypography-root MuiTypography-body1 css-1vu7541">
                                                            ถอนไปแล้วทั้งสิ้น:{
                                                                this.state.historylist
                                                                    .filter((listitem) => listitem.type == "WD")
                                                                    .reduce((sum, listitem) => sum + (listitem.amount || 0), 0)
                                                            } บาท
                                                        </p>
                                                    </div>
                                                    <button
                                                        className="MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-6t4wih"
                                                        tabIndex={0}
                                                        type="button"
                                                        onClick={(e) => {
                                                            this.checkurl(e, "/manager/statement")
                                                        }}

                                                    >
                                                        <p className="MuiTypography-root MuiTypography-body1 css-9gjvdr">
                                                            <svg
                                                                className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1nrhc3k"
                                                                focusable="false"
                                                                aria-hidden="true"
                                                                viewBox="0 0 24 24"
                                                                data-testid="HistoryIcon"
                                                            >
                                                                <path d="M13 3c-4.97 0-9 4.03-9 9H1l3.89 3.89.07.14L9 12H6c0-3.87 3.13-7 7-7s7 3.13 7 7-3.13 7-7 7c-1.93 0-3.68-.79-4.94-2.06l-1.42 1.42C8.27 19.99 10.51 21 13 21c4.97 0 9-4.03 9-9s-4.03-9-9-9zm-1 5v5l4.28 2.54.72-1.21-3.5-2.08V8H12z" />
                                                            </svg>
                                                            <span style={{ marginLeft: 6 }}>ดูประวัติการถอน</span>
                                                        </p>
                                                        <span className="MuiTouchRipple-root css-w0pj6f" />
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="MuiBox-root css-8atqhb">
                                                <p className="MuiTypography-root MuiTypography-body1 css-jy5r26">
                                                    ถอนเงินจำนวน :
                                                </p>
                                                <p className="MuiTypography-root MuiTypography-body1 css-1dturyp">0</p>
                                                <div className="MuiFormControl-root MuiTextField-root css-lag06o">
                                                    <label
                                                        className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-outlined MuiFormLabel-colorPrimary MuiFormLabel-filled css-u9osun"
                                                        data-shrink="true"
                                                        htmlFor=":r13:"
                                                        id=":r13:-label"
                                                    >
                                                        กรอกจำนวนเงินที่ต้องการถอน
                                                    </label>
                                                    <div className="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-formControl css-1v4ccyo">
                                                        <input
                                                            aria-invalid="false"
                                                            type="text"
                                                            id="withdraw_amount"
                                                            onChange={this.onChange}
                                                            value={this.state.withdrawamount}
                                                            name="withdrawamount"
                                                            required="required"
                                                            pattern="[0-9]*"
                                                            inputMode="text"
                                                            className="MuiInputBase-input MuiOutlinedInput-input css-1x5jdmq"
                                                            defaultValue={0}
                                                        />
                                                        <fieldset
                                                            aria-hidden="true"
                                                            className="MuiOutlinedInput-notchedOutline css-igs3ac"
                                                        >
                                                            <legend className="css-1in441m">
                                                                <span>กรอกจำนวนเงินที่ต้องการถอน</span>
                                                            </legend>
                                                        </fieldset>
                                                    </div>
                                                </div>

                                                <button
                                                    onClick={(e) => this.submitwithdraw(e)}
                                                    className={this.state.withdrawamount > 0 ? "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-jeibqb" : "MuiButtonBase-root Mui-disabled MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-jeibqb"}
                                                    tabIndex={-1}
                                                    type="button"
                                                    disabled=""
                                                >
                                                    ยืนยันการถอน
                                                </button>
                                            </div>

                                        </div>

                                        <div className={"MuiBox-root css-nn4ogy"} style={{ display: isMobile ? "" : "none" }}>
                                            <div className="MuiBox-root css-1s1oyiq">
                                                <p className="MuiTypography-root MuiTypography-body1 css-1x7rlir">
                                                    ถอนไปแล้วทั้งสิ้น: {
                                                        this.state.historylist
                                                            .filter((listitem) => listitem.type === "WD")
                                                            .reduce((sum, listitem) => sum + (listitem.amount || 0), 0)
                                                    } บาท
                                                </p>
                                            </div>
                                            <button
                                                className="MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-7ze848"
                                                tabIndex={0}
                                                type="button"
                                                onClick={(e) => {
                                                    this.checkurl(e, "/manager/statement")
                                                }}
                                            >
                                                <p className="MuiTypography-root MuiTypography-body1 css-35maa2">
                                                    <svg
                                                        className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-94lb6d"
                                                        focusable="false"
                                                        aria-hidden="true"
                                                        viewBox="0 0 24 24"
                                                        data-testid="HistoryIcon"
                                                    >
                                                        <path d="M13 3c-4.97 0-9 4.03-9 9H1l3.89 3.89.07.14L9 12H6c0-3.87 3.13-7 7-7s7 3.13 7 7-3.13 7-7 7c-1.93 0-3.68-.79-4.94-2.06l-1.42 1.42C8.27 19.99 10.51 21 13 21c4.97 0 9-4.03 9-9s-4.03-9-9-9zm-1 5v5l4.28 2.54.72-1.21-3.5-2.08V8H12z" />
                                                    </svg>
                                                    <span style={{ marginLeft: 6 }}>ดูประวัติการถอน</span>
                                                </p>
                                                <span className="MuiTouchRipple-root css-w0pj6f" />
                                            </button>
                                            <div className="MuiBox-root css-aq0ghh">
                                                <div className="MuiBox-root css-b8wnqg">
                                                    <div className="MuiBox-root css-9nvt73">
                                                        <span
                                                            style={{
                                                                boxSizing: "border-box",
                                                                display: "inline-block",
                                                                overflow: "hidden",
                                                                width: "initial",
                                                                height: "initial",
                                                                background: "none",
                                                                opacity: 1,
                                                                border: 0,
                                                                margin: 0,
                                                                padding: 0,
                                                                position: "relative",
                                                                maxWidth: "100%"
                                                            }}
                                                        >
                                                            <span
                                                                style={{
                                                                    boxSizing: "border-box",
                                                                    display: "block",
                                                                    width: "initial",
                                                                    height: "initial",
                                                                    background: "none",
                                                                    opacity: 1,
                                                                    border: 0,
                                                                    margin: 0,
                                                                    padding: 0,
                                                                    maxWidth: "100%"
                                                                }}
                                                            >
                                                                <img
                                                                    alt=""
                                                                    aria-hidden="true"
                                                                    src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2760%27%20height=%2760%27/%3e"
                                                                    style={{
                                                                        display: "block",
                                                                        maxWidth: "100%",
                                                                        width: "initial",
                                                                        height: "initial",
                                                                        background: "none",
                                                                        opacity: 1,
                                                                        border: 0,
                                                                        margin: 0,
                                                                        padding: 0
                                                                    }}
                                                                />
                                                            </span>
                                                            <img
                                                                alt="BANK LOGO"
                                                                src={accnumber !== null ? accnumberx.img_path : ""}
                                                                decoding="async"
                                                                data-nimg="intrinsic"
                                                                style={{
                                                                    position: "absolute",
                                                                    inset: 0,
                                                                    boxSizing: "border-box",
                                                                    padding: 0,
                                                                    border: "none",
                                                                    margin: "auto",
                                                                    display: "block",
                                                                    width: 0,
                                                                    height: 0,
                                                                    minWidth: "100%",
                                                                    maxWidth: "100%",
                                                                    minHeight: "100%",
                                                                    maxHeight: "100%"
                                                                }}
                                                            />
                                                        </span>
                                                    </div>
                                                    <div className="MuiBox-root css-1fx0tq">
                                                        <p className="MuiTypography-root MuiTypography-body1 css-1e4i3ql">
                                                            บัญชีธนาคารของท่าน
                                                        </p>
                                                        <div className="MuiBox-root css-0">
                                                            <p className="MuiTypography-root MuiTypography-body1 css-xwuunc">
                                                                {accnumber !== null ? accnumberx.fullname_th : ""}
                                                            </p>
                                                            <p className="MuiTypography-root MuiTypography-body1 css-989l4c">
                                                                {accnumber !== null ? accnumberx.accno.substring(0, 3) + "-" + accnumberx.accno.substring(3, 9) + "-" + accnumberx.accno.substring(9, 12) : ""}
                                                            </p>
                                                            <p className="MuiTypography-root MuiTypography-body1 css-xwuunc">
                                                                {fullname}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="MuiBox-root css-1iyhli1">
                                                    <div className="MuiBox-root css-17s8qi7">
                                                        <p className="MuiTypography-root MuiTypography-body1 css-1uq7lfg">
                                                            สถานะการถอน: สำเร็จ
                                                        </p>
                                                    </div>
                                                    <div className="MuiBox-root css-yt8ynl">
                                                        <p className="MuiTypography-root MuiTypography-body1 css-1uq7lfg">
                                                            <span style={{ color: "rgb(160, 160, 160)" }}>TURNOVER: ไม่มี</span>
                                                        </p>
                                                    </div>
                                                </div>

                                                {/* <button
                                                    className="MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-7ze848"
                                                    tabIndex={0}
                                                    type="button"
                                                    onClick={(e) => {
                                                        this.checkurl(e, "/manager/ranking")
                                                    }}
                                                >
                                                    <p className="MuiTypography-root MuiTypography-body1 css-35maa2">
                                                        <svg
                                                            className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-94lb6d"
                                                            focusable="false"
                                                            aria-hidden="true"
                                                            viewBox="0 0 24 24"
                                                            data-testid="HistoryIcon"
                                                        >
                                                            <path d="M13 3c-4.97 0-9 4.03-9 9H1l3.89 3.89.07.14L9 12H6c0-3.87 3.13-7 7-7s7 3.13 7 7-3.13 7-7 7c-1.93 0-3.68-.79-4.94-2.06l-1.42 1.42C8.27 19.99 10.51 21 13 21c4.97 0 9-4.03 9-9s-4.03-9-9-9zm-1 5v5l4.28 2.54.72-1.21-3.5-2.08V8H12z" />
                                                        </svg>
                                                        <span style={{ marginLeft: 6 }}>ตรวจสอบ Rank</span>
                                                    </p>
                                                    <span className="MuiTouchRipple-root css-w0pj6f" />
                                                </button> */}
                                            </div>
                                            <div className="MuiBox-root css-8atqhb">
                                                <p className="MuiTypography-root MuiTypography-body1 css-jy5r26">
                                                    ถอนเงินจำนวน :
                                                </p>
                                                <p className="MuiTypography-root MuiTypography-body1 css-1dturyp">{this.state.withdrawamount}</p>
                                                <div className="MuiFormControl-root MuiTextField-root css-lag06o">
                                                    <label
                                                        className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-outlined MuiFormLabel-colorPrimary MuiFormLabel-filled css-u9osun"
                                                        data-shrink="true"
                                                        htmlFor=":ru:"
                                                        id=":ru:-label"
                                                    >
                                                        กรอกจำนวนเงินที่ต้องการถอน
                                                    </label>
                                                    <div className="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-formControl css-1v4ccyo">
                                                        <input
                                                            aria-invalid="false"
                                                            type="text"
                                                            id="withdraw_amount"
                                                            onChange={this.onChange}
                                                            value={this.state.withdrawamount}
                                                            name="withdrawamount"
                                                            required="required"
                                                            pattern="[0-9]*"
                                                            inputMode="text"
                                                            className="MuiInputBase-input MuiOutlinedInput-input css-1x5jdmq"
                                                            defaultValue={0}
                                                        />
                                                        <fieldset
                                                            aria-hidden="true"
                                                            className="MuiOutlinedInput-notchedOutline css-igs3ac"
                                                        >
                                                            <legend className="css-1in441m">
                                                                <span>กรอกจำนวนเงินที่ต้องการถอน</span>
                                                            </legend>
                                                        </fieldset>
                                                    </div>
                                                </div>
                                                <button
                                                    onClick={(e) => this.submitwithdraw(e)}
                                                    className={this.state.withdrawamount > 0 ? "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-jeibqb" : "MuiButtonBase-root Mui-disabled MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-jeibqb"}

                                                    tabIndex={-1}
                                                    type="button"
                                                    disabled=""
                                                >
                                                    ยืนยันการถอน
                                                </button>
                                            </div>
                                        </div>


                                    </div>

                                    {/* </div> */}
                                    <div className="MuiBox-root css-1uus8u6" style={{ display: tabselect == 1 && tabselectdeposit == 1 ? "" : "none" }}>
                                        <div className={"MuiBox-root css-0"}>
                                            <div className={isMobile ? "MuiBox-root css-155ff3i" : "MuiBox-root css-10a73ai"}>
                                                <div className={isMobile ? "MuiBox-root css-6gi0jt" : "MuiBox-root css-1eilatu"}>
                                                    <button
                                                        className="MuiButtonBase-root MuiFab-root MuiFab-circular MuiFab-sizeSmall MuiFab-default css-jjtg8b"
                                                        tabIndex={0}
                                                        type="button"
                                                        aria-label="back"
                                                        onClick={(e) => {
                                                            this.checktab(e, 0)
                                                        }}
                                                    >
                                                        <svg
                                                            className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1kpcl3g"
                                                            focusable="false"
                                                            aria-hidden="true"
                                                            viewBox="0 0 24 24"
                                                            data-testid="ArrowBackIosNewIcon"
                                                        >
                                                            <path d="M17.77 3.77 16 2 6 12l10 10 1.77-1.77L9.54 12z" />
                                                        </svg>
                                                        <span className="MuiTouchRipple-root css-w0pj6f" />
                                                    </button>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="MuiBox-root css-17os7fp">
                                            {mainacc !== null ? (
                                                listacc
                                                    .filter(listitem => {
                                                        if (accnumber !== null) {
                                                            if (accnumberx.accbank === 99) {
                                                                return listitem.bankid === 99; // กรอง bankid == 99
                                                            }
                                                            return listitem.bankid !== 99; // กรอง bankid != 99
                                                        }
                                                        return false; // ถ้า accnumber เป็น null ไม่ต้องแสดงรายการ
                                                    }).map((listitem, i) => (
                                                        <div className="MuiBox-root css-19ttywd" key={i}>
                                                            <div className="MuiBox-root css-0">
                                                                <span
                                                                    style={{
                                                                        boxSizing: "border-box",
                                                                        display: "inline-block",
                                                                        overflow: "hidden",
                                                                        width: "initial",
                                                                        height: "initial",
                                                                        background: "none",
                                                                        opacity: 1,
                                                                        border: 0,
                                                                        margin: 0,
                                                                        padding: 0,
                                                                        position: "relative",
                                                                        maxWidth: "100%",
                                                                    }}
                                                                >
                                                                    <span
                                                                        style={{
                                                                            boxSizing: "border-box",
                                                                            display: "block",
                                                                            width: "initial",
                                                                            height: "initial",
                                                                            background: "none",
                                                                            opacity: 1,
                                                                            border: 0,
                                                                            margin: 0,
                                                                            padding: 0,
                                                                            maxWidth: "100%",
                                                                        }}
                                                                    >
                                                                        <img
                                                                            alt=""
                                                                            aria-hidden="true"
                                                                            src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2780%27%20height=%2780%27/%3e"
                                                                            style={{
                                                                                display: "block",
                                                                                maxWidth: "100%",
                                                                                width: "initial",
                                                                                height: "initial",
                                                                                background: "none",
                                                                                opacity: 1,
                                                                                border: 0,
                                                                                margin: 0,
                                                                                padding: 0,
                                                                            }}
                                                                        />
                                                                    </span>
                                                                    <img
                                                                        alt="BANK LOGO"
                                                                        src={listitem.img_path}
                                                                        decoding="async"
                                                                        data-nimg="intrinsic"
                                                                        style={{
                                                                            position: "absolute",
                                                                            inset: 0,
                                                                            boxSizing: "border-box",
                                                                            padding: 0,
                                                                            border: "none",
                                                                            margin: "auto",
                                                                            display: "block",
                                                                            width: 0,
                                                                            height: 0,
                                                                            minWidth: "100%",
                                                                            maxWidth: "100%",
                                                                            minHeight: "100%",
                                                                            maxHeight: "100%",
                                                                        }}
                                                                    />
                                                                </span>
                                                            </div>
                                                            <div className="MuiBox-root css-0">
                                                                <p className="MuiTypography-root MuiTypography-body1 css-xwuunc">
                                                                    {listitem.fullname_th}
                                                                </p>
                                                                <p className="MuiTypography-root MuiTypography-body1 css-989l4c">
                                                                    {`${listitem.accnumber.substring(0, 3)}-${listitem.accnumber.substring(3, 9)}-${listitem.accnumber.substring(9, 12)}`}
                                                                </p>
                                                                <p className="MuiTypography-root MuiTypography-body1 css-xwuunc">
                                                                    {`${listitem.first_name_en} ${listitem.last_name_en}`}
                                                                </p>
                                                                <p className="MuiTypography-root MuiTypography-body1 css-t7pqt5">
                                                                    <a
                                                                        href={() => false}
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => this.copyCode(listitem.accnumber, 1)}
                                                                    >
                                                                        <svg
                                                                            className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1yls13q"
                                                                            focusable="false"
                                                                            aria-hidden="true"
                                                                            viewBox="0 0 24 24"
                                                                            data-testid="FileCopyIcon"
                                                                        >
                                                                            <path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm-1 4 6 6v10c0 1.1-.9 2-2 2H7.99C6.89 23 6 22.1 6 21l.01-14c0-1.1.89-2 1.99-2h7zm-1 7h5.5L14 6.5V12z" />
                                                                        </svg>{" "}
                                                                        คัดลอก
                                                                    </a>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    ))
                                            ) : (
                                                ""
                                            )}

                                        </div>
                                        <div className={isMobile ? "MuiBox-root css-jmsy68" : "MuiBox-root css-1c0pxvd"}>
                                            <div className={isMobile ? "MuiBox-root css-1127y87" : "MuiBox-root css-xmfo6e"}>
                                                <div className="MuiBox-root css-1ip6ovm">
                                                    <div className="MuiBox-root css-0">
                                                        <span
                                                            style={{
                                                                boxSizing: "border-box",
                                                                display: "inline-block",
                                                                overflow: "hidden",
                                                                width: "initial",
                                                                height: "initial",
                                                                background: "none",
                                                                opacity: 1,
                                                                border: 0,
                                                                margin: 0,
                                                                padding: 0,
                                                                position: "relative",
                                                                maxWidth: "100%"
                                                            }}
                                                        >
                                                            <span
                                                                style={{
                                                                    boxSizing: "border-box",
                                                                    display: "block",
                                                                    width: "initial",
                                                                    height: "initial",
                                                                    background: "none",
                                                                    opacity: 1,
                                                                    border: 0,
                                                                    margin: 0,
                                                                    padding: 0,
                                                                    maxWidth: "100%"
                                                                }}
                                                            >
                                                                <img
                                                                    alt=""
                                                                    aria-hidden="true"
                                                                    src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2730%27%20height=%2730%27/%3e"
                                                                    style={{
                                                                        display: "block",
                                                                        maxWidth: "100%",
                                                                        width: "initial",
                                                                        height: "initial",
                                                                        background: "none",
                                                                        opacity: 1,
                                                                        border: 0,
                                                                        margin: 0,
                                                                        padding: 0
                                                                    }}
                                                                />
                                                            </span>
                                                            <img
                                                                alt="transfer"
                                                                src="/build/web/igame-index-lobby-wm/img/icon_1.webp"
                                                                decoding="async"
                                                                data-nimg="intrinsic"
                                                                style={{
                                                                    position: "absolute",
                                                                    inset: 0,
                                                                    boxSizing: "border-box",
                                                                    padding: 0,
                                                                    border: "none",
                                                                    margin: "auto",
                                                                    display: "block",
                                                                    width: 0,
                                                                    height: 0,
                                                                    minWidth: "100%",
                                                                    maxWidth: "100%",
                                                                    minHeight: "100%",
                                                                    maxHeight: "100%"
                                                                }}
                                                            />
                                                            <noscript />
                                                        </span>
                                                    </div>
                                                    <p className="MuiTypography-root MuiTypography-body1 css-13aw9id">
                                                        โอนเงินโดยใช้
                                                        <span style={{ color: "rgb(252, 186, 0)", marginLeft: 5 }}>
                                                            บัญชีที่สมัครเท่านั้น
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className={isMobile ? "MuiBox-root css-1127y87" : "MuiBox-root css-xmfo6e"}>
                                                <div className="MuiBox-root css-1ip6ovm">
                                                    <div className="MuiBox-root css-0">
                                                        <span
                                                            style={{
                                                                boxSizing: "border-box",
                                                                display: "inline-block",
                                                                overflow: "hidden",
                                                                width: "initial",
                                                                height: "initial",
                                                                background: "none",
                                                                opacity: 1,
                                                                border: 0,
                                                                margin: 0,
                                                                padding: 0,
                                                                position: "relative",
                                                                maxWidth: "100%"
                                                            }}
                                                        >
                                                            <span
                                                                style={{
                                                                    boxSizing: "border-box",
                                                                    display: "block",
                                                                    width: "initial",
                                                                    height: "initial",
                                                                    background: "none",
                                                                    opacity: 1,
                                                                    border: 0,
                                                                    margin: 0,
                                                                    padding: 0,
                                                                    maxWidth: "100%"
                                                                }}
                                                            >
                                                                <img
                                                                    alt=""
                                                                    aria-hidden="true"
                                                                    src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2730%27%20height=%2730%27/%3e"
                                                                    style={{
                                                                        display: "block",
                                                                        maxWidth: "100%",
                                                                        width: "initial",
                                                                        height: "initial",
                                                                        background: "none",
                                                                        opacity: 1,
                                                                        border: 0,
                                                                        margin: 0,
                                                                        padding: 0
                                                                    }}
                                                                />
                                                            </span>
                                                            <img
                                                                alt="transfer"
                                                                src="/build/web/igame-index-lobby-wm/img/icon_2.webp"
                                                                decoding="async"
                                                                data-nimg="intrinsic"
                                                                style={{
                                                                    position: "absolute",
                                                                    inset: 0,
                                                                    boxSizing: "border-box",
                                                                    padding: 0,
                                                                    border: "none",
                                                                    margin: "auto",
                                                                    display: "block",
                                                                    width: 0,
                                                                    height: 0,
                                                                    minWidth: "100%",
                                                                    maxWidth: "100%",
                                                                    minHeight: "100%",
                                                                    maxHeight: "100%"
                                                                }}
                                                            />
                                                            <noscript />
                                                        </span>
                                                    </div>
                                                    <p className="MuiTypography-root MuiTypography-body1 css-13aw9id">
                                                        สามารถโอนเข้าบัญชีใดก็ได้
                                                        <span style={{ color: "rgb(252, 186, 0)", marginLeft: 5 }}>
                                                            ด้านล่าง
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className={isMobile ? "MuiBox-root css-1127y87" : "MuiBox-root css-xmfo6e"}>
                                                <div className="MuiBox-root css-1ip6ovm">
                                                    <div className="MuiBox-root css-0">
                                                        <span
                                                            style={{
                                                                boxSizing: "border-box",
                                                                display: "inline-block",
                                                                overflow: "hidden",
                                                                width: "initial",
                                                                height: "initial",
                                                                background: "none",
                                                                opacity: 1,
                                                                border: 0,
                                                                margin: 0,
                                                                padding: 0,
                                                                position: "relative",
                                                                maxWidth: "100%"
                                                            }}
                                                        >
                                                            <span
                                                                style={{
                                                                    boxSizing: "border-box",
                                                                    display: "block",
                                                                    width: "initial",
                                                                    height: "initial",
                                                                    background: "none",
                                                                    opacity: 1,
                                                                    border: 0,
                                                                    margin: 0,
                                                                    padding: 0,
                                                                    maxWidth: "100%"
                                                                }}
                                                            >
                                                                <img
                                                                    alt=""
                                                                    aria-hidden="true"
                                                                    src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2730%27%20height=%2730%27/%3e"
                                                                    style={{
                                                                        display: "block",
                                                                        maxWidth: "100%",
                                                                        width: "initial",
                                                                        height: "initial",
                                                                        background: "none",
                                                                        opacity: 1,
                                                                        border: 0,
                                                                        margin: 0,
                                                                        padding: 0
                                                                    }}
                                                                />
                                                            </span>
                                                            <img
                                                                alt="transfer"
                                                                src="/build/web/igame-index-lobby-wm/img/icon_3.webp"
                                                                decoding="async"
                                                                data-nimg="intrinsic"
                                                                style={{
                                                                    position: "absolute",
                                                                    inset: 0,
                                                                    boxSizing: "border-box",
                                                                    padding: 0,
                                                                    border: "none",
                                                                    margin: "auto",
                                                                    display: "block",
                                                                    width: 0,
                                                                    height: 0,
                                                                    minWidth: "100%",
                                                                    maxWidth: "100%",
                                                                    minHeight: "100%",
                                                                    maxHeight: "100%"
                                                                }}
                                                            />
                                                            <noscript />
                                                        </span>
                                                    </div>
                                                    <p className="MuiTypography-root MuiTypography-body1 css-13aw9id">
                                                        ระบบจะเติมเงินอัตโนมัติ
                                                        <span style={{ color: "rgb(252, 186, 0)", marginLeft: 5 }}>
                                                            ภายใน 10 วินาที
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className={isMobile ? "MuiBox-root css-1127y87" : "MuiBox-root css-xmfo6e"}>
                                                <div className="MuiBox-root css-1ip6ovm">
                                                    <div className="MuiBox-root css-0">
                                                        <span
                                                            style={{
                                                                boxSizing: "border-box",
                                                                display: "inline-block",
                                                                overflow: "hidden",
                                                                width: "initial",
                                                                height: "initial",
                                                                background: "none",
                                                                opacity: 1,
                                                                border: 0,
                                                                margin: 0,
                                                                padding: 0,
                                                                position: "relative",
                                                                maxWidth: "100%"
                                                            }}
                                                        >
                                                            <span
                                                                style={{
                                                                    boxSizing: "border-box",
                                                                    display: "block",
                                                                    width: "initial",
                                                                    height: "initial",
                                                                    background: "none",
                                                                    opacity: 1,
                                                                    border: 0,
                                                                    margin: 0,
                                                                    padding: 0,
                                                                    maxWidth: "100%"
                                                                }}
                                                            >
                                                                <img
                                                                    alt=""
                                                                    aria-hidden="true"
                                                                    src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2730%27%20height=%2730%27/%3e"
                                                                    style={{
                                                                        display: "block",
                                                                        maxWidth: "100%",
                                                                        width: "initial",
                                                                        height: "initial",
                                                                        background: "none",
                                                                        opacity: 1,
                                                                        border: 0,
                                                                        margin: 0,
                                                                        padding: 0
                                                                    }}
                                                                />
                                                            </span>
                                                            <img
                                                                alt="transfer"
                                                                src="/build/web/igame-index-lobby-wm/img/icon_3.webp"
                                                                decoding="async"
                                                                data-nimg="intrinsic"
                                                                style={{
                                                                    position: "absolute",
                                                                    inset: 0,
                                                                    boxSizing: "border-box",
                                                                    padding: 0,
                                                                    border: "none",
                                                                    margin: "auto",
                                                                    display: "block",
                                                                    width: 0,
                                                                    height: 0,
                                                                    minWidth: "100%",
                                                                    maxWidth: "100%",
                                                                    minHeight: "100%",
                                                                    maxHeight: "100%"
                                                                }}
                                                            />
                                                            <noscript />
                                                        </span>
                                                    </div>
                                                    <p className="MuiTypography-root MuiTypography-body1 css-13aw9id">
                                                        ห้ามโอนจาก
                                                        <span style={{ color: "rgb(252, 186, 0)", marginLeft: 5 }}>
                                                            วอลเลทเข้าบัญชีธนาคาร
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className={isMobile ? "MuiBox-root css-1127y87" : "MuiBox-root css-xmfo6e"}>
                                                <div className="MuiBox-root css-1ip6ovm">
                                                    <div className="MuiBox-root css-0">
                                                        <span
                                                            style={{
                                                                boxSizing: "border-box",
                                                                display: "inline-block",
                                                                overflow: "hidden",
                                                                width: "initial",
                                                                height: "initial",
                                                                background: "none",
                                                                opacity: 1,
                                                                border: 0,
                                                                margin: 0,
                                                                padding: 0,
                                                                position: "relative",
                                                                maxWidth: "100%"
                                                            }}
                                                        >
                                                            <span
                                                                style={{
                                                                    boxSizing: "border-box",
                                                                    display: "block",
                                                                    width: "initial",
                                                                    height: "initial",
                                                                    background: "none",
                                                                    opacity: 1,
                                                                    border: 0,
                                                                    margin: 0,
                                                                    padding: 0,
                                                                    maxWidth: "100%"
                                                                }}
                                                            >
                                                                <img
                                                                    alt=""
                                                                    aria-hidden="true"
                                                                    src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2730%27%20height=%2730%27/%3e"
                                                                    style={{
                                                                        display: "block",
                                                                        maxWidth: "100%",
                                                                        width: "initial",
                                                                        height: "initial",
                                                                        background: "none",
                                                                        opacity: 1,
                                                                        border: 0,
                                                                        margin: 0,
                                                                        padding: 0
                                                                    }}
                                                                />
                                                            </span>
                                                            <img
                                                                alt="transfer"
                                                                src="/build/web/igame-index-lobby-wm/img/icon_3.webp"
                                                                decoding="async"
                                                                data-nimg="intrinsic"
                                                                style={{
                                                                    position: "absolute",
                                                                    inset: 0,
                                                                    boxSizing: "border-box",
                                                                    padding: 0,
                                                                    border: "none",
                                                                    margin: "auto",
                                                                    display: "block",
                                                                    width: 0,
                                                                    height: 0,
                                                                    minWidth: "100%",
                                                                    maxWidth: "100%",
                                                                    minHeight: "100%",
                                                                    maxHeight: "100%"
                                                                }}
                                                            />
                                                            <noscript />
                                                        </span>
                                                    </div>
                                                    <p className="MuiTypography-root MuiTypography-body1 css-13aw9id">
                                                        ห้ามโอนจาก
                                                        <span style={{ color: "rgb(252, 186, 0)", marginLeft: 5 }}>
                                                            บัญชีธนาคารเข้าวอเลท
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="MuiBox-root css-0" />
                                    </div>
                                    <div className="MuiBox-root css-1uus8u6" style={{ display: tabselect == 1 && (tabselectdeposit == 2 || tabselectdeposit == 3) ? "" : "none" }}>
                                        <div className="MuiBox-root css-0">
                                            <div className="MuiBox-root css-kyzb81">
                                                <div className="MuiBox-root css-4g6ai3">
                                                    <button
                                                        onClick={(e) => {
                                                            this.checktab(e, 0)
                                                        }}
                                                        className="MuiButtonBase-root MuiFab-root MuiFab-circular MuiFab-sizeSmall MuiFab-default css-jjtg8b"
                                                        tabIndex={0}
                                                        type="button"
                                                        aria-label="back"
                                                    >
                                                        <svg
                                                            className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1kpcl3g"
                                                            focusable="false"
                                                            aria-hidden="true"
                                                            viewBox="0 0 24 24"
                                                            data-testid="ArrowBackIosNewIcon"
                                                        >
                                                            <path d="M17.77 3.77 16 2 6 12l10 10 1.77-1.77L9.54 12z" />
                                                        </svg>
                                                        <span className="MuiTouchRipple-root css-w0pj6f" />
                                                    </button>
                                                </div>
                                            </div>
                                            <div className={isMobile ? "MuiBox-root css-14acyxj" : "MuiBox-root css-m4dcxi"}>
                                                <div className={isMobile ? "MuiBox-root css-1s57e2" : "MuiBox-root css-horqx0"}>
                                                    <div className="MuiBox-root css-z7mtfw">
                                                        <p className={isMobile ? "MuiTypography-root MuiTypography-body1 css-1jnfdqw" : "MuiTypography-root MuiTypography-body1 css-1l3vgja"}>
                                                            QR พร้อมเพย์
                                                        </p>
                                                    </div>
                                                    <div className={isMobile ? "MuiBox-root css-ocosf2" : "MuiBox-root css-v6clrr"}>
                                                        <p className={isMobile ? "MuiTypography-root MuiTypography-body1 css-eouwvp" : "MuiTypography-root MuiTypography-body1 css-1ea94c7"}>
                                                            ***QR Code ใช้ได้ครั้งเดียวเท่านั้น ห้ามใช้ซ้ำเด็ดขาด
                                                        </p>
                                                    </div>
                                                    <div className="MuiBox-root css-ggmmyg" style={{ display: tabselect == 1 && tabselectdeposit == 2 ? "" : "none" }}>
                                                        <p className="MuiTypography-root MuiTypography-body1 css-re002a">
                                                            ฝากเงิน
                                                        </p>
                                                        <div className="MuiFormControl-root MuiTextField-root css-9li811">
                                                            <label
                                                                className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-outlined MuiFormLabel-colorPrimary MuiFormLabel-filled css-u9osun"
                                                                data-shrink="true"
                                                                htmlFor=":r58:"
                                                                id=":r58:-label"
                                                            >
                                                                กรอกจำนวนเงินที่ต้องการฝาก
                                                            </label>
                                                            <div className="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-formControl css-1v4ccyo">
                                                                <input
                                                                    aria-invalid="false"
                                                                    value={this.state.depositamount}
                                                                    type="number"
                                                                    className="MuiInputBase-input MuiOutlinedInput-input css-1x5jdmq"
                                                                    defaultValue={0}
                                                                />
                                                                <fieldset
                                                                    aria-hidden="true"
                                                                    className="MuiOutlinedInput-notchedOutline css-igs3ac"
                                                                >
                                                                    <legend className="css-1in441m">
                                                                        <span>กรอกจำนวนเงินที่ต้องการฝาก</span>
                                                                    </legend>
                                                                </fieldset>
                                                            </div>
                                                        </div>
                                                        <div className="MuiBox-root css-1ybila9">
                                                            <a onClick={(e) => this.handleClickdeposit(e, 300)}>
                                                                <div className="MuiBox-root css-0">
                                                                    <div className="MuiBox-root css-1al89uq">
                                                                        <p className="MuiTypography-root MuiTypography-body1 css-1ea94c7">
                                                                            300
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </a>
                                                            <a onClick={(e) => this.handleClickdeposit(e, 500)}>
                                                                <div className="MuiBox-root css-0">
                                                                    <div className="MuiBox-root css-1al89uq">
                                                                        <p className="MuiTypography-root MuiTypography-body1 css-1ea94c7">
                                                                            500
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </a>
                                                            <a onClick={(e) => this.handleClickdeposit(e, 1000)}>
                                                                <div className="MuiBox-root css-0">
                                                                    <div className="MuiBox-root css-1al89uq">
                                                                        <p className="MuiTypography-root MuiTypography-body1 css-1ea94c7">
                                                                            1,000
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </a>
                                                            <a onClick={(e) => this.handleClickdeposit(e, 2000)}>
                                                                <div className="MuiBox-root css-0">
                                                                    <div className="MuiBox-root css-1al89uq">
                                                                        <p className="MuiTypography-root MuiTypography-body1 css-1ea94c7">
                                                                            2,000
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </a>
                                                            <a onClick={(e) => this.handleClickdeposit(e, 5000)}>
                                                                <div className="MuiBox-root css-0">
                                                                    <div className="MuiBox-root css-1al89uq">
                                                                        <p className="MuiTypography-root MuiTypography-body1 css-1ea94c7">
                                                                            5,000
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </a>
                                                            <a onClick={(e) => this.handleClickdeposit(e, 10000)}>
                                                                <div className="MuiBox-root css-0">
                                                                    <div className="MuiBox-root css-1al89uq">
                                                                        <p className="MuiTypography-root MuiTypography-body1 css-1ea94c7">
                                                                            10,000
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </a>

                                                        </div>
                                                        <a onClick={(e) => this.checktab(e, 3)} className="MuiBox-root css-1tujprv">
                                                            <div>
                                                                <p className="MuiTypography-root MuiTypography-body1 css-1ea94c7">
                                                                    ยืนยัน
                                                                </p>

                                                            </div>
                                                        </a>
                                                    </div>
                                                    <div className="MuiBox-root css-1pn5s3u" style={{ display: tabselect == 1 && tabselectdeposit == 3 ? "" : "none" }}>
                                                        <p className="MuiTypography-root MuiTypography-body1 css-1rhxn48">
                                                            กรุณาโอนเงินภายใน: <span style={{ color: "rgb(255, 0, 0)" }}>09:34</span>
                                                        </p>
                                                        <div className="MuiBox-root css-18f8w4o">
                                                            <div className="MuiBox-root css-1eo7a01">
                                                                <div className="MuiBox-root css-r7q7qr">
                                                                    <span
                                                                        style={{
                                                                            boxSizing: "border-box",
                                                                            display: "inline-block",
                                                                            overflow: "hidden",
                                                                            width: "initial",
                                                                            height: "initial",
                                                                            background: "none",
                                                                            opacity: 1,
                                                                            border: 0,
                                                                            margin: 0,
                                                                            padding: 0,
                                                                            position: "relative",
                                                                            maxWidth: "100%"
                                                                        }}
                                                                    >
                                                                        <span
                                                                            style={{
                                                                                boxSizing: "border-box",
                                                                                display: "block",
                                                                                width: "initial",
                                                                                height: "initial",
                                                                                background: "none",
                                                                                opacity: 1,
                                                                                border: 0,
                                                                                margin: 0,
                                                                                padding: 0,
                                                                                maxWidth: "100%"
                                                                            }}
                                                                        >
                                                                            <img
                                                                                alt=""
                                                                                aria-hidden="true"
                                                                                src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%27300%27%20height=%2760%27/%3e"
                                                                                style={{
                                                                                    display: "block",
                                                                                    maxWidth: "100%",
                                                                                    width: "initial",
                                                                                    height: "initial",
                                                                                    background: "none",
                                                                                    opacity: 1,
                                                                                    border: 0,
                                                                                    margin: 0,
                                                                                    padding: 0
                                                                                }}
                                                                            />
                                                                        </span>
                                                                        <img
                                                                            alt="thai_qr_payment"
                                                                            src="/build/web/igame-index-lobby-wm/img/thai_qr_payment.png"
                                                                            decoding="async"
                                                                            data-nimg="intrinsic"
                                                                            style={{
                                                                                borderTopLeftRadius: 10,
                                                                                borderTopRightRadius: 10,
                                                                                position: "absolute",
                                                                                inset: 0,
                                                                                boxSizing: "border-box",
                                                                                padding: 0,
                                                                                border: "none",
                                                                                margin: "auto",
                                                                                display: "block",
                                                                                width: 0,
                                                                                height: 0,
                                                                                minWidth: "100%",
                                                                                maxWidth: "100%",
                                                                                minHeight: "100%",
                                                                                maxHeight: "100%"
                                                                            }}
                                                                        />
                                                                    </span>
                                                                </div>
                                                                <div className="MuiBox-root css-0" >
                                                                    <div className="react-joyride" />
                                                                    <div id="react-joyride-portal" style={{ display: isMobile || qrstep == 0 ? "none" : "block" }}>
                                                                        <div
                                                                            className="react-joyride__overlay"
                                                                            data-test-id="overlay"
                                                                            role="presentation"
                                                                            style={{
                                                                                display: qrstep == 0 ? "none" : "block",
                                                                                cursor: "pointer",
                                                                                height: 1308,
                                                                                pointerEvents: "auto",
                                                                                inset: 0,
                                                                                overflow: "hidden",
                                                                                position: "absolute",
                                                                                zIndex: 20000,
                                                                                backgroundColor: "rgba(0, 0, 0, 0.5)",
                                                                                mixBlendMode: "hard-light"
                                                                            }}
                                                                        >
                                                                            <div
                                                                                className="react-joyride__spotlight"
                                                                                data-test-id="spotlight"
                                                                                style={{
                                                                                    display: qrstep == 0 ? "none" : "block",
                                                                                    borderRadius: 4,
                                                                                    position: "absolute",
                                                                                    backgroundColor: "gray",
                                                                                    height: 274,
                                                                                    left: 367,
                                                                                    opacity: 1,
                                                                                    pointerEvents: "auto",
                                                                                    top: 765,
                                                                                    transition: "opacity 0.2s ease 0s",
                                                                                    width: 270
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div id="react-joyride-step-0" style={{ display: !isMobile && qrstep == 1 ? "block" : "none", zIndex: 20100 }}>
                                                                        <div
                                                                            className="__floater __floater__open"
                                                                            style={{
                                                                                display: "inline-block",
                                                                                filter: "drop-shadow(rgba(0, 0, 0, 0.3) 0px 0px 3px)",
                                                                                maxWidth: "100%",
                                                                                opacity: 1,
                                                                                position: "absolute",
                                                                                transition: "opacity 0.3s ease 0s, transform 0.2s ease 0s",
                                                                                visibility: "visible",
                                                                                zIndex: 20100,
                                                                                padding: "16px 0px 0px",
                                                                                willChange: "transform",
                                                                                top: 0,
                                                                                left: 0,
                                                                                transform: "translate3d(312px, 1049px, 0px)"
                                                                            }}
                                                                            x-placement="bottom"
                                                                        >
                                                                            <div className="__floater__body">
                                                                                <div
                                                                                    aria-label="QR Code ใช้ได้ครั้งเดียวเท่านั้น   ห้ามใช้ซ้ำเด็ดขาด"
                                                                                    className="react-joyride__tooltip"
                                                                                    aria-modal="true"
                                                                                    role="alertdialog"
                                                                                    style={{
                                                                                        backgroundColor: "rgb(255, 255, 255)",
                                                                                        borderRadius: 5,
                                                                                        boxSizing: "border-box",
                                                                                        color: "rgb(51, 51, 51)",
                                                                                        fontSize: 16,
                                                                                        maxWidth: "100%",
                                                                                        padding: 15,
                                                                                        position: "relative",
                                                                                        width: 380
                                                                                    }}
                                                                                >
                                                                                    <div style={{ lineHeight: "1.4", textAlign: "center" }}>
                                                                                        <div style={{ padding: "20px 10px" }}>
                                                                                            <div>
                                                                                                QR Code ใช้ได้ครั้งเดียวเท่านั้น{" "}
                                                                                                <strong style={{ color: "red" }}>ห้ามใช้ซ้ำเด็ดขาด</strong>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div
                                                                                        style={{
                                                                                            alignItems: "center",
                                                                                            display: "flex",
                                                                                            justifyContent: "flex-end",
                                                                                            marginTop: 15
                                                                                        }}
                                                                                    >
                                                                                        <div style={{ flex: "1 1 0%" }}>
                                                                                            <button
                                                                                                onClick={(e) => {
                                                                                                    this.setState({
                                                                                                        qrstep: 0,
                                                                                                    });
                                                                                                }}
                                                                                                aria-live="off"
                                                                                                data-test-id="button-skip"
                                                                                                type="button"
                                                                                                aria-label="ข้าม"
                                                                                                data-action="skip"
                                                                                                role="button"
                                                                                                title="ข้าม"
                                                                                                style={{
                                                                                                    backgroundColor: "transparent",
                                                                                                    border: 0,
                                                                                                    borderRadius: 0,
                                                                                                    color: "rgb(51, 51, 51)",
                                                                                                    cursor: "pointer",
                                                                                                    fontSize: 14,
                                                                                                    lineHeight: 1,
                                                                                                    padding: 8,
                                                                                                    appearance: "none"
                                                                                                }}
                                                                                            >
                                                                                                ข้าม
                                                                                            </button>
                                                                                        </div>
                                                                                        <button
                                                                                            onClick={(e) => {
                                                                                                this.setState({
                                                                                                    qrstep: 2,
                                                                                                });
                                                                                            }}
                                                                                            data-test-id="button-primary"
                                                                                            type="button"
                                                                                            aria-label="ถัดไป"
                                                                                            data-action="primary"
                                                                                            role="button"
                                                                                            title="ถัดไป"
                                                                                            style={{
                                                                                                backgroundColor: "rgb(255, 0, 68)",
                                                                                                border: 0,
                                                                                                borderRadius: 4,
                                                                                                color: "rgb(255, 255, 255)",
                                                                                                cursor: "pointer",
                                                                                                fontSize: 16,
                                                                                                lineHeight: 1,
                                                                                                padding: 8,
                                                                                                appearance: "none"
                                                                                            }}
                                                                                        >
                                                                                            <span>ถัดไป (1/2)</span>
                                                                                        </button>
                                                                                    </div>
                                                                                    <button
                                                                                        onClick={(e) => {
                                                                                            this.setState({
                                                                                                qrstep: 2,
                                                                                            });
                                                                                        }}
                                                                                        type="button"
                                                                                        data-test-id="button-close"
                                                                                        aria-label="ปิด"
                                                                                        data-action="close"
                                                                                        role="button"
                                                                                        title="ปิด"
                                                                                        style={{
                                                                                            backgroundColor: "transparent",
                                                                                            border: 0,
                                                                                            borderRadius: 0,
                                                                                            cursor: "pointer",
                                                                                            fontSize: 16,
                                                                                            lineHeight: 1,
                                                                                            padding: 15,
                                                                                            appearance: "none",
                                                                                            position: "absolute",
                                                                                            right: 0,
                                                                                            top: 0
                                                                                        }}
                                                                                    >
                                                                                        <svg
                                                                                            height="14px"
                                                                                            preserveAspectRatio="xMidYMid"
                                                                                            version="1.1"
                                                                                            viewBox="0 0 18 18"
                                                                                            width="14px"
                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                        >
                                                                                            <g>
                                                                                                <path
                                                                                                    d="M8.13911129,9.00268191 L0.171521827,17.0258467 C-0.0498027049,17.248715 -0.0498027049,17.6098394 0.171521827,17.8327545 C0.28204354,17.9443526 0.427188206,17.9998706 0.572051765,17.9998706 C0.71714958,17.9998706 0.862013139,17.9443526 0.972581703,17.8327545 L9.0000937,9.74924618 L17.0276057,17.8327545 C17.1384085,17.9443526 17.2832721,17.9998706 17.4281356,17.9998706 C17.5729992,17.9998706 17.718097,17.9443526 17.8286656,17.8327545 C18.0499901,17.6098862 18.0499901,17.2487618 17.8286656,17.0258467 L9.86135722,9.00268191 L17.8340066,0.973848225 C18.0553311,0.750979934 18.0553311,0.389855532 17.8340066,0.16694039 C17.6126821,-0.0556467968 17.254037,-0.0556467968 17.0329467,0.16694039 L9.00042166,8.25611765 L0.967006424,0.167268345 C0.745681892,-0.0553188426 0.387317931,-0.0553188426 0.165993399,0.167268345 C-0.0553311331,0.390136635 -0.0553311331,0.751261038 0.165993399,0.974176179 L8.13920499,9.00268191 L8.13911129,9.00268191 Z"
                                                                                                    fill="#333"
                                                                                                />
                                                                                            </g>
                                                                                        </svg>
                                                                                    </button>
                                                                                </div>
                                                                                <div
                                                                                    className="__floater__arrow"
                                                                                    style={{
                                                                                        pointerEvents: "none",
                                                                                        position: "absolute",
                                                                                        width: "100%",
                                                                                        left: 0,
                                                                                        right: 0,
                                                                                        top: 0,
                                                                                        height: 16
                                                                                    }}
                                                                                >
                                                                                    <span
                                                                                        style={{
                                                                                            display: "inline-flex",
                                                                                            position: "absolute",
                                                                                            marginLeft: 8,
                                                                                            marginRight: 8,
                                                                                            left: 166
                                                                                        }}
                                                                                    >
                                                                                        <svg
                                                                                            width={32}
                                                                                            height={16}
                                                                                            version="1.1"
                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                        >
                                                                                            <polygon points="32,16 16,0 0,16" fill="#fff" />
                                                                                        </svg>
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <span
                                                                            style={{
                                                                                cursor: "help",
                                                                                display: "inline-flex",
                                                                                flexDirection: "column",
                                                                                zIndex: 20100,
                                                                                position: "absolute",
                                                                                visibility: "hidden",
                                                                                willChange: "transform",
                                                                                top: 0,
                                                                                left: 0,
                                                                                transform: "translate3d(484px, 1011px, 0px)"
                                                                            }}
                                                                            x-placement="bottom"
                                                                        >
                                                                            <button
                                                                                onClick={(e) => {
                                                                                    this.setState({
                                                                                        qrstep: 0,
                                                                                    });
                                                                                }}
                                                                                className="react-joyride__beacon"
                                                                                data-test-id="button-beacon"
                                                                                type="button"
                                                                                aria-label="Open the dialog"
                                                                                title="Open the dialog"
                                                                                style={{
                                                                                    backgroundColor: "transparent",
                                                                                    border: 0,
                                                                                    borderRadius: 0,
                                                                                    color: "rgb(85, 85, 85)",
                                                                                    cursor: "pointer",
                                                                                    fontSize: 16,
                                                                                    lineHeight: 1,
                                                                                    padding: 8,
                                                                                    appearance: "none",
                                                                                    display: "inline-block",
                                                                                    height: 36,
                                                                                    position: "relative",
                                                                                    width: 36,
                                                                                    zIndex: 20000
                                                                                }}
                                                                            >
                                                                                <span
                                                                                    style={{
                                                                                        animation:
                                                                                            "1.2s ease-in-out 0s infinite normal none running joyride-beacon-inner",
                                                                                        backgroundColor: "rgb(255, 0, 68)",
                                                                                        borderRadius: "50%",
                                                                                        display: "block",
                                                                                        height: "50%",
                                                                                        left: "50%",
                                                                                        opacity: "0.7",
                                                                                        position: "absolute",
                                                                                        top: "50%",
                                                                                        transform: "translate(-50%, -50%)",
                                                                                        width: "50%"
                                                                                    }}
                                                                                />
                                                                                <span
                                                                                    style={{
                                                                                        animation:
                                                                                            "1.2s ease-in-out 0s infinite normal none running joyride-beacon-outer",
                                                                                        backgroundColor: "rgba(255, 0, 68, 0.2)",
                                                                                        border: "2px solid rgb(255, 0, 68)",
                                                                                        borderRadius: "50%",
                                                                                        boxSizing: "border-box",
                                                                                        display: "block",
                                                                                        height: "100%",
                                                                                        left: 0,
                                                                                        opacity: "0.9",
                                                                                        position: "absolute",
                                                                                        top: 0,
                                                                                        transformOrigin: "center center",
                                                                                        width: "100%"
                                                                                    }}
                                                                                />
                                                                            </button>
                                                                        </span>
                                                                    </div>
                                                                    <div id="react-joyride-step-1" style={{ display: !isMobile && qrstep == 2 ? "block" : "none", zIndex: 20100 }}>
                                                                        <div
                                                                            className="__floater __floater__open"
                                                                            style={{
                                                                                display: "inline-block",
                                                                                filter: "drop-shadow(rgba(0, 0, 0, 0.3) 0px 0px 3px)",
                                                                                maxWidth: "100%",
                                                                                opacity: 1,
                                                                                position: "absolute",
                                                                                transition: "opacity 0.3s ease 0s, transform 0.2s ease 0s",
                                                                                visibility: "visible",
                                                                                zIndex: 20100,
                                                                                padding: "16px 0px 0px",
                                                                                willChange: "transform",
                                                                                top: 0,
                                                                                left: 0,
                                                                                transform: "translate3d(312px, 1049px, 0px)"
                                                                            }}
                                                                            x-placement="bottom"
                                                                        >
                                                                            <div className="__floater__body">
                                                                                <div
                                                                                    aria-label="ใช้แอพพลิเคชั่นธนาคารของท่านสแกน เพื่อฝากเงิน"
                                                                                    className="react-joyride__tooltip"
                                                                                    aria-modal="true"
                                                                                    role="alertdialog"
                                                                                    style={{
                                                                                        backgroundColor: "rgb(255, 255, 255)",
                                                                                        borderRadius: 5,
                                                                                        boxSizing: "border-box",
                                                                                        color: "rgb(51, 51, 51)",
                                                                                        fontSize: 16,
                                                                                        maxWidth: "100%",
                                                                                        padding: 15,
                                                                                        position: "relative",
                                                                                        width: 380
                                                                                    }}
                                                                                >
                                                                                    <div style={{ lineHeight: "1.4", textAlign: "center" }}>
                                                                                        <div style={{ padding: "20px 10px" }}>
                                                                                            ใช้แอพพลิเคชั่นธนาคารของท่านสแกน เพื่อฝากเงิน
                                                                                        </div>
                                                                                    </div>
                                                                                    <div
                                                                                        style={{
                                                                                            alignItems: "center",
                                                                                            display: "flex",
                                                                                            justifyContent: "flex-end",
                                                                                            marginTop: 15
                                                                                        }}
                                                                                    >
                                                                                        <div style={{ flex: "1 1 0%" }} />
                                                                                        <button
                                                                                            onClick={(e) => {
                                                                                                this.setState({
                                                                                                    qrstep: 1,
                                                                                                });
                                                                                            }}
                                                                                            data-test-id="button-back"
                                                                                            type="button"
                                                                                            aria-label="ย้อนกลับ"
                                                                                            data-action="back"
                                                                                            role="button"
                                                                                            title="ย้อนกลับ"
                                                                                            style={{
                                                                                                backgroundColor: "transparent",
                                                                                                border: 0,
                                                                                                borderRadius: 0,
                                                                                                color: "rgb(255, 0, 68)",
                                                                                                cursor: "pointer",
                                                                                                fontSize: 16,
                                                                                                lineHeight: 1,
                                                                                                padding: 8,
                                                                                                appearance: "none",
                                                                                                marginLeft: "auto",
                                                                                                marginRight: 5
                                                                                            }}
                                                                                        >
                                                                                            ย้อนกลับ
                                                                                        </button>
                                                                                        <button
                                                                                            onClick={(e) => {
                                                                                                this.setState({
                                                                                                    qrstep: 0,
                                                                                                });
                                                                                            }}
                                                                                            data-test-id="button-primary"
                                                                                            type="button"
                                                                                            aria-label="ตกลง"
                                                                                            data-action="primary"
                                                                                            role="button"
                                                                                            title="ตกลง"
                                                                                            style={{
                                                                                                backgroundColor: "rgb(255, 0, 68)",
                                                                                                border: 0,
                                                                                                borderRadius: 4,
                                                                                                color: "rgb(255, 255, 255)",
                                                                                                cursor: "pointer",
                                                                                                fontSize: 16,
                                                                                                lineHeight: 1,
                                                                                                padding: 8,
                                                                                                appearance: "none"
                                                                                            }}
                                                                                        >
                                                                                            <span>ตกลง (2/2)</span>
                                                                                        </button>
                                                                                    </div>
                                                                                    <button
                                                                                        onClick={(e) => {
                                                                                            this.setState({
                                                                                                qrstep: 0,
                                                                                            });
                                                                                        }}
                                                                                        type="button"
                                                                                        data-test-id="button-close"
                                                                                        aria-label="ปิด"
                                                                                        data-action="close"
                                                                                        role="button"
                                                                                        title="ปิด"
                                                                                        style={{
                                                                                            backgroundColor: "transparent",
                                                                                            border: 0,
                                                                                            borderRadius: 0,
                                                                                            cursor: "pointer",
                                                                                            fontSize: 16,
                                                                                            lineHeight: 1,
                                                                                            padding: 15,
                                                                                            appearance: "none",
                                                                                            position: "absolute",
                                                                                            right: 0,
                                                                                            top: 0
                                                                                        }}
                                                                                    >
                                                                                        <svg
                                                                                            height="14px"
                                                                                            preserveAspectRatio="xMidYMid"
                                                                                            version="1.1"
                                                                                            viewBox="0 0 18 18"
                                                                                            width="14px"
                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                        >
                                                                                            <g>
                                                                                                <path
                                                                                                    d="M8.13911129,9.00268191 L0.171521827,17.0258467 C-0.0498027049,17.248715 -0.0498027049,17.6098394 0.171521827,17.8327545 C0.28204354,17.9443526 0.427188206,17.9998706 0.572051765,17.9998706 C0.71714958,17.9998706 0.862013139,17.9443526 0.972581703,17.8327545 L9.0000937,9.74924618 L17.0276057,17.8327545 C17.1384085,17.9443526 17.2832721,17.9998706 17.4281356,17.9998706 C17.5729992,17.9998706 17.718097,17.9443526 17.8286656,17.8327545 C18.0499901,17.6098862 18.0499901,17.2487618 17.8286656,17.0258467 L9.86135722,9.00268191 L17.8340066,0.973848225 C18.0553311,0.750979934 18.0553311,0.389855532 17.8340066,0.16694039 C17.6126821,-0.0556467968 17.254037,-0.0556467968 17.0329467,0.16694039 L9.00042166,8.25611765 L0.967006424,0.167268345 C0.745681892,-0.0553188426 0.387317931,-0.0553188426 0.165993399,0.167268345 C-0.0553311331,0.390136635 -0.0553311331,0.751261038 0.165993399,0.974176179 L8.13920499,9.00268191 L8.13911129,9.00268191 Z"
                                                                                                    fill="#333"
                                                                                                />
                                                                                            </g>
                                                                                        </svg>
                                                                                    </button>
                                                                                </div>
                                                                                <div
                                                                                    className="__floater__arrow"
                                                                                    style={{
                                                                                        pointerEvents: "none",
                                                                                        position: "absolute",
                                                                                        width: "100%",
                                                                                        left: 0,
                                                                                        right: 0,
                                                                                        top: 0,
                                                                                        height: 16
                                                                                    }}
                                                                                >
                                                                                    <span
                                                                                        style={{
                                                                                            display: "inline-flex",
                                                                                            position: "absolute",
                                                                                            marginLeft: 8,
                                                                                            marginRight: 8,
                                                                                            left: 166
                                                                                        }}
                                                                                    >
                                                                                        <svg
                                                                                            width={32}
                                                                                            height={16}
                                                                                            version="1.1"
                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                        >
                                                                                            <polygon points="32,16 16,0 0,16" fill="#fff" />
                                                                                        </svg>
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <span
                                                                            style={{
                                                                                cursor: "help",
                                                                                display: "inline-flex",
                                                                                flexDirection: "column",
                                                                                zIndex: 20100,
                                                                                position: "absolute",
                                                                                top: "-1000px",
                                                                                left: "-1000px",
                                                                                visibility: "hidden"
                                                                            }}
                                                                            x-placement="bottom"
                                                                        >
                                                                            <button
                                                                                className="react-joyride__beacon"
                                                                                data-test-id="button-beacon"
                                                                                type="button"
                                                                                aria-label="Open the dialog"
                                                                                title="Open the dialog"
                                                                                style={{
                                                                                    backgroundColor: "transparent",
                                                                                    border: 0,
                                                                                    borderRadius: 0,
                                                                                    color: "rgb(85, 85, 85)",
                                                                                    cursor: "pointer",
                                                                                    fontSize: 16,
                                                                                    lineHeight: 1,
                                                                                    padding: 8,
                                                                                    appearance: "none",
                                                                                    display: "inline-block",
                                                                                    height: 36,
                                                                                    position: "relative",
                                                                                    width: 36,
                                                                                    zIndex: 20000
                                                                                }}
                                                                            >
                                                                                <span
                                                                                    style={{
                                                                                        animation:
                                                                                            "1.2s ease-in-out 0s infinite normal none running joyride-beacon-inner",
                                                                                        backgroundColor: "rgb(255, 0, 68)",
                                                                                        borderRadius: "50%",
                                                                                        display: "block",
                                                                                        height: "50%",
                                                                                        left: "50%",
                                                                                        opacity: "0.7",
                                                                                        position: "absolute",
                                                                                        top: "50%",
                                                                                        transform: "translate(-50%, -50%)",
                                                                                        width: "50%"
                                                                                    }}
                                                                                />
                                                                                <span
                                                                                    style={{
                                                                                        animation:
                                                                                            "1.2s ease-in-out 0s infinite normal none running joyride-beacon-outer",
                                                                                        backgroundColor: "rgba(255, 0, 68, 0.2)",
                                                                                        border: "2px solid rgb(255, 0, 68)",
                                                                                        borderRadius: "50%",
                                                                                        boxSizing: "border-box",
                                                                                        display: "block",
                                                                                        height: "100%",
                                                                                        left: 0,
                                                                                        opacity: "0.9",
                                                                                        position: "absolute",
                                                                                        top: 0,
                                                                                        transformOrigin: "center center",
                                                                                        width: "100%"
                                                                                    }}
                                                                                />
                                                                            </button>
                                                                        </span>
                                                                    </div>
                                                                    <div className="MuiBox-root css-0" id="QRPanel" >
                                                                        <span
                                                                            style={{

                                                                                boxSizing: "border-box",
                                                                                display: "inline-block",
                                                                                overflow: "hidden",
                                                                                width: "initial",
                                                                                height: "initial",
                                                                                background: "none",
                                                                                opacity: 1,
                                                                                border: 0,
                                                                                margin: 0,
                                                                                padding: 0,
                                                                                position: "relative",
                                                                                maxWidth: "100%"
                                                                            }}
                                                                        >
                                                                            <span
                                                                                style={{
                                                                                    boxSizing: "border-box",
                                                                                    width: "initial",
                                                                                    height: "initial",
                                                                                    background: "none",
                                                                                    opacity: 1,
                                                                                    border: 0,
                                                                                    margin: 0,
                                                                                    padding: 0,
                                                                                    maxWidth: "100%"
                                                                                }}
                                                                            >
                                                                                <img
                                                                                    alt=""
                                                                                    aria-hidden="true"
                                                                                    src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%27250%27%20height=%27250%27/%3e"
                                                                                    style={{
                                                                                        maxWidth: "100%",
                                                                                        width: "initial",
                                                                                        height: "initial",
                                                                                        background: "none",
                                                                                        opacity: 1,
                                                                                        border: 0,
                                                                                        margin: 0,
                                                                                        padding: 0
                                                                                    }}
                                                                                />
                                                                            </span>
                                                                            <img alt="QR Code"
                                                                                src={this.state.qrcode}
                                                                                decoding="async"
                                                                                data-nimg="intrinsic"
                                                                                style={{

                                                                                    display: this.state.qrcode != null ? "block" : "none",
                                                                                    position: "absolute",
                                                                                    inset: 0,
                                                                                    boxSizing: "border-box",
                                                                                    padding: 0,
                                                                                    border: "none",
                                                                                    margin: "auto",
                                                                                    width: 0,
                                                                                    height: 0,
                                                                                    minWidth: "100%",
                                                                                    maxWidth: "100%",
                                                                                    minHeight: "100%",
                                                                                    maxHeight: "100%"
                                                                                }}
                                                                            />

                                                                        </span>
                                                                        <div className="MuiBox-root css-k04key" style={{ display: this.state.qrcode != null ? "none" : "" }} >
                                                                            <div className="loaderQR" />
                                                                        </div>
                                                                    </div>

                                                                </div>

                                                                <div className="MuiBox-root css-kmckuy">
                                                                    <p className="MuiTypography-root MuiTypography-body1 css-1fih6ln">
                                                                        {this.state.depositamount}
                                                                    </p>
                                                                    <p className="MuiTypography-root MuiTypography-body1 css-10utm2h">
                                                                        บาท (BAHT)
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="MuiBox-root css-1gtsqq7">
                                                                <a href={this.state.qrcode} download="qrcode.png">
                                                                    <div className="MuiBox-root css-3o8ygt" id="save-qr-button">

                                                                        <div className="MuiBox-root css-1nl99vy">
                                                                            <svg
                                                                                width={24}
                                                                                height={24}
                                                                                viewBox="0 0 24 24"
                                                                                strokeWidth={2}
                                                                                stroke="currentColor"
                                                                                fill="none"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            >
                                                                                {" "}
                                                                                <path stroke="none" d="M0 0h24v24H0z" />{" "}
                                                                                <path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2" />{" "}
                                                                                <polyline points="7 11 12 16 17 11" />{" "}
                                                                                <line x1={12} y1={4} x2={12} y2={16} />
                                                                            </svg>
                                                                        </div>

                                                                        <p className={isMobile ? "MuiTypography-root MuiTypography-body1 css-17nk34x" : "MuiTypography-root MuiTypography-body1 css-17nk34h"}>
                                                                            บันทึก
                                                                        </p>

                                                                    </div>
                                                                </a>
                                                                <a onClick={(e) => {
                                                                    this.cancleqrcode()

                                                                }}>
                                                                    <div className="MuiBox-root css-nhvxfc">

                                                                        <div className="MuiBox-root css-gozhb5">
                                                                            <svg
                                                                                width={24}
                                                                                height={24}
                                                                                viewBox="0 0 24 24"
                                                                                strokeWidth={2}
                                                                                stroke="currentColor"
                                                                                fill="none"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            >
                                                                                {" "}
                                                                                <path stroke="none" d="M0 0h24v24H0z" />{" "}
                                                                                <line x1={18} y1={6} x2={6} y2={18} />{" "}
                                                                                <line x1={6} y1={6} x2={18} y2={18} />
                                                                            </svg>
                                                                        </div>
                                                                        <p className={isMobile ? "MuiTypography-root MuiTypography-body1 css-yqx1ux" : "MuiTypography-root MuiTypography-body1 css-yqx1uy"}>

                                                                            ยกเลิกรายการ
                                                                        </p>

                                                                    </div>
                                                                </a>
                                                            </div>

                                                            <div className="MuiBox-root css-a9a81e" onClick={(e) => {
                                                                this.confirmqrcode()

                                                            }}>

                                                                <div className="MuiBox-root css-d4bshj">
                                                                    <svg
                                                                        width={30}
                                                                        height={30}
                                                                        viewBox="0 0 24 24"
                                                                        strokeWidth={2}
                                                                        stroke="currentColor"
                                                                        fill="none"
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                    >
                                                                        {" "}
                                                                        <path
                                                                            stroke="none"
                                                                            d="M0 0h24v24H0z"
                                                                        /> <path d="M7 12l5 5l10 -10" /> <path d="M2 12l5 5m5 -5l5 -5" />
                                                                    </svg>
                                                                </div>
                                                                <p className={isMobile ? "MuiTypography-root MuiTypography-body1 css-17nk34x" : "MuiTypography-root MuiTypography-body1 css-17nk34h"}>

                                                                    ยืนยันการโอนเงิน
                                                                </p>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={isMobile ? "MuiBox-root css-1vtyosv" : "MuiBox-root css-1my7il4"}>
                                                    <p className={isMobile ? "MuiTypography-root MuiTypography-body1 css-1l89ba3" : "MuiTypography-root MuiTypography-body1 css-k33gq0"}>
                                                        <span style={{ marginRight: 10 }}>•</span>QR Code นี้ใช้ได้เพียง{" "}
                                                        <span
                                                            style={{
                                                                fontFamily: "KanitSemiBold",
                                                                fontSize: 18,
                                                                color: "rgb(130, 185, 52)"
                                                            }}
                                                        >
                                                            1 ครั้งเท่านั้น
                                                        </span>
                                                    </p>
                                                    <p className={isMobile ? "MuiTypography-root MuiTypography-body1 css-1l89ba3" : "MuiTypography-root MuiTypography-body1 css-k33gq0"}>
                                                        <span style={{ marginRight: 10 }}>•</span>ห้ามแก้ไข{" "}
                                                        <span
                                                            style={{
                                                                fontFamily: "KanitSemiBold",
                                                                fontSize: 18,
                                                                color: "rgb(130, 185, 52)"
                                                            }}
                                                        >
                                                            จำนวนเงิน
                                                        </span>
                                                    </p>
                                                    <p className={isMobile ? "MuiTypography-root MuiTypography-body1 css-1l89ba3" : "MuiTypography-root MuiTypography-body1 css-k33gq0"}>
                                                        <span style={{ marginRight: 10 }}>•</span>ต้องทำการโอนภายใน{" "}
                                                        <span
                                                            style={{
                                                                fontFamily: "KanitSemiBold",
                                                                fontSize: 18,
                                                                color: "rgb(130, 185, 52)"
                                                            }}
                                                        >
                                                            ระยะเวลาที่กำหนด
                                                        </span>
                                                    </p>
                                                    <p className={isMobile ? "MuiTypography-root MuiTypography-body1 css-1l89ba3" : "MuiTypography-root MuiTypography-body1 css-k33gq0"}>
                                                        <span style={{ marginRight: 10 }}>•</span>หากผิดเงื่อนไข{" "}
                                                        <span
                                                            style={{
                                                                fontFamily: "KanitSemiBold",
                                                                fontSize: 18,
                                                                color: "rgb(130, 185, 52)"
                                                            }}
                                                        >
                                                            เงินจะไม่เข้า
                                                        </span>{" "}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="MuiBox-root css-1uus8u6" style={{ display: tabselect == 1 && tabselectdeposit == 4 ? "" : "none" }}>
                                        <div className="MuiBox-root css-0">
                                            <div className="MuiBox-root css-1y1dsbn">
                                                <div className="MuiBox-root css-4g6ai3">
                                                    <button
                                                        className="MuiButtonBase-root MuiFab-root MuiFab-circular MuiFab-sizeSmall MuiFab-default css-jjtg8b"
                                                        tabIndex={0}
                                                        type="button"
                                                        aria-label="back"
                                                        onClick={(e) => {
                                                            this.checktab(e, 0)
                                                        }}
                                                    >
                                                        <svg
                                                            className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1kpcl3g"
                                                            focusable="false"
                                                            aria-hidden="true"
                                                            viewBox="0 0 24 24"
                                                            data-testid="ArrowBackIosNewIcon"
                                                        >
                                                            <path d="M17.77 3.77 16 2 6 12l10 10 1.77-1.77L9.54 12z" />
                                                        </svg>
                                                        <span className="MuiTouchRipple-root css-w0pj6f" />
                                                    </button>
                                                </div>
                                                <div className={isMobile ? "MuiBox-root css-tofwxk" : "MuiBox-root css-1e6etgl"}>
                                                    <div className={isMobile ? "MuiBox-root css-3zzx4j" : "MuiBox-root css-kqrcco"}>
                                                        <span
                                                            style={{
                                                                boxSizing: "border-box",
                                                                display: "inline-block",
                                                                overflow: "hidden",
                                                                width: "initial",
                                                                height: "initial",
                                                                background: "none",
                                                                opacity: 1,
                                                                border: 0,
                                                                margin: 0,
                                                                padding: 0,
                                                                position: "relative",
                                                                maxWidth: "100%"
                                                            }}
                                                        >
                                                            <span
                                                                style={{
                                                                    boxSizing: "border-box",
                                                                    display: "block",
                                                                    width: "initial",
                                                                    height: "initial",
                                                                    background: "none",
                                                                    opacity: 1,
                                                                    border: 0,
                                                                    margin: 0,
                                                                    padding: 0,
                                                                    maxWidth: "100%"
                                                                }}
                                                            >
                                                                <img
                                                                    alt=""
                                                                    aria-hidden="true"
                                                                    src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2730%27%20height=%2730%27/%3e"
                                                                    style={{
                                                                        display: "block",
                                                                        maxWidth: "100%",
                                                                        width: "initial",
                                                                        height: "initial",
                                                                        background: "none",
                                                                        opacity: 1,
                                                                        border: 0,
                                                                        margin: 0,
                                                                        padding: 0
                                                                    }}
                                                                />
                                                            </span>
                                                            <img
                                                                alt="transfer"
                                                                src="/build/web/igame-index-lobby-wm/img/icon_1.webp"
                                                                decoding="async"
                                                                data-nimg="intrinsic"
                                                                style={{
                                                                    position: "absolute",
                                                                    inset: 0,
                                                                    boxSizing: "border-box",
                                                                    padding: 0,
                                                                    border: "none",
                                                                    margin: "auto",
                                                                    display: "block",
                                                                    width: 0,
                                                                    height: 0,
                                                                    minWidth: "100%",
                                                                    maxWidth: "100%",
                                                                    minHeight: "100%",
                                                                    maxHeight: "100%"
                                                                }}
                                                            />
                                                        </span>
                                                    </div>
                                                    <p className={isMobile ? "MuiTypography-root MuiTypography-body1 css-mmxl9m" : "MuiTypography-root MuiTypography-body1 css-mmxl9m"}>
                                                        <span style={{ color: "rgb(252, 186, 0)" }}>อัพโหลดสลิป</span>
                                                        <span style={{ color: "white", marginLeft: 6 }}>
                                                            เพื่อทำการอัพเดทยอดเงินด้วยตนเอง
                                                        </span>
                                                    </p>
                                                </div>
                                                <div className="MuiBox-root css-0" />
                                            </div>
                                            <div className="MuiBox-root css-1vd84sn">
                                                <div className="MuiBox-root css-10zh34x">
                                                    <label
                                                        style={{ display: (this.state.files.length > 0) ? "none" : "" }}
                                                        htmlFor="upload"
                                                        className="btn btn-copy-account btn-sm w-100"
                                                        id="uploadButton"
                                                    >
                                                        <svg
                                                            className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1yncfsi"
                                                            focusable="false"
                                                            aria-hidden="true"
                                                            viewBox="0 0 24 24"
                                                            data-testid="CloudUploadIcon"
                                                        >
                                                            <path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM14 13v4h-4v-4H7l5-5 5 5h-3z" />
                                                        </svg>
                                                        <p className="MuiTypography-root MuiTypography-body1 css-it1798">
                                                            คลิกเพื่ออัพโหลดไฟล์สลิป
                                                        </p>
                                                    </label>
                                                    <input type="file" hidden onChange={(e) => this.handleFileSelected(e)} className="btn btn-copy-account btn-sm w-100" id="upload" name="filename"></input>
                                                    <img id="imagePreview" src={(this.state.files.length > 0) ? URL.createObjectURL(this.state.files[0]) : null} width="100%" height="100%" className="w-100" />
                                                    {/* <input
                                                        type="file"
                                                        accept="image/*"
                                                        id="slip-upload"
                                                        style={{ display: "none" }}
                                                    />
                                                    <div id="qrfrg" /> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="MuiContainer-root MuiContainer-maxWidthLg css-1qsxih2" style={{ display: tabselect == 3 ? "" : "none" }}>
                                <div className="MuiBox-root css-185xt85">
                                    <svg
                                        className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1kjx1i6"
                                        focusable="false"
                                        aria-hidden="true"
                                        viewBox="0 0 24 24"
                                        data-testid="HistoryIcon"
                                    >
                                        <path d="M13 3c-4.97 0-9 4.03-9 9H1l3.89 3.89.07.14L9 12H6c0-3.87 3.13-7 7-7s7 3.13 7 7-3.13 7-7 7c-1.93 0-3.68-.79-4.94-2.06l-1.42 1.42C8.27 19.99 10.51 21 13 21c4.97 0 9-4.03 9-9s-4.03-9-9-9zm-1 5v5l4.28 2.54.72-1.21-3.5-2.08V8H12z" />
                                    </svg>
                                    <p className="MuiTypography-root MuiTypography-body1 css-1vvjge2">
                                        ประวัติฝากถอน
                                    </p>
                                </div>
                                <div className="MuiBox-root css-1gtlm75">
                                    {this.state.historylist.filter((listitem) => listitem.type !== "CC").map((listitem, i) => (
                                        <div className="MuiBox-root css-xe2p4q">
                                            <div className="MuiBox-root css-gg4vpm">
                                                <div className="MuiBox-root css-0">
                                                    <p className="MuiTypography-root MuiTypography-body1 css-q45k9g">
                                                        <span style={{ color: listitem.type === "DE" ? "rgb(129, 212, 57)" : listitem.type === "BN" ? "rgb(19, 98, 234)" : "rgb(234, 19, 61)" }}> {(listitem.type === "DE") ? "ฝาก" : (listitem.type === "BN") ? "โบนัส" : "ถอน"}</span>
                                                        <span style={{ marginLeft: 10 }}>
                                                            <span style={{ color: "rgb(249, 201, 0)", display: (listitem.status === "2") ? "" : "none" }}>กำลังดำเนินการ</span>
                                                            <span style={{ color: "rgb(249, 201, 0)", display: (listitem.status === "0") ? "" : "none" }}>รอดำเนินการ</span>
                                                            <span style={{ color: "rgb(249, 201, 0)", display: (listitem.status === "4") ? "" : "none" }}>ไม่สำเร็จ</span>
                                                            <span style={{ color: "rgb(249, 201, 0)", display: (listitem.status === "5") ? "" : "none" }}>ไม่สำเร็จ</span>
                                                            <span style={{ color: "rgb(129, 212, 57)", display: (listitem.status === "1") ? "" : "none" }}>สำเร็จ</span>
                                                        </span>
                                                    </p>
                                                </div>
                                                <div className="MuiBox-root css-0">
                                                    <p style={{ color: listitem.type === "DE" ? "rgb(129, 212, 57)" : "rgb(234, 19, 61)" }} className="MuiTypography-root MuiTypography-body1 css-lh9az3">
                                                        {listitem.amount}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="MuiBox-root css-1a2cqpf">
                                                <div className="MuiBox-root css-k008qs">
                                                    <p className="MuiTypography-root MuiTypography-body1 css-12jnaqg">
                                                        {moment(listitem.createdate).format('DD/MM/YY')}
                                                    </p>
                                                    <p className="MuiTypography-root MuiTypography-body1 css-1rkinoq">
                                                        {moment(listitem.createdate).format('HH:mm') + " น."}
                                                    </p>
                                                </div>
                                                <div className="MuiBox-root css-0" />
                                            </div>
                                        </div>
                                    ))}
                                    {/* <div className="MuiBox-root css-xe2p4q">
                                        <div className="MuiBox-root css-gg4vpm">
                                            <div className="MuiBox-root css-0">
                                                <p className="MuiTypography-root MuiTypography-body1 css-q45k9g">
                                                    <span style={{ color: "rgb(129, 212, 57)" }}>ฝาก</span>
                                                    <span style={{ marginLeft: 10 }}>
                                                        <span style={{ color: "rgb(129, 212, 57)" }}>สำเร็จ</span>
                                                    </span>
                                                </p>
                                            </div>
                                            <div className="MuiBox-root css-0">
                                                <p className="MuiTypography-root MuiTypography-body1 css-t9yzf6">
                                                    +300
                                                </p>
                                            </div>
                                        </div>
                                        <div className="MuiBox-root css-1a2cqpf">
                                            <div className="MuiBox-root css-k008qs">
                                                <p className="MuiTypography-root MuiTypography-body1 css-12jnaqg">
                                                    10 ส.ค. 2024
                                                </p>
                                                <p className="MuiTypography-root MuiTypography-body1 css-1rkinoq">
                                                    14:28 น.
                                                </p>
                                            </div>
                                            <div className="MuiBox-root css-0" />
                                        </div>
                                    </div> */}
                                </div>
                                <div className="MuiBox-root css-1sjt00n">
                                    <nav
                                        aria-label="pagination navigation"
                                        className="MuiPagination-root MuiPagination-outlined css-1e4e398"
                                    >
                                        <ul className="MuiPagination-ul css-nhb8h9">
                                            <li>
                                                <button
                                                    className="MuiButtonBase-root Mui-disabled MuiPaginationItem-root MuiPaginationItem-sizeLarge MuiPaginationItem-outlined MuiPaginationItem-rounded Mui-disabled MuiPaginationItem-previousNext css-189vk5p"
                                                    tabIndex={-1}
                                                    type="button"
                                                    disabled=""
                                                    aria-label="Go to previous page"
                                                >
                                                    <svg
                                                        className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiPaginationItem-icon css-1qz46so"
                                                        focusable="false"
                                                        aria-hidden="true"
                                                        viewBox="0 0 24 24"
                                                        data-testid="NavigateBeforeIcon"
                                                    >
                                                        <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
                                                    </svg>
                                                </button>
                                            </li>
                                            <li>
                                                <button
                                                    className="MuiButtonBase-root MuiPaginationItem-root MuiPaginationItem-sizeLarge MuiPaginationItem-outlined MuiPaginationItem-rounded Mui-selected MuiPaginationItem-page css-189vk5p"
                                                    tabIndex={0}
                                                    type="button"
                                                    aria-label="page 1"
                                                    aria-current="true"
                                                >
                                                    1<span className="MuiTouchRipple-root css-w0pj6f" />
                                                </button>
                                            </li>
                                            <li>
                                                <button
                                                    className="MuiButtonBase-root Mui-disabled MuiPaginationItem-root MuiPaginationItem-sizeLarge MuiPaginationItem-outlined MuiPaginationItem-rounded Mui-disabled MuiPaginationItem-previousNext css-189vk5p"
                                                    tabIndex={-1}
                                                    type="button"
                                                    disabled=""
                                                    aria-label="Go to next page"
                                                >
                                                    <svg
                                                        className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiPaginationItem-icon css-1qz46so"
                                                        focusable="false"
                                                        aria-hidden="true"
                                                        viewBox="0 0 24 24"
                                                        data-testid="NavigateNextIcon"
                                                    >
                                                        <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
                                                    </svg>
                                                </button>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                            <div className="MuiContainer-root MuiContainer-maxWidthLg css-1qsxih2" style={{ display: tabselect == 27 ? "" : "none" }}>
                                <div className="MuiBox-root css-185xt85">
                                    <svg
                                        className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1kjx1i6"
                                        focusable="false"
                                        aria-hidden="true"
                                        viewBox="0 0 24 24"
                                        data-testid="HistoryIcon"
                                    >
                                        <path d="M13 3c-4.97 0-9 4.03-9 9H1l3.89 3.89.07.14L9 12H6c0-3.87 3.13-7 7-7s7 3.13 7 7-3.13 7-7 7c-1.93 0-3.68-.79-4.94-2.06l-1.42 1.42C8.27 19.99 10.51 21 13 21c4.97 0 9-4.03 9-9s-4.03-9-9-9zm-1 5v5l4.28 2.54.72-1.21-3.5-2.08V8H12z" />
                                    </svg>
                                    <p className="MuiTypography-root MuiTypography-body1 css-1vvjge2">
                                        ประวัติรับ Ranking
                                    </p>
                                </div>
                                <div className="pt-2 pb-2">
                                    <div className="MuiBox-root css-1gtlm76">
                                        <div className="bg-profile-box-overview p-2 mb-2">
                                            <div className="-group-trace-wrapper">

                                                <div className="x-profile-group-trace-condition ">
                                                    <div className="-card-condition-wrapper">
                                                        <div className="-card-condition-heading">
                                                            <span className="-title">
                                                                เงื่อนไขขั้น {(tblnextrank === null) ? "" : tblnextrank.name}
                                                                {(tblnextrank === null) ? "" : <img
                                                                    style={{ marginLeft: "10px" }}
                                                                    width="50px"
                                                                    className="mr-3"
                                                                    src={(tblnextrank.image == null || tblnextrank.image == "") ? "https://betner.co/build/rank/1.jpeg" : (tblnextrank.picrack == null || tblnextrank.picrack == "") ? "https://betner.co/build/rank/" + tblnextrank.image : tblnextrank.picrack}
                                                                    alt=""
                                                                />}
                                                            </span>
                                                        </div>
                                                        <div className="-card-condition-description">
                                                            ฝากอีก <span className="-highlight">{(rankob === null) ? "" : (rankob.highamount - rankpoint + 1).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</span> บาท
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="text-right">
                                                <small id="max_deposit">{(rankob === null) ? 0 : ((rankpoint / rankob.highamount) * 100)} %</small>
                                            </div>
                                            <div className="pb-0">
                                                <div
                                                    className="progress progress-lv"
                                                    role="progressbar"
                                                    aria-label="Example with label"
                                                    aria-valuenow={(rankob === null) ? 0 : ((rankpoint / rankob.highamount) * 100)}
                                                    style={{ height: "5px !important" }}
                                                    aria-valuemin={0}
                                                    aria-valuemax={(rankob === null) ? 0 : rankob.highamount - rankpoint}
                                                >
                                                    <div
                                                        className="progress-bar"
                                                        style={{ width: (rankob === null) ? "" : ((rankpoint / rankob.highamount) * 100) + "%" }}
                                                    />
                                                </div>
                                            </div>

                                            <div className="d-flex justify-content-between">

                                                <div className="align-self-center text-left pt-2">
                                                    <div role="button">
                                                        <small className="font-play-text">
                                                            ยอดฝากปัจจุบัน
                                                        </small>
                                                        <div>
                                                            <b>{(rankob === null) ? "" : rankob.lowamount.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} บาท</b>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="align-self-center text-right pt-2">
                                                    <div role="button">
                                                        <small className="font-play-text">
                                                            คุณต้องทำยอดฝาก
                                                        </small>
                                                        <div>
                                                            <b>{(rankob === null) ? "" : (rankob.highamount + 1).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}  บาท</b>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr className="m-0 color-hr" />
                                        </div>
                                        <table className="table bg-dark-table">
                                            {this.state.tblrankall.map((listitem, i) => (
                                                <tr key={i} id={listitem.no} style={{ color: "white" }}>
                                                    <td className="-description-body-wrapper">
                                                        <img
                                                            width={"100px"}
                                                            className="img-fluid -profile-image"
                                                            src={(listitem.picrack != null ? listitem.picrack : "/build/rank/" + listitem.image)}

                                                            alt=""
                                                        />
                                                        <div className="-title-wrapper">
                                                            <p><span className="-title">{listitem.name}</span>        </p>
                                                        </div>
                                                        <div className="-state-wrapper">
                                                            <span className="-text-description">{"เติม " + listitem.lowamount.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} บาทขึ้นไป {(listitem.no === 1) ? "" : "โบนัส " + listitem.claimamount.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + " บาท"} </span>
                                                        </div>
                                                    </td>
                                                    <td className="-transaction-body-wrapper">
                                                        {
                                                            (listitem.no === 1) ? "" :
                                                                (listitem.lowamount <= rankpoint && !listitem.checked) ? <div> <img
                                                                    src="https://asset.cloudigame.co/build/admin/img/wt_theme/popular_3/ic_success.png"
                                                                    className="-ic"
                                                                    width={"30px"}
                                                                    alt=""
                                                                /><span className="-state-title" style={{ marginLeft: "10px" }}>สำเร็จ</span></div> :
                                                                    (!listitem.checked) ? "ยังไม่ผ่านเงื่อนไข" : <a href={() => false} style={{ cursor: "pointer" }} className="text-white js-account-approve-aware btn -btn-deposit active" onClick={(e) => {
                                                                        this.redeemrank(e, listitem)
                                                                    }}><div className="f-7">Cliam</div></a>}
                                                    </td>
                                                </tr>
                                            ))}

                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="MuiContainer-root MuiContainer-maxWidthLg css-1qsxih2" style={{ display: tabselect == 28 ? "" : "none" }}>
                                <div className="MuiBox-root css-185xt85">
                                    <svg
                                        className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1kjx1i6"
                                        focusable="false"
                                        aria-hidden="true"
                                        viewBox="0 0 24 24"
                                        data-testid="HistoryIcon"
                                    >
                                        <path d="M13 3c-4.97 0-9 4.03-9 9H1l3.89 3.89.07.14L9 12H6c0-3.87 3.13-7 7-7s7 3.13 7 7-3.13 7-7 7c-1.93 0-3.68-.79-4.94-2.06l-1.42 1.42C8.27 19.99 10.51 21 13 21c4.97 0 9-4.03 9-9s-4.03-9-9-9zm-1 5v5l4.28 2.54.72-1.21-3.5-2.08V8H12z" />
                                    </svg>
                                    <p className="MuiTypography-root MuiTypography-body1 css-1vvjge2">
                                        วงล้อนำโชค
                                    </p>
                                </div>
                                <div className="MuiBox-root css-1gtlm76">
                                    <Luckywhell props={this.state} />
                                </div>
                            </div>
                            <div className="MuiContainer-root MuiContainer-maxWidthLg css-1qsxih2" style={{ display: tabselect == 4 ? "" : "none" }}>
                                <div className="MuiBox-root css-1idkcu9">
                                    <p class="MuiTypography-root MuiTypography-body1 css-gatbua">ชวนเพื่อน</p>
                                    <div className="referral-container MuiBox-root css-pzkg3g">
                                        <p className={isMobile ? "MuiTypography-root MuiTypography-body1 css-j0xn0g" : "MuiTypography-root MuiTypography-body1 css-1ebealq"}>
                                            {this.state.linkaff}
                                        </p>
                                        <a
                                            href={() => false} style={{ cursor: "pointer" }} onClick={() => this.copyCode(this.state.linkaff)}
                                            className={isMobile ? "MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium css-9g2ayf" : "MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium css-9g2ayf"}
                                            tabIndex={0}
                                            type="button"
                                        >
                                            คัดลอกลิงค์
                                            <span className="MuiTouchRipple-root css-w0pj6f" />
                                        </a>
                                        <div className={isMobile ? "MuiBox-root css-p6uj52" : "MuiBox-root css-1q7njkh"}>
                                            <h6 className={isMobile ? "MuiTypography-root MuiTypography-subtitle1 css-add3au" : "MuiTypography-root MuiTypography-subtitle1 css-rzri9o"}>
                                                นำโค้ดแนะนำเพื่อนให้เพื่อนกรอกตอนสมัครสมาชิก
                                                เพื่อรับโบนัสและสิทธิพิเศษต่างๆ
                                            </h6>
                                            <h6 className={isMobile ? "MuiTypography-root MuiTypography-subtitle1 css-add3au" : "MuiTypography-root MuiTypography-subtitle1 css-rzri9o"}>
                                                หรือ คัดลอกลิ้งค์ด้านบน ส่งไปให้เพื่อนสมัคร
                                                เพื่อรับโบนัสและสิทธิพิเศษต่างๆเช่นกัน
                                            </h6>
                                        </div>
                                        <div className={isMobile ? "MuiBox-root css-7buviw" : "MuiBox-root css-e5kyuz"}>
                                            <div className={isMobile ? "MuiBox-root css-1gwszu2" : "MuiBox-root css-1gwszu2"}>
                                                <div className={isMobile ? "MuiBox-root css-34rafo" : "MuiBox-root css-34rafo"}>
                                                    <p className={isMobile ? "MuiTypography-root MuiTypography-body1 css-t4qzmy" : "MuiTypography-root MuiTypography-body1 css-t4qzmy"}>
                                                        จำนวนสมัคร
                                                    </p>
                                                </div>
                                                <div className={isMobile ? "MuiBox-root css-p060kb" : "MuiBox-root css-p060kb"}>
                                                    <p className={isMobile ? "MuiTypography-root MuiTypography-body1 css-t4qzmy" : "MuiTypography-root MuiTypography-body1 css-t4qzmy"}> {this.state.stats.totalSignups} </p>
                                                </div>
                                            </div>
                                            <div className={isMobile ? "MuiBox-root css-88lslg" : "MuiBox-root css-88lslg"}>
                                                <div className={isMobile ? "MuiBox-root css-34rafo" : "MuiBox-root css-34rafo"}>
                                                    <p className={isMobile ? "MuiTypography-root MuiTypography-body1 css-t4qzmy" : "MuiTypography-root MuiTypography-body1 css-t4qzmy"}>
                                                        กระเป๋าชวนเพื่อน
                                                    </p>
                                                </div>
                                                <div className={isMobile ? "MuiBox-root css-p060kb" : "MuiBox-root css-p060kb"}>
                                                    <p className={isMobile ? "MuiTypography-root MuiTypography-body1 css-t4qzmy" : "MuiTypography-root MuiTypography-body1 css-t4qzmy"}>฿ {tblprofile.AffiliateCash} </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={isMobile ? "MuiBox-root css-a8y9ya" : "MuiBox-root css-a8y9ya"}>
                                            <div className={isMobile ? "MuiBox-root css-cw78xn" : "MuiBox-root css-cw78xn"}>
                                                <div className={isMobile ? "MuiBox-root css-34rafo" : "MuiBox-root css-34rafo"}>
                                                    <p className={isMobile ? "MuiTypography-root MuiTypography-body1 css-t4qzmy" : "MuiTypography-root MuiTypography-body1 css-t4qzmy"}>
                                                        รายรับทั้งหมด
                                                    </p>
                                                </div>
                                                <div className={isMobile ? "MuiBox-root css-p060kb" : "MuiBox-root css-p060kb"}>
                                                    <p className={isMobile ? "MuiTypography-root MuiTypography-body1 css-t4qzmy" : "MuiTypography-root MuiTypography-body1 css-t4qzmy"}>฿{this.state.reports.totalAmount} </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={isMobile ? "MuiBox-root css-18kmyx0" : "MuiBox-root css-18kmyx0"}>
                                            <button
                                                onClick={(e) => {
                                                    this.opencliaminvite(e, 'bonusrankaccount')
                                                }}
                                                className={isMobile ? "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-1tpwfgo" : "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-1d3mjjb"}
                                                tabIndex={0}
                                                type="button"
                                            >
                                                <p className={isMobile ? "MuiTypography-root MuiTypography-body1 css-t4qzmy" : "MuiTypography-root MuiTypography-body1 css-t4qzmy"}>
                                                    ถอนโบนัส
                                                </p>
                                                <span className={isMobile ? "MuiTouchRipple-root css-w0pj6f" : "MuiTouchRipple-root css-w0pj6f"} />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="MuiBox-root css-1e3sj1t" style={{ display: isMobile ? "none" : "" }}></div>
                            <div style={{ display: tabselect == 0 && tabselect != 1 && tabselect != 2 ? "" : "none", overflow: "auto " }} className={isMobile ? "MuiBox-root css-1d2vgvs" : "MuiBox-root css-1re1m1f"}>
                                <div className={isMobile ? "MuiBox-root css-1pz06by" : "MuiBox-root css-1fxfs3m"}>
                                    <a role="button" onClick={(e) => {
                                        this.checkurl(e, "/game/hit")
                                    }} >
                                        <span
                                            style={{
                                                boxSizing: "border-box",
                                                display: "block",
                                                overflow: "hidden",
                                                width: "initial",
                                                height: "initial",
                                                background: "none",
                                                opacity: 1,
                                                border: 0,
                                                margin: 0,
                                                padding: 0,
                                                position: "absolute",
                                                inset: 0
                                            }}
                                        >
                                            <img
                                                alt="Top 10 อันดับเกมส์ยอดฮิต"
                                                sizes="100vw"
                                                src="/build/web/igame-index-lobby-wm/img/head.webp"
                                                decoding="async"
                                                data-nimg="fill"
                                                style={{
                                                    position: "absolute",
                                                    inset: 0,
                                                    boxSizing: "border-box",
                                                    padding: 0,
                                                    border: "none",
                                                    margin: "auto",
                                                    display: "block",
                                                    width: 0,
                                                    height: 0,
                                                    minWidth: "100%",
                                                    maxWidth: "100%",
                                                    minHeight: "100%",
                                                    maxHeight: "100%",
                                                    objectFit: "contain"
                                                }}
                                            />
                                        </span>
                                    </a>
                                </div>
                                <div className={isMobile ? "zoom MuiBox-root css-1oakptk" : "zoom MuiBox-root css-1p25yup"}>
                                    <a target="_blank"
                                        //  href="/game/enter?p=PGSOFT&c=treasures-aztec"
                                        href={() => false}
                                        onClick={(e) => this.redeem(e, "87", "pgslot")}

                                    >
                                        <span
                                            style={{
                                                boxSizing: "border-box",
                                                display: "block",
                                                overflow: "hidden",
                                                width: "initial",
                                                height: "initial",
                                                background: "none",
                                                opacity: 1,
                                                border: 0,
                                                margin: 0,
                                                padding: 0,
                                                position: "absolute",
                                                inset: 0
                                            }}
                                        >
                                            <img
                                                alt="Top 10 อันดับเกมส์ยอดฮิต"
                                                sizes="100vw"
                                                src="https://ump1.fun/_next/image?url=https%3A%2F%2Fd12lxcfs2l5om8.cloudfront.net%2Fnew_webimages%2Ftop10%2Fgame1-3.webp&w=3840&q=75"
                                                decoding="async"
                                                data-nimg="fill"
                                                style={{
                                                    position: "absolute",
                                                    inset: 0,
                                                    boxSizing: "border-box",
                                                    padding: 0,
                                                    border: "none",
                                                    margin: "auto",
                                                    display: "block",
                                                    width: 0,
                                                    height: 0,
                                                    minWidth: "100%",
                                                    maxWidth: "100%",
                                                    minHeight: "100%",
                                                    maxHeight: "100%",
                                                    objectFit: "contain"
                                                }}
                                            />
                                        </span>
                                    </a>
                                </div>
                                <div className={isMobile ? "zoom MuiBox-root css-1oakptk" : "zoom MuiBox-root css-1p25yup"}>
                                    <a target="_blank"
                                        //href="/game/enter?p=PGSOFT&c=mahjong-ways2"
                                        href={() => false}
                                        onClick={(e) => this.redeem(e, "74", "pgslot")}
                                    >
                                        <span
                                            style={{
                                                boxSizing: "border-box",
                                                display: "block",
                                                overflow: "hidden",
                                                width: "initial",
                                                height: "initial",
                                                background: "none",
                                                opacity: 1,
                                                border: 0,
                                                margin: 0,
                                                padding: 0,
                                                position: "absolute",
                                                inset: 0
                                            }}
                                        >
                                            <img
                                                alt="Top 10 อันดับเกมส์ยอดฮิต"
                                                sizes="100vw"
                                                src="https://ump1.fun/_next/image?url=https%3A%2F%2Fd12lxcfs2l5om8.cloudfront.net%2Fnew_webimages%2Ftop10%2Fgame2-3.webp&w=3840&q=75"
                                                decoding="async"
                                                data-nimg="fill"
                                                style={{
                                                    position: "absolute",
                                                    inset: 0,
                                                    boxSizing: "border-box",
                                                    padding: 0,
                                                    border: "none",
                                                    margin: "auto",
                                                    display: "block",
                                                    width: 0,
                                                    height: 0,
                                                    minWidth: "100%",
                                                    maxWidth: "100%",
                                                    minHeight: "100%",
                                                    maxHeight: "100%",
                                                    objectFit: "contain"
                                                }}
                                            />
                                        </span>
                                    </a>
                                </div>
                                <div className={isMobile ? "zoom MuiBox-root css-1oakptk" : "zoom MuiBox-root css-1p25yup"}>
                                    <a target="_blank"

                                        //</div>href="/game/enter?p=PGSOFT&c=lucky-neko"
                                        href={() => false}
                                        onClick={(e) => this.redeem(e, "89", "pgslot")}
                                    >
                                        <span
                                            style={{
                                                boxSizing: "border-box",
                                                display: "block",
                                                overflow: "hidden",
                                                width: "initial",
                                                height: "initial",
                                                background: "none",
                                                opacity: 1,
                                                border: 0,
                                                margin: 0,
                                                padding: 0,
                                                position: "absolute",
                                                inset: 0
                                            }}
                                        >
                                            <img
                                                alt="Top 10 อันดับเกมส์ยอดฮิต"
                                                sizes="100vw"
                                                src="https://ump1.fun/_next/image?url=https%3A%2F%2Fd12lxcfs2l5om8.cloudfront.net%2Fnew_webimages%2Ftop10%2Fgame3-3.webp&w=3840&q=75"
                                                decoding="async"
                                                data-nimg="fill"
                                                style={{
                                                    position: "absolute",
                                                    inset: 0,
                                                    boxSizing: "border-box",
                                                    padding: 0,
                                                    border: "none",
                                                    margin: "auto",
                                                    display: "block",
                                                    width: 0,
                                                    height: 0,
                                                    minWidth: "100%",
                                                    maxWidth: "100%",
                                                    minHeight: "100%",
                                                    maxHeight: "100%",
                                                    objectFit: "contain"
                                                }}
                                            />
                                        </span>
                                    </a>
                                </div>
                                <div className={isMobile ? "zoom MuiBox-root css-1oakptk" : "zoom MuiBox-root css-1p25yup"}>
                                    <a target="_blank"
                                        // href="/game/enter?p=PGSOFT&c=wild-bounty-sd"
                                        href={() => false}
                                        onClick={(e) => this.redeem(e, "135", "pgslot")}
                                    >
                                        <span
                                            style={{
                                                boxSizing: "border-box",
                                                display: "block",
                                                overflow: "hidden",
                                                width: "initial",
                                                height: "initial",
                                                background: "none",
                                                opacity: 1,
                                                border: 0,
                                                margin: 0,
                                                padding: 0,
                                                position: "absolute",
                                                inset: 0
                                            }}
                                        >
                                            <img
                                                alt="Top 10 อันดับเกมส์ยอดฮิต"
                                                sizes="100vw"
                                                src="https://ump1.fun/_next/image?url=https%3A%2F%2Fd12lxcfs2l5om8.cloudfront.net%2Fnew_webimages%2Ftop10%2Fgame4-3.webp&w=3840&q=75"
                                                decoding="async"
                                                data-nimg="fill"
                                                style={{
                                                    position: "absolute",
                                                    inset: 0,
                                                    boxSizing: "border-box",
                                                    padding: 0,
                                                    border: "none",
                                                    margin: "auto",
                                                    display: "block",
                                                    width: 0,
                                                    height: 0,
                                                    minWidth: "100%",
                                                    maxWidth: "100%",
                                                    minHeight: "100%",
                                                    maxHeight: "100%",
                                                    objectFit: "contain"
                                                }}
                                            />
                                        </span>
                                    </a>
                                </div>
                                <div className={isMobile ? "zoom MuiBox-root css-1oakptk" : "zoom MuiBox-root css-1p25yup"}>
                                    <a target="_blank"
                                        href={() => false}
                                        onClick={(e) => this.redeem(e, "126", "pgslot")}

                                    //</div>href="/game/enter?p=PGSOFT&c=fortune-tiger"
                                    >
                                        <span
                                            style={{
                                                boxSizing: "border-box",
                                                display: "block",
                                                overflow: "hidden",
                                                width: "initial",
                                                height: "initial",
                                                background: "none",
                                                opacity: 1,
                                                border: 0,
                                                margin: 0,
                                                padding: 0,
                                                position: "absolute",
                                                inset: 0
                                            }}
                                        >
                                            <img
                                                alt="Top 10 อันดับเกมส์ยอดฮิต"
                                                sizes="100vw"
                                                src="https://ump1.fun/_next/image?url=https%3A%2F%2Fd12lxcfs2l5om8.cloudfront.net%2Fnew_webimages%2Ftop10%2Fgame5-3.webp&w=3840&q=75"
                                                decoding="async"
                                                data-nimg="fill"
                                                style={{
                                                    position: "absolute",
                                                    inset: 0,
                                                    boxSizing: "border-box",
                                                    padding: 0,
                                                    border: "none",
                                                    margin: "auto",
                                                    display: "block",
                                                    width: 0,
                                                    height: 0,
                                                    minWidth: "100%",
                                                    maxWidth: "100%",
                                                    minHeight: "100%",
                                                    maxHeight: "100%",
                                                    objectFit: "contain"
                                                }}
                                            />
                                        </span>
                                    </a>
                                </div>
                                <div className={isMobile ? "zoom MuiBox-root css-1oakptk" : "zoom MuiBox-root css-1p25yup"}>
                                    <a target="_blank"
                                        // href="/game/enter?p=PGSOFT&c=ways-of-qilin"
                                        href={() => false}
                                        onClick={(e) => this.redeem(e, "106", "pgslot")}
                                    >
                                        <span
                                            style={{
                                                boxSizing: "border-box",
                                                display: "block",
                                                overflow: "hidden",
                                                width: "initial",
                                                height: "initial",
                                                background: "none",
                                                opacity: 1,
                                                border: 0,
                                                margin: 0,
                                                padding: 0,
                                                position: "absolute",
                                                inset: 0
                                            }}
                                        >
                                            <img
                                                alt="Top 10 อันดับเกมส์ยอดฮิต"
                                                sizes="100vw"
                                                src="https://ump1.fun/_next/image?url=https%3A%2F%2Fd12lxcfs2l5om8.cloudfront.net%2Fnew_webimages%2Ftop10%2Fgame6-3.webp&w=3840&q=75"
                                                decoding="async"
                                                data-nimg="fill"
                                                style={{
                                                    position: "absolute",
                                                    inset: 0,
                                                    boxSizing: "border-box",
                                                    padding: 0,
                                                    border: "none",
                                                    margin: "auto",
                                                    display: "block",
                                                    width: 0,
                                                    height: 0,
                                                    minWidth: "100%",
                                                    maxWidth: "100%",
                                                    minHeight: "100%",
                                                    maxHeight: "100%",
                                                    objectFit: "contain"
                                                }}
                                            />
                                        </span>
                                    </a>
                                </div>
                                <div className={isMobile ? "zoom MuiBox-root css-1oakptk" : "zoom MuiBox-root css-1p25yup"}>
                                    <a target="_blank "
                                        href={() => false}
                                        onClick={(e) => this.redeem(e, "104", "pgslot")}
                                    >
                                        <span
                                            style={{
                                                boxSizing: "border-box",
                                                display: "block",
                                                overflow: "hidden",
                                                width: "initial",
                                                height: "initial",
                                                background: "none",
                                                opacity: 1,
                                                border: 0,
                                                margin: 0,
                                                padding: 0,
                                                position: "absolute",
                                                inset: 0
                                            }}
                                        >
                                            <img
                                                alt="Top 10 อันดับเกมส์ยอดฮิต"
                                                sizes="100vw"
                                                src="https://ump1.fun/_next/image?url=https%3A%2F%2Fd12lxcfs2l5om8.cloudfront.net%2Fnew_webimages%2Ftop10%2Fgame7-3.webp&w=3840&q=75"
                                                decoding="async"
                                                data-nimg="fill"
                                                style={{
                                                    position: "absolute",
                                                    inset: 0,
                                                    boxSizing: "border-box",
                                                    padding: 0,
                                                    border: "none",
                                                    margin: "auto",
                                                    display: "block",
                                                    width: 0,
                                                    height: 0,
                                                    minWidth: "100%",
                                                    maxWidth: "100%",
                                                    minHeight: "100%",
                                                    maxHeight: "100%",
                                                    objectFit: "contain"
                                                }}
                                            />
                                        </span>
                                    </a>
                                </div>
                                <div className={isMobile ? "zoom MuiBox-root css-1oakptk" : "zoom MuiBox-root css-1p25yup"}>
                                    <a target="_blank"
                                        //</div>href="/game/enter?p=PGSOFT&c=fortune-ox"
                                        href={() => false}
                                        onClick={(e) => this.redeem(e, "98", "pgslot")}
                                    >
                                        <span
                                            style={{
                                                boxSizing: "border-box",
                                                display: "block",
                                                overflow: "hidden",
                                                width: "initial",
                                                height: "initial",
                                                background: "none",
                                                opacity: 1,
                                                border: 0,
                                                margin: 0,
                                                padding: 0,
                                                position: "absolute",
                                                inset: 0
                                            }}
                                        >
                                            <img
                                                alt="Top 10 อันดับเกมส์ยอดฮิต"
                                                sizes="100vw"
                                                src="https://ump1.fun/_next/image?url=https%3A%2F%2Fd12lxcfs2l5om8.cloudfront.net%2Fnew_webimages%2Ftop10%2Fgame8-3.webp&w=3840&q=75"
                                                decoding="async"
                                                data-nimg="fill"
                                                style={{
                                                    position: "absolute",
                                                    inset: 0,
                                                    boxSizing: "border-box",
                                                    padding: 0,
                                                    border: "none",
                                                    margin: "auto",
                                                    display: "block",
                                                    width: 0,
                                                    height: 0,
                                                    minWidth: "100%",
                                                    maxWidth: "100%",
                                                    minHeight: "100%",
                                                    maxHeight: "100%",
                                                    objectFit: "contain"
                                                }}
                                            />
                                        </span>
                                    </a>
                                </div>
                                <div className={isMobile ? "zoom MuiBox-root css-1oakptk" : "zoom MuiBox-root css-1p25yup"}>
                                    <a target="_blank" //</div>href="/game/enter?p=PGSOFT&c=cocktail-nite"
                                        href={() => false}
                                        onClick={(e) => this.redeem(e, "117", "pgslot")}
                                    >
                                        <span
                                            style={{
                                                boxSizing: "border-box",
                                                display: "block",
                                                overflow: "hidden",
                                                width: "initial",
                                                height: "initial",
                                                background: "none",
                                                opacity: 1,
                                                border: 0,
                                                margin: 0,
                                                padding: 0,
                                                position: "absolute",
                                                inset: 0
                                            }}
                                        >
                                            <img
                                                alt="Top 10 อันดับเกมส์ยอดฮิต"
                                                sizes="100vw"
                                                src="https://ump1.fun/_next/image?url=https%3A%2F%2Fd12lxcfs2l5om8.cloudfront.net%2Fnew_webimages%2Ftop10%2Fgame9-3.webp&w=3840&q=75"
                                                decoding="async"
                                                data-nimg="fill"
                                                style={{
                                                    position: "absolute",
                                                    inset: 0,
                                                    boxSizing: "border-box",
                                                    padding: 0,
                                                    border: "none",
                                                    margin: "auto",
                                                    display: "block",
                                                    width: 0,
                                                    height: 0,
                                                    minWidth: "100%",
                                                    maxWidth: "100%",
                                                    minHeight: "100%",
                                                    maxHeight: "100%",
                                                    objectFit: "contain"
                                                }}
                                            />
                                        </span>
                                    </a>
                                </div>
                                <div className={isMobile ? "zoom MuiBox-root css-1oakptk" : "zoom MuiBox-root css-1p25yup"}>
                                    <a target="_blank"

                                        //</div>href="/game/enter?p=PGSOFT&c=cai-shen-wins"
                                        href={() => false}
                                        onClick={(e) => this.redeem(e, "71", "pgslot")}
                                    >
                                        <span
                                            style={{
                                                boxSizing: "border-box",
                                                display: "block",
                                                overflow: "hidden",
                                                width: "initial",
                                                height: "initial",
                                                background: "none",
                                                opacity: 1,
                                                border: 0,
                                                margin: 0,
                                                padding: 0,
                                                position: "absolute",
                                                inset: 0
                                            }}
                                        >
                                            <img
                                                alt="Top 10 อันดับเกมส์ยอดฮิต"
                                                src="https://ump1.fun/_next/image?url=https%3A%2F%2Fd12lxcfs2l5om8.cloudfront.net%2Fnew_webimages%2Ftop10%2Fgame10-3.webp&w=1920&q=75"
                                                decoding="async"
                                                data-nimg="fill"
                                                style={{
                                                    position: "absolute",
                                                    inset: 0,
                                                    boxSizing: "border-box",
                                                    padding: 0,
                                                    border: "none",
                                                    margin: "auto",
                                                    display: "block",
                                                    width: 0,
                                                    height: 0,
                                                    minWidth: "100%",
                                                    maxWidth: "100%",
                                                    minHeight: "100%",
                                                    maxHeight: "100%",
                                                    objectFit: "contain"
                                                }}
                                            />
                                            <noscript />
                                        </span>
                                    </a>
                                </div>
                            </div>
                            <div className="MuiBox-root css-1e3sj1t" style={{ display: isMobile ? "none" : "" }} ></div>
                            <div style={{ display: tabselect == 0 && tabselect != 1 && tabselect != 2 ? "" : "none" }} className="MuiBox-root css-0">
                                <div className={isMobile ? "MuiBox-root css-aongy4" : "MuiBox-root css-5idq5c"}>
                                    <div className={isMobile ? "MuiBox-root css-1ovc8b1" : "MuiBox-root css-6d5fwk"}>
                                        <button
                                            className={isMobile ? "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-1wlne5i" : "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-1eafaes"}
                                            tabIndex={0}
                                            onClick={(e) => {
                                                this.checkurl(e, "/game/hit")
                                            }}
                                            type="button"
                                        >
                                            <div className="MuiBox-root css-1hyoz7m">
                                                <span
                                                    style={{
                                                        boxSizing: "border-box",
                                                        display: "inline-block",
                                                        overflow: "hidden",
                                                        width: "initial",
                                                        height: "initial",
                                                        background: "none",
                                                        opacity: 1,
                                                        border: 0,
                                                        margin: 0,
                                                        padding: 0,
                                                        position: "relative",
                                                        maxWidth: "100%"
                                                    }}
                                                >
                                                    <span
                                                        style={{
                                                            boxSizing: "border-box",
                                                            display: "block",
                                                            width: "initial",
                                                            height: "initial",
                                                            background: "none",
                                                            opacity: 1,
                                                            border: 0,
                                                            margin: 0,
                                                            padding: 0,
                                                            maxWidth: "100%"
                                                        }}
                                                    >
                                                        <img
                                                            alt=""
                                                            aria-hidden="true"
                                                            src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2745%27%20height=%2745%27/%3e"
                                                            style={{
                                                                maxWidth: "100%",
                                                                width: "initial",
                                                                height: "initial",
                                                                background: "none",
                                                                opacity: 1,
                                                                border: 0,
                                                                margin: 0,
                                                                padding: 0,
                                                                display: isMobile ? "none" : ""
                                                            }}
                                                        />
                                                        <img
                                                            alt=""
                                                            aria-hidden="true"
                                                            src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2720%27%20height=%2720%27/%3e"
                                                            style={{
                                                                display: "block",
                                                                maxWidth: "100%",
                                                                width: "initial",
                                                                height: "initial",
                                                                background: "none",
                                                                opacity: 1,
                                                                border: 0,
                                                                margin: 0,
                                                                padding: 0,
                                                                display: isMobile ? "" : "none"
                                                            }}
                                                        />
                                                    </span>
                                                    <img
                                                        alt="ประเภทเกมส์"
                                                        src="/build/web/igame-index-lobby-wm/img/new_hit_icon.webp"
                                                        decoding="async"
                                                        data-nimg="intrinsic"
                                                        style={{
                                                            position: "absolute",
                                                            inset: 0,
                                                            boxSizing: "border-box",
                                                            padding: 0,
                                                            border: "none",
                                                            margin: "auto",
                                                            display: "block",
                                                            width: 0,
                                                            height: 0,
                                                            minWidth: "100%",
                                                            maxWidth: "100%",
                                                            minHeight: "100%",
                                                            maxHeight: "100%"
                                                        }}
                                                    />
                                                </span>
                                                <p className={isMobile ? "MuiTypography-root MuiTypography-body1 css-av4ofy" : "MuiTypography-root MuiTypography-body1 css-15s8hr5"}>
                                                    เกมส์ยอดฮิต
                                                </p>
                                            </div>
                                            <span className="MuiTouchRipple-root css-w0pj6f" />
                                        </button>
                                        <button
                                            onClick={(e) => {
                                                this.checkurl(e, "/game/slot")
                                            }}
                                            className={isMobile ? "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-1wlne5i" : "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-1eafaes"}
                                            tabIndex={0}
                                            type="button"
                                        >
                                            <div className="MuiBox-root css-1hyoz7m">
                                                <span
                                                    style={{
                                                        boxSizing: "border-box",
                                                        display: "inline-block",
                                                        overflow: "hidden",
                                                        width: "initial",
                                                        height: "initial",
                                                        background: "none",
                                                        opacity: 1,
                                                        border: 0,
                                                        margin: 0,
                                                        padding: 0,
                                                        position: "relative",
                                                        maxWidth: "100%"
                                                    }}
                                                >
                                                    <span
                                                        style={{
                                                            boxSizing: "border-box",
                                                            display: "block",
                                                            width: "initial",
                                                            height: "initial",
                                                            background: "none",
                                                            opacity: 1,
                                                            border: 0,
                                                            margin: 0,
                                                            padding: 0,
                                                            maxWidth: "100%"
                                                        }}
                                                    >
                                                        <img
                                                            alt=""
                                                            aria-hidden="true"
                                                            src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2745%27%20height=%2745%27/%3e"
                                                            style={{
                                                                display: isMobile ? "none" : "",
                                                                maxWidth: "100%",
                                                                width: "initial",
                                                                height: "initial",
                                                                background: "none",
                                                                opacity: 1,
                                                                border: 0,
                                                                margin: 0,
                                                                padding: 0
                                                            }}
                                                        />
                                                        <img
                                                            alt=""
                                                            aria-hidden="true"
                                                            src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2720%27%20height=%2720%27/%3e"
                                                            style={{
                                                                display: isMobile ? "" : "none",
                                                                maxWidth: "100%",
                                                                width: "initial",
                                                                height: "initial",
                                                                background: "none",
                                                                opacity: 1,
                                                                border: 0,
                                                                margin: 0,
                                                                padding: 0

                                                            }}
                                                        />

                                                    </span>
                                                    <img
                                                        alt="ประเภทเกมส์"
                                                        src="/build/web/igame-index-lobby-wm/img/new_slot_icon.webp"
                                                        decoding="async"
                                                        data-nimg="intrinsic"
                                                        style={{
                                                            position: "absolute",
                                                            inset: 0,
                                                            boxSizing: "border-box",
                                                            padding: 0,
                                                            border: "none",
                                                            margin: "auto",
                                                            display: "block",
                                                            width: 0,
                                                            height: 0,
                                                            minWidth: "100%",
                                                            maxWidth: "100%",
                                                            minHeight: "100%",
                                                            maxHeight: "100%"
                                                        }}
                                                    />
                                                </span>
                                                <p className={isMobile ? "MuiTypography-root MuiTypography-body1 css-av4ofy" : "MuiTypography-root MuiTypography-body1 css-15s8hr5"}>
                                                    สล็อตมาใหม่
                                                </p>
                                            </div>
                                            <span className="MuiTouchRipple-root css-w0pj6f" />
                                        </button>
                                        <button
                                            className={isMobile ? "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-1wlne5i" : "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-1eafaes"}
                                            tabIndex={0}
                                            type="button"
                                            onClick={(e) => {
                                                this.checkurl(e, "/game/providor")
                                            }}
                                        >
                                            <div className="MuiBox-root css-1hyoz7m">
                                                <span
                                                    style={{
                                                        boxSizing: "border-box",
                                                        display: "inline-block",
                                                        overflow: "hidden",
                                                        width: "initial",
                                                        height: "initial",
                                                        background: "none",
                                                        opacity: 1,
                                                        border: 0,
                                                        margin: 0,
                                                        padding: 0,
                                                        position: "relative",
                                                        maxWidth: "100%"
                                                    }}
                                                >
                                                    <span
                                                        style={{
                                                            boxSizing: "border-box",
                                                            display: "block",
                                                            width: "initial",
                                                            height: "initial",
                                                            background: "none",
                                                            opacity: 1,
                                                            border: 0,
                                                            margin: 0,
                                                            padding: 0,
                                                            maxWidth: "100%"
                                                        }}
                                                    >
                                                        <img
                                                            alt=""
                                                            aria-hidden="true"
                                                            src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2745%27%20height=%2745%27/%3e"
                                                            style={{
                                                                display: "block",
                                                                maxWidth: "100%",
                                                                width: "initial",
                                                                height: "initial",
                                                                background: "none",
                                                                opacity: 1,
                                                                border: 0,
                                                                margin: 0,
                                                                padding: 0,
                                                                display: isMobile ? "none" : ""
                                                            }}
                                                        />
                                                        <img
                                                            alt=""
                                                            aria-hidden="true"
                                                            src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2720%27%20height=%2720%27/%3e"
                                                            style={{
                                                                display: "block",
                                                                maxWidth: "100%",
                                                                width: "initial",
                                                                height: "initial",
                                                                background: "none",
                                                                opacity: 1,
                                                                border: 0,
                                                                margin: 0,
                                                                padding: 0,
                                                                display: isMobile ? "" : "none"
                                                            }}
                                                        />
                                                    </span>
                                                    <img
                                                        alt="ประเภทเกมส์"
                                                        src="/build/web/igame-index-lobby-wm/img/new_provider_icon.webp" decoding="async"
                                                        data-nimg="intrinsic"
                                                        style={{
                                                            position: "absolute",
                                                            inset: 0,
                                                            boxSizing: "border-box",
                                                            padding: 0,
                                                            border: "none",
                                                            margin: "auto",
                                                            display: "block",
                                                            width: 0,
                                                            height: 0,
                                                            minWidth: "100%",
                                                            maxWidth: "100%",
                                                            minHeight: "100%",
                                                            maxHeight: "100%"
                                                        }}
                                                    />
                                                </span>
                                                <p className={isMobile ? "MuiTypography-root MuiTypography-body1 css-av4ofy" : "MuiTypography-root MuiTypography-body1 css-15s8hr5"}>
                                                    ค่ายเกมส์
                                                </p>
                                            </div>
                                            <span className="MuiTouchRipple-root css-w0pj6f" />
                                        </button>
                                        <button
                                            className={isMobile ? "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-1wlne5i" : "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-1eafaes"}
                                            tabIndex={0}
                                            type="button"
                                            onClick={(e) => {
                                                this.checkurl(e, "/game/casino")
                                            }}
                                        >
                                            <div className="MuiBox-root css-1hyoz7m">
                                                <span
                                                    style={{
                                                        boxSizing: "border-box",
                                                        display: "inline-block",
                                                        overflow: "hidden",
                                                        width: "initial",
                                                        height: "initial",
                                                        background: "none",
                                                        opacity: 1,
                                                        border: 0,
                                                        margin: 0,
                                                        padding: 0,
                                                        position: "relative",
                                                        maxWidth: "100%"
                                                    }}
                                                >
                                                    <span
                                                        style={{
                                                            boxSizing: "border-box",
                                                            display: "block",
                                                            width: "initial",
                                                            height: "initial",
                                                            background: "none",
                                                            opacity: 1,
                                                            border: 0,
                                                            margin: 0,
                                                            padding: 0,
                                                            maxWidth: "100%"
                                                        }}
                                                    >
                                                        <img
                                                            alt=""
                                                            aria-hidden="true"
                                                            src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2745%27%20height=%2745%27/%3e"
                                                            style={{
                                                                display: "block",
                                                                maxWidth: "100%",
                                                                width: "initial",
                                                                height: "initial",
                                                                background: "none",
                                                                opacity: 1,
                                                                border: 0,
                                                                margin: 0,
                                                                padding: 0,
                                                                display: isMobile ? "none" : ""
                                                            }}
                                                        />
                                                        <img
                                                            alt=""
                                                            aria-hidden="true"
                                                            src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2720%27%20height=%2720%27/%3e"
                                                            style={{
                                                                display: "block",
                                                                maxWidth: "100%",
                                                                width: "initial",
                                                                height: "initial",
                                                                background: "none",
                                                                opacity: 1,
                                                                border: 0,
                                                                margin: 0,
                                                                padding: 0,
                                                                display: isMobile ? "" : "none"
                                                            }}
                                                        />
                                                    </span>
                                                    <img
                                                        alt="ประเภทเกมส์"
                                                        src="/build/web/igame-index-lobby-wm/img/new_livecasino_icon.webp"
                                                        decoding="async"
                                                        data-nimg="intrinsic"
                                                        style={{
                                                            position: "absolute",
                                                            inset: 0,
                                                            boxSizing: "border-box",
                                                            padding: 0,
                                                            border: "none",
                                                            margin: "auto",
                                                            display: "block",
                                                            width: 0,
                                                            height: 0,
                                                            minWidth: "100%",
                                                            maxWidth: "100%",
                                                            minHeight: "100%",
                                                            maxHeight: "100%"
                                                        }}
                                                    />
                                                </span>
                                                <p className={isMobile ? "MuiTypography-root MuiTypography-body1 css-av4ofy" : "MuiTypography-root MuiTypography-body1 css-15s8hr5"}>
                                                    คาสิโนสด
                                                </p>
                                            </div>
                                            <span className="MuiTouchRipple-root css-w0pj6f" />
                                        </button>
                                        <button
                                            className={isMobile ? "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-1wlne5i" : "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-1eafaes"}
                                            tabIndex={0}
                                            type="button"
                                            onClick={(e) => {
                                                this.checkurl(e, "/game/sport")
                                            }}
                                        >
                                            <div className="MuiBox-root css-1hyoz7m">
                                                <span
                                                    style={{
                                                        boxSizing: "border-box",
                                                        display: "inline-block",
                                                        overflow: "hidden",
                                                        width: "initial",
                                                        height: "initial",
                                                        background: "none",
                                                        opacity: 1,
                                                        border: 0,
                                                        margin: 0,
                                                        padding: 0,
                                                        position: "relative",
                                                        maxWidth: "100%"
                                                    }}
                                                >
                                                    <span
                                                        style={{
                                                            boxSizing: "border-box",
                                                            display: "block",
                                                            width: "initial",
                                                            height: "initial",
                                                            background: "none",
                                                            opacity: 1,
                                                            border: 0,
                                                            margin: 0,
                                                            padding: 0,
                                                            maxWidth: "100%"
                                                        }}
                                                    >
                                                        <img
                                                            alt=""
                                                            aria-hidden="true"
                                                            src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2745%27%20height=%2745%27/%3e"
                                                            style={{
                                                                display: "block",
                                                                maxWidth: "100%",
                                                                width: "initial",
                                                                height: "initial",
                                                                background: "none",
                                                                opacity: 1,
                                                                border: 0,
                                                                margin: 0,
                                                                padding: 0,
                                                                display: isMobile ? "none" : ""
                                                            }}
                                                        />
                                                        <img
                                                            alt=""
                                                            aria-hidden="true"
                                                            src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2720%27%20height=%2720%27/%3e"
                                                            style={{
                                                                display: "block",
                                                                maxWidth: "100%",
                                                                width: "initial",
                                                                height: "initial",
                                                                background: "none",
                                                                opacity: 1,
                                                                border: 0,
                                                                margin: 0,
                                                                padding: 0,
                                                                display: isMobile ? "" : "none"
                                                            }}
                                                        />
                                                    </span>
                                                    <img
                                                        alt="ประเภทเกมส์"
                                                        src="/build/web/igame-index-lobby-wm/img/new_sport_icon.webp"
                                                        decoding="async"
                                                        data-nimg="intrinsic"
                                                        style={{
                                                            position: "absolute",
                                                            inset: 0,
                                                            boxSizing: "border-box",
                                                            padding: 0,
                                                            border: "none",
                                                            margin: "auto",
                                                            display: "block",
                                                            width: 0,
                                                            height: 0,
                                                            minWidth: "100%",
                                                            maxWidth: "100%",
                                                            minHeight: "100%",
                                                            maxHeight: "100%"
                                                        }}
                                                    />
                                                </span>
                                                <p className={isMobile ? "MuiTypography-root MuiTypography-body1 css-av4ofy" : "MuiTypography-root MuiTypography-body1 css-15s8hr5"}>
                                                    กีฬาพารวย
                                                </p>
                                            </div>
                                            <span className="MuiTouchRipple-root css-w0pj6f" />
                                        </button>
                                        <button
                                            className={isMobile ? "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-1wlne5i" : "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-1eafaes"}
                                            tabIndex={0}
                                            type="button"
                                            onClick={(e) => {
                                                this.checkurl(e, "/game/fishing")
                                            }}
                                        >
                                            <div className="MuiBox-root css-1hyoz7m">
                                                <span
                                                    style={{
                                                        boxSizing: "border-box",
                                                        display: "inline-block",
                                                        overflow: "hidden",
                                                        width: "initial",
                                                        height: "initial",
                                                        background: "none",
                                                        opacity: 1,
                                                        border: 0,
                                                        margin: 0,
                                                        padding: 0,
                                                        position: "relative",
                                                        maxWidth: "100%"
                                                    }}
                                                >
                                                    <span
                                                        style={{
                                                            boxSizing: "border-box",
                                                            display: "block",
                                                            width: "initial",
                                                            height: "initial",
                                                            background: "none",
                                                            opacity: 1,
                                                            border: 0,
                                                            margin: 0,
                                                            padding: 0,
                                                            maxWidth: "100%"
                                                        }}
                                                    >
                                                        <img
                                                            alt=""
                                                            aria-hidden="true"
                                                            src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2745%27%20height=%2745%27/%3e"
                                                            style={{
                                                                display: "block",
                                                                maxWidth: "100%",
                                                                width: "initial",
                                                                height: "initial",
                                                                background: "none",
                                                                opacity: 1,
                                                                border: 0,
                                                                margin: 0,
                                                                padding: 0,
                                                                display: isMobile ? "none" : ""
                                                            }}
                                                        />
                                                        <img
                                                            alt=""
                                                            aria-hidden="true"
                                                            src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2720%27%20height=%2720%27/%3e"
                                                            style={{
                                                                display: "block",
                                                                maxWidth: "100%",
                                                                width: "initial",
                                                                height: "initial",
                                                                background: "none",
                                                                opacity: 1,
                                                                border: 0,
                                                                margin: 0,
                                                                padding: 0,
                                                                display: isMobile ? "" : "none"
                                                            }}
                                                        />
                                                    </span>
                                                    <img
                                                        alt="ประเภทเกมส์"
                                                        src="/build/web/igame-index-lobby-wm/img/new_fishing_icon.webp"
                                                        decoding="async"
                                                        data-nimg="intrinsic"
                                                        style={{
                                                            position: "absolute",
                                                            inset: 0,
                                                            boxSizing: "border-box",
                                                            padding: 0,
                                                            border: "none",
                                                            margin: "auto",
                                                            display: "block",
                                                            width: 0,
                                                            height: 0,
                                                            minWidth: "100%",
                                                            maxWidth: "100%",
                                                            minHeight: "100%",
                                                            maxHeight: "100%"
                                                        }}
                                                    />
                                                </span>
                                                <p className={isMobile ? "MuiTypography-root MuiTypography-body1 css-av4ofy" : "MuiTypography-root MuiTypography-body1 css-15s8hr5"}>
                                                    ยิงปลา
                                                </p>
                                            </div>
                                            <span className="MuiTouchRipple-root css-w0pj6f" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: tabselect == 0 && tabselect != 1 && tabselect != 2 ? "" : "none" }} className={isMobile ? "MuiBox-root css-4hd7e7" : "MuiBox-root css-1rckm58"}>
                                <div className={isMobile ? "MuiBox-root css-1gg2hi7" : "MuiBox-root css-bo07ty"}>
                                    <button
                                        className="MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-12mu22"
                                        tabIndex={0}
                                        type="button"
                                    >
                                        <span className="MuiButton-startIcon MuiButton-iconSizeMedium css-6xugel">
                                            <span
                                                style={{
                                                    boxSizing: "border-box",
                                                    display: "inline-block",
                                                    overflow: "hidden",
                                                    width: "initial",
                                                    height: "initial",
                                                    background: "none",
                                                    opacity: 1,
                                                    border: 0,
                                                    margin: 0,
                                                    padding: 0,
                                                    position: "relative",
                                                    maxWidth: "100%"
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        boxSizing: "border-box",
                                                        display: "block",
                                                        width: "initial",
                                                        height: "initial",
                                                        background: "none",
                                                        opacity: 1,
                                                        border: 0,
                                                        margin: 0,
                                                        padding: 0,
                                                        maxWidth: "100%"
                                                    }}
                                                >
                                                    <img
                                                        alt=""
                                                        aria-hidden="true"
                                                        src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2732%27%20height=%2732%27/%3e"
                                                        style={{
                                                            display: isMobile ? "none" : "block",
                                                            maxWidth: "100%",
                                                            width: "initial",
                                                            height: "initial",
                                                            background: "none",
                                                            opacity: 1,
                                                            border: 0,
                                                            margin: 0,
                                                            padding: 0
                                                        }}
                                                    />
                                                    <img
                                                        alt=""
                                                        aria-hidden="true"
                                                        src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2718%27%20height=%2718%27/%3e"
                                                        style={{
                                                            display: isMobile ? "block" : "none",
                                                            maxWidth: "100%",
                                                            width: "initial",
                                                            height: "initial",
                                                            background: "none",
                                                            opacity: 1,
                                                            border: 0,
                                                            margin: 0,
                                                            padding: 0
                                                        }}
                                                    />
                                                </span>
                                                <img
                                                    alt="LOGO"
                                                    // srcSet="https://ump1.fun/_next/image?url=https%3A%2F%2Fd12lxcfs2l5om8.cloudfront.net%2Fnew_webimages%2Fbanner_select_type%2Fnew_pg_icon.webp&w=32&q=75 1x, https://ump1.fun/_next/image?url=https%3A%2F%2Fd12lxcfs2l5om8.cloudfront.net%2Fnew_webimages%2Fbanner_select_type%2Fnew_pg_icon.webp&w=64&q=75 2x"
                                                    src="https://ump1.fun/_next/image?url=https%3A%2F%2Fd12lxcfs2l5om8.cloudfront.net%2Fnew_webimages%2Fbanner_select_type%2Fnew_pg_icon.webp&w=64&q=75"
                                                    decoding="async"
                                                    data-nimg="intrinsic"
                                                    style={{
                                                        position: "absolute",
                                                        inset: 0,
                                                        boxSizing: "border-box",
                                                        padding: 0,
                                                        border: "none",
                                                        margin: "auto",
                                                        display: "block",
                                                        width: 0,
                                                        height: 0,
                                                        minWidth: "100%",
                                                        maxWidth: "100%",
                                                        minHeight: "100%",
                                                        maxHeight: "100%"
                                                    }}
                                                />
                                            </span>
                                        </span>
                                        <p className={isMobile ? "MuiTypography-root MuiTypography-body1 css-mi843t" : "MuiTypography-root MuiTypography-body1 css-1slmur6"}>
                                            เกมส์ PG
                                        </p>
                                        <span className="MuiTouchRipple-root css-w0pj6f" />
                                    </button>
                                    <button
                                        className={isMobile ? "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium zoom css-ltuan1" : "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium zoom css-zbunea"}
                                        tabIndex={0}
                                        type="button"
                                        role="button" onClick={(e) => {
                                            this.checkurl(e, "/game/list/pgslot")
                                        }}
                                    >
                                        <p className={isMobile ? "MuiTypography-root MuiTypography-body1 css-1j3mhkr" : "MuiTypography-root MuiTypography-body1 css-1ousqo0"}>
                                            ดูทั้งหมด คลิก
                                        </p>
                                        <span className="MuiTouchRipple-root css-w0pj6f" />
                                    </button>
                                </div>
                                <div className={isMobile ? "MuiBox-root css-1ldg1c1" : "MuiBox-root css-1dzwj8z"}>
                                    {this.state.pglist.map((listitem, i) => (
                                        <div className={isMobile ? "MuiBox-root css-1baulvz" : "MuiBox-root css-b1x869"}>
                                            <div className={isMobile ? "gameBox MuiBox-root css-1no3ddw" : "gameBox MuiBox-root css-nb57xa"}>
                                                <span
                                                    style={{
                                                        boxSizing: "border-box",
                                                        display: "block",
                                                        overflow: "hidden",
                                                        width: "initial",
                                                        height: "initial",
                                                        background: "none",
                                                        opacity: 1,
                                                        border: 0,
                                                        margin: 0,
                                                        padding: 0,
                                                        position: "absolute",
                                                        inset: 0
                                                    }}
                                                >
                                                    <img
                                                        alt="GAME"
                                                        sizes="100vw"
                                                        src={listitem.image}
                                                        decoding="async"
                                                        data-nimg="fill"
                                                        className="gameImg"
                                                        style={{
                                                            background: "rgba(0, 0, 0, 0.5)",
                                                            borderRadius: 20,
                                                            position: "absolute",
                                                            inset: 0,
                                                            boxSizing: "border-box",
                                                            padding: 0,
                                                            border: "none",
                                                            margin: "auto",
                                                            display: "block",
                                                            width: 0,
                                                            height: 0,
                                                            minWidth: "100%",
                                                            maxWidth: "100%",
                                                            minHeight: "100%",
                                                            maxHeight: "100%"
                                                        }}
                                                    />
                                                </span>
                                                <a href={() => false}
                                                    onClick={(e) => this.redeem(e, listitem.gameId, listitem.partner)}>
                                                    <div className={isMobile ? "gameBoxButton MuiBox-root css-1hy15ul" : "gameBoxButton MuiBox-root css-14m96ey"}>
                                                        <p className={isMobile ? "MuiTypography-root MuiTypography-body1 css-1scklri" : "MuiTypography-root MuiTypography-body1 css-bjeqy7"}>
                                                            เล่นเกมส์
                                                        </p>
                                                    </div>
                                                </a>
                                            </div>

                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div style={{ display: tabselect == 0 && tabselect != 1 && tabselect != 2 ? "" : "none" }} className={isMobile ? "MuiBox-root css-4hd7e7" : "MuiBox-root css-1rckm58"}>
                                <div className={isMobile ? "MuiBox-root css-1gg2hi7" : "MuiBox-root css-bo07ty"}>
                                    <button
                                        role="button" onClick={(e) => {
                                            this.checkurl(e, "/game/hit")
                                        }}
                                        className="MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-12mu22"
                                        tabIndex={0}
                                        type="button"
                                    >
                                        <span className="MuiButton-startIcon MuiButton-iconSizeMedium css-6xugel">
                                            <span
                                                style={{
                                                    boxSizing: "border-box",
                                                    display: "inline-block",
                                                    overflow: "hidden",
                                                    width: "initial",
                                                    height: "initial",
                                                    background: "none",
                                                    opacity: 1,
                                                    border: 0,
                                                    margin: 0,
                                                    padding: 0,
                                                    position: "relative",
                                                    maxWidth: "100%"
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        boxSizing: "border-box",
                                                        display: "block",
                                                        width: "initial",
                                                        height: "initial",
                                                        background: "none",
                                                        opacity: 1,
                                                        border: 0,
                                                        margin: 0,
                                                        padding: 0,
                                                        maxWidth: "100%"
                                                    }}
                                                >
                                                    <img
                                                        alt=""
                                                        aria-hidden="true"
                                                        src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2732%27%20height=%2732%27/%3e"
                                                        style={{
                                                            display: isMobile ? "none" : "block",
                                                            maxWidth: "100%",
                                                            width: "initial",
                                                            height: "initial",
                                                            background: "none",
                                                            opacity: 1,
                                                            border: 0,
                                                            margin: 0,
                                                            padding: 0
                                                        }}
                                                    />
                                                    <img
                                                        alt=""
                                                        aria-hidden="true"
                                                        src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2718%27%20height=%2718%27/%3e"
                                                        style={{
                                                            display: isMobile ? "block" : "none",
                                                            maxWidth: "100%",
                                                            width: "initial",
                                                            height: "initial",
                                                            background: "none",
                                                            opacity: 1,
                                                            border: 0,
                                                            margin: 0,
                                                            padding: 0
                                                        }}
                                                    />
                                                </span>
                                                <img
                                                    alt="LOGO"
                                                    //  srcSet="https://ump1.fun/_next/image?url=https%3A%2F%2Fd12lxcfs2l5om8.cloudfront.net%2Fnew_webimages%2Fbanner_select_type%2Fnew_hit_icon.webp&w=32&q=75 1x, https://ump1.fun/_next/image?url=https%3A%2F%2Fd12lxcfs2l5om8.cloudfront.net%2Fnew_webimages%2Fbanner_select_type%2Fnew_hit_icon.webp&w=64&q=75 2x"
                                                    //   src="https://ump1.fun/_next/image?url=https%3A%2F%2Fd12lxcfs2l5om8.cloudfront.net%2Fnew_webimages%2Fbanner_select_type%2Fnew_hit_icon.webp&w=64&q=75"
                                                    src="/build/web/igame-index-lobby-wm/img/new_hit_icon.webp"


                                                    decoding="async"
                                                    data-nimg="intrinsic"
                                                    style={{
                                                        position: "absolute",
                                                        inset: 0,
                                                        boxSizing: "border-box",
                                                        padding: 0,
                                                        border: "none",
                                                        margin: "auto",
                                                        display: "block",
                                                        width: 0,
                                                        height: 0,
                                                        minWidth: "100%",
                                                        maxWidth: "100%",
                                                        minHeight: "100%",
                                                        maxHeight: "100%"
                                                    }}
                                                />
                                            </span>
                                        </span>
                                        <p className={isMobile ? "MuiTypography-root MuiTypography-body1 css-mi843t" : "MuiTypography-root MuiTypography-body1 css-1slmur6"}>
                                            เกมส์ยอดฮิต
                                        </p>
                                        <span className="MuiTouchRipple-root css-w0pj6f" />
                                    </button>
                                    <button
                                        className={isMobile ? "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium zoom css-ltuan1" : "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium zoom css-zbunea"}
                                        tabIndex={0}
                                        type="button"
                                        role="button" onClick={(e) => {
                                            this.checkurl(e, "/game/hit")
                                        }}
                                    >
                                        <p className={isMobile ? "MuiTypography-root MuiTypography-body1 css-1j3mhkr" : "MuiTypography-root MuiTypography-body1 css-1ousqo0"}>
                                            ดูทั้งหมด คลิก
                                        </p>
                                        <span className="MuiTouchRipple-root css-w0pj6f" />
                                    </button>
                                </div>
                                <div className={isMobile ? "MuiBox-root css-1ldg1c1" : "MuiBox-root css-1dzwj8z"}>
                                    {this.state.gamehitlist.map((listitem, i) => (
                                        <div className={isMobile ? "MuiBox-root css-1baulvz" : "MuiBox-root css-b1x869"}>
                                            <div className={isMobile ? "gameBox MuiBox-root css-1no3ddw" : "gameBox MuiBox-root css-nb57xa"}>
                                                <span
                                                    style={{
                                                        boxSizing: "border-box",
                                                        display: "block",
                                                        overflow: "hidden",
                                                        width: "initial",
                                                        height: "initial",
                                                        background: "none",
                                                        opacity: 1,
                                                        border: 0,
                                                        margin: 0,
                                                        padding: 0,
                                                        position: "absolute",
                                                        inset: 0
                                                    }}
                                                >
                                                    <img
                                                        alt="GAME"
                                                        sizes="100vw"
                                                        src={listitem.image}
                                                        decoding="async"
                                                        data-nimg="fill"
                                                        className="gameImg"
                                                        style={{
                                                            background: "rgba(0, 0, 0, 0.5)",
                                                            borderRadius: 20,
                                                            position: "absolute",
                                                            inset: 0,
                                                            boxSizing: "border-box",
                                                            padding: 0,
                                                            border: "none",
                                                            margin: "auto",
                                                            display: "block",
                                                            width: 0,
                                                            height: 0,
                                                            minWidth: "100%",
                                                            maxWidth: "100%",
                                                            minHeight: "100%",
                                                            maxHeight: "100%"
                                                        }}
                                                    />
                                                </span>
                                                <a href={() => false}
                                                    onClick={(e) => this.redeem(e, listitem.gameId, listitem.partner)}>
                                                    <div className={isMobile ? "gameBoxButton MuiBox-root css-1hy15ul" : "gameBoxButton MuiBox-root css-14m96ey"}>
                                                        <p className={isMobile ? "MuiTypography-root MuiTypography-body1 css-1scklri" : "MuiTypography-root MuiTypography-body1 css-bjeqy7"}>
                                                            เล่นเกมส์
                                                        </p>
                                                    </div>
                                                </a>
                                            </div>

                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div style={{ display: tabselect == 0 && tabselect != 1 && tabselect != 2 ? "" : "none" }} className={isMobile ? "MuiBox-root css-4hd7e7" : "MuiBox-root css-1rckm58"}>
                                <div className={isMobile ? "MuiBox-root css-1gg2hi7" : "MuiBox-root css-bo07ty"}>
                                    <button
                                        role="button" onClick={(e) => {
                                            this.checkurl(e, "/game/slot")
                                        }}
                                        className="MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-12mu22"
                                        tabIndex={0}
                                        type="button"
                                    >
                                        <span className="MuiButton-startIcon MuiButton-iconSizeMedium css-6xugel">
                                            <span
                                                style={{
                                                    boxSizing: "border-box",
                                                    display: "inline-block",
                                                    overflow: "hidden",
                                                    width: "initial",
                                                    height: "initial",
                                                    background: "none",
                                                    opacity: 1,
                                                    border: 0,
                                                    margin: 0,
                                                    padding: 0,
                                                    position: "relative",
                                                    maxWidth: "100%"
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        display: isMobile ? "none" : "block",
                                                        maxWidth: "100%",
                                                        width: "initial",
                                                        height: "initial",
                                                        background: "none",
                                                        opacity: 1,
                                                        border: 0,
                                                        margin: 0,
                                                        padding: 0
                                                    }}
                                                >
                                                    <img
                                                        alt=""
                                                        aria-hidden="true"
                                                        src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2732%27%20height=%2732%27/%3e"
                                                        style={{
                                                            display: isMobile ? "none" : "block",
                                                            maxWidth: "100%",
                                                            width: "initial",
                                                            height: "initial",
                                                            background: "none",
                                                            opacity: 1,
                                                            border: 0,
                                                            margin: 0,
                                                            padding: 0
                                                        }}
                                                    />
                                                    <img
                                                        alt=""
                                                        aria-hidden="true"
                                                        src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2718%27%20height=%2718%27/%3e"
                                                        style={{
                                                            display: isMobile ? "block" : "none",
                                                            maxWidth: "100%",
                                                            width: "initial",
                                                            height: "initial",
                                                            background: "none",
                                                            opacity: 1,
                                                            border: 0,
                                                            margin: 0,
                                                            padding: 0
                                                        }}
                                                    />

                                                </span>
                                                <img
                                                    alt="ประเภทเกมส์"
                                                    src="/build/web/igame-index-lobby-wm/img/new_slot_icon.webp"
                                                    decoding="async"
                                                    data-nimg="intrinsic"
                                                    style={{
                                                        position: "absolute",
                                                        inset: 0,
                                                        boxSizing: "border-box",
                                                        padding: 0,
                                                        border: "none",
                                                        margin: "auto",
                                                        display: "block",
                                                        width: 0,
                                                        height: 0,
                                                        minWidth: "100%",
                                                        maxWidth: "100%",
                                                        minHeight: "100%",
                                                        maxHeight: "100%"
                                                    }}
                                                />
                                            </span>
                                        </span>
                                        <p className={isMobile ? "MuiTypography-root MuiTypography-body1 css-mi843t" : "MuiTypography-root MuiTypography-body1 css-1slmur6"}>
                                            สล็อตมาใหม่
                                        </p>
                                        <span className="MuiTouchRipple-root css-w0pj6f" />
                                    </button>
                                    <button
                                        className={isMobile ? "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium zoom css-ltuan1" : "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium zoom css-zbunea"}
                                        tabIndex={0}
                                        type="button"
                                        role="button" onClick={(e) => {
                                            this.checkurl(e, "/game/slot")
                                        }}
                                    >
                                        <p className={isMobile ? "MuiTypography-root MuiTypography-body1 css-1j3mhkr" : "MuiTypography-root MuiTypography-body1 css-1ousqo0"}>
                                            ดูทั้งหมด คลิก
                                        </p>
                                        <span className="MuiTouchRipple-root css-w0pj6f" />
                                    </button>
                                </div>
                                <div className={isMobile ? "MuiBox-root css-1ldg1c1" : "MuiBox-root css-1dzwj8z"}>
                                    {this.state.slotlist
                                        .sort((a, b) => a.id - b.id) // Sort by ascending order of id
                                        .map((listitem, i) => (
                                            <div className={isMobile ? "MuiBox-root css-1baulvz" : "MuiBox-root css-b1x869"}>
                                                <div className={isMobile ? "gameBox MuiBox-root css-1no3ddw" : "gameBox MuiBox-root css-nb57xa"}>
                                                    <span
                                                        style={{
                                                            boxSizing: "border-box",
                                                            display: "block",
                                                            overflow: "hidden",
                                                            width: "initial",
                                                            height: "initial",
                                                            background: "none",
                                                            opacity: 1,
                                                            border: 0,
                                                            margin: 0,
                                                            padding: 0,
                                                            position: "absolute",
                                                            inset: 0
                                                        }}
                                                    >
                                                        <img
                                                            alt="GAME"
                                                            sizes="100vw"
                                                            src={listitem.image}
                                                            decoding="async"
                                                            data-nimg="fill"
                                                            className="gameImg"
                                                            style={{
                                                                background: "rgba(0, 0, 0, 0.5)",
                                                                borderRadius: 20,
                                                                position: "absolute",
                                                                inset: 0,
                                                                boxSizing: "border-box",
                                                                padding: 0,
                                                                border: "none",
                                                                margin: "auto",
                                                                display: "block",
                                                                width: 0,
                                                                height: 0,
                                                                minWidth: "100%",
                                                                maxWidth: "100%",
                                                                minHeight: "100%",
                                                                maxHeight: "100%"
                                                            }}
                                                        />
                                                    </span>
                                                    <a href={() => false}
                                                        onClick={(e) => this.redeem(e, listitem.gameId, listitem.partner)}
                                                    >
                                                        <div className={isMobile ? "gameBoxButton MuiBox-root css-1hy15ul" : "gameBoxButton MuiBox-root css-14m96ey"}>
                                                            <p className={isMobile ? "MuiTypography-root MuiTypography-body1 css-1scklri" : "MuiTypography-root MuiTypography-body1 css-bjeqy7"}>
                                                                เล่นเกมส์
                                                            </p>
                                                        </div>
                                                    </a>
                                                </div>

                                            </div>
                                        ))}
                                </div>
                            </div>

                            <div style={{ display: tabselect == 0 && tabselect != 1 && tabselect != 2 ? "" : "none" }} className="MuiBox-root css-4hd7e7">
                                <div className={isMobile ? "MuiBox-root css-1gg2hi7" : "MuiBox-root css-bo07ty"}>
                                    <button
                                        role="button" onClick={(e) => {
                                            this.checkurl(e, "/game/casino")
                                        }}
                                        className="MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-12mu22"
                                        tabIndex={0}
                                        type="button"
                                    >
                                        <span className="MuiButton-startIcon MuiButton-iconSizeMedium css-6xugel">
                                            <span
                                                style={{
                                                    boxSizing: "border-box",
                                                    display: "inline-block",
                                                    overflow: "hidden",
                                                    width: "initial",
                                                    height: "initial",
                                                    background: "none",
                                                    opacity: 1,
                                                    border: 0,
                                                    margin: 0,
                                                    padding: 0,
                                                    position: "relative",
                                                    maxWidth: "100%"
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        boxSizing: "border-box",
                                                        display: "block",
                                                        width: "initial",
                                                        height: "initial",
                                                        background: "none",
                                                        opacity: 1,
                                                        border: 0,
                                                        margin: 0,
                                                        padding: 0,
                                                        maxWidth: "100%"
                                                    }}
                                                >
                                                    <img
                                                        alt=""
                                                        aria-hidden="true"
                                                        src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2732%27%20height=%2732%27/%3e"
                                                        style={{
                                                            display: isMobile ? "none" : "block",
                                                            maxWidth: "100%",
                                                            width: "initial",
                                                            height: "initial",
                                                            background: "none",
                                                            opacity: 1,
                                                            border: 0,
                                                            margin: 0,
                                                            padding: 0
                                                        }}
                                                    />
                                                    <img
                                                        alt=""
                                                        aria-hidden="true"
                                                        src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2718%27%20height=%2718%27/%3e"
                                                        style={{
                                                            display: isMobile ? "block" : "none",
                                                            maxWidth: "100%",
                                                            width: "initial",
                                                            height: "initial",
                                                            background: "none",
                                                            opacity: 1,
                                                            border: 0,
                                                            margin: 0,
                                                            padding: 0
                                                        }}
                                                    />
                                                </span>
                                                <img
                                                    alt="LOGO"
                                                    src="/build/web/igame-index-lobby-wm/img/new_livecasino_icon.webp"
                                                    decoding="async"
                                                    data-nimg="intrinsic"
                                                    style={{
                                                        position: "absolute",
                                                        inset: 0,
                                                        boxSizing: "border-box",
                                                        padding: 0,
                                                        border: "none",
                                                        margin: "auto",
                                                        display: "block",
                                                        width: 0,
                                                        height: 0,
                                                        minWidth: "100%",
                                                        maxWidth: "100%",
                                                        minHeight: "100%",
                                                        maxHeight: "100%"
                                                    }}
                                                />
                                            </span>
                                        </span>
                                        <p className={isMobile ? "MuiTypography-root MuiTypography-body1 css-mi843t" : "MuiTypography-root MuiTypography-body1 css-1slmur6"}>
                                            คาสิโนสด
                                        </p>
                                        <span className="MuiTouchRipple-root css-w0pj6f" />
                                    </button>
                                    <button className={isMobile ? "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium zoom css-ltuan1" : "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium zoom css-zbunea"}
                                        tabIndex={0}
                                        role="button" onClick={(e) => {
                                            this.checkurl(e, "/game/casino")
                                        }}
                                        type="button"
                                    >
                                        <p className={isMobile ? "MuiTypography-root MuiTypography-body1 css-1j3mhkr" : "MuiTypography-root MuiTypography-body1 css-1ousqo0"}>
                                            ดูทั้งหมด คลิก
                                        </p>
                                        <span className="MuiTouchRipple-root css-w0pj6f" />
                                    </button>
                                </div>
                                <div className={isMobile ? "MuiBox-root css-1ldg1c1" : "MuiBox-root css-1dzwj8z"}>
                                    {this.state.categorylist.map((listitem, i) => (
                                        <div key={i} className={isMobile ? "MuiBox-root css-1baulvz" : "MuiBox-root css-b1x869"}>
                                            <div className={isMobile ? "gameBox MuiBox-root css-1no3ddw" : "gameBox MuiBox-root css-nb57xa"}>
                                                <span
                                                    style={{
                                                        boxSizing: "border-box",
                                                        display: "block",
                                                        overflow: "hidden",
                                                        width: "initial",
                                                        height: "initial",
                                                        background: "none",
                                                        opacity: 1,
                                                        border: 0,
                                                        margin: 0,
                                                        padding: 0,
                                                        position: "absolute",
                                                        inset: 0
                                                    }}
                                                >
                                                    <img
                                                        alt="GAME"
                                                        sizes="100vw"
                                                        src={listitem.imagesec}
                                                        decoding="async"
                                                        data-nimg="fill"
                                                        className="gameImg"
                                                        style={{
                                                            background: "rgba(0, 0, 0, 0.5)",
                                                            borderRadius: 20,
                                                            position: "absolute",
                                                            inset: 0,
                                                            boxSizing: "border-box",
                                                            padding: 0,
                                                            border: "none",
                                                            margin: "auto",
                                                            display: "block",
                                                            width: 0,
                                                            height: 0,
                                                            minWidth: "100%",
                                                            maxWidth: "100%",
                                                            minHeight: "100%",
                                                            maxHeight: "100%"
                                                        }}
                                                    />
                                                </span>
                                                <a href={() => false}
                                                    onClick={(e) => this.redeem(e, listitem.gameId, listitem.partner)}
                                                >
                                                    <div className={isMobile ? "gameBoxButton MuiBox-root css-1hy15ul" : "gameBoxButton MuiBox-root css-14m96ey"}>
                                                        <p className={isMobile ? "MuiTypography-root MuiTypography-body1 css-1scklri" : "MuiTypography-root MuiTypography-body1 css-bjeqy7"}>
                                                            เล่นเกมส์
                                                        </p>
                                                    </div>
                                                </a>
                                            </div>

                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div style={{ display: tabselect == 0 && tabselect != 1 && tabselect != 2 ? "" : "none" }} className={isMobile ? "MuiBox-root css-4hd7e7" : "MuiBox-root css-1rckm58"}>
                                <div className={isMobile ? "MuiBox-root css-1gg2hi7" : "MuiBox-root css-bo07ty"}>
                                    <button
                                        role="button" onClick={(e) => {
                                            this.checkurl(e, "/game/sport")
                                        }}
                                        className="MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-12mu22"
                                        tabIndex={0}
                                        type="button"
                                    >
                                        <span className="MuiButton-startIcon MuiButton-iconSizeMedium css-6xugel">
                                            <span
                                                style={{
                                                    boxSizing: "border-box",
                                                    display: "inline-block",
                                                    overflow: "hidden",
                                                    width: "initial",
                                                    height: "initial",
                                                    background: "none",
                                                    opacity: 1,
                                                    border: 0,
                                                    margin: 0,
                                                    padding: 0,
                                                    position: "relative",
                                                    maxWidth: "100%"
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        boxSizing: "border-box",
                                                        display: "block",
                                                        width: "initial",
                                                        height: "initial",
                                                        background: "none",
                                                        opacity: 1,
                                                        border: 0,
                                                        margin: 0,
                                                        padding: 0,
                                                        maxWidth: "100%"
                                                    }}
                                                >
                                                    <img
                                                        alt=""
                                                        aria-hidden="true"
                                                        src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2732%27%20height=%2732%27/%3e"
                                                        style={{
                                                            display: isMobile ? "none" : "block",
                                                            maxWidth: "100%",
                                                            width: "initial",
                                                            height: "initial",
                                                            background: "none",
                                                            opacity: 1,
                                                            border: 0,
                                                            margin: 0,
                                                            padding: 0
                                                        }}
                                                    />
                                                    <img
                                                        alt=""
                                                        aria-hidden="true"
                                                        src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2718%27%20height=%2718%27/%3e"
                                                        style={{
                                                            display: isMobile ? "block" : "none",
                                                            maxWidth: "100%",
                                                            width: "initial",
                                                            height: "initial",
                                                            background: "none",
                                                            opacity: 1,
                                                            border: 0,
                                                            margin: 0,
                                                            padding: 0
                                                        }}
                                                    />
                                                </span>
                                                <img
                                                    alt="LOGO"
                                                    src="/build/web/igame-index-lobby-wm/img/new_sport_icon.webp" decoding="async"
                                                    data-nimg="intrinsic"
                                                    style={{
                                                        position: "absolute",
                                                        inset: 0,
                                                        boxSizing: "border-box",
                                                        padding: 0,
                                                        border: "none",
                                                        margin: "auto",
                                                        display: "block",
                                                        width: 0,
                                                        height: 0,
                                                        minWidth: "100%",
                                                        maxWidth: "100%",
                                                        minHeight: "100%",
                                                        maxHeight: "100%"
                                                    }}
                                                />
                                            </span>
                                        </span>
                                        <p className={isMobile ? "MuiTypography-root MuiTypography-body1 css-mi843t" : "MuiTypography-root MuiTypography-body1 css-1slmur6"}>
                                            กีฬาพารวย
                                        </p>
                                        <span className="MuiTouchRipple-root css-w0pj6f" />
                                    </button>
                                    <button
                                        role="button" onClick={(e) => {
                                            this.checkurl(e, "/game/sport")
                                        }}
                                        className={isMobile ? "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium zoom css-ltuan1" : "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium zoom css-zbunea"}
                                        tabIndex={0}
                                        type="button"
                                    >
                                        <p className={isMobile ? "MuiTypography-root MuiTypography-body1 css-1j3mhkr" : "MuiTypography-root MuiTypography-body1 css-1ousqo0"}>
                                            ดูทั้งหมด คลิก
                                        </p>
                                        <span className="MuiTouchRipple-root css-w0pj6f" />
                                    </button>
                                </div>
                                <div className={isMobile ? "MuiBox-root css-1ldg1c1" : "MuiBox-root css-1dzwj8z"}>
                                    {this.state.sportlist.map((listitem, i) => (
                                        <div className={isMobile ? "MuiBox-root css-1baulvz" : "MuiBox-root css-b1x869"}>
                                            <div className={isMobile ? "gameBox MuiBox-root css-1no3ddw" : "gameBox MuiBox-root css-nb57xa"}>
                                                <span
                                                    style={{
                                                        boxSizing: "border-box",
                                                        display: "block",
                                                        overflow: "hidden",
                                                        width: "initial",
                                                        height: "initial",
                                                        background: "none",
                                                        opacity: 1,
                                                        border: 0,
                                                        margin: 0,
                                                        padding: 0,
                                                        position: "absolute",
                                                        inset: 0
                                                    }}
                                                >
                                                    <img
                                                        alt="GAME"
                                                        sizes="100vw"
                                                        src={listitem.imagesec}
                                                        decoding="async"
                                                        data-nimg="fill"
                                                        className="gameImg"
                                                        style={{
                                                            background: "rgba(0, 0, 0, 0.5)",
                                                            borderRadius: 20,
                                                            position: "absolute",
                                                            inset: 0,
                                                            boxSizing: "border-box",
                                                            padding: 0,
                                                            border: "none",
                                                            margin: "auto",
                                                            display: "block",
                                                            width: 0,
                                                            height: 0,
                                                            minWidth: "100%",
                                                            maxWidth: "100%",
                                                            minHeight: "100%",
                                                            maxHeight: "100%"
                                                        }}
                                                    />
                                                </span>
                                                <a href={() => false}
                                                    onClick={(e) => this.redeem(e, listitem.gameId, listitem.partner)}>
                                                    <div className={isMobile ? "gameBoxButton MuiBox-root css-1hy15ul" : "gameBoxButton MuiBox-root css-14m96ey"}>
                                                        <p className={isMobile ? "MuiTypography-root MuiTypography-body1 css-1scklri" : "MuiTypography-root MuiTypography-body1 css-bjeqy7"}>
                                                            เล่นเกมส์
                                                        </p>
                                                    </div>
                                                </a>
                                            </div>

                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div style={{ display: tabselect == 0 && tabselect != 1 && tabselect != 2 ? "" : "none" }} className={isMobile ? "MuiBox-root css-4hd7e7" : "MuiBox-root css-1rckm58"}>
                                <div className={isMobile ? "MuiBox-root css-1gg2hi7" : "MuiBox-root css-bo07ty"}>
                                    <button
                                        role="button" onClick={(e) => {
                                            this.checkurl(e, "/game/fishing")
                                        }}
                                        className="MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-12mu22"
                                        tabIndex={0}
                                        type="button"
                                    >
                                        <span className="MuiButton-startIcon MuiButton-iconSizeMedium css-6xugel">
                                            <span
                                                style={{
                                                    boxSizing: "border-box",
                                                    display: "inline-block",
                                                    overflow: "hidden",
                                                    width: "initial",
                                                    height: "initial",
                                                    background: "none",
                                                    opacity: 1,
                                                    border: 0,
                                                    margin: 0,
                                                    padding: 0,
                                                    position: "relative",
                                                    maxWidth: "100%"
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        boxSizing: "border-box",
                                                        display: "block",
                                                        width: "initial",
                                                        height: "initial",
                                                        background: "none",
                                                        opacity: 1,
                                                        border: 0,
                                                        margin: 0,
                                                        padding: 0,
                                                        maxWidth: "100%"
                                                    }}
                                                >
                                                    <img
                                                        alt=""
                                                        aria-hidden="true"
                                                        src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2732%27%20height=%2732%27/%3e"
                                                        style={{
                                                            display: isMobile ? "none" : "block",
                                                            maxWidth: "100%",
                                                            width: "initial",
                                                            height: "initial",
                                                            background: "none",
                                                            opacity: 1,
                                                            border: 0,
                                                            margin: 0,
                                                            padding: 0
                                                        }}
                                                    />
                                                    <img
                                                        alt=""
                                                        aria-hidden="true"
                                                        src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2718%27%20height=%2718%27/%3e"
                                                        style={{
                                                            display: isMobile ? "block" : "none",
                                                            maxWidth: "100%",
                                                            width: "initial",
                                                            height: "initial",
                                                            background: "none",
                                                            opacity: 1,
                                                            border: 0,
                                                            margin: 0,
                                                            padding: 0
                                                        }}
                                                    />
                                                </span>
                                                <img
                                                    alt="LOGO"
                                                    src="/build/web/igame-index-lobby-wm/img/new_fishing_icon.webp"
                                                    decoding="async"
                                                    data-nimg="intrinsic"
                                                    style={{
                                                        position: "absolute",
                                                        inset: 0,
                                                        boxSizing: "border-box",
                                                        padding: 0,
                                                        border: "none",
                                                        margin: "auto",
                                                        display: "block",
                                                        width: 0,
                                                        height: 0,
                                                        minWidth: "100%",
                                                        maxWidth: "100%",
                                                        minHeight: "100%",
                                                        maxHeight: "100%"
                                                    }}
                                                />
                                            </span>
                                        </span>
                                        <p className={isMobile ? "MuiTypography-root MuiTypography-body1 css-mi843t" : "MuiTypography-root MuiTypography-body1 css-1slmur6"}>
                                            ยิงปลา
                                        </p>
                                        <span className="MuiTouchRipple-root css-w0pj6f" />
                                    </button>
                                    <button
                                        role="button" onClick={(e) => {
                                            this.checkurl(e, "/game/fishing")
                                        }}
                                        className={isMobile ? "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium zoom css-ltuan1" : "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium zoom css-zbunea"}
                                        tabIndex={0}
                                        type="button"
                                    >
                                        <p className={isMobile ? "MuiTypography-root MuiTypography-body1 css-1j3mhkr" : "MuiTypography-root MuiTypography-body1 css-1ousqo0"}>
                                            ดูทั้งหมด คลิก
                                        </p>
                                        <span className="MuiTouchRipple-root css-w0pj6f" />
                                    </button>
                                </div>
                                <div className={isMobile ? "MuiBox-root css-1ldg1c1" : "MuiBox-root css-1dzwj8z"}>
                                    {this.state.fishinglist.map((listitem, i) => (
                                        <div className={isMobile ? "MuiBox-root css-1baulvz" : "MuiBox-root css-b1x869"}>
                                            <div className={isMobile ? "gameBox MuiBox-root css-1no3ddw" : "gameBox MuiBox-root css-nb57xa"}>
                                                <span
                                                    style={{
                                                        boxSizing: "border-box",
                                                        display: "block",
                                                        overflow: "hidden",
                                                        width: "initial",
                                                        height: "initial",
                                                        background: "none",
                                                        opacity: 1,
                                                        border: 0,
                                                        margin: 0,
                                                        padding: 0,
                                                        position: "absolute",
                                                        inset: 0
                                                    }}
                                                >
                                                    <img
                                                        alt="GAME"
                                                        sizes="100vw"
                                                        src={listitem.image}
                                                        decoding="async"
                                                        data-nimg="fill"
                                                        className="gameImg"
                                                        style={{
                                                            background: "rgba(0, 0, 0, 0.5)",
                                                            borderRadius: 20,
                                                            position: "absolute",
                                                            inset: 0,
                                                            boxSizing: "border-box",
                                                            padding: 0,
                                                            border: "none",
                                                            margin: "auto",
                                                            display: "block",
                                                            width: 0,
                                                            height: 0,
                                                            minWidth: "100%",
                                                            maxWidth: "100%",
                                                            minHeight: "100%",
                                                            maxHeight: "100%"
                                                        }}
                                                    />
                                                </span>
                                                <a href={() => false}
                                                    onClick={(e) => this.redeem(e, listitem.gameId, listitem.partner)}>
                                                    <div className={isMobile ? "gameBoxButton MuiBox-root css-1hy15ul" : "gameBoxButton MuiBox-root css-14m96ey"}>
                                                        <p className={isMobile ? "MuiTypography-root MuiTypography-body1 css-1scklri" : "MuiTypography-root MuiTypography-body1 css-bjeqy7"}>
                                                            เล่นเกมส์
                                                        </p>
                                                    </div>
                                                </a>
                                            </div>

                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="MuiBox-root css-1pg8bi" />
                            <div style={{ display: tabselect == 0 && tabselect != 1 && tabselect != 2 ? "" : "none" }} className="MuiBox-root css-1ykdma4">
                                <div className="MuiBox-root css-11ze7cv">
                                    <div className="MuiBox-root css-8atqhb">
                                        <div className="MuiAutocomplete-root MuiAutocomplete-hasPopupIcon css-1aplae0">
                                            <div className={isMobile ? "MuiFormControl-root MuiFormControl-fullWidth MuiTextField-root css-164i1v6" : "MuiFormControl-root MuiFormControl-fullWidth MuiTextField-root css-19kdhbs"}>
                                                <label
                                                    className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-sizeSmall MuiInputLabel-outlined MuiFormLabel-colorPrimary css-u9osun"
                                                    data-shrink="true"
                                                    htmlFor=":r3q:"
                                                    id=":r3q:-label"
                                                >
                                                    ค้นหาเกมส์
                                                </label>
                                                <div className="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-fullWidth MuiInputBase-formControl MuiInputBase-sizeSmall MuiInputBase-adornedStart MuiInputBase-adornedEnd MuiAutocomplete-inputRoot css-1si533m">
                                                    <svg
                                                        className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1okqa6k"
                                                        focusable="false"
                                                        aria-hidden="true"
                                                        viewBox="0 0 24 24"
                                                        data-testid="SearchIcon"
                                                    >
                                                        <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
                                                    </svg>
                                                    <input
                                                        aria-invalid="false"
                                                        autoComplete="off"
                                                        id="txtsech2"
                                                        name="txtsech2"
                                                        type="text"
                                                        onChange={this.onChange}
                                                        className="MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputSizeSmall MuiInputBase-inputAdornedStart MuiInputBase-inputAdornedEnd MuiAutocomplete-input MuiAutocomplete-inputFocused css-1y3zh1"
                                                        aria-autocomplete="list"
                                                        aria-expanded="false"
                                                        autoCapitalize="none"
                                                        spellCheck="false"
                                                        role="combobox"
                                                        defaultValue=""
                                                    />
                                                    <div className="MuiAutocomplete-endAdornment css-2iz2x6">
                                                        <button
                                                            className="MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeMedium MuiAutocomplete-popupIndicator css-uge3vf"
                                                            tabIndex={-1}
                                                            type="button"
                                                            aria-label="Open"
                                                            title="Open"
                                                        >
                                                            <svg
                                                                className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv"
                                                                focusable="false"
                                                                aria-hidden="true"
                                                                viewBox="0 0 24 24"
                                                                data-testid="ArrowDropDownIcon"
                                                            >
                                                                <path d="M7 10l5 5 5-5z" />
                                                            </svg>
                                                            <span className="MuiTouchRipple-root css-w0pj6f" />
                                                        </button>
                                                    </div>
                                                    <fieldset
                                                        aria-hidden="true"
                                                        className="MuiOutlinedInput-notchedOutline css-igs3ac"
                                                    >
                                                        <legend className="css-1in441m">
                                                            <span>ค้นหาเกมส์</span>
                                                        </legend>
                                                    </fieldset>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="MuiBox-root css-1pg8bi" style={{ display: isMobile ? "none" : "" }} />
                            <div style={{ display: tabselect == 0 && tabselect != 1 && tabselect != 2 ? "" : "none" }} className={isMobile ? "MuiBox-root css-au4qhk" : "MuiBox-root css-1ernbou"}>
                                <button className={isMobile ? "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-1vtfrim" : "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-ywr8d2"
                                } tabIndex={0}
                                    type="button"
                                >
                                    <span className="MuiButton-startIcon MuiButton-iconSizeMedium css-6xugel">
                                        <span
                                            style={{
                                                boxSizing: "border-box",
                                                display: "inline-block",
                                                overflow: "hidden",
                                                width: "initial",
                                                height: "initial",
                                                background: "none",
                                                opacity: 1,
                                                border: 0,
                                                margin: 0,
                                                padding: 0,
                                                position: "relative",
                                                maxWidth: "100%"
                                            }}
                                        >
                                            <span
                                                style={{
                                                    boxSizing: "border-box",
                                                    display: "block",
                                                    width: "initial",
                                                    height: "initial",
                                                    background: "none",
                                                    opacity: 1,
                                                    border: 0,
                                                    margin: 0,
                                                    padding: 0,
                                                    maxWidth: "100%"
                                                }}
                                            >
                                                <img
                                                    alt=""
                                                    aria-hidden="true"
                                                    src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2732%27%20height=%2732%27/%3e"
                                                    style={{
                                                        display: isMobile ? "none" : "block",
                                                        maxWidth: "100%",
                                                        width: "initial",
                                                        height: "initial",
                                                        background: "none",
                                                        opacity: 1,
                                                        border: 0,
                                                        margin: 0,
                                                        padding: 0
                                                    }}
                                                />
                                                <img
                                                    alt=""
                                                    aria-hidden="true"

                                                    src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2718%27%20height=%2718%27/%3e"
                                                    style={{
                                                        display: isMobile ? "block" : "none",
                                                        maxWidth: "100%",
                                                        width: "initial",
                                                        height: "initial",
                                                        background: "none",
                                                        opacity: 1,
                                                        border: 0,
                                                        margin: 0,
                                                        padding: 0
                                                    }}
                                                />
                                            </span>
                                            <img
                                                alt="LOGO"
                                                src="/build/web/igame-index-lobby-wm/img/new_provider_icon.webp"
                                                decoding="async"
                                                data-nimg="intrinsic"
                                                style={{
                                                    position: "absolute",
                                                    inset: 0,
                                                    boxSizing: "border-box",
                                                    padding: 0,
                                                    border: "none",
                                                    margin: "auto",
                                                    display: "block",
                                                    width: 0,
                                                    height: 0,
                                                    minWidth: "100%",
                                                    maxWidth: "100%",
                                                    minHeight: "100%",
                                                    maxHeight: "100%"
                                                }}
                                            />
                                        </span>
                                    </span>
                                    <p className={isMobile ? "MuiTypography-root MuiTypography-body1 css-1i6p67w" : "MuiTypography-root MuiTypography-body1 css-t7ppd8"}>
                                        ค่ายเกมส์
                                    </p>
                                    <span className="MuiTouchRipple-root css-w0pj6f" />
                                </button>
                                <div className={isMobile ? "MuiBox-root css-154mysy" : "MuiBox-root css-1hn8o11"}>
                                    {listitallgame.map((listitem, i) => (
                                        <>
                                            <div className={isMobile ? "providerBox MuiBox-root css-1tat7d9" : "providerBox MuiBox-root css-dlsca0"}>
                                                <div className={isMobile ? "gameBoxButton MuiBox-root css-kpks52" : "gameBoxButton MuiBox-root css-rafu0n"}>
                                                    <a href={() => false}
                                                        onClick={(e) => this.checkurl(e, '/list/' + listitem.partner)}>
                                                        <p className={isMobile ? "MuiTypography-root MuiTypography-body1 css-967ez6" : "MuiTypography-root MuiTypography-body1 css-1scklri"}>
                                                            เลือกเกมส์
                                                        </p>
                                                    </a>
                                                </div>
                                                <div className="provider-img-con">
                                                    <span
                                                        style={{
                                                            boxSizing: "border-box",
                                                            display: "block",
                                                            overflow: "hidden",
                                                            width: "initial",
                                                            height: "initial",
                                                            background: "none",
                                                            opacity: 1,
                                                            border: 0,
                                                            margin: 0,
                                                            padding: 0,
                                                            position: "absolute",
                                                            inset: 0
                                                        }}
                                                    >
                                                        <img
                                                            alt="Provider PGSOFT"
                                                            sizes="100vw"
                                                            src={listitem.image}
                                                            decoding="async"
                                                            data-nimg="fill"
                                                            style={{
                                                                position: "absolute",
                                                                inset: 0,
                                                                boxSizing: "border-box",
                                                                padding: 0,
                                                                border: "none",
                                                                margin: "auto",
                                                                display: "block",
                                                                width: 0,
                                                                height: 0,
                                                                minWidth: "100%",
                                                                maxWidth: "100%",
                                                                minHeight: "100%",
                                                                maxHeight: "100%",
                                                                objectFit: "cover",
                                                                objectPosition: "center center"
                                                            }}
                                                        />
                                                    </span>
                                                </div>

                                            </div>
                                            {parseInt(listitallgame.length / 2) === i ? <br /> : ""}
                                        </>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div style={{ display: tabselect == 10 || tabselect == 11 || tabselect == 12 || tabselect == 13 || tabselect == 14 || tabselect == 18 || tabselect == 20 ? "" : "none" }} className="MuiBox-root css-0" >
                            <div className={isMobile ? "MuiBox-root css-f6bsem" : "MuiBox-root css-bdfdqm"} style={{ display: tabselect !== 0 ? "" : "none" }} >
                                <button className={isMobile ? "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium  css-5oh6yz" : "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-luxecs"}
                                    tabIndex={0}
                                    id="gamehit"
                                    role="button" onClick={(e) => {
                                        this.checkurl(e, "/game/hit")
                                    }}
                                >
                                    <div className={isMobile ? "MuiBox-root css-1myz5gc" : "MuiBox-root css-1gptgav"}>
                                        <span
                                            style={{
                                                boxSizing: "border-box",
                                                display: "inline-block",
                                                overflow: "hidden",
                                                width: "initial",
                                                height: "initial",
                                                background: "none",
                                                opacity: 1,
                                                border: 0,
                                                margin: 0,
                                                padding: 0,
                                                position: "relative",
                                                maxWidth: "100%"
                                            }}
                                        >
                                            <span
                                                style={{
                                                    boxSizing: "border-box",
                                                    display: "block",
                                                    width: "initial",
                                                    height: "initial",
                                                    background: "none",
                                                    opacity: 1,
                                                    border: 0,
                                                    margin: 0,
                                                    padding: 0,
                                                    maxWidth: "100%"
                                                }}
                                            >
                                                <img
                                                    alt=""
                                                    aria-hidden="true"
                                                    src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2745%27%20height=%2745%27/%3e"
                                                    style={{
                                                        display: isMobile ? "none" : "",
                                                        maxWidth: "100%",
                                                        width: "initial",
                                                        height: "initial",
                                                        background: "none",
                                                        opacity: 1,
                                                        border: 0,
                                                        margin: 0,
                                                        padding: 0
                                                    }}
                                                />
                                                <img
                                                    alt=""
                                                    aria-hidden="true"
                                                    src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2720%27%20height=%2720%27/%3e"
                                                    style={{
                                                        display: isMobile ? "" : "none",
                                                        maxWidth: "100%",
                                                        width: "initial",
                                                        height: "initial",
                                                        background: "none",
                                                        opacity: 1,
                                                        border: 0,
                                                        margin: 0,
                                                        padding: 0
                                                    }}
                                                />
                                            </span>
                                            <img
                                                alt="ประเภทเกมส์"
                                                src="/build/web/igame-index-lobby-wm/img/new_hit_icon.webp"
                                                decoding="async"
                                                data-nimg="intrinsic"
                                                style={{
                                                    position: "absolute",
                                                    inset: 0,
                                                    boxSizing: "border-box",
                                                    padding: 0,
                                                    border: "none",
                                                    margin: "auto",
                                                    display: "block",
                                                    width: 0,
                                                    height: 0,
                                                    minWidth: "100%",
                                                    maxWidth: "100%",
                                                    minHeight: "100%",
                                                    maxHeight: "100%"
                                                }}
                                            />
                                        </span>
                                        <p className={isMobile ? "MuiTypography-root MuiTypography-body1 css-1bw2arz" : "MuiTypography-root MuiTypography-body1 css-wdswgq"}>
                                            เกมส์ยอดฮิต
                                        </p>
                                    </div>
                                    <span className="MuiTouchRipple-root css-w0pj6f" />
                                </button>
                                <button className={isMobile ? "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-5oh6yz" : "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-luxecs"}
                                    tabIndex={0}
                                    id="gameslot"
                                    role="button" onClick={(e) => {
                                        this.checkurl(e, "/game/slot")
                                    }}
                                    type="button"
                                >
                                    <div className="MuiBox-root css-1gptgav">
                                        <span
                                            style={{
                                                boxSizing: "border-box",
                                                display: "inline-block",
                                                overflow: "hidden",
                                                width: "initial",
                                                height: "initial",
                                                background: "none",
                                                opacity: 1,
                                                border: 0,
                                                margin: 0,
                                                padding: 0,
                                                position: "relative",
                                                maxWidth: "100%"
                                            }}
                                        >
                                            <span
                                                style={{
                                                    boxSizing: "border-box",
                                                    display: "block",
                                                    width: "initial",
                                                    height: "initial",
                                                    background: "none",
                                                    opacity: 1,
                                                    border: 0,
                                                    margin: 0,
                                                    padding: 0,
                                                    maxWidth: "100%"
                                                }}
                                            >
                                                <img
                                                    alt=""
                                                    aria-hidden="true"
                                                    src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2745%27%20height=%2745%27/%3e"
                                                    style={{
                                                        display: isMobile ? "none" : "",
                                                        maxWidth: "100%",
                                                        width: "initial",
                                                        height: "initial",
                                                        background: "none",
                                                        opacity: 1,
                                                        border: 0,
                                                        margin: 0,
                                                        padding: 0
                                                    }}
                                                />
                                                <img
                                                    style={{
                                                        display: isMobile ? "" : "none",
                                                        maxWidth: "100%",
                                                        width: "initial",
                                                        height: "initial",
                                                        background: "none",
                                                        opacity: 1,
                                                        border: 0,
                                                        margin: 0,
                                                        padding: 0
                                                    }}
                                                    alt=""
                                                    aria-hidden="true"
                                                    src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2720%27%20height=%2720%27/%3e"
                                                />
                                            </span>
                                            <img
                                                alt="ประเภทเกมส์"
                                                src="/build/web/igame-index-lobby-wm/img/new_slot_icon.webp"
                                                decoding="async"
                                                data-nimg="intrinsic"
                                                style={{
                                                    position: "absolute",
                                                    inset: 0,
                                                    boxSizing: "border-box",
                                                    padding: 0,
                                                    border: "none",
                                                    margin: "auto",
                                                    display: "block",
                                                    width: 0,
                                                    height: 0,
                                                    minWidth: "100%",
                                                    maxWidth: "100%",
                                                    minHeight: "100%",
                                                    maxHeight: "100%"
                                                }}
                                            />
                                        </span>
                                        <p className={isMobile ? "MuiTypography-root MuiTypography-body1 css-1bw2arz" : "MuiTypography-root MuiTypography-body1 css-wdswgq"}>
                                            สล็อตมาใหม่
                                        </p>
                                    </div>
                                    <span className="MuiTouchRipple-root css-w0pj6f" />
                                </button>
                                <button className={isMobile ? "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium  css-5oh6yz" : "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-luxecs"}
                                    tabIndex={0}
                                    type="button"
                                    id="gameprovidor"
                                    role="button" onClick={(e) => {
                                        this.checkurl(e, "/game/providor")
                                    }}
                                >
                                    <div className="MuiBox-root css-1gptgav">
                                        <span
                                            style={{
                                                boxSizing: "border-box",
                                                display: "inline-block",
                                                overflow: "hidden",
                                                width: "initial",
                                                height: "initial",
                                                background: "none",
                                                opacity: 1,
                                                border: 0,
                                                margin: 0,
                                                padding: 0,
                                                position: "relative",
                                                maxWidth: "100%"
                                            }}
                                        >
                                            <span
                                                style={{
                                                    boxSizing: "border-box",
                                                    display: "block",
                                                    width: "initial",
                                                    height: "initial",
                                                    background: "none",
                                                    opacity: 1,
                                                    border: 0,
                                                    margin: 0,
                                                    padding: 0,
                                                    maxWidth: "100%"
                                                }}
                                            >
                                                <img
                                                    alt=""
                                                    aria-hidden="true"
                                                    src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2745%27%20height=%2745%27/%3e"
                                                    style={{
                                                        display: isMobile ? "none" : "",
                                                        maxWidth: "100%",
                                                        width: "initial",
                                                        height: "initial",
                                                        background: "none",
                                                        opacity: 1,
                                                        border: 0,
                                                        margin: 0,
                                                        padding: 0
                                                    }}
                                                />
                                                <img
                                                    style={{
                                                        display: isMobile ? "block" : "none",
                                                        maxWidth: "100%",
                                                        width: "initial",
                                                        height: "initial",
                                                        background: "none",
                                                        opacity: 1,
                                                        border: 0,
                                                        margin: 0,
                                                        padding: 0
                                                    }}
                                                    alt=""
                                                    aria-hidden="true"
                                                    src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2720%27%20height=%2720%27/%3e"
                                                />
                                            </span>
                                            <img
                                                alt="ประเภทเกมส์"
                                                src="/build/web/igame-index-lobby-wm/img/new_provider_icon.webp" decoding="async"
                                                data-nimg="intrinsic"
                                                style={{
                                                    position: "absolute",
                                                    inset: 0,
                                                    boxSizing: "border-box",
                                                    padding: 0,
                                                    border: "none",
                                                    margin: "auto",
                                                    display: "block",
                                                    width: 0,
                                                    height: 0,
                                                    minWidth: "100%",
                                                    maxWidth: "100%",
                                                    minHeight: "100%",
                                                    maxHeight: "100%"
                                                }}
                                            />
                                        </span>
                                        <p className={isMobile ? "MuiTypography-root MuiTypography-body1 css-1bw2arz" : "MuiTypography-root MuiTypography-body1 css-wdswgq"}>
                                            ค่ายเกมส์
                                        </p>
                                    </div>
                                    <span className="MuiTouchRipple-root css-w0pj6f" />
                                </button>
                                <button className={isMobile ? "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-5oh6yz" : "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-luxecs"} tabIndex={0}
                                    type="button"
                                    id="gamecasino"
                                    role="button" onClick={(e) => {
                                        this.checkurl(e, "/game/casino")
                                    }}
                                >
                                    <div className="MuiBox-root css-1gptgav">
                                        <span
                                            style={{
                                                boxSizing: "border-box",
                                                display: "inline-block",
                                                overflow: "hidden",
                                                width: "initial",
                                                height: "initial",
                                                background: "none",
                                                opacity: 1,
                                                border: 0,
                                                margin: 0,
                                                padding: 0,
                                                position: "relative",
                                                maxWidth: "100%"
                                            }}
                                        >
                                            <span
                                                style={{
                                                    boxSizing: "border-box",
                                                    display: "block",
                                                    width: "initial",
                                                    height: "initial",
                                                    background: "none",
                                                    opacity: 1,
                                                    border: 0,
                                                    margin: 0,
                                                    padding: 0,
                                                    maxWidth: "100%"
                                                }}
                                            >
                                                <img
                                                    alt=""
                                                    aria-hidden="true"
                                                    src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2745%27%20height=%2745%27/%3e"
                                                    style={{
                                                        display: isMobile ? "none" : "",
                                                        maxWidth: "100%",
                                                        width: "initial",
                                                        height: "initial",
                                                        background: "none",
                                                        opacity: 1,
                                                        border: 0,
                                                        margin: 0,
                                                        padding: 0
                                                    }}
                                                />
                                                <img
                                                    style={{
                                                        display: isMobile ? "block" : "none",
                                                        maxWidth: "100%",
                                                        width: "initial",
                                                        height: "initial",
                                                        background: "none",
                                                        opacity: 1,
                                                        border: 0,
                                                        margin: 0,
                                                        padding: 0
                                                    }}
                                                    alt=""
                                                    aria-hidden="true"
                                                    src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2720%27%20height=%2720%27/%3e"
                                                />
                                            </span>
                                            <img
                                                alt="ประเภทเกมส์"
                                                src="/build/web/igame-index-lobby-wm/img/new_livecasino_icon.webp"
                                                decoding="async"
                                                data-nimg="intrinsic"
                                                style={{
                                                    position: "absolute",
                                                    inset: 0,
                                                    boxSizing: "border-box",
                                                    padding: 0,
                                                    border: "none",
                                                    margin: "auto",
                                                    display: "block",
                                                    width: 0,
                                                    height: 0,
                                                    minWidth: "100%",
                                                    maxWidth: "100%",
                                                    minHeight: "100%",
                                                    maxHeight: "100%"
                                                }}
                                            />
                                        </span>
                                        <p className={isMobile ? "MuiTypography-root MuiTypography-body1 css-1bw2arz" : "MuiTypography-root MuiTypography-body1 css-wdswgq"}>
                                            คาสิโนสด
                                        </p>
                                    </div>
                                    <span className="MuiTouchRipple-root css-w0pj6f" />
                                </button>
                                <button className={isMobile ? "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-5oh6yz" : "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-luxecs"} tabIndex={0}
                                    type="button"
                                    id="gamesport"
                                    role="button" onClick={(e) => {
                                        this.checkurl(e, "/game/sport")
                                    }}
                                >
                                    <div className="MuiBox-root css-1gptgav">
                                        <span
                                            style={{
                                                boxSizing: "border-box",
                                                display: "inline-block",
                                                overflow: "hidden",
                                                width: "initial",
                                                height: "initial",
                                                background: "none",
                                                opacity: 1,
                                                border: 0,
                                                margin: 0,
                                                padding: 0,
                                                position: "relative",
                                                maxWidth: "100%"
                                            }}
                                        >
                                            <span
                                                style={{
                                                    boxSizing: "border-box",
                                                    display: "block",
                                                    width: "initial",
                                                    height: "initial",
                                                    background: "none",
                                                    opacity: 1,
                                                    border: 0,
                                                    margin: 0,
                                                    padding: 0,
                                                    maxWidth: "100%"
                                                }}
                                            >
                                                <img
                                                    alt=""
                                                    aria-hidden="true"
                                                    src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2745%27%20height=%2745%27/%3e"
                                                    style={{
                                                        display: isMobile ? "none" : "",
                                                        maxWidth: "100%",
                                                        width: "initial",
                                                        height: "initial",
                                                        background: "none",
                                                        opacity: 1,
                                                        border: 0,
                                                        margin: 0,
                                                        padding: 0
                                                    }}
                                                />
                                                <img
                                                    style={{
                                                        display: isMobile ? "block" : "none",
                                                        maxWidth: "100%",
                                                        width: "initial",
                                                        height: "initial",
                                                        background: "none",
                                                        opacity: 1,
                                                        border: 0,
                                                        margin: 0,
                                                        padding: 0
                                                    }}
                                                    alt=""
                                                    aria-hidden="true"
                                                    src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2720%27%20height=%2720%27/%3e"
                                                />
                                            </span>
                                            <img
                                                alt="ประเภทเกมส์"

                                                src="/build/web/igame-index-lobby-wm/img/new_sport_icon.webp"
                                                decoding="async"
                                                data-nimg="intrinsic"
                                                style={{
                                                    position: "absolute",
                                                    inset: 0,
                                                    boxSizing: "border-box",
                                                    padding: 0,
                                                    border: "none",
                                                    margin: "auto",
                                                    display: "block",
                                                    width: 0,
                                                    height: 0,
                                                    minWidth: "100%",
                                                    maxWidth: "100%",
                                                    minHeight: "100%",
                                                    maxHeight: "100%"
                                                }}
                                            />
                                        </span>
                                        <p className={isMobile ? "MuiTypography-root MuiTypography-body1 css-1bw2arz" : "MuiTypography-root MuiTypography-body1 css-wdswgq"}>
                                            กีฬาพารวย
                                        </p>
                                    </div>
                                    <span className="MuiTouchRipple-root css-w0pj6f" />
                                </button>
                                <button className={isMobile ? "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-5oh6yz" : "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-luxecs"}
                                    tabIndex={0}
                                    type="button"
                                    id="gamefishing"
                                    role="button" onClick={(e) => {
                                        this.checkurl(e, "/game/fishing")
                                    }}
                                >
                                    <div className="MuiBox-root css-1gptgav">
                                        <span
                                            style={{
                                                boxSizing: "border-box",
                                                display: "inline-block",
                                                overflow: "hidden",
                                                width: "initial",
                                                height: "initial",
                                                background: "none",
                                                opacity: 1,
                                                border: 0,
                                                margin: 0,
                                                padding: 0,
                                                position: "relative",
                                                maxWidth: "100%"
                                            }}
                                        >
                                            <span
                                                style={{
                                                    boxSizing: "border-box",
                                                    display: "block",
                                                    width: "initial",
                                                    height: "initial",
                                                    background: "none",
                                                    opacity: 1,
                                                    border: 0,
                                                    margin: 0,
                                                    padding: 0,
                                                    maxWidth: "100%"
                                                }}
                                            >
                                                <img
                                                    alt=""
                                                    aria-hidden="true"
                                                    src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2745%27%20height=%2745%27/%3e"
                                                    style={{
                                                        display: isMobile ? "none" : "",
                                                        maxWidth: "100%",
                                                        width: "initial",
                                                        height: "initial",
                                                        background: "none",
                                                        opacity: 1,
                                                        border: 0,
                                                        margin: 0,
                                                        padding: 0
                                                    }}
                                                />
                                                <img
                                                    alt=""
                                                    aria-hidden="true"
                                                    src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2720%27%20height=%2720%27/%3e"
                                                    style={{
                                                        display: isMobile ? "" : "none",
                                                        maxWidth: "100%",
                                                        width: "initial",
                                                        height: "initial",
                                                        background: "none",
                                                        opacity: 1,
                                                        border: 0,
                                                        margin: 0,
                                                        padding: 0
                                                    }}
                                                />
                                            </span>
                                            <img
                                                alt="ประเภทเกมส์"
                                                src="/build/web/igame-index-lobby-wm/img/new_fishing_icon.webp"
                                                decoding="async"
                                                data-nimg="intrinsic"
                                                style={{
                                                    position: "absolute",
                                                    inset: 0,
                                                    boxSizing: "border-box",
                                                    padding: 0,
                                                    border: "none",
                                                    margin: "auto",
                                                    display: "block",
                                                    width: 0,
                                                    height: 0,
                                                    minWidth: "100%",
                                                    maxWidth: "100%",
                                                    minHeight: "100%",
                                                    maxHeight: "100%"
                                                }}
                                            />
                                        </span>
                                        <p className={isMobile ? "MuiTypography-root MuiTypography-body1 css-1bw2arz" : "MuiTypography-root MuiTypography-body1 css-wdswgq"}>
                                            ยิงปลา
                                        </p>
                                    </div>
                                    <span className="MuiTouchRipple-root css-w0pj6f" />
                                </button>
                            </div>
                            <div className={isMobile ? "MuiBox-root css-1w7fvqs" : "MuiBox-root css-m3yxkf"}>
                                <div className="MuiBox-root css-j7qwjs">
                                    <button className={isMobile ? "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-hhdgnx" : "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-1o0y2hp"}
                                        tabIndex={0}
                                        type="button"
                                    >
                                        <span className="MuiButton-startIcon MuiButton-iconSizeMedium css-6xugel">
                                            <span
                                                style={{
                                                    boxSizing: "border-box",
                                                    display: "inline-block",
                                                    overflow: "hidden",
                                                    width: "initial",
                                                    height: "initial",
                                                    background: "none",
                                                    opacity: 1,
                                                    border: 0,
                                                    margin: 0,
                                                    padding: 0,
                                                    position: "relative",
                                                    maxWidth: "100%"
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        boxSizing: "border-box",
                                                        display: "block",
                                                        width: "initial",
                                                        height: "initial",
                                                        background: "none",
                                                        opacity: 1,
                                                        border: 0,
                                                        margin: 0,
                                                        padding: 0,
                                                        maxWidth: "100%"
                                                    }}
                                                >
                                                    <img
                                                        alt=""
                                                        aria-hidden="true"
                                                        src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2732%27%20height=%2732%27/%3e"
                                                        style={{
                                                            display: isMobile ? "none" : "block",
                                                            maxWidth: "100%",
                                                            width: "initial",
                                                            height: "initial",
                                                            background: "none",
                                                            opacity: 1,
                                                            border: 0,
                                                            margin: 0,
                                                            padding: 0
                                                        }}
                                                    />
                                                    <img
                                                        alt=""
                                                        aria-hidden="true"
                                                        src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2718%27%20height=%2718%27/%3e"
                                                        style={{
                                                            display: isMobile ? "block" : "none",
                                                            maxWidth: "100%",
                                                            width: "initial",
                                                            height: "initial",
                                                            background: "none",
                                                            opacity: 1,
                                                            border: 0,
                                                            margin: 0,
                                                            padding: 0
                                                        }}
                                                    />
                                                </span>
                                                <img
                                                    alt="LOGO"
                                                    src="/build/web/igame-index-lobby-wm/img/new_provider_icon.webp" decoding="async"
                                                    data-nimg="intrinsic"
                                                    style={{
                                                        position: "absolute",
                                                        inset: 0,
                                                        boxSizing: "border-box",
                                                        padding: 0,
                                                        border: "none",
                                                        margin: "auto",
                                                        display: "block",
                                                        width: 0,
                                                        height: 0,
                                                        minWidth: "100%",
                                                        maxWidth: "100%",
                                                        minHeight: "100%",
                                                        maxHeight: "100%"
                                                    }}
                                                />
                                            </span>
                                        </span>
                                        <p className={isMobile ? "MuiTypography-root MuiTypography-body1 css-q9cbq3" : "MuiTypography-root MuiTypography-body1 css-12bx5xy"}>
                                            ค่ายเกมส์
                                        </p>
                                        <span className="MuiTouchRipple-root css-w0pj6f" />
                                    </button>
                                    <div className="MuiBox-root css-15ydtuu">
                                        {this.state.providorlist.map((listitem, i) => (
                                            <div className="zoom  MuiBox-root css-0">
                                                <a onClick={(e) => {
                                                    this.checkurl(e, "/game/list/" + listitem.partner, listitem)
                                                }}>
                                                    <div className={isMobile ? "MuiBox-root css-iu5b7h" : "MuiBox-root css-1fs2gpx"}>
                                                        <span
                                                            style={{
                                                                boxSizing: "border-box",
                                                                display: "inline-block",
                                                                overflow: "hidden",
                                                                width: "initial",
                                                                height: "initial",
                                                                background: "none",
                                                                opacity: 1,
                                                                border: 0,
                                                                margin: 0,
                                                                padding: 0,
                                                                position: "relative",
                                                                maxWidth: "100%"
                                                            }}
                                                        >
                                                            <span
                                                                style={{
                                                                    boxSizing: "border-box",
                                                                    display: "block",
                                                                    width: "initial",
                                                                    height: "initial",
                                                                    background: "none",
                                                                    opacity: 1,
                                                                    border: 0,
                                                                    margin: 0,
                                                                    padding: 0,
                                                                    maxWidth: "100%"
                                                                }}
                                                            >
                                                                <img
                                                                    alt=""
                                                                    aria-hidden="true"
                                                                    src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%27210%27%20height=%2771%27/%3e"
                                                                    style={{
                                                                        display: isMobile ? "none" : "block",
                                                                        maxWidth: "100%",
                                                                        width: "initial",
                                                                        height: "initial",
                                                                        background: "none",
                                                                        opacity: 1,
                                                                        border: 0,
                                                                        margin: 0,
                                                                        padding: 0
                                                                    }}
                                                                />
                                                                <img
                                                                    alt=""
                                                                    aria-hidden="true"
                                                                    src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2790%27%20height=%2730%27/%3e"
                                                                    style={{
                                                                        display: isMobile ? "block" : "none",
                                                                        maxWidth: "100%",
                                                                        width: "initial",
                                                                        height: "initial",
                                                                        background: "none",
                                                                        opacity: 1,
                                                                        border: 0,
                                                                        margin: 0,
                                                                        padding: 0
                                                                    }}
                                                                />
                                                            </span>
                                                            <img
                                                                alt="PROVIDER"
                                                                src={listitem.selectimg}
                                                                decoding="async"
                                                                data-nimg="intrinsic"
                                                                id={listitem.partner}
                                                                className={listitem.partner == this.state.partner ? "providerButton" : ""}
                                                                style={{
                                                                    borderRadius: 45,
                                                                    position: "absolute",
                                                                    inset: 0,
                                                                    boxSizing: "border-box",
                                                                    padding: 0,
                                                                    border: "none",
                                                                    margin: "auto",
                                                                    display: "block",
                                                                    width: 0,
                                                                    height: 0,
                                                                    minWidth: "100%",
                                                                    maxWidth: "100%",
                                                                    minHeight: "100%",
                                                                    maxHeight: "100%"
                                                                }}
                                                            />
                                                        </span>
                                                    </div>
                                                </a>
                                            </div>

                                        ))}
                                    </div>
                                </div>
                                <div className="MuiBox-root css-13wijbl">
                                    <div className="MuiBox-root css-owqwd5">
                                        <div className={isMobile ? "MuiBox-root css-8atqhb" : "MuiBox-root css-126n10b"}>
                                            <div className="MuiBox-root css-8atqhb">
                                                <div className={isMobile ? "MuiFormControl-root MuiFormControl-fullWidth MuiTextField-root css-164i1v6" : "MuiAutocomplete-root MuiAutocomplete-hasPopupIcon css-9v45ci"}>
                                                    <div className={isMobile ? "MuiAutocomplete-root MuiAutocomplete-hasPopupIcon css-9v45ci" : "MuiFormControl-root MuiFormControl-fullWidth MuiTextField-root css-19kdhbs"}>
                                                        <label
                                                            className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-sizeSmall MuiInputLabel-outlined MuiFormLabel-colorPrimary css-u9osun"
                                                            data-shrink="true"
                                                            htmlFor=":r41:"
                                                            id=":r41:-label"
                                                        >
                                                            ค้นหาเกมส์
                                                        </label>
                                                        <div className="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-fullWidth MuiInputBase-formControl MuiInputBase-sizeSmall MuiInputBase-adornedStart MuiInputBase-adornedEnd MuiAutocomplete-inputRoot css-1si533m">
                                                            <svg
                                                                className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1okqa6k"
                                                                focusable="false"
                                                                aria-hidden="true"
                                                                viewBox="0 0 24 24"
                                                                data-testid="SearchIcon"
                                                            >
                                                                <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
                                                            </svg>
                                                            <input
                                                                aria-invalid="false"
                                                                autoComplete="off"
                                                                name="txtsech"
                                                                id="txtsech"
                                                                type="text"
                                                                onChange={this.onChange}
                                                                className="MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputSizeSmall MuiInputBase-inputAdornedStart MuiInputBase-inputAdornedEnd MuiAutocomplete-input MuiAutocomplete-inputFocused css-1y3zh1"
                                                                aria-autocomplete="list"
                                                                aria-expanded="false"
                                                                autoCapitalize="none"
                                                                spellCheck="false"
                                                                role="combobox"
                                                                defaultValue=""
                                                            />
                                                            <div className="MuiAutocomplete-endAdornment css-2iz2x6">
                                                                <button
                                                                    className="MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeMedium MuiAutocomplete-popupIndicator css-uge3vf"
                                                                    tabIndex={-1}
                                                                    type="button"
                                                                    aria-label="Open"
                                                                    title="Open"
                                                                >
                                                                    <svg
                                                                        className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv"
                                                                        focusable="false"
                                                                        aria-hidden="true"
                                                                        viewBox="0 0 24 24"
                                                                        data-testid="ArrowDropDownIcon"
                                                                    >
                                                                        <path d="M7 10l5 5 5-5z" />
                                                                    </svg>
                                                                    <span className="MuiTouchRipple-root css-w0pj6f" />
                                                                </button>
                                                            </div>
                                                            <fieldset
                                                                aria-hidden="true"
                                                                className="MuiOutlinedInput-notchedOutline css-igs3ac"
                                                            >
                                                                <legend className="css-1in441m">
                                                                    <span>ค้นหาเกมส์</span>
                                                                </legend>
                                                            </fieldset>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={isMobile ? "MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-1 css-br1lvw" : "MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-1 css-1u6jyqf"}>
                                        {listitallgame.map((listitem, i) => (
                                            <div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-3 MuiGrid-grid-sm-3 MuiGrid-grid-md-3 MuiGrid-grid-lg-3 css-qaqqqn">
                                                <div className={isMobile ? "gameBox MuiBox-root css-u5zj0i" : "gameBox MuiBox-root css-nnhpf1"}>
                                                    {" "}
                                                    <span
                                                        style={{
                                                            boxSizing: "border-box",
                                                            display: "block",
                                                            overflow: "hidden",
                                                            width: "initial",
                                                            height: "initial",
                                                            background: "none",
                                                            opacity: 1,
                                                            border: 0,
                                                            margin: 0,
                                                            padding: 0,
                                                            position: "absolute",
                                                            inset: 0
                                                        }}
                                                    >
                                                        <img
                                                            alt="GAME"
                                                            sizes="100vw"
                                                            src={listitem.image}
                                                            decoding="async"
                                                            data-nimg="fill"
                                                            className="gameImg"
                                                            style={{
                                                                borderRadius: 20,
                                                                position: "absolute",
                                                                inset: 0,
                                                                boxSizing: "border-box",
                                                                padding: 0,
                                                                border: "none",
                                                                margin: "auto",
                                                                display: "block",
                                                                width: 0,
                                                                height: 0,
                                                                minWidth: "100%",
                                                                maxWidth: "100%",
                                                                minHeight: "100%",
                                                                maxHeight: "100%"
                                                            }}
                                                        />
                                                    </span>
                                                    <a
                                                        onClick={(e) => this.redeemcheck(e, listitem)}
                                                    >

                                                        <div className={isMobile ? "gameBoxButton MuiBox-root css-10y872b" : "gameBoxButton MuiBox-root css-cqk145"}>
                                                            <p className={isMobile ? "MuiTypography-root MuiTypography-body1 css-92a4h4" : "MuiTypography-root MuiTypography-body1 css-bjeqy7"}>
                                                                เล่นเกมส์
                                                            </p>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        ))}



                                    </div>
                                </div>
                            </div>

                        </div>
                        <div style={{ display: tabselect == 7 ? "" : "none" }} className="MuiBox-root css-0" >
                            <div className="MuiContainer-root MuiContainer-maxWidthLg css-1qsxih2">
                                <div className="MuiBox-root css-1gfoyof">
                                    <p className="MuiTypography-root MuiTypography-body1 css-1expvne">
                                        โปรโมชั่น
                                    </p>
                                    <div className="MuiBox-root css-crbbbp">
                                        {prolistx.map(prolists => (
                                            <a
                                                href="#0"
                                                style={{ cursor: "pointer" }}
                                                onClick={(e) => this.checkpro(e, prolists.no)}
                                            >
                                                <div className="MuiBox-root css-1p5pvtm">
                                                    <div className="MuiBox-root css-1goxm6q">
                                                        <div className="MuiBox-root css-10m9nup">
                                                            <img
                                                                src={prolists.linkpicture}
                                                                alt="โปรโมชั่น"
                                                                style={{
                                                                    objectFit: "cover",
                                                                    objectPosition: "center center",
                                                                    width: 320,
                                                                    height: 320,
                                                                    borderRadius: 10
                                                                }}
                                                            />
                                                        </div>
                                                    </div>

                                                </div>
                                            </a>
                                        ))}
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div style={{ display: tabselect == 22 ? "" : "none" }} className="MuiBox-root css-0" >
                            <div className="MuiBox-root css-1a2ylu7">
                                <div className="MuiBox-root css-kipvlc" style={{ display: this.state.finish === 0 ? "" : "none" }}>
                                    <span
                                        style={{
                                            boxSizing: "border-box",
                                            display: "inline-block",
                                            overflow: "hidden",
                                            width: "initial",
                                            height: "initial",
                                            background: "none",
                                            opacity: 1,
                                            position: "relative",
                                            maxWidth: "100%"
                                        }}
                                    >
                                        <span
                                            style={{
                                                boxSizing: "border-box",
                                                display: "block",
                                                width: "initial",
                                                height: "initial",
                                                background: "none",
                                            }}
                                        >
                                            <img
                                                alt=""
                                                aria-hidden="true"
                                                src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%27275%27%20height=%2790%27/%3e"
                                                style={{
                                                    display: "block",
                                                    width: "initial",
                                                    height: "initial",
                                                    background: "none",
                                                    opacity: 1,
                                                }}
                                            />
                                        </span>
                                        <img
                                            alt="LOGO"
                                            src={this.state.logoimg}
                                            decoding="async"
                                            data-nimg="intrinsic"
                                            style={{
                                                position: "absolute",
                                                inset: 0,
                                                boxSizing: "border-box",
                                                padding: 0,
                                                border: "none",
                                                margin: "auto",
                                                display: "block",
                                                width: 0,
                                                height: 0,
                                                minWidth: "100%",
                                                maxWidth: "100%",
                                                minHeight: "150%",
                                                maxHeight: "100%"
                                            }}
                                        />
                                    </span>
                                    <p className="MuiTypography-root MuiTypography-body1 css-1n6j6u9">
                                        สมัครสมาชิก
                                    </p>
                                    <div className="MuiBox-root css-8atqhb" style={{ display: this.state.stateregis === 1 ? "" : "none" }}>
                                        <div className="MuiFormControl-root css-13sljp9" />
                                        <div className="MuiFormControl-root css-1fab3uj">
                                            <p className="MuiTypography-root MuiTypography-body1 css-m73dwr">
                                                กรุณากรอกเบอร์โทรศัพท์ของท่าน
                                            </p>
                                            <div className="MuiFormControl-root MuiTextField-root css-1ihe2hn">
                                                <label
                                                    className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-outlined MuiFormLabel-colorPrimary css-u9osun"
                                                    data-shrink="true"
                                                    htmlFor=":r16:"
                                                    id=":r16:-label"
                                                >
                                                    เบอร์โทรศัพท์
                                                </label>
                                                <div className="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-formControl MuiInputBase-adornedStart css-1ufn0jl">
                                                    <div className="MuiInputAdornment-root MuiInputAdornment-positionStart MuiInputAdornment-outlined MuiInputAdornment-sizeMedium css-1a6giau">
                                                        <span className="notranslate">​</span>
                                                        <svg
                                                            className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-gg5vco"
                                                            focusable="false"
                                                            aria-hidden="true"
                                                            viewBox="0 0 24 24"
                                                            data-testid="PersonIcon"
                                                        >
                                                            <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" />
                                                        </svg>
                                                    </div>
                                                    <input
                                                        onChange={this.onChangex}
                                                        type="tel"
                                                        id="phoneNumber"
                                                        autoComplete="off"
                                                        name="phoneNumber"
                                                        aria-invalid="false"
                                                        placeholder="กรุณากรอกเบอร์โทรศัพท์"
                                                        className="MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputAdornedStart css-1ixds2g"
                                                        defaultValue=""
                                                    />
                                                    <fieldset
                                                        aria-hidden="true"
                                                        className="MuiOutlinedInput-notchedOutline css-igs3ac"
                                                    >
                                                        <legend className="css-1in441m">
                                                            <span>เบอร์โทรศัพท์</span>
                                                        </legend>
                                                    </fieldset>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="MuiBox-root css-k1z727">
                                            <a
                                                ref={(input) => { this.nameInput = input; }}
                                                onClick={(e) => {
                                                    this.nextstep(e, 1)
                                                }}
                                                className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeLarge MuiButton-containedSizeLarge css-18sq5gr"
                                                tabIndex={0}
                                                type="submit"
                                            >
                                                <span>
                                                    <p className="MuiTypography-root MuiTypography-body1 css-1o9b57b">
                                                        ดำเนินการต่อ
                                                    </p>
                                                </span>
                                                <span className="MuiTouchRipple-root css-w0pj6f" />
                                            </a>
                                        </div>
                                    </div>
                                    <div className="MuiBox-root css-8atqhb" style={{ display: this.state.stateregis === 2 ? "" : "none" }}>
                                        <div className="MuiFormControl-root css-13sljp9" />
                                        <div className="MuiFormControl-root css-1fab3uj">
                                            <p className="MuiTypography-root MuiTypography-body1 css-nzaaub">
                                                กรุณากรอกรหัสผ่านของท่าน
                                            </p>
                                            <div className="MuiFormControl-root MuiTextField-root css-1ihe2hn">
                                                <div className="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-formControl MuiInputBase-adornedEnd css-1bn53lx">
                                                    <input
                                                        aria-invalid="false"
                                                        onChange={this.onChangex}
                                                        type={!this.state.showpass ? "password" : "text"}
                                                        id="password"
                                                        autoComplete="off"
                                                        name="password"
                                                        placeholder="กรุณาใส่รหัสผ่าน"
                                                        className="MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputAdornedEnd css-1uvydh2"
                                                        defaultValue=""
                                                    />
                                                    <div className="MuiInputAdornment-root MuiInputAdornment-positionEnd MuiInputAdornment-outlined MuiInputAdornment-sizeMedium css-1nvf7g0">
                                                        <button
                                                            className="MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeMedium css-1yxmbwk"
                                                            tabIndex={0}
                                                            type="button"
                                                            style={{ color: "grey" }}
                                                            onClick={(e) => this.changepass(e)}
                                                        >
                                                            <svg
                                                                className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-wx6vbk"
                                                                focusable="false"
                                                                aria-hidden="true"
                                                                viewBox="0 0 24 24"
                                                                data-testid="VisibilityIcon"
                                                            >
                                                                <path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z" />
                                                            </svg>
                                                            <span className="MuiTouchRipple-root css-w0pj6f" />
                                                        </button>
                                                    </div>
                                                    <fieldset
                                                        aria-hidden="true"
                                                        className="MuiOutlinedInput-notchedOutline css-igs3ac"
                                                    >
                                                        <legend className="css-1ftyaf0">
                                                            <span>รหัสผ่าน</span>
                                                        </legend>
                                                    </fieldset>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="MuiBox-root css-k1z727">
                                            <a
                                                onClick={(e) => {
                                                    this.nextstep(e, 2)
                                                }}
                                                role="button"
                                                className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeLarge MuiButton-containedSizeLarge css-18sq5gr"
                                                tabIndex={0}
                                                type="submit"
                                            >
                                                <span>
                                                    <p className="MuiTypography-root MuiTypography-body1 css-1o9b57b">
                                                        ดำเนินการต่อ
                                                    </p>
                                                </span>
                                                <span className="MuiTouchRipple-root css-w0pj6f" />
                                            </a>
                                        </div>
                                    </div>
                                    <div className="MuiBox-root css-8atqhb" style={{ display: this.state.stateregis === 3 ? "" : "none" }}>
                                        <div className="MuiFormControl-root css-13sljp9" />
                                        <p className="MuiTypography-root MuiTypography-body1 css-1vccmfu">
                                            กรุณาเลือกธนาคารของท่าน
                                        </p>
                                        <div className="MuiFormControl-root css-ljodi6">
                                            <label
                                                className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-outlined MuiFormLabel-colorPrimary Mui-focused css-9p9a3m"
                                                data-shrink="true"
                                            >
                                                ธนาคาร
                                            </label>
                                            <div
                                                required=""
                                                className="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-formControl css-1kt567w"
                                            >
                                                <div
                                                    tabIndex={0}
                                                    role="button"
                                                    aria-expanded={isPopupOpen} // Dynamically set aria-expanded
                                                    aria-haspopup="listbox"
                                                    className="MuiSelect-select MuiSelect-outlined MuiInputBase-input MuiOutlinedInput-input css-qiwgdb"
                                                    onClick={this.togglePopup} // Toggle popup on click
                                                >
                                                    {selectedItem ? (
                                                        <div className="MuiBox-root css-k008qs">
                                                            <span
                                                                style={{
                                                                    boxSizing: "border-box",
                                                                    display: "inline-block",
                                                                    overflow: "hidden",
                                                                    width: "initial",
                                                                    height: "initial",
                                                                    background: "none",
                                                                    opacity: 1,
                                                                    border: 0,
                                                                    margin: 0,
                                                                    padding: 0,
                                                                    position: "relative",
                                                                    maxWidth: "100%",
                                                                }}
                                                            >                   <span
                                                                style={{
                                                                    boxSizing: "border-box",
                                                                    display: "block",
                                                                    width: "initial",
                                                                    height: "initial",
                                                                    background: "none",
                                                                    opacity: 1,
                                                                    border: 0,
                                                                    margin: 0,
                                                                    padding: 0,
                                                                    maxWidth: "100%",
                                                                }}
                                                            >
                                                                    <img
                                                                        alt=""
                                                                        aria-hidden="true"
                                                                        src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2730%27%20height=%2730%27/%3e"
                                                                        style={{
                                                                            display: "block",
                                                                            maxWidth: "100%",
                                                                            width: "initial",
                                                                            height: "initial",
                                                                            background: "none",
                                                                            opacity: 1,
                                                                            border: 0,
                                                                            margin: 0,
                                                                            padding: 0,
                                                                        }}
                                                                    />
                                                                </span>
                                                                <img
                                                                    alt={selectedItem.name}
                                                                    src={selectedItem.imgSrc}
                                                                    decoding="async"
                                                                    style={{
                                                                        position: "absolute",
                                                                        inset: 0,
                                                                        boxSizing: "border-box",
                                                                        padding: 0,
                                                                        border: "none",
                                                                        margin: "auto",
                                                                        display: "block",
                                                                        width: 0,
                                                                        height: 0,
                                                                        minWidth: "100%",
                                                                        maxWidth: "100%",
                                                                        minHeight: "100%",
                                                                        maxHeight: "100%",
                                                                    }}
                                                                />

                                                            </span>
                                                            <div className="MuiBox-root css-ogoe61">{selectedItem.name}</div>
                                                        </div>
                                                    ) : (
                                                        <span className="notranslate">​</span>
                                                    )}
                                                </div>
                                                <svg
                                                    className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSelect-icon MuiSelect-iconOutlined css-1636szt"
                                                    focusable="false"
                                                    aria-hidden="true"
                                                    viewBox="0 0 24 24"
                                                    data-testid="ArrowDropDownIcon"
                                                >
                                                    <path d="M7 10l5 5 5-5z" />
                                                </svg>
                                                <fieldset
                                                    aria-hidden="true"
                                                    className="MuiOutlinedInput-notchedOutline css-igs3ac"
                                                >
                                                    <legend className="css-1ftyaf0">
                                                        <span>ธนาคาร&nbsp;*</span>
                                                    </legend>
                                                </fieldset>
                                            </div>

                                            {/* Popup list */}
                                            {isPopupOpen && (
                                                <div className="popup-list">
                                                    <ul
                                                        className="MuiList-root MuiList-padding MuiMenu-list css-r8u8y9"
                                                        role="listbox"
                                                        tabIndex={-1}
                                                    >
                                                        {menuItems.map((item) => (
                                                            <li
                                                                key={item.id}
                                                                className={item.classes}
                                                                tabIndex={0}
                                                                role="option"
                                                                aria-selected={selectedItem?.id === item.id}
                                                                onClick={() => this.handleSelectItem(item)} // Select item
                                                            >
                                                                <div className="MuiBox-root css-k008qs">
                                                                    <span
                                                                        style={{
                                                                            boxSizing: "border-box",
                                                                            display: "inline-block",
                                                                            overflow: "hidden",
                                                                            width: "initial",
                                                                            height: "initial",
                                                                            background: "none",
                                                                            opacity: 1,
                                                                            border: 0,
                                                                            margin: 0,
                                                                            padding: 0,
                                                                            position: "relative",
                                                                            maxWidth: "100%",
                                                                        }}
                                                                    >
                                                                        <span
                                                                            style={{
                                                                                boxSizing: "border-box",
                                                                                display: "block",
                                                                                width: "initial",
                                                                                height: "initial",
                                                                                background: "none",
                                                                                opacity: 1,
                                                                                border: 0,
                                                                                margin: 0,
                                                                                padding: 0,
                                                                                maxWidth: "100%",
                                                                            }}
                                                                        >
                                                                            <img
                                                                                alt=""
                                                                                aria-hidden="true"
                                                                                src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2730%27%20height=%2730%27/%3e"
                                                                                style={{
                                                                                    display: "block",
                                                                                    maxWidth: "100%",
                                                                                    width: "initial",
                                                                                    height: "initial",
                                                                                    background: "none",
                                                                                    opacity: 1,
                                                                                    border: 0,
                                                                                    margin: 0,
                                                                                    padding: 0,
                                                                                }}
                                                                            />
                                                                        </span>
                                                                        <img
                                                                            alt={item.name}
                                                                            src={item.imgSrc}
                                                                            decoding="async"
                                                                            style={{
                                                                                position: "absolute",
                                                                                inset: 0,
                                                                                boxSizing: "border-box",
                                                                                padding: 0,
                                                                                border: "none",
                                                                                margin: "auto",
                                                                                display: "block",
                                                                                width: 0,
                                                                                height: 0,
                                                                                minWidth: "100%",
                                                                                maxWidth: "100%",
                                                                                minHeight: "100%",
                                                                                maxHeight: "100%",
                                                                            }}
                                                                        />
                                                                    </span>
                                                                    <div className="MuiBox-root css-ogoe61">{item.name}</div>
                                                                </div>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            )}
                                        </div>

                                        <div className="MuiFormControl-root css-3p8s6t">
                                            <div className="MuiFormControl-root MuiTextField-root css-1kp0jwp">
                                                <div className="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-formControl css-1v4ccyo">
                                                    <input
                                                        aria-invalid="false"
                                                        onChange={this.onChangex}
                                                        id="accnumbertxt"
                                                        inputMode="text"
                                                        name="accnumbertxt"
                                                        placeholder="กรุณาใส่เลขบัญชีธนาคาร"
                                                        className="MuiInputBase-input MuiOutlinedInput-input css-1x5jdmq"
                                                        defaultValue=""
                                                    />
                                                    <fieldset
                                                        aria-hidden="true"
                                                        className="MuiOutlinedInput-notchedOutline css-igs3ac"
                                                    >
                                                        <legend className="css-1ftyaf0">
                                                            <span>เลขบัญชีธนาคาร</span>
                                                        </legend>
                                                    </fieldset>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="MuiFormControl-root css-3p8s6t" style={{ display: this.state.trueaccount ? "none" : "" }}>
                                            <div className="MuiFormControl-root MuiTextField-root css-1kp0jwp" >
                                                <div className="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-formControl css-1v4ccyo">
                                                    <input
                                                        aria-invalid="false"
                                                        onChange={this.onChangex}
                                                        id="fname"
                                                        value={this.state.fname}
                                                        name="fname"
                                                        placeholder="กรุณาใส่ชื่อ-นามสกุล"
                                                        className="MuiInputBase-input MuiOutlinedInput-input css-1x5jdmq"
                                                        defaultValue=""
                                                    />
                                                    <fieldset
                                                        aria-hidden="true"
                                                        className="MuiOutlinedInput-notchedOutline css-igs3ac"
                                                    >
                                                        <legend className="css-1ftyaf0">
                                                            <span>ชื่อบัญชีธนาคาร</span>
                                                        </legend>
                                                    </fieldset>
                                                </div>
                                            </div>
                                        </div>
                                        <p className="MuiTypography-root MuiTypography-body1 css-1n647cb">
                                            **ต้องใช้บัญชีที่สมัครในการฝากเงินเท่านั้น**
                                        </p>
                                        <div className="MuiBox-root css-k1z727">
                                            <a
                                                onClick={(e) => {
                                                    this.nextstep(e, 25)
                                                }}
                                                className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeLarge MuiButton-containedSizeLarge css-18sq5gr"
                                                tabIndex={0}
                                                type="submit"
                                            >
                                                <span>
                                                    <p className="MuiTypography-root MuiTypography-body1 css-1o9b57b">
                                                        สร้างบัญชี
                                                    </p>
                                                </span>
                                                <span className="MuiTouchRipple-root css-w0pj6f" />
                                            </a>
                                        </div>
                                    </div>

                                    <div className="text-divider"
                                        style={{ width: "100%", color: "white", fontFamily: "kanit" }}
                                    >
                                        หรือ
                                    </div>
                                    <p className="MuiTypography-root MuiTypography-body1 css-q757p0">
                                        มีบัญชีอยู่แล้ว
                                        <a onClick={(e) => {
                                            this.checkurl(e, "/")
                                        }}>
                                            <span style={{ color: "rgb(249, 189, 0)", cursor: "pointer", marginLeft: 4 }} >
                                                เข้าสู่ระบบ
                                            </span>
                                        </a>
                                    </p>
                                    <div className="MuiBox-root css-10hgpyj" style={{ display: this.state.stateregis === 2 || this.state.stateregis === 3 ? "" : "none" }}>
                                        <a
                                            onClick={(e) => {
                                                this.nextstep(e, 11)
                                            }}
                                            role="button" >
                                            <svg
                                                className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv"
                                                focusable="false"
                                                aria-hidden="true"
                                                viewBox="0 0 24 24"
                                                data-testid="ArrowBackIosNewIcon"
                                            >
                                                <path d="M17.77 3.77 16 2 6 12l10 10 1.77-1.77L9.54 12z" />
                                            </svg>
                                            ย้อนกลับ
                                        </a>
                                    </div>
                                </div>
                                <div className="MuiBox-root css-1hfpf9" style={{ display: this.state.finish === 1 ? "" : "none" }}>
                                    {/* <div className="MuiBox-root css-yfmsqm"> */}

                                    <span
                                        style={{
                                            boxSizing: "border-box",
                                            display: "inline-block",
                                            overflow: "hidden",
                                            width: "initial",
                                            height: "initial",
                                            background: "none",
                                            opacity: 1,
                                            position: "relative",
                                            maxWidth: "100%"
                                        }}
                                    >
                                        <span
                                            style={{
                                                boxSizing: "border-box",
                                                display: "block",
                                                width: "initial",
                                                height: "initial",
                                                background: "none",
                                            }}
                                        >
                                            <img
                                                alt=""
                                                aria-hidden="true"
                                                src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%27275%27%20height=%2790%27/%3e"
                                                style={{
                                                    display: "block",
                                                    width: "initial",
                                                    height: "initial",
                                                    background: "none",
                                                    opacity: 1,
                                                }}
                                            />
                                        </span>
                                        <img
                                            alt="LOGO"
                                            src={this.state.logoimg}
                                            decoding="async"
                                            data-nimg="intrinsic"
                                            style={{
                                                position: "absolute",
                                                inset: 0,
                                                boxSizing: "border-box",
                                                padding: 0,
                                                border: "none",
                                                margin: "auto",
                                                display: "block",
                                                width: 0,
                                                height: 0,
                                                minWidth: "100%",
                                                maxWidth: "100%",
                                                minHeight: "150%",
                                                maxHeight: "100%"
                                            }}
                                        />
                                    </span>
                                    <p className="MuiTypography-root MuiTypography-body1 css-2dffbj">
                                        สมัครสมาชิกสำเร็จ
                                    </p>
                                    <div className="MuiBox-root css-8atqhb">
                                        <div className="MuiFormControl-root css-13sljp9" />
                                        <p className="MuiTypography-root MuiTypography-body1 css-8j6uui">
                                            ขอบคุณสำหรับการสมัคร คุณสามารถใช้
                                        </p>
                                        <p className="MuiTypography-root MuiTypography-body1 css-m73dwr">
                                            ชื่อเข้าระบบ และ รหัสผ่านด้านล่างที่ตั้งไว้เพื่อเข้าสู่ระบบ
                                        </p>
                                        <div className="MuiBox-root css-14x47vi">
                                            <button
                                                className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeLarge MuiButton-containedSizeLarge css-1u31813"
                                                tabIndex={0}
                                                type="button"
                                            >
                                                <span
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        alignItems: "flex-start"
                                                    }}
                                                >
                                                    <p className="MuiTypography-root MuiTypography-body1 css-19xp9hg">
                                                        ชื่อเข้าสู่ระบบ : {this.state.phoneNumber}
                                                    </p>
                                                    <p className="MuiTypography-root MuiTypography-body1 css-19xp9hg">
                                                        รหัสผ่าน :  {this.state.password}
                                                    </p>
                                                </span>
                                                <span className="MuiTouchRipple-root css-w0pj6f" />
                                            </button>
                                        </div>
                                        <div className="MuiBox-root css-tfvdse">
                                            <button
                                                onClick={(e) => {
                                                    this.login(e)
                                                }}
                                                className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeLarge MuiButton-containedSizeLarge css-1280ve3"
                                                tabIndex={0}
                                                type="button"
                                            >
                                                <span>
                                                    <p className="MuiTypography-root MuiTypography-body1 css-p47uff">
                                                        เข้าสู่ระบบ
                                                    </p>
                                                </span>
                                                <span className="MuiTouchRipple-root css-w0pj6f" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                {/* </div> */}


                            </div>

                        </div>
                    </main>
                    <div className="MuiToolbar-root MuiToolbar-gutters MuiToolbar-regular css-d2eobw" />
                    <div style={{ display: tabselect !== null ? "" : "none" }} className="MuiBox-root css-10pci0o">
                        {/* <div className="MuiBox-root css-1gb624v">
                            <div className="MuiBox-root css-0">
                                <span
                                    style={{
                                        boxSizing: "border-box",
                                        display: "inline-block",
                                        overflow: "hidden",
                                        width: "initial",
                                        height: "initial",
                                        background: "none",
                                        opacity: 1,
                                        border: 0,
                                        margin: 0,
                                        padding: 0,
                                        position: "relative",
                                        maxWidth: "100%"
                                    }}
                                >
                                    <span
                                        style={{
                                            boxSizing: "border-box",
                                            display: "block",
                                            width: "initial",
                                            height: "initial",
                                            background: "none",
                                            opacity: 1,
                                            border: 0,
                                            margin: 0,
                                            padding: 0,
                                            maxWidth: "100%"
                                        }}
                                    >
                                        <img
                                            alt=""
                                            aria-hidden="true"
                                            src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%27186%27%20height=%2751%27/%3e"
                                            style={{
                                                display: "block",
                                                maxWidth: "100%",
                                                width: "initial",
                                                height: "initial",
                                                background: "none",
                                                opacity: 1,
                                                border: 0,
                                                margin: 0,
                                                padding: 0
                                            }}
                                        />
                                    </span>
                                    <img
                                        alt="logo"
                                        src="/build/web/igame-index-lobby-wm/img/banner_1.webp"
                                        decoding="async"
                                        data-nimg="intrinsic"
                                        style={{
                                            position: "absolute",
                                            inset: 0,
                                            boxSizing: "border-box",
                                            padding: 0,
                                            border: "none",
                                            margin: "auto",
                                            display: "block",
                                            width: 0,
                                            height: 0,
                                            minWidth: "100%",
                                            maxWidth: "100%",
                                            minHeight: "100%",
                                            maxHeight: "100%"
                                        }}
                                    />
                                </span>
                            </div>
                            <div className="MuiBox-root css-0" style={{ marginLeft: 20 }}>
                                <span
                                    style={{
                                        boxSizing: "border-box",
                                        display: "inline-block",
                                        overflow: "hidden",
                                        width: "initial",
                                        height: "initial",
                                        background: "none",
                                        opacity: 1,
                                        border: 0,
                                        margin: 0,
                                        padding: 0,
                                        position: "relative",
                                        maxWidth: "100%"
                                    }}
                                >
                                    <span
                                        style={{
                                            boxSizing: "border-box",
                                            display: "block",
                                            width: "initial",
                                            height: "initial",
                                            background: "none",
                                            opacity: 1,
                                            border: 0,
                                            margin: 0,
                                            padding: 0,
                                            maxWidth: "100%"
                                        }}
                                    >
                                        <img
                                            alt=""
                                            aria-hidden="true"
                                            src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2791%27%20height=%2742%27/%3e"
                                            style={{
                                                display: "block",
                                                maxWidth: "100%",
                                                width: "initial",
                                                height: "initial",
                                                background: "none",
                                                opacity: 1,
                                                border: 0,
                                                margin: 0,
                                                padding: 0
                                            }}
                                        />
                                    </span>
                                    <img
                                        alt="logo"
                                        src="/build/web/igame-index-lobby-wm/img/banner_2.webp"
                                        decoding="async"
                                        data-nimg="intrinsic"
                                        style={{
                                            position: "absolute",
                                            inset: 0,
                                            boxSizing: "border-box",
                                            padding: 0,
                                            border: "none",
                                            margin: "auto",
                                            display: "block",
                                            width: 0,
                                            height: 0,
                                            minWidth: "100%",
                                            maxWidth: "100%",
                                            minHeight: "100%",
                                            maxHeight: "100%"
                                        }}
                                    />
                                </span>
                            </div>
                            <div className="MuiBox-root css-0" style={{ marginLeft: 20 }}>
                                <span
                                    style={{
                                        boxSizing: "border-box",
                                        display: "inline-block",
                                        overflow: "hidden",
                                        width: "initial",
                                        height: "initial",
                                        background: "none",
                                        opacity: 1,
                                        border: 0,
                                        margin: 0,
                                        padding: 0,
                                        position: "relative",
                                        maxWidth: "100%"
                                    }}
                                >
                                    <span
                                        style={{
                                            boxSizing: "border-box",
                                            display: "block",
                                            width: "initial",
                                            height: "initial",
                                            background: "none",
                                            opacity: 1,
                                            border: 0,
                                            margin: 0,
                                            padding: 0,
                                            maxWidth: "100%"
                                        }}
                                    >
                                        <img
                                            alt=""
                                            aria-hidden="true"
                                            src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2766%27%20height=%2751%27/%3e"
                                            style={{
                                                display: "block",
                                                maxWidth: "100%",
                                                width: "initial",
                                                height: "initial",
                                                background: "none",
                                                opacity: 1,
                                                border: 0,
                                                margin: 0,
                                                padding: 0
                                            }}
                                        />
                                    </span>
                                    <img
                                        alt="logo"
                                        src="/build/web/igame-index-lobby-wm/img/banner_3.webp"
                                        decoding="async"
                                        data-nimg="intrinsic"
                                        style={{
                                            position: "absolute",
                                            inset: 0,
                                            boxSizing: "border-box",
                                            padding: 0,
                                            border: "none",
                                            margin: "auto",
                                            display: "block",
                                            width: 0,
                                            height: 0,
                                            minWidth: "100%",
                                            maxWidth: "100%",
                                            minHeight: "100%",
                                            maxHeight: "100%"
                                        }}
                                    />
                                </span>
                            </div>
                        </div> */}
                        <div className="MuiBox-root css-he5ki4" >
                            <div className="MuiBox-root css-1caud2s">
                                <div className="MuiBox-root css-sohav1">
                                    <span
                                        style={{
                                            boxSizing: "border-box",
                                            display: "inline-block",
                                            overflow: "hidden",
                                            width: "initial",
                                            height: "initial",
                                            background: "none",
                                            opacity: 1,
                                            border: 0,
                                            margin: 0,
                                            padding: 0,
                                            position: "relative",
                                            maxWidth: "100%"
                                        }}
                                    >
                                        <span
                                            style={{
                                                boxSizing: "border-box",
                                                display: "block",
                                                width: "initial",
                                                height: "initial",
                                                background: "none",
                                                opacity: 1,
                                                border: 0,
                                                margin: 0,
                                                padding: 0,
                                                maxWidth: "100%"
                                            }}
                                        >
                                            <img
                                                alt=""
                                                aria-hidden="true"
                                                src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%27140%27%20height=%2750%27/%3e"
                                                style={{
                                                    display: "block",
                                                    maxWidth: "100%",
                                                    width: "initial",
                                                    height: "initial",
                                                    background: "none",
                                                    opacity: 1,
                                                    border: 0,
                                                    margin: 0,
                                                    padding: 0
                                                }}
                                            />
                                        </span>
                                        <img
                                            alt="logo"

                                            src={this.state.logoimg}
                                            decoding="async"
                                            data-nimg="intrinsic"
                                            style={{
                                                position: "absolute",
                                                inset: 0,
                                                boxSizing: "border-box",
                                                padding: 0,
                                                border: "none",
                                                margin: "auto",
                                                display: "block",
                                                width: 0,
                                                height: 0,
                                                minWidth: "100%",
                                                maxWidth: "100%",
                                                minHeight: "150%",
                                                maxHeight: "100%",
                                                objectFit: "contain"
                                            }}
                                        />
                                    </span>
                                </div>
                                <div className="MuiBox-root css-1wrv3o0">
                                    <p className="MuiTypography-root MuiTypography-body1 css-pwaqkg">
                                        Version 1.0.0 Copyright © 2024 Supported by {this.state.webname}  สล็อตออนไลน์
                                        ระบบออโต้ทันสมัย ฝาก – ถอน ภายใน 3 วิ
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="MuiBox-root css-gds6h3" style={{ display: this.state.addhome && isMobile ? "" : "none" }} id="ios-install-popup" role="dialog">
                        <div >
                            <span
                                style={{
                                    boxSizing: "border-box",
                                    display: "inline-block",
                                    overflow: "hidden",
                                    width: "initial",
                                    height: "initial",
                                    background: "none",
                                    opacity: 1,
                                    border: 0,
                                    margin: 0,
                                    padding: 0,
                                    position: "relative",
                                    maxWidth: "100%"
                                }}
                            >
                                <span
                                    style={{
                                        boxSizing: "border-box",
                                        display: "block",
                                        width: "initial",
                                        height: "initial",
                                        background: "none",
                                        opacity: 1,
                                        border: 0,
                                        margin: 0,
                                        padding: 0,
                                        maxWidth: "100%"
                                    }}
                                >
                                    <img
                                        alt=""
                                        aria-hidden="true"
                                        src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2760%27%20height=%2760%27/%3e"
                                        style={{
                                            display: "block",
                                            maxWidth: "100%",
                                            width: "initial",
                                            height: "initial",
                                            background: "none",
                                            opacity: 1,
                                            border: 0,
                                            margin: 0,
                                            padding: 0
                                        }}
                                    />
                                </span>
                                <img
                                    alt="LOGO"
                                    src="https://cdn.javisx.com/image/df15ccc6-1031-499d-9bc2-f6011fdb8f73.jpg"
                                    decoding="async"
                                    data-nimg="intrinsic"
                                    //  srcSet="/_next/image?url=https%3A%2F%2Fd12lxcfs2l5om8.cloudfront.net%2Fbbtenyu%2Fwebimages%2Fpwa_logo%2Fbbtenyu.png&w=64&q=75 1x, /_next/image?url=https%3A%2F%2Fd12lxcfs2l5om8.cloudfront.net%2Fbbtenyu%2Fwebimages%2Fpwa_logo%2Fbbtenyu.png&w=128&q=75 2x"
                                    style={{
                                        position: "absolute",
                                        inset: 0,
                                        boxSizing: "border-box",
                                        padding: 0,
                                        border: "none",
                                        margin: "auto",
                                        display: "block",
                                        width: 0,
                                        height: 0,
                                        minWidth: "100%",
                                        maxWidth: "100%",
                                        minHeight: "100%",
                                        maxHeight: "100%",
                                        objectFit: "contain"
                                    }}
                                />

                                <noscript />
                            </span>
                            <p
                                role="button" onClick={(e) => {
                                    this.setState({
                                        modalpopup: true,
                                        addhome: false

                                    });
                                }}
                                style={{
                                    fontFamily: "ks",
                                    fontSize: 18,
                                    padding: 10,
                                    letterSpacing: "0.2px",
                                    lineHeight: "0.7"
                                }}
                            >
                                เพิ่มแอพพลิเคชั่น Jga898 Game ไปยังหน้าแรก
                                <br />
                                <span style={{ fontSize: 14 }}>
                                    Tap on{" "}
                                    <svg
                                        className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-gc172t"
                                        focusable="false"
                                        aria-hidden="true"
                                        viewBox="0 0 24 24"
                                        data-testid="IosShareIcon"
                                    >
                                        <path d="m16 5-1.42 1.42-1.59-1.59V16h-1.98V4.83L9.42 6.42 8 5l4-4 4 4zm4 5v11c0 1.1-.9 2-2 2H6c-1.11 0-2-.9-2-2V10c0-1.11.89-2 2-2h3v2H6v11h12V10h-3V8h3c1.1 0 2 .89 2 2z" />
                                    </svg>{" "}
                                    and select
                                </span>
                                <span style={{ color: "rgb(56, 133, 130)" }}> Add to Home Screen</span>
                            </p>
                            <div className="triangle-bbJga88 MuiBox-root css-6gjq7r" />
                            <div
                                role="button" onClick={(e) => {
                                    this.setState({
                                        addhome: false,
                                    });
                                }}
                                style={{
                                    position: "absolute",
                                    top: 2,
                                    right: 2,
                                    width: 20,
                                    height: 20,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    cursor: "pointer"
                                }}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-ezug14"
                                    focusable="false"
                                    aria-hidden="true"
                                    viewBox="0 0 24 24"
                                    width="24"
                                    height="24"
                                >
                                    <path d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                                </svg>
                            </div>
                        </div>
                    </div>

                    <header className="MuiPaper-root MuiPaper-elevation MuiPaper-elevation4 MuiAppBar-root MuiAppBar-colorPrimary MuiAppBar-positionFixed mui-fixed css-1xul1co" style={{ display: isMobile && (tabselect == 0 || tabselect == 1 || tabselect == 2 || tabselect == 3 || tabselect == 4 || tabselect == 7) ? "" : "none" }} >
                        <div className="MuiBox-root css-1bnunrr ">
                            <div className="MuiBox-root css-1gx990s">
                                <a role="button" onClick={(e) => {
                                    this.checkurl(e, "/")
                                }}>
                                    <span
                                        style={{
                                            boxSizing: "border-box",
                                            display: "inline-block",
                                            overflow: "hidden",
                                            width: "initial",
                                            height: "initial",
                                            background: "none",
                                            opacity: 1,
                                            border: 0,
                                            margin: 0,
                                            padding: 0,
                                            maxWidth: "100%"
                                        }}
                                    >
                                        <span
                                            style={{
                                                boxSizing: "border-box",
                                                display: "block",
                                                width: "initial",
                                                height: "initial",
                                                background: "none",
                                                opacity: 1,
                                                border: 0,
                                                margin: 0,
                                                padding: 0,
                                                maxWidth: "100%"
                                            }}
                                        >
                                            <img
                                                alt=""
                                                aria-hidden="true"
                                                src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2765%27%20height=%2765%27/%3e"
                                                style={{
                                                    display: "block",
                                                    maxWidth: "100%",
                                                    width: "initial",
                                                    height: "initial",
                                                    background: "none",
                                                    opacity: 1,
                                                    border: 0,
                                                    margin: 0,
                                                    padding: 0
                                                }}
                                            />
                                        </span>
                                        <img
                                            alt="PLAY"
                                            src="https://cdn.javisx.com/image/231e2349-6766-4cab-a21a-5cfbf63f5ea9.png"
                                            decoding="async"
                                            data-nimg="intrinsic"
                                            style={{
                                                margin: "auto",
                                                position: "absolute",
                                                inset: 0,
                                                boxSizing: "border-box",
                                                padding: 0,
                                                border: "none",
                                                display: "block",
                                                width: 0,
                                                height: 0,
                                                minWidth: "100%",
                                                maxWidth: "100%",
                                                minHeight: "100%",
                                                maxHeight: "100%",
                                                objectFit: "contain"
                                            }}
                                        />
                                    </span>
                                </a>
                            </div>
                            <div className="MuiBox-root css-18spxf1">
                                <a role="button" onClick={(e) => {
                                    this.checkurl(e, "/manager/deposit")
                                }}>
                                    <span
                                        style={{
                                            boxSizing: "border-box",
                                            display: "inline-block",
                                            overflow: "hidden",
                                            width: "initial",
                                            height: "initial",
                                            background: "none",
                                            opacity: 1,
                                            border: 0,
                                            margin: 0,
                                            padding: 0,
                                            position: "relative",
                                            maxWidth: "100%"
                                        }}
                                    >
                                        <span
                                            style={{
                                                boxSizing: "border-box",
                                                display: "block",
                                                width: "initial",
                                                height: "initial",
                                                background: "none",
                                                opacity: 1,
                                                border: 0,
                                                margin: 0,
                                                padding: 0,
                                                maxWidth: "100%"
                                            }}
                                        >
                                            <img
                                                alt=""
                                                aria-hidden="true"
                                                src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2730%27%20height=%2730%27/%3e"
                                                style={{
                                                    display: "block",
                                                    maxWidth: "100%",
                                                    width: "initial",
                                                    height: "initial",
                                                    background: "none",
                                                    opacity: 1,
                                                    border: 0,
                                                    margin: 0,
                                                    padding: 0
                                                }}
                                            />
                                        </span>
                                        <img
                                            alt="LOGO"
                                            src="https://cdn.javisx.com/image/e88d69d4-d84f-4809-bf46-ab621c0351a7.png"
                                            decoding="async"
                                            data-nimg="intrinsic"
                                            style={{
                                                position: "absolute",
                                                inset: 0,
                                                boxSizing: "border-box",
                                                padding: 0,
                                                border: "none",
                                                margin: "auto",
                                                display: "block",
                                                width: 0,
                                                height: 0,
                                                minWidth: "100%",
                                                maxWidth: "100%",
                                                minHeight: "100%",
                                                maxHeight: "100%",
                                                objectFit: "contain"
                                            }}
                                        />
                                    </span>

                                    <p className="MuiTypography-root MuiTypography-body1 css-dlcerf">
                                        ฝากเงิน
                                    </p>
                                </a>
                            </div>
                            <div className="MuiBox-root css-18spxf1">
                                <a role="button" onClick={(e) => {
                                    this.checkurl(e, "/manager/withdraw")
                                }}>
                                    <span
                                        style={{
                                            boxSizing: "border-box",
                                            display: "inline-block",
                                            overflow: "hidden",
                                            width: "initial",
                                            height: "initial",
                                            background: "none",
                                            opacity: 1,
                                            border: 0,
                                            margin: 0,
                                            padding: 0,
                                            position: "relative",
                                            maxWidth: "100%"
                                        }}
                                    >
                                        <span
                                            style={{
                                                boxSizing: "border-box",
                                                display: "block",
                                                width: "initial",
                                                height: "initial",
                                                background: "none",
                                                opacity: 1,
                                                border: 0,
                                                margin: 0,
                                                padding: 0,
                                                maxWidth: "100%"
                                            }}
                                        >
                                            <img
                                                alt=""
                                                aria-hidden="true"
                                                src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2730%27%20height=%2730%27/%3e"
                                                style={{
                                                    display: "block",
                                                    maxWidth: "100%",
                                                    width: "initial",
                                                    height: "initial",
                                                    background: "none",
                                                    opacity: 1,
                                                    border: 0,
                                                    margin: 0,
                                                    padding: 0
                                                }}
                                            />
                                        </span>
                                        <img
                                            alt="LOGO"
                                            src="https://cdn.javisx.com/image/c9934c15-388e-49a0-a5c3-eae17ee2a435.png"
                                            decoding="async"
                                            data-nimg="intrinsic"
                                            style={{
                                                position: "absolute",
                                                inset: 0,
                                                boxSizing: "border-box",
                                                padding: 0,
                                                border: "none",
                                                margin: "auto",
                                                display: "block",
                                                width: 0,
                                                height: 0,
                                                minWidth: "100%",
                                                maxWidth: "100%",
                                                minHeight: "100%",
                                                maxHeight: "100%",
                                                objectFit: "contain"
                                            }}
                                        />
                                    </span>

                                    <p className="MuiTypography-root MuiTypography-body1 css-dlcerf">
                                        ถอนเงิน
                                    </p>
                                </a>
                            </div>
                            <div className="MuiBox-root css-18spxf1">
                                <a role="button" onClick={(e) => {
                                    this.checkurl(e, "/refer/overview")
                                }}>
                                    <span
                                        style={{
                                            boxSizing: "border-box",
                                            display: "inline-block",
                                            overflow: "hidden",
                                            width: "initial",
                                            height: "initial",
                                            background: "none",
                                            opacity: 1,
                                            border: 0,
                                            margin: 0,
                                            padding: 0,
                                            position: "relative",
                                            maxWidth: "100%"
                                        }}
                                    >
                                        <span
                                            style={{
                                                boxSizing: "border-box",
                                                display: "block",
                                                width: "initial",
                                                height: "initial",
                                                background: "none",
                                                opacity: 1,
                                                border: 0,
                                                margin: 0,
                                                padding: 0,
                                                maxWidth: "100%"
                                            }}
                                        >
                                            <img
                                                alt=""
                                                aria-hidden="true"
                                                src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2730%27%20height=%2730%27/%3e"
                                                style={{
                                                    display: "block",
                                                    maxWidth: "100%",
                                                    width: "initial",
                                                    height: "initial",
                                                    background: "none",
                                                    opacity: 1,
                                                    border: 0,
                                                    margin: 0,
                                                    padding: 0
                                                }}
                                            />
                                        </span>
                                        <img
                                            alt="LOGO"
                                            src="https://cdn.javisx.com/image/a388a8af-d32b-4eb9-9458-fdf0d7902238.png"
                                            decoding="async"
                                            data-nimg="intrinsic"
                                            style={{
                                                position: "absolute",
                                                inset: 0,
                                                boxSizing: "border-box",
                                                padding: 0,
                                                border: "none",
                                                margin: "auto",
                                                display: "block",
                                                width: 0,
                                                height: 0,
                                                minWidth: "100%",
                                                maxWidth: "100%",
                                                minHeight: "100%",
                                                maxHeight: "100%",
                                                objectFit: "contain"
                                            }}
                                        />
                                    </span>

                                    <p className="MuiTypography-root MuiTypography-body1 css-dlcerf">
                                        ชวนเพื่อน
                                    </p>
                                </a>
                            </div>
                            <div className="MuiBox-root css-18spxf1">
                                <a role="button" onClick={(e) => {
                                    this.checkurl(e, "/manager/promotion")
                                }}>
                                    <span
                                        style={{
                                            boxSizing: "border-box",
                                            display: "inline-block",
                                            overflow: "hidden",
                                            width: "initial",
                                            height: "initial",
                                            background: "none",
                                            opacity: 1,
                                            border: 0,
                                            margin: 0,
                                            padding: 0,
                                            position: "relative",
                                            maxWidth: "100%"
                                        }}
                                    >
                                        <span
                                            style={{
                                                boxSizing: "border-box",
                                                display: "block",
                                                width: "initial",
                                                height: "initial",
                                                background: "none",
                                                opacity: 1,
                                                border: 0,
                                                margin: 0,
                                                padding: 0,
                                                maxWidth: "100%"
                                            }}
                                        >
                                            <img
                                                alt=""
                                                aria-hidden="true"
                                                src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2730%27%20height=%2730%27/%3e"
                                                style={{
                                                    display: "block",
                                                    maxWidth: "100%",
                                                    width: "initial",
                                                    height: "initial",
                                                    background: "none",
                                                    opacity: 1,
                                                    border: 0,
                                                    margin: 0,
                                                    padding: 0
                                                }}
                                            />
                                        </span>
                                        <img
                                            alt="LOGO"
                                            src="https://cdn.javisx.com/image/3da20fb2-4aba-479c-855e-5f9c12df3d13.png"
                                            decoding="async"
                                            data-nimg="intrinsic"
                                            style={{
                                                position: "absolute",
                                                inset: 0,
                                                boxSizing: "border-box",
                                                padding: 0,
                                                border: "none",
                                                margin: "auto",
                                                display: "block",
                                                width: 0,
                                                height: 0,
                                                minWidth: "100%",
                                                maxWidth: "100%",
                                                minHeight: "100%",
                                                maxHeight: "100%",
                                                objectFit: "contain"
                                            }}
                                        />
                                    </span>

                                    <p className="MuiTypography-root MuiTypography-body1 css-dlcerf">
                                        โปรโมชั่น
                                    </p>
                                </a>
                            </div>
                            <div className="MuiBox-root css-18spxf1">
                                <a href={this.state.linelink}>
                                    <span
                                        style={{
                                            boxSizing: "border-box",
                                            display: "inline-block",
                                            overflow: "hidden",
                                            width: "initial",
                                            height: "initial",
                                            background: "none",
                                            opacity: 1,
                                            border: 0,
                                            margin: 0,
                                            padding: 0,
                                            position: "relative",
                                            maxWidth: "100%"
                                        }}
                                    >
                                        <span
                                            style={{
                                                boxSizing: "border-box",
                                                display: "block",
                                                width: "initial",
                                                height: "initial",
                                                background: "none",
                                                opacity: 1,
                                                border: 0,
                                                margin: 0,
                                                padding: 0,
                                                maxWidth: "100%"
                                            }}
                                        >
                                            <img
                                                alt=""
                                                aria-hidden="true"
                                                src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2730%27%20height=%2730%27/%3e"
                                                style={{
                                                    display: "block",
                                                    maxWidth: "100%",
                                                    width: "initial",
                                                    height: "initial",
                                                    background: "none",
                                                    opacity: 1,
                                                    border: 0,
                                                    margin: 0,
                                                    padding: 0
                                                }}
                                            />
                                        </span>
                                        <img
                                            alt="LOGO"
                                            src="https://cdn.javisx.com/image/ed53f8be-bbcf-4f34-b6dd-3263b1626272.png"
                                            decoding="async"
                                            data-nimg="intrinsic"
                                            style={{
                                                position: "absolute",
                                                inset: 0,
                                                boxSizing: "border-box",
                                                padding: 0,
                                                border: "none",
                                                margin: "auto",
                                                display: "block",
                                                width: 0,
                                                height: 0,
                                                minWidth: "100%",
                                                maxWidth: "100%",
                                                minHeight: "100%",
                                                maxHeight: "100%",
                                                objectFit: "contain"
                                            }}
                                        />
                                    </span>

                                    <p className="MuiTypography-root MuiTypography-body1 css-dlcerf">
                                        ช่วยเหลือ
                                    </p>
                                </a>
                            </div>
                        </div>
                    </header>
                </div>
                <div className="MuiBox-root css-0" />
                <div ref={node => {
                    this.node = node;
                }} role="presentation" style={{ display: this.state.modalx && !isMobile ? "" : "none" }} className="MuiDialog-root MuiModal-root css-126xj0f">

                    <div
                        aria-hidden="true"
                        className="MuiBackdrop-root css-919eu4"
                        style={{
                            opacity: 1,
                            transition: "opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
                        }}
                    />
                    <div tabIndex={0} data-testid="sentinelStart" />
                    <div
                        className="MuiDialog-container MuiDialog-scrollPaper css-ekeie0"
                        role="presentation"
                        tabIndex={-1}
                        style={{
                            opacity: 1,
                            transition: "opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
                        }}
                    >
                        <div
                            className={isMobile ? "MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation24 MuiDialog-paper MuiDialog-paperScrollPaper MuiDialog-paperWidthSm MuiDialog-paperFullWidth css-mbdu2s" : "MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation24 MuiDialog-paper MuiDialog-paperScrollPaper MuiDialog-paperWidthMd MuiDialog-paperFullWidth css-cwpu7v"}
                            role="dialog"
                            aria-labelledby=":re:"
                        >
                            <div >
                                <form className={isMobile ? "MuiBox-root css-1pk6t12" : "MuiBox-root css-1g55zed"}>
                                    <a role="button" classname="close f-1 "
                                        onClick={(e) => {
                                            this.setState({
                                                modalx: false
                                            });
                                        }}
                                        aria-label="Close">

                                        <img width={"30px"} src="/build/web/igame-index-lobby-wm/img/signs-close-icon-png.webp"></img>
                                    </a>
                                    <p style={{ color: "white", textAlign: "center", paddingTop: "60px", fontSize: "24px" }} className="MuiTypography-root MuiTypography-body1 css-9l3uo3">
                                        เข้าสู่ระบบ
                                    </p>
                                    <p style={{ color: "white", textAlign: "center" }} className="MuiTypography-root MuiTypography-body1 css-9l3uo3"  >
                                        ยังไม่เป็นสมาชิก?{" "}
                                        <span style={{ color: "rgb(252, 189, 17)", cursor: "pointer" }} role="button" onClick={(e) => {
                                            this.checkurl(e, "/register")
                                        }}>
                                            คลิกเพื่อสมัครสมาชิก
                                        </span>
                                    </p>
                                    <div style={{ marginTop: 60 }} className="MuiGrid2-root MuiGrid2-container MuiGrid2-direction-xs-column css-fief6b" >
                                        <div className="MuiFormControl-root css-13sljp9" style={{ width: "85%" }}>
                                            <div className="MuiFormControl-root MuiTextField-root css-bh91h3">
                                                <div style={{ outline: "none" }} className="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-formControl css-1v4ccyo">
                                                    <input

                                                        type="tel"
                                                        id="username"
                                                        name="username"
                                                        onChange={this.onChange}
                                                        required=""
                                                        maxLength={10}
                                                        minLength={10}
                                                        className="MuiInputBase-input MuiOutlinedInput-input css-1x5jdmq"
                                                        defaultValue=""
                                                    />
                                                    <fieldset
                                                        aria-hidden="true"
                                                        className="MuiOutlinedInput-notchedOutline css-igs3ac"
                                                    >
                                                        <legend className="css-1ftyaf0">
                                                            <span>เบอร์โทรศัพท์&nbsp;*</span>
                                                        </legend>
                                                    </fieldset>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="MuiFormControl-root css-13sljp9"
                                            style={{ width: "85%", marginTop: 15 }}
                                        >
                                            <div className="MuiFormControl-root MuiTextField-root css-bh91h3">
                                                <div className="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-formControl MuiInputBase-adornedEnd css-1bn53lx">
                                                    <input
                                                        type={!this.state.showpass ? "password" : "text"}
                                                        aria-invalid="false"
                                                        id="password"
                                                        name="password"
                                                        onChange={this.onChange}
                                                        aria-label="รหัสผ่าน"
                                                        placeholder="รหัสผ่าน"
                                                        aria-describedby="basic-addon1"
                                                        className="MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputAdornedEnd css-1uvydh2"
                                                        defaultValue=""
                                                    />
                                                    <div className="MuiInputAdornment-root MuiInputAdornment-positionEnd MuiInputAdornment-outlined MuiInputAdornment-sizeMedium css-1nvf7g0">
                                                        <button
                                                            className="MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeMedium css-1yxmbwk"
                                                            tabIndex={0}
                                                            type="button"
                                                            style={{ color: "grey" }}
                                                            onClick={(e) => this.changepass(e)}
                                                        >
                                                            <svg classname="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-wx6vbk" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="VisibilityIcon"><path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"></path></svg>
                                                            <span className="MuiTouchRipple-root css-w0pj6f" />
                                                        </button>
                                                    </div>
                                                    <fieldset
                                                        aria-hidden="true"
                                                        className="MuiOutlinedInput-notchedOutline css-igs3ac"
                                                    >
                                                        <legend className="css-1ftyaf0">
                                                            <span>รหัสผ่าน&nbsp;*</span>
                                                        </legend>
                                                    </fieldset>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="MuiFormControl-root css-13sljp9"
                                            style={{ width: "85%", marginTop: 10, alignItems: "flex-end" }}
                                        >
                                            <p className="MuiTypography-root MuiTypography-body1 css-1csifr0">
                                                <a
                                                    href={this.state.linelink}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    style={{ color: "white", textDecoration: "none" }}
                                                >
                                                    ลืมรหัสผ่าน
                                                </a>
                                            </p>
                                        </div>
                                        <div className="MuiFormControl-root css-13sljp9" />
                                        <div
                                            className="MuiFormControl-root css-13sljp9"
                                            style={{ width: "85%", marginTop: 30 }}
                                        >
                                            <button
                                                className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeLarge MuiButton-containedSizeLarge css-nw9d58"
                                                tabIndex={0}
                                                onClick={(e) => {
                                                    this.login(e)
                                                }}>

                                                <span>
                                                    <p
                                                        className="MuiTypography-root MuiTypography-body1 css-9l3uo3"
                                                        style={{
                                                            fontFamily: "ksbold",
                                                            fontSize: 30,
                                                            marginLeft: 10,
                                                            letterSpacing: "0.03em"
                                                        }}
                                                    >
                                                        เข้าสู่ระบบ
                                                    </p>
                                                </span>
                                                <span className="MuiTouchRipple-root css-w0pj6f" />
                                            </button>
                                        </div>
                                        <div
                                            className="MuiFormControl-root css-13sljp9"
                                            style={{ width: "85%", marginTop: 15, alignItems: "center" }}
                                        >
                                            <p className="MuiTypography-root MuiTypography-body1 css-1vnawoq">
                                                พบปัญหาติดต่อทีมงาน
                                            </p>
                                        </div>
                                        <div
                                            className="MuiFormControl-root css-13sljp9"
                                            style={{ width: "85%", marginTop: 15 }}
                                        >
                                            <a href={this.state.linelink} target="_blank" rel="noreferrer">
                                                <button
                                                    className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeLarge MuiButton-containedSizeLarge css-qlru1u"
                                                    tabIndex={0}
                                                    type="button"
                                                >
                                                    <span
                                                        style={{
                                                            boxSizing: "border-box",
                                                            display: "inline-block",
                                                            overflow: "hidden",
                                                            width: "initial",
                                                            height: "initial",
                                                            background: "none",
                                                            opacity: 1,
                                                            border: 0,
                                                            margin: 0,
                                                            padding: 0,
                                                            position: "relative",
                                                            maxWidth: "100%"
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                boxSizing: "border-box",
                                                                display: "block",
                                                                width: "initial",
                                                                height: "initial",
                                                                background: "none",
                                                                opacity: 1,
                                                                border: 0,
                                                                margin: 0,
                                                                padding: 0,
                                                                maxWidth: "100%"
                                                            }}
                                                        >
                                                            <img
                                                                alt=""
                                                                aria-hidden="true"
                                                                src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2730%27%20height=%2730%27/%3e"
                                                                style={{
                                                                    display: "block",
                                                                    maxWidth: "100%",
                                                                    width: "initial",
                                                                    height: "initial",
                                                                    background: "none",
                                                                    opacity: 1,
                                                                    border: 0,
                                                                    margin: 0,
                                                                    padding: 0
                                                                }}
                                                            />
                                                        </span>
                                                        <img
                                                            alt=""
                                                            src="/build/web/igame-index-lobby-wm/img/line_logo.webp"
                                                            decoding="async"
                                                            data-nimg="intrinsic"
                                                            style={{
                                                                position: "absolute",
                                                                inset: 0,
                                                                boxSizing: "border-box",
                                                                padding: 0,
                                                                border: "none",
                                                                margin: "auto",
                                                                display: "block",
                                                                width: 0,
                                                                height: 0,
                                                                minWidth: "100%",
                                                                maxWidth: "100%",
                                                                minHeight: "100%",
                                                                maxHeight: "100%",
                                                                objectFit: "cover"
                                                            }}
                                                        />
                                                    </span>
                                                    <span>
                                                        <p
                                                            className="MuiTypography-root MuiTypography-body1 css-9l3uo3"
                                                            style={{
                                                                fontFamily: "ksbold",
                                                                fontSize: 30,
                                                                marginLeft: 10,
                                                                letterSpacing: "0.03em"
                                                            }}
                                                        >
                                                            คลิกเพื่อติดต่อทีมงาน
                                                        </p>
                                                    </span>
                                                    <span className="MuiTouchRipple-root css-w0pj6f" />
                                                </button>
                                            </a>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div tabIndex={0} data-testid="sentinelEnd" />
                </div>
                <div role="presentation" style={{ display: this.state.modaldeposit ? "" : "none" }} className="MuiDialog-root MuiModal-root css-126xj0f">
                    <div
                        aria-hidden="true"
                        className="MuiBackdrop-root css-919eu4"
                        style={{
                            opacity: 1,
                            transition: "opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
                        }}
                    />
                    <div tabIndex={0} data-testid="sentinelStart" />
                    <div
                        className="MuiDialog-container MuiDialog-scrollPaper css-ekeie0"
                        role="presentation"
                        tabIndex={-1}
                        style={{
                            opacity: 1,
                            transition: "opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
                        }}
                    >
                        <div
                            className="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation24 MuiDialog-paper MuiDialog-paperScrollPaper MuiDialog-paperWidthSm MuiDialog-paperFullWidth css-mbdu2s"
                            role="dialog"
                            aria-labelledby=":r2g:"
                            style={{ borderRadius: 20, background: "transparent" }}
                        >
                            <div className="MuiBox-root css-12eo8ix">
                                <p style={{ marginTop: "20px" }} className="MuiTypography-root MuiTypography-body1 css-13aw9id">
                                    หลังจากลูกค้าฝากสำเร็จ
                                </p>
                                <p style={{ color: "rgb(252, 186, 0)" }} className="MuiTypography-root MuiTypography-body1 css-13aw9id">
                                    กดปุ่มข้างล่างนี้
                                </p>
                                <p className={isMobile ? "MuiTypography-root MuiTypography-body1 css-p19i6x" : "MuiTypography-root MuiTypography-body1 css-gatbua"}>

                                </p>
                                <a
                                    role="button"
                                    href="/" // เพิ่ม href ที่คุณต้องการ
                                    onClick={(e) => {
                                        e.preventDefault(); // ป้องกันการนำทางหากต้องการทำงานเฉพาะ onClick
                                        this.setState({
                                            modaldeposit: false,
                                        });
                                    }}
                                    className="MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium zoom css-hbiykx"
                                    tabIndex={0}
                                    type="button"
                                >
                                    <h1 className="MuiTypography-root MuiTypography-body1 css-1rpabqk">
                                        ฝากสำเร็จ
                                    </h1>
                                    <span className="MuiTouchRipple-root css-w0pj6f" />
                                </a>

                                <p style={{ marginTop: "20px" }} className="MuiTypography-root MuiTypography-body1 css-13aw9id">
                                    ถ้าเครดิตไม่อัพเดท <span style={{ color: "rgb(252, 186, 0)" }}> ภายใน 5 นาที</span>
                                </p>
                                <p className="MuiTypography-root MuiTypography-body1 css-13aw9id">
                                    ท่านสามารถอัพโหลดสลิปที่
                                </p>
                                <p style={{ color: "rgb(252, 186, 0)", marginBottom: "20px" }} className="MuiTypography-root MuiTypography-body1 css-13aw9id">
                                    เมนูอัพโหลดสลิป
                                </p>
                            </div>
                        </div>
                    </div>
                    <div tabIndex={0} data-testid="sentinelEnd" />
                </div>
                <div role="presentation" style={{ display: this.state.modal ? "" : "none" }} className="MuiDialog-root MuiModal-root css-126xj0f">
                    <div
                        aria-hidden="true"
                        className="MuiBackdrop-root css-919eu4"
                        style={{
                            opacity: 1,
                            transition: "opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
                        }}
                    />
                    <div tabIndex={0} data-testid="sentinelStart" />
                    <div
                        className="MuiDialog-container MuiDialog-scrollPaper css-ekeie0"
                        role="presentation"
                        tabIndex={-1}
                        style={{
                            opacity: 1,
                            transition: "opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
                        }}
                    >
                        <a role="button" classname="close f-1 "
                            onClick={(e) => {
                                this.setState({
                                    modal: false
                                });
                            }}
                            aria-label="Close">

                            <img width={"30px"} src="/build/web/igame-index-lobby-wm/img/signs-close-icon-png.webp"></img>
                        </a>
                        <div
                            className="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation24 MuiDialog-paper MuiDialog-paperScrollPaper MuiDialog-paperWidthSm MuiDialog-paperFullWidth css-mbdu2s"
                            role="dialog"
                            aria-labelledby=":r2g:"
                            style={{ borderRadius: 20, background: "transparent" }}
                        >
                            <div className="MuiBox-root css-12eo8is">
                                <span
                                    style={{
                                        boxSizing: "border-box",
                                        display: "inline-block",
                                        overflow: "hidden",
                                        width: "initial",
                                        height: "initial",
                                        background: "none",
                                        opacity: 1,
                                        border: 0,
                                        margin: 0,
                                        padding: 0,
                                        position: "relative",
                                        maxWidth: "100%"
                                    }}
                                >
                                    <span
                                        style={{
                                            boxSizing: "border-box",
                                            display: "block",
                                            width: "initial",
                                            height: "initial",
                                            background: "none",
                                            opacity: 1,
                                            border: 0,
                                            margin: 0,
                                            padding: 0,
                                            maxWidth: "100%"
                                        }}
                                    >
                                        <img
                                            alt=""
                                            aria-hidden="true"
                                            src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%27275%27%20height=%2790%27/%3e"
                                            style={{
                                                display: "block",
                                                maxWidth: "100%",
                                                width: "initial",
                                                height: "initial",
                                                background: "none",
                                                opacity: 1,
                                                border: 0,
                                                margin: 0,
                                                padding: 0
                                            }}
                                        />
                                    </span>
                                    <img
                                        alt="LOGO"
                                        src={this.state.logoimg}
                                        decoding="async"
                                        data-nimg="intrinsic"
                                        style={{
                                            position: "absolute",
                                            inset: 0,
                                            boxSizing: "border-box",
                                            padding: 0,
                                            border: "none",
                                            margin: "auto",
                                            display: "block",
                                            width: 0,
                                            height: 0,
                                            minWidth: "100%",
                                            maxWidth: "100%",
                                            minHeight: "150%",
                                            maxHeight: "100%"
                                        }}
                                    />
                                </span>
                                <div className="MuiBox-root css-62nru7">
                                    <button
                                        className={isMobile ? "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-1m6igjk" : "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-1s1a29v"}
                                        tabIndex={0}
                                        type="button"
                                        onClick={(e) => {
                                            this.checkurl(e, "/")
                                        }}
                                    >
                                        <p className="MuiTypography-root MuiTypography-body1 css-iqngd6">
                                            หน้าแรก
                                        </p>
                                        <span className="MuiTouchRipple-root css-w0pj6f" />
                                    </button>
                                    <button
                                        onClick={(e) => {

                                            this.setState({
                                                popupprofile: true,
                                                modal: false,
                                                tabprofile: 1,
                                            });

                                        }}
                                        className={isMobile ? "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-1m6igjk" : "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-1s1a29v"}
                                        tabIndex={0}
                                        type="button"
                                    >
                                        <p className="MuiTypography-root MuiTypography-body1 css-iqngd6">
                                            ข้อมูลส่วนตัว
                                        </p>
                                        <span className="MuiTouchRipple-root css-w0pj6f" />
                                    </button>
                                </div>
                                <div className="MuiBox-root css-k008qs">
                                    <button
                                        className={isMobile ? "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-1m6igjk" : "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-1s1a29v"}
                                        tabIndex={0}
                                        type="button"
                                        onClick={(e) => {
                                            this.checkurl(e, "/manager/deposit")
                                        }}
                                    >
                                        <p className="MuiTypography-root MuiTypography-body1 css-iqngd6">
                                            ฝากเงิน
                                        </p>
                                        <span className="MuiTouchRipple-root css-w0pj6f" />
                                    </button>
                                    <button
                                        className={isMobile ? "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-1m6igjk" : "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-1s1a29v"}
                                        tabIndex={0}
                                        type="button"
                                        onClick={(e) => {
                                            this.checkurl(e, "/manager/withdraw")
                                        }}
                                    >
                                        <p className="MuiTypography-root MuiTypography-body1 css-iqngd6">
                                            ถอนเงิน
                                        </p>
                                        <span className="MuiTouchRipple-root css-w0pj6f" />
                                    </button>
                                </div>
                                <div className="MuiBox-root css-k008qs">
                                    <button
                                        className={isMobile ? "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-1m6igjk" : "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-1s1a29v"}
                                        tabIndex={0}
                                        type="button"
                                        onClick={(e) => {
                                            this.checkurl(e, "/manager/promotion")
                                        }}
                                    >
                                        <p className="MuiTypography-root MuiTypography-body1 css-iqngd6">
                                            โปรโมชั่น
                                        </p>
                                        <span className="MuiTouchRipple-root css-w0pj6f" />
                                    </button>
                                    <button
                                        className={isMobile ? "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-1m6igjk" : "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-1s1a29v"}
                                        tabIndex={0}
                                        onClick={(e) => {
                                            this.checkurl(e, "/refer/overview")
                                        }}
                                        type="button"
                                    >
                                        <p className="MuiTypography-root MuiTypography-body1 css-iqngd6">
                                            ชวนเพื่อน
                                        </p>
                                        <span className="MuiTouchRipple-root css-w0pj6f" />
                                    </button>
                                </div>
                                <div className="MuiBox-root css-k008qs">
                                    <button
                                        className={isMobile ? "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-1m6igjk" : "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-1s1a29v"}
                                        tabIndex={0}
                                        type="button"

                                        onClick={(e) => {
                                            this.checkurl(e, "/manager/statement")
                                        }}
                                    >
                                        <p className="MuiTypography-root MuiTypography-body1 css-iqngd6">
                                            ประวัติ
                                        </p>
                                        <span className="MuiTouchRipple-root css-w0pj6f" />
                                    </button>
                                    <a
                                        href={this.state.linelink}
                                        className={isMobile ? "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-1m6igjk" : "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-1s1a29v"}
                                        tabIndex={0}
                                        type="button"
                                    >
                                        <p className="MuiTypography-root MuiTypography-body1 css-iqngd6">
                                            ติดต่อเจ้าหน้าที่
                                        </p>
                                        <span className="MuiTouchRipple-root css-w0pj6f" />
                                    </a>
                                </div>

                                <div className="MuiBox-root css-1wcba0h">
                                    <a
                                        className={isMobile ? "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-1j5e4lv" : "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-1eebtcj"}
                                        tabIndex={0}
                                        type="button"
                                        href='/logout'
                                    >
                                        <p className="MuiTypography-root MuiTypography-body1 css-iqngd6">
                                            ออกจากระบบ
                                        </p>
                                        <span className="MuiTouchRipple-root css-w0pj6f" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div tabIndex={0} data-testid="sentinelEnd" />
                </div>

                <div role="presentation" style={{ display: this.state.modalpromotion ? "" : "none" }} className="MuiDialog-root MuiModal-root css-126xj0f">
                    <div
                        aria-hidden="true"
                        className="MuiBackdrop-root css-919eu4"
                        style={{
                            opacity: 1,
                            transition: "opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
                        }}
                    />
                    <div tabIndex={0} data-testid="sentinelStart" />
                    {(prodetail != null) ?
                        <div
                            className="MuiDialog-container MuiDialog-scrollPaper css-ekeie0"
                            role="presentation"
                            tabIndex={-1}
                            style={{
                                opacity: 1,
                                transition: "opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
                            }}
                        >
                            <a role="button" classname="close f-1 "
                                onClick={(e) => {
                                    this.setState({
                                        modalpromotion: false
                                    });
                                }}
                                aria-label="Close">

                                <img width={"30px"} src="/build/web/igame-index-lobby-wm/img/signs-close-icon-png.webp"></img>
                            </a>
                            <div
                                className="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation24 MuiDialog-paper MuiDialog-paperScrollPaper MuiDialog-paperWidthMd MuiDialog-paperFullWidth css-cwpu7v"
                                role="dialog"
                                aria-labelledby=":r17:"
                                style={{ borderRadius: 30 }}
                            >
                                <div style={{ display: isMobile ? "" : "none" }} className="MuiBox-root css-dpbce0">
                                    <div className={isMobile ? "MuiBox-root css-xhdgqk" : "MuiBox-root css-1wqcx23"}>

                                        <p className="MuiTypography-root MuiTypography-body1 css-1u5kmj0">  {prodetail.proname}</p>
                                        <p className="MuiTypography-root MuiTypography-body1 css-96nhoq">  <section
                                            dangerouslySetInnerHTML={{ __html: prodetail.detail }}
                                        /></p>
                                        <div className={isMobile ? "MuiBox-root css-i9gxme" : "MuiBox-root css-1wq3n3l"} >
                                            <div className={isMobile ? "MuiBox-root css-16o0ywx" : "MuiBox-root css-16o0yw0"}>
                                                <span
                                                    style={{
                                                        boxSizing: "border-box",
                                                        display: "block",
                                                        overflow: "hidden",
                                                        width: "initial",
                                                        height: "initial",
                                                        background: "none",
                                                        opacity: 1,
                                                        border: 0,
                                                        margin: 0,
                                                        padding: 0,
                                                        position: "absolute",
                                                        inset: 0
                                                    }}
                                                >
                                                    <img
                                                        alt="Promotion"
                                                        src={prodetail.linkpicture}
                                                        decoding="async"
                                                        data-nimg="fill"
                                                        style={{
                                                            position: "absolute",
                                                            inset: 0,
                                                            boxSizing: "border-box",
                                                            padding: 0,
                                                            border: "none",
                                                            margin: "auto",
                                                            display: "block",
                                                            width: 0,
                                                            height: 0,
                                                            minWidth: "100%",
                                                            maxWidth: "100%",
                                                            minHeight: "100%",
                                                            maxHeight: "100%"
                                                        }}
                                                    //srcSet="/_next/image?url=https%3A%2F%2Fd12lxcfs2l5om8.cloudfront.net%2Fddthai%2Fwebimages%2Fpromotion%2Fddthai_1723289737113.jpg&w=640&q=75 640w, /_next/image?url=https%3A%2F%2Fd12lxcfs2l5om8.cloudfront.net%2Fddthai%2Fwebimages%2Fpromotion%2Fddthai_1723289737113.jpg&w=750&q=75 750w, /_next/image?url=https%3A%2F%2Fd12lxcfs2l5om8.cloudfront.net%2Fddthai%2Fwebimages%2Fpromotion%2Fddthai_1723289737113.jpg&w=828&q=75 828w, /_next/image?url=https%3A%2F%2Fd12lxcfs2l5om8.cloudfront.net%2Fddthai%2Fwebimages%2Fpromotion%2Fddthai_1723289737113.jpg&w=1080&q=75 1080w, /_next/image?url=https%3A%2F%2Fd12lxcfs2l5om8.cloudfront.net%2Fddthai%2Fwebimages%2Fpromotion%2Fddthai_1723289737113.jpg&w=1200&q=75 1200w, /_next/image?url=https%3A%2F%2Fd12lxcfs2l5om8.cloudfront.net%2Fddthai%2Fwebimages%2Fpromotion%2Fddthai_1723289737113.jpg&w=1920&q=75 1920w, /_next/image?url=https%3A%2F%2Fd12lxcfs2l5om8.cloudfront.net%2Fddthai%2Fwebimages%2Fpromotion%2Fddthai_1723289737113.jpg&w=2048&q=75 2048w, /_next/image?url=https%3A%2F%2Fd12lxcfs2l5om8.cloudfront.net%2Fddthai%2Fwebimages%2Fpromotion%2Fddthai_1723289737113.jpg&w=3840&q=75 3840w"
                                                    />
                                                </span>
                                            </div>
                                        </div>
                                        <div className={isMobile ? "MuiBox-root css-na4cvc" : "MuiBox-root css-1wq3n3l"}>
                                            <button
                                                style={{ display: (username !== null) ? "" : "none" }}
                                                onClick={(e) => this.redeempro(e, prodetail.no)}
                                                className="MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-xalak3"
                                                tabIndex={0}
                                                type="button"
                                            >
                                                <p className="MuiTypography-root MuiTypography-body1 css-rbs110">
                                                    รับโปรโมชั่น
                                                </p>
                                                <span className="MuiTouchRipple-root css-w0pj6f" />
                                            </button>
                                        </div>

                                    </div>
                                </div>

                                <div style={{ display: !isMobile ? "" : "none" }} className="MuiBox-root css-1wqcx23">
                                    <div className="MuiBox-root css-5x76yb">
                                        <p className="MuiTypography-root MuiTypography-body1 css-1lxvjln">
                                            {prodetail.proname}
                                        </p>
                                        <p className="MuiTypography-root MuiTypography-body1 css-iqngd6">
                                            <section
                                                dangerouslySetInnerHTML={{ __html: prodetail.detail }}
                                            />
                                        </p>
                                        <div className="MuiBox-root css-i9gxme" />
                                        <div className="MuiBox-root css-na4cvc">
                                            <button
                                                style={{ display: (username !== null) ? "" : "none" }}
                                                onClick={(e) => this.redeempro(e, prodetail.no)}
                                                className="MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-xalak3"
                                                tabIndex={0}
                                                type="button"
                                            >
                                                <p className="MuiTypography-root MuiTypography-body1 css-rbs110">
                                                    รับโปรโมชั่น
                                                </p>
                                                <span className="MuiTouchRipple-root css-w0pj6f" />
                                            </button>
                                        </div>
                                    </div>
                                    <div className={isMobile ? "MuiBox-root css-1wq3n3x" : "MuiBox-root css-1wq3n3l"} >
                                        <div className={isMobile ? "MuiBox-root css-16o0ywx" : "MuiBox-root css-16o0yw0"}>
                                            <span
                                                style={{
                                                    boxSizing: "border-box",
                                                    display: "block",
                                                    overflow: "hidden",
                                                    width: "initial",
                                                    height: "initial",
                                                    background: "none",
                                                    opacity: 1,
                                                    border: 0,
                                                    margin: 0,
                                                    padding: 0,
                                                    position: "absolute",
                                                    inset: 0
                                                }}
                                            >
                                                <img
                                                    alt="Promotion"
                                                    src={prodetail.linkpicture}
                                                    decoding="async"
                                                    data-nimg="fill"
                                                    style={{
                                                        position: "absolute",
                                                        inset: 0,
                                                        boxSizing: "border-box",
                                                        padding: 0,
                                                        border: "none",
                                                        margin: "auto",
                                                        display: "block",
                                                        width: 0,
                                                        height: 0,
                                                        minWidth: "100%",
                                                        maxWidth: "100%",
                                                        minHeight: "100%",
                                                        maxHeight: "100%"
                                                    }}
                                                //srcSet="/_next/image?url=https%3A%2F%2Fd12lxcfs2l5om8.cloudfront.net%2Fddthai%2Fwebimages%2Fpromotion%2Fddthai_1723289737113.jpg&w=640&q=75 640w, /_next/image?url=https%3A%2F%2Fd12lxcfs2l5om8.cloudfront.net%2Fddthai%2Fwebimages%2Fpromotion%2Fddthai_1723289737113.jpg&w=750&q=75 750w, /_next/image?url=https%3A%2F%2Fd12lxcfs2l5om8.cloudfront.net%2Fddthai%2Fwebimages%2Fpromotion%2Fddthai_1723289737113.jpg&w=828&q=75 828w, /_next/image?url=https%3A%2F%2Fd12lxcfs2l5om8.cloudfront.net%2Fddthai%2Fwebimages%2Fpromotion%2Fddthai_1723289737113.jpg&w=1080&q=75 1080w, /_next/image?url=https%3A%2F%2Fd12lxcfs2l5om8.cloudfront.net%2Fddthai%2Fwebimages%2Fpromotion%2Fddthai_1723289737113.jpg&w=1200&q=75 1200w, /_next/image?url=https%3A%2F%2Fd12lxcfs2l5om8.cloudfront.net%2Fddthai%2Fwebimages%2Fpromotion%2Fddthai_1723289737113.jpg&w=1920&q=75 1920w, /_next/image?url=https%3A%2F%2Fd12lxcfs2l5om8.cloudfront.net%2Fddthai%2Fwebimages%2Fpromotion%2Fddthai_1723289737113.jpg&w=2048&q=75 2048w, /_next/image?url=https%3A%2F%2Fd12lxcfs2l5om8.cloudfront.net%2Fddthai%2Fwebimages%2Fpromotion%2Fddthai_1723289737113.jpg&w=3840&q=75 3840w"
                                                />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : <div>
                            <div className="box-content-pro mt-2 pt-2 pb-2">
                                <div className="sub-content-pr0 p-2">ข้อตกลงเเละเงื่อนไข</div>
                            </div>
                            <div className="text-left" style={{ color: "white" }}>
                                <section
                                    dangerouslySetInnerHTML={{ __html: prodetail.detail }}
                                />
                            </div>
                            <div className="card-footer" style={{ display: (prodetail.prolist == 5) ? "none" : "" }}>
                                <button
                                    className="btn "
                                    style={{ fontWeight: "300", background: "radial-gradient(100% 100% at 50% 0, #f9cf95 0, #c69a5d 100%)", display: (username !== null) ? "" : "none" }}
                                    onClick={(e) => this.redeempro(e, prodetail.no)}
                                >
                                    <span>รับโปรโมชั่น</span>
                                </button>
                            </div>
                        </div>}
                    <div tabIndex={0} data-testid="sentinelEnd" />

                </div >

                <div role="presentation" style={{ display: this.state.modalx && isMobile ? "" : "none" }} className="MuiDialog-root MuiModal-root css-126xj0f">
                    <div
                        aria-hidden="false"
                        className="MuiBackdrop-root css-919eu4"
                        style={{
                            opacity: 1,
                            transition: "opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
                        }}
                    />
                    <div tabIndex={0} data-testid="sentinelStart" />
                    <div
                        className="MuiDialog-container MuiDialog-scrollPaper css-ekeie0"
                        role="presentation"
                        tabIndex={-1}
                        style={{
                            opacity: 1,
                            transition: "opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
                        }}
                    >
                        <div
                            className="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation24 MuiDialog-paper MuiDialog-paperScrollPaper MuiDialog-paperWidthMd MuiDialog-paperFullWidth css-cwpu7v"
                            role="dialog"
                            aria-labelledby=":r17:"
                            style={{
                                borderRadius: 30,
                                background: "transparent",
                                boxShadow: "rgba(0, 0, 0, 0.5) 10px 10px 10px 10px",
                                border: "1px solid rgb(0, 0, 0)"
                            }}
                        >
                            <div className="MuiBox-root css-k008qs">
                                <div className="MuiBox-root css-6nndln">
                                    <form className="MuiBox-root css-1lstxr">
                                        <a role="button" classname="close f-1 "
                                            onClick={(e) => {
                                                this.setState({
                                                    modalx: false
                                                });
                                            }}
                                            aria-label="Close">

                                            <img width={"30px"} src="/build/web/igame-index-lobby-wm/img/signs-close-icon-png.webp"></img>
                                        </a>
                                        <p
                                            className="MuiTypography-root MuiTypography-body1 css-9l3uo3"
                                            style={{
                                                fontFamily: "ksbold",
                                                marginLeft: 30,
                                                marginTop: 30,
                                                color: "white",
                                                fontSize: 58
                                            }}
                                        >
                                            เข้าสู่ระบบ
                                        </p>
                                        <p

                                            className="MuiTypography-root MuiTypography-body1 css-9l3uo3"
                                            style={{
                                                fontFamily: "ks",
                                                marginLeft: 30,
                                                color: "white",
                                                fontSize: 20
                                            }}
                                        >
                                            ยังไม่เป็นสมาชิก?{" "}
                                            <span onClick={(e) => {
                                                this.checkurl(e, "/register")
                                            }} style={{ color: "rgb(252, 189, 17)", cursor: "pointer" }}>
                                                คลิกเพื่อสมัครสมาชิก
                                            </span>
                                        </p>
                                        <div
                                            className="MuiGrid2-root MuiGrid2-container MuiGrid2-direction-xs-column css-fief6b"
                                            style={{ marginTop: 20 }}
                                        >
                                            <div
                                                className="MuiFormControl-root css-13sljp9"
                                                style={{ width: "85%" }}
                                            >
                                                <div className="MuiFormControl-root MuiTextField-root css-bh91h3">
                                                    <label
                                                        className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-outlined MuiFormLabel-colorPrimary MuiFormLabel-filled Mui-required css-u9osun"
                                                        data-shrink="true"
                                                        htmlFor=":r1i:"
                                                        id=":r1i:-label"
                                                    >
                                                        เบอร์โทรศัพท์
                                                        <span
                                                            aria-hidden="true"
                                                            className="MuiFormLabel-asterisk MuiInputLabel-asterisk css-sp68t1"
                                                        >
                                                            *
                                                        </span>
                                                    </label>
                                                    <div className="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-formControl css-1v4ccyo">
                                                        <input
                                                            type="tel"
                                                            id="username"
                                                            name="username"
                                                            onChange={this.onChange}
                                                            required=""
                                                            maxLength={10}
                                                            minLength={10}
                                                            placeholder="เบอร์โทรศัพท์"
                                                            className="MuiInputBase-input MuiOutlinedInput-input css-1x5jdmq"
                                                            defaultValue=""
                                                        />
                                                        <fieldset
                                                            aria-hidden="true"
                                                            className="MuiOutlinedInput-notchedOutline css-igs3ac"
                                                        >
                                                            {/* <legend className="css-1in441m">
                                                                <span>เบอร์โทรศัพท์&nbsp;*</span>
                                                            </legend> */}
                                                        </fieldset>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="MuiFormControl-root css-13sljp9"
                                                style={{ width: "85%", marginTop: 15 }}
                                            >
                                                <div className="MuiFormControl-root MuiTextField-root css-bh91h3">
                                                    <div className="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-formControl MuiInputBase-adornedEnd css-1bn53lx">
                                                        <input
                                                            type={!this.state.showpass ? "password" : "text"}
                                                            aria-invalid="false"
                                                            id="password"
                                                            name="password"
                                                            onChange={this.onChange}
                                                            aria-label="รหัสผ่าน"
                                                            placeholder="รหัสผ่าน"
                                                            aria-describedby="basic-addon1"
                                                            className="MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputAdornedEnd css-1uvydh2"
                                                            defaultValue=""
                                                        />
                                                        <div className="MuiInputAdornment-root MuiInputAdornment-positionEnd MuiInputAdornment-outlined MuiInputAdornment-sizeMedium css-1nvf7g0">
                                                            <button
                                                                className="MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeMedium css-1yxmbwk"
                                                                tabIndex={0}
                                                                type="button"
                                                                style={{ color: "grey" }}
                                                                onClick={(e) => this.changepass(e)}
                                                            >
                                                                <svg
                                                                    className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-wx6vbk"
                                                                    focusable="false"
                                                                    aria-hidden="true"
                                                                    viewBox="0 0 24 24"
                                                                    data-testid="VisibilityIcon"
                                                                >
                                                                    <path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z" />
                                                                </svg>
                                                                <span className="MuiTouchRipple-root css-w0pj6f" />
                                                            </button>
                                                        </div>
                                                        <fieldset

                                                            aria-hidden="true"
                                                            className="MuiOutlinedInput-notchedOutline css-igs3ac"
                                                        >
                                                            <legend className="css-1ftyaf0">
                                                                <span>รหัสผ่าน&nbsp;*</span>
                                                            </legend>
                                                        </fieldset>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="MuiFormControl-root css-13sljp9"
                                                style={{ width: "85%", marginTop: 10, alignItems: "flex-end" }}
                                            >
                                                <p className="MuiTypography-root MuiTypography-body1 css-1csifr0">
                                                    <a
                                                        href={this.state.linelink}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        style={{ color: "white", textDecoration: "none" }}
                                                    >
                                                        ลืมรหัสผ่าน
                                                    </a>
                                                </p>
                                            </div>
                                            <div className="MuiFormControl-root css-13sljp9" />
                                            <div
                                                className="MuiFormControl-root css-13sljp9"
                                                style={{ width: "85%", marginTop: 30 }}
                                            >
                                                <button
                                                    onClick={(e) => {
                                                        this.login(e)
                                                    }}
                                                    className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeLarge MuiButton-containedSizeLarge css-nw9d58"
                                                    tabIndex={0}
                                                    type="submit"
                                                >
                                                    <span>
                                                        <p
                                                            className="MuiTypography-root MuiTypography-body1 css-9l3uo3"
                                                            style={{
                                                                fontFamily: "ksbold",
                                                                fontSize: 30,
                                                                marginLeft: 10,
                                                                letterSpacing: "0.03em"
                                                            }}
                                                        >
                                                            เข้าสู่ระบบ
                                                        </p>
                                                    </span>
                                                    <span className="MuiTouchRipple-root css-w0pj6f" />
                                                </button>
                                            </div>
                                            <div
                                                className="MuiFormControl-root css-13sljp9"
                                                style={{ width: "85%", marginTop: 15, alignItems: "center" }}
                                            >
                                                <p className="MuiTypography-root MuiTypography-body1 css-1vnawoq">
                                                    พบปัญหาติดต่อทีมงาน
                                                </p>
                                            </div>
                                            <div
                                                className="MuiFormControl-root css-13sljp9"
                                                style={{ width: "85%", marginTop: 15 }}
                                            >
                                                <a
                                                    href={this.state.linelink}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <button
                                                        className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeLarge MuiButton-containedSizeLarge css-qlru1u"
                                                        tabIndex={0}
                                                        type="button"
                                                    >
                                                        <span
                                                            style={{
                                                                boxSizing: "border-box",
                                                                display: "inline-block",
                                                                overflow: "hidden",
                                                                width: "initial",
                                                                height: "initial",
                                                                background: "none",
                                                                opacity: 1,
                                                                border: 0,
                                                                margin: 0,
                                                                padding: 0,
                                                                position: "relative",
                                                                maxWidth: "100%"
                                                            }}
                                                        >
                                                            <span
                                                                style={{
                                                                    boxSizing: "border-box",
                                                                    display: "block",
                                                                    width: "initial",
                                                                    height: "initial",
                                                                    background: "none",
                                                                    opacity: 1,
                                                                    border: 0,
                                                                    margin: 0,
                                                                    padding: 0,
                                                                    maxWidth: "100%"
                                                                }}
                                                            >
                                                                <img
                                                                    alt=""
                                                                    aria-hidden="true"
                                                                    src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2730%27%20height=%2730%27/%3e"
                                                                    style={{
                                                                        display: "block",
                                                                        maxWidth: "100%",
                                                                        width: "initial",
                                                                        height: "initial",
                                                                        background: "none",
                                                                        opacity: 1,
                                                                        border: 0,
                                                                        margin: 0,
                                                                        padding: 0
                                                                    }}
                                                                />
                                                            </span>
                                                            <img
                                                                alt=""
                                                                src="/build/web/igame-index-lobby-wm/img/line_logo.webp"
                                                                decoding="async"
                                                                data-nimg="intrinsic"
                                                                style={{
                                                                    position: "absolute",
                                                                    inset: 0,
                                                                    boxSizing: "border-box",
                                                                    padding: 0,
                                                                    border: "none",
                                                                    margin: "auto",
                                                                    display: "block",
                                                                    width: 0,
                                                                    height: 0,
                                                                    minWidth: "100%",
                                                                    maxWidth: "100%",
                                                                    minHeight: "100%",
                                                                    maxHeight: "100%",
                                                                    objectFit: "cover"
                                                                }}
                                                            // srcSet="/_next/image?url=https%3A%2F%2Fd12lxcfs2l5om8.cloudfront.net%2Fbbcosina%2Fwebimages%2Flogin_page%2Fline_logo.png&w=32&q=75 1x, /_next/image?url=https%3A%2F%2Fd12lxcfs2l5om8.cloudfront.net%2Fbbcosina%2Fwebimages%2Flogin_page%2Fline_logo.png&w=64&q=75 2x"
                                                            />
                                                        </span>
                                                        <span>
                                                            <p
                                                                className="MuiTypography-root MuiTypography-body1 css-9l3uo3"
                                                                style={{
                                                                    fontFamily: "ksbold",
                                                                    fontSize: 24,
                                                                    marginLeft: 10,
                                                                    letterSpacing: "0.03em"
                                                                }}
                                                            >
                                                                คลิกเพื่อติดต่อทีมงาน
                                                            </p>
                                                        </span>
                                                        <span className="MuiTouchRipple-root css-w0pj6f" />
                                                    </button>
                                                </a>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div tabIndex={0} data-testid="sentinelEnd" />
                </div>
                <div role="presentation" style={{
                    display: this.state.modalpopup && isMobile ? "" : "none",
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    zIndex: 10000, // ค่าที่เหมาะสมสำหรับป๊อบอัพ
                    pointerEvents: "auto" // จับการคลิกบนป๊อบอัพ
                }} className="MuiDialog-root MuiModal-root css-126xj0f">
                    <div
                        aria-hidden="false"
                        className="MuiBackdrop-root css-919eu4"
                        style={{
                            opacity: 1,
                            transition: "opacity 225ms cubic-bezier(0.4, 0, 0.2, 1)"
                        }}
                    />
                    <div tabIndex={0} data-testid="sentinelStart" />
                    <div
                        className="MuiDialog-container MuiDialog-scrollPaper css-ekeie0"
                        role="presentation"
                        tabIndex={-1}
                        style={{
                            opacity: 1,
                            transition: "opacity 225ms cubic-bezier(0.4, 0, 0.2, 1)"
                        }}
                    >
                        <div
                            className="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation24 MuiDialog-paper MuiDialog-paperScrollPaper MuiDialog-paperWidthLg MuiDialog-paperFullWidth css-1nhstuc"
                            role="dialog"
                            aria-labelledby=":r3:"
                            style={{ borderRadius: 30, background: "rgb(45, 46, 74)" }}
                        >
                            <div className="MuiBox-root css-c4r07k">
                                <div className="MuiBox-root css-hboir5">
                                    <div className="MuiBox-root css-11ze7cv">
                                        <button
                                            id="toggle-ios"
                                            className={`MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium ${ios ? "css-1tx6u52" : "css-z0mm8"
                                                }`} tabIndex={0}
                                            type="button"
                                            onClick={this.handleIosClick}
                                        >
                                            <p className="MuiTypography-root MuiTypography-body1 css-ofau66">
                                                ios
                                            </p>
                                            <span className="MuiTouchRipple-root css-w0pj6f" />
                                        </button>
                                    </div>
                                    <div className="MuiBox-root css-11ze7cv">
                                        <button
                                            id="toggle-android"
                                            className={`MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium ${!ios ? "css-1tx6u52" : "css-z0mm8"
                                                }`} tabIndex={0}
                                            onClick={this.handleAndroidClick}
                                            type="button"
                                        >
                                            <p className="MuiTypography-root MuiTypography-body1 css-ofau66">
                                                android
                                            </p>
                                            <span className="MuiTouchRipple-root css-w0pj6f" />
                                        </button>
                                    </div>
                                </div>
                                <div className="MuiBox-root css-bb6zj">
                                    <div style={{ marginTop: 10, marginBottom: 10 }}>
                                        <p className="MuiTypography-root MuiTypography-body1 css-qw6uke">
                                            ดาวน์โหลดแอปพลิเคชั่น
                                        </p>
                                    </div>
                                    <div className="MuiBox-root css-r7q7qr">
                                        {/* <div className="carousel-root" style={{ display: ios ? "" : "none" }}>
                                            <div className="carousel carousel-slider" style={{ width: "100%" }}>
                                                <ul className="control-dots ios-dots">
                                                    {Array.from({ length: 4 }).map((_, index) => (
                                                        <li
                                                            key={`ios-dot-${index}`}
                                                            title={`slide item ${index + 1}`}
                                                            style={{
                                                                background: index === 0 ? "rgb(227, 49, 44)" : "lightgrey",
                                                                width: 10,
                                                                height: 10,
                                                                display: "inline-block",
                                                                borderRadius: "50%",
                                                                margin: "0px 8px",
                                                            }}
                                                            value={index}
                                                            role="button"
                                                            tabIndex={0}
                                                            aria-label={`Navigate to slide ${index + 1}`}
                                                        />
                                                    ))}
                                                </ul>
                                                <button
                                                    type="button"
                                                    aria-label="previous slide / item"
                                                    className="control-arrow control-prev control-disabled"
                                                />
                                                <div className="slider-wrapper axis-horizontal">
                                                    <ul
                                                        className="slider animated ios-slider"
                                                        style={{
                                                            transform: "translate3d(-300%, 0px, 0px)",
                                                            transitionDuration: "350ms",
                                                        }}
                                                    >
                                                        {[
                                                            {
                                                                src: "https://d12lxcfs2l5om8.cloudfront.net/new_webimages/manager/download/ios/download_1.png",
                                                                alt: "กดปุ่มด้านล่าง",
                                                                text: "กดปุ่มด้านล่างตามภาพ\nเพื่อเพิ่มแอปพลิเคชั่น",
                                                            },
                                                            {
                                                                src: "https://d12lxcfs2l5om8.cloudfront.net/new_webimages/manager/download/ios/download_2.png",
                                                                alt: "เลือก Add to Home Screen",
                                                                text: "เลือก Add to Home Screen / เพิ่มลงไปยังหน้าจอโฮม",
                                                            },
                                                            {
                                                                src: "https://d12lxcfs2l5om8.cloudfront.net/new_webimages/manager/download/ios/download_3.png",
                                                                alt: "เพิ่มทางลัด",
                                                                text: "กด 'เพิ่ม' ทางลัด\nเข้าสู่เกมส์ลงในหน้าโฮม",
                                                            },
                                                            {
                                                                src: "https://d12lxcfs2l5om8.cloudfront.net/new_webimages/manager/download/ios/download_4.png",
                                                                alt: "ตรวจสอบหน้าโฮม",
                                                                text: "ตรวจสอบหน้าโฮม ว่ามีไอคอนขึ้นแล้ว",
                                                            },
                                                        ].map((slide, index) => (
                                                            <li
                                                                key={`ios-slide-${index}`}
                                                                className={`slide ${index === 0 ? "selected" : ""}`}
                                                                style={{ minWidth: "100%" }}
                                                                aria-hidden={index !== 0}
                                                            >
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        alignItems: "center",
                                                                        flexDirection: "column",
                                                                        height: 480,
                                                                    }}
                                                                >
                                                                    <img
                                                                        src={slide.src}
                                                                        alt={slide.alt}
                                                                        draggable="false"
                                                                        style={{
                                                                            borderRadius: 20,
                                                                            maxHeight: 400,
                                                                            maxWidth: 198,
                                                                        }}
                                                                    />
                                                                    <div
                                                                        style={{
                                                                            marginTop: 10,
                                                                            marginBottom: 25,
                                                                            display: "flex",
                                                                            flexDirection: "column",
                                                                            justifyContent: "center",
                                                                            alignItems: "center",
                                                                            color: "white",
                                                                            fontSize: 15,
                                                                            fontWeight: 400,
                                                                            textAlign: "center",
                                                                        }}
                                                                    >
                                                                        {slide.text.split("\n").map((line, lineIndex) => (
                                                                            <p key={`line-${lineIndex}`}>{line}</p>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                                <button
                                                    type="button"
                                                    aria-label="next slide / item"
                                                    className="control-arrow control-next control-disabled"
                                                />
                                            </div>
                                        </div> */}

                                        <div className="carousel-root" style={{ display: ios ? "" : "none" }}>
                                            <button
                                                onClick={this.prevSlide}
                                                style={{
                                                    position: "absolute",
                                                    left: "10px",
                                                    top: "50%",
                                                    transform: "translateY(-50%)",
                                                    zIndex: 1000,
                                                    background: "rgba(0,0,0,0.5)",
                                                    color: "white",
                                                    border: "none",
                                                    borderRadius: "50%",
                                                    width: "40px",
                                                    height: "40px",
                                                }}
                                            >
                                                &#9664;
                                            </button>
                                            <div className="carousel carousel-slider" style={{ width: "100%" }}>

                                                <div style={{ position: "relative", overflow: "hidden" }}>
                                                    {/* ปุ่ม Back */}



                                                    {/* ภาพ Slide */}

                                                    <div className="slider-wrapper" style={{ display: "flex", width: "100%" }}>
                                                        {this.iosSlides.map((slide, index) => (
                                                            <div
                                                                key={index}
                                                                style={{
                                                                    minWidth: "100%",
                                                                    display: index === currentIndex ? "block" : "none",
                                                                    textAlign: "center",
                                                                }}
                                                            >

                                                                <img
                                                                    src={slide.src}
                                                                    alt={slide.alt}
                                                                    style={{
                                                                        borderRadius: 20,
                                                                        maxHeight: 400,
                                                                        maxWidth: 198,
                                                                    }}
                                                                />
                                                                <p style={{ color: "white", marginTop: 10 }}>
                                                                    {slide.text.split("\n").map((line, lineIndex) => (
                                                                        <span key={lineIndex}>{line}</span>
                                                                    ))}
                                                                </p>
                                                            </div>
                                                        ))}
                                                    </div>


                                                    {/* ปุ่ม Next */}

                                                </div>
                                            </div>
                                            <button
                                                onClick={this.nextSlide}
                                                style={{
                                                    position: "absolute",
                                                    right: "10px",
                                                    top: "50%",
                                                    transform: "translateY(-50%)",
                                                    zIndex: 1000,
                                                    background: "rgba(0,0,0,0.5)",
                                                    color: "white",
                                                    border: "none",
                                                    borderRadius: "50%",
                                                    width: "40px",
                                                    height: "40px",
                                                }}
                                            >
                                                &#9654;
                                            </button>
                                        </div>


                                        <div className="carousel-root" style={{ display: ios ? "none" : "" }}>
                                            <button
                                                onClick={this.prevSlideA}
                                                style={{
                                                    position: "absolute",
                                                    left: "10px",
                                                    top: "50%",
                                                    transform: "translateY(-50%)",
                                                    zIndex: 1000,
                                                    background: "rgba(0,0,0,0.5)",
                                                    color: "white",
                                                    border: "none",
                                                    borderRadius: "50%",
                                                    width: "40px",
                                                    height: "40px",
                                                }}
                                            >
                                                &#9664;
                                            </button>

                                            <div style={{ position: "relative", overflow: "hidden" }}>
                                                {/* ปุ่ม Back */}

                                                {/* ภาพ Slide */}
                                                <div className="carousel carousel-slider" style={{ width: "100%" }}>
                                                    <div className="slider-wrapper" style={{ display: "flex", width: "100%" }}>
                                                        {this.androidSlides.map((slide, index) => (
                                                            <div
                                                                key={index}
                                                                style={{
                                                                    minWidth: "100%",
                                                                    display: index === currentIndexA ? "block" : "none",
                                                                    textAlign: "center",
                                                                }}
                                                            >
                                                                <img
                                                                    src={slide.src}
                                                                    alt={slide.alt}
                                                                    style={{
                                                                        borderRadius: 20,
                                                                        maxHeight: 400,
                                                                        maxWidth: 198,
                                                                    }}
                                                                />
                                                                <p style={{ color: "white", marginTop: 10 }}>{slide.text}</p>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>

                                                {/* ปุ่ม Next */}

                                            </div>
                                            <button
                                                onClick={this.nextSlideA}
                                                style={{
                                                    position: "absolute",
                                                    right: "10px",
                                                    top: "50%",
                                                    transform: "translateY(-50%)",
                                                    zIndex: 1000,
                                                    background: "rgba(0,0,0,0.5)",
                                                    color: "white",
                                                    border: "none",
                                                    borderRadius: "50%",
                                                    width: "40px",
                                                    height: "40px",
                                                }}
                                            >
                                                &#9654;
                                            </button>
                                        </div>
                                        {/* <div className="carousel-root" style={{ display: ios ? "none" : "" }}>
                                            <div className="carousel carousel-slider" style={{ width: "100%" }}>
                                                <ul className="control-dots android-dots">
                                                    {Array.from({ length: 3 }).map((_, index) => (
                                                        <li
                                                            key={`android-dot-${index}`}
                                                            title={`slide item ${index + 1}`}
                                                            style={{
                                                                background: index === 0 ? "rgb(227, 49, 44)" : "lightgrey",
                                                                width: 10,
                                                                height: 10,
                                                                display: "inline-block",
                                                                borderRadius: "50%",
                                                                margin: "0px 8px",
                                                            }}
                                                            role="button"
                                                            tabIndex={0}
                                                            aria-label={`Navigate to slide ${index + 1}`}
                                                        />
                                                    ))}
                                                </ul>
                                                <div className="slider-wrapper axis-horizontal">
                                                    <ul
                                                        className="slider animated android-slider"
                                                        style={{
                                                            transform: "translate3d(0, 0, 0)",
                                                            transition: "350ms",
                                                        }}
                                                    >
                                                        {[

                                                            {
                                                                src: "https://d12lxcfs2l5om8.cloudfront.net/new_webimages/manager/download/android/download_1.png",
                                                                alt: "กดปุ่มติดตั้ง",
                                                                text: "กดปุ่มติดตั้งตามภาพด้านบน",
                                                            },
                                                            {
                                                                src: "https://d12lxcfs2l5om8.cloudfront.net/new_webimages/manager/download/android/download_2.png",
                                                                alt: "เลือกติดตั้ง",
                                                                text: "เลือก 'ติดตั้ง'",
                                                            },
                                                            {
                                                                src: "https://d12lxcfs2l5om8.cloudfront.net/new_webimages/manager/download/android/download_3.png",
                                                                alt: "ตรวจสอบหน้าโฮม",
                                                                text: "ตรวจสอบหน้าโฮม ว่ามีไอคอนขึ้นแล้ว",
                                                            },
                                                        ].map((slide, index) => (
                                                            <li
                                                                key={`android-slide-${index}`}
                                                                className={`slide ${index === 0 ? "selected" : ""}`}
                                                                style={{ minWidth: "100%" }}
                                                                aria-hidden={index !== 0}
                                                            >
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        alignItems: "center",
                                                                        flexDirection: "column",
                                                                        height: 480,
                                                                    }}
                                                                >
                                                                    <img
                                                                        src={slide.src}
                                                                        alt={slide.alt}
                                                                        draggable="false"
                                                                        style={{
                                                                            borderRadius: 20,
                                                                            maxHeight: 400,
                                                                            maxWidth: 198,
                                                                        }}
                                                                    />
                                                                    <div
                                                                        style={{
                                                                            marginTop: 10,
                                                                            marginBottom: 25,
                                                                            display: "flex",
                                                                            flexDirection: "column",
                                                                            justifyContent: "center",
                                                                            alignItems: "center",
                                                                            color: "white",
                                                                            fontSize: 15,
                                                                            fontWeight: 400,
                                                                            textAlign: "center",
                                                                        }}
                                                                    >
                                                                        {slide.text.split("\n").map((line, lineIndex) => (
                                                                            <p key={`line-${lineIndex}`}>{line}</p>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div> */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div tabIndex={0} data-testid="sentinelEnd" />
                </div>

                {/* <div id="nprogress" style={{  opacity: 1 }}>
                    <div
                        className="bar"
                        role="bar"
                        style={{
                            transition: loader ? "width 5s ease":""
                        }}
                    >
                        <div className="peg" />
                    </div>
                    <div className="spinner" role="spinner">
                        <div className="spinner-icon" />
                    </div>
                </div> */}

                <div
                    id="floating-lucky-wheel"
                    style={{ display: this.state.luckywheeldisplay ? "" : "none" }}
                    className="lucky-wheel-container"
                    draggable="true"
                >
                    <img
                        onClick={(e) => {
                            this.checkurl(e, "/minigame/luckywheel");
                        }}
                        draggable="false"
                        className="floating-lucky-wheel content-img"
                        src="https://cdn.javisx.com/image/9899b324-89e9-46ff-9552-93699b1747ce.gif"
                        alt="floating-lucky-wheel"
                    />
                    <a
                        onClick={(e) => {
                            this.closewheel(e);
                        }}
                    >
                        <span className="close-btn">×</span> {/* แสดงเครื่องหมายปิด */}
                    </a>
                </div>
                <div role="presentation" style={{ display: this.state.popupprofile && !isMobile ? "" : "none" }} className="MuiDialog-root MuiModal-root css-126xj0f">
                    <div
                        aria-hidden="false"
                        className="MuiBackdrop-root css-919eu4"
                        style={{
                            opacity: 1,
                            transition: "opacity 225ms cubic-bezier(0.4, 0, 0.2, 1)"
                        }}
                    />
                    <div tabIndex={0} data-testid="sentinelStart" />
                    <div
                        className="MuiDialog-container MuiDialog-scrollPaper css-ekeie0"
                        role="presentation"
                        tabIndex={-1}
                        style={{
                            opacity: 1,
                            transition: "opacity 225ms cubic-bezier(0.4, 0, 0.2, 1)"
                        }}
                    >
                        <div
                            className="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation24 MuiDialog-paper MuiDialog-paperScrollPaper MuiDialog-paperWidthLg MuiDialog-paperFullWidth css-1nhstuc"
                            role="dialog"
                            aria-labelledby=":ri:"
                            style={{
                                borderRadius: 30,
                                background: "linear-gradient(rgb(65, 64, 66) 30%, rgb(35, 31, 32) 100%)"
                            }}
                        >
                            <div className="MuiBox-root css-k008qs">
                                <div className="MuiBox-root css-125zl25">
                                    <button
                                        onClick={(e) => {

                                            this.setState({
                                                tabprofile: 1,
                                            });

                                        }}
                                        className="MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-16hew80"
                                        tabIndex={0}
                                        type="button"
                                    >
                                        <p className="MuiTypography-root MuiTypography-body1 css-1o91qow">
                                            ข้อมูลส่วนตัว
                                        </p>
                                        <span className="MuiTouchRipple-root css-w0pj6f" />
                                    </button>
                                    <button
                                        onClick={(e) => {

                                            this.setState({
                                                tabprofile: 2,
                                            });

                                        }}
                                        className="MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-8ulote"
                                        tabIndex={0}
                                        type="button"
                                    >
                                        <p className="MuiTypography-root MuiTypography-body1 css-1o91qow">
                                            เปลี่ยนรหัสผ่าน
                                        </p>
                                        <span className="MuiTouchRipple-root css-w0pj6f" />
                                    </button>
                                    <div className="MuiBox-root css-i9gxme" />
                                    <button
                                        href="/logout"
                                        className="MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-1by5e5o"
                                        tabIndex={0}
                                        type="button"
                                    >
                                        <svg
                                            className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1okqa6k"
                                            focusable="false"
                                            aria-hidden="true"
                                            viewBox="0 0 24 24"
                                            data-testid="ExitToAppIcon"
                                        >
                                            <path d="M10.09 15.59 11.5 17l5-5-5-5-1.41 1.41L12.67 11H3v2h9.67l-2.58 2.59zM19 3H5c-1.11 0-2 .9-2 2v4h2V5h14v14H5v-4H3v4c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z" />
                                        </svg>
                                        <p className="MuiTypography-root MuiTypography-body1 css-1o91qow">
                                            ออกจากระบบ
                                        </p>
                                        <span className="MuiTouchRipple-root css-w0pj6f" />
                                    </button>
                                </div>
                                <div className="MuiBox-root css-1ajiwg8">
                                    <div className="MuiBox-root css-1sjcmme" style={{ display: this.state.tabprofile == 1 ? "" : "none" }}>
                                        <div className="MuiBox-root css-1mv6lwv">
                                            <div className="MuiBox-root css-1j0jj7z">
                                                <p className="MuiTypography-root MuiTypography-body1 css-yamfrd">
                                                    บัญชีธนาคารของท่าน
                                                </p>
                                                <div className="MuiBox-root css-1r2f04i">
                                                    <span
                                                        style={{
                                                            boxSizing: "border-box",
                                                            display: "inline-block",
                                                            overflow: "hidden",
                                                            width: "initial",
                                                            height: "initial",
                                                            background: "none",
                                                            opacity: 1,
                                                            border: 0,
                                                            margin: 0,
                                                            padding: 0,
                                                            position: "relative",
                                                            maxWidth: "100%"
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                boxSizing: "border-box",
                                                                display: "block",
                                                                width: "initial",
                                                                height: "initial",
                                                                background: "none",
                                                                opacity: 1,
                                                                border: 0,
                                                                margin: 0,
                                                                padding: 0,
                                                                maxWidth: "100%"
                                                            }}
                                                        >
                                                            <img
                                                                alt=""
                                                                aria-hidden="true"
                                                                src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2750%27%20height=%2750%27/%3e"
                                                                style={{
                                                                    display: "block",
                                                                    maxWidth: "100%",
                                                                    width: "initial",
                                                                    height: "initial",
                                                                    background: "none",
                                                                    opacity: 1,
                                                                    border: 0,
                                                                    margin: 0,
                                                                    padding: 0
                                                                }}
                                                            />
                                                        </span>
                                                        <img
                                                            alt="BANK LOGO"
                                                            src={accnumber !== null ? accnumberx.img_path : ""}
                                                            decoding="async"
                                                            data-nimg="intrinsic"
                                                            style={{
                                                                position: "absolute",
                                                                inset: 0,
                                                                boxSizing: "border-box",
                                                                padding: 0,
                                                                border: "none",
                                                                margin: "auto",
                                                                display: "block",
                                                                width: 0,
                                                                height: 0,
                                                                minWidth: "100%",
                                                                maxWidth: "100%",
                                                                minHeight: "100%",
                                                                maxHeight: "100%"
                                                            }}
                                                        //  srcSet="/_next/image?url=https%3A%2F%2Fd12lxcfs2l5om8.cloudfront.net%2Fnew_webimages%2Fbank%2Fscb.webp&w=64&q=75 1x, /_next/image?url=https%3A%2F%2Fd12lxcfs2l5om8.cloudfront.net%2Fnew_webimages%2Fbank%2Fscb.webp&w=128&q=75 2x"
                                                        />
                                                        <noscript />
                                                    </span>
                                                </div>
                                                <div className="MuiBox-root css-0">
                                                    <p className="MuiTypography-root MuiTypography-body1 css-xwuunc">
                                                        {accnumber !== null ? accnumberx.fullname_th : ""}
                                                    </p>
                                                    <p className="MuiTypography-root MuiTypography-body1 css-989l4c">
                                                        {accnumber !== null ? accnumberx.accno.substring(0, 3) + "-" + accnumberx.accno.substring(3, 9) + "-" + accnumberx.accno.substring(9, 12) : ""}
                                                    </p>

                                                    <p className="MuiTypography-root MuiTypography-body1 css-xwuunc" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="MuiBox-root css-1sa9vfb">
                                            <p className="MuiTypography-root MuiTypography-body1 css-881e75">
                                                สิทธิพิเศษสำหรับแต่ละเลเวล
                                            </p>
                                            <p className="MuiTypography-root MuiTypography-body1 css-5kjraz">
                                                สิทธิพิเศษและโปรโมชั่นในแต่ละเลเวล จะถูกอัพเดทเพิ่มขึ้นมาเรื่อยๆ
                                                ในอนาคต
                                            </p>
                                        </div>
                                        <div className="MuiBox-root css-1mzpksw">
                                            {this.state.tblrankall
                                                .filter(rank => rank.name.toLowerCase() !== "new") // กรองการ์ดที่ไม่ใช่ "new"
                                                .map((rank, index) => {
                                                    // กำหนดสีพื้นหลังตามชื่อ
                                                    let gradientBackground = "";
                                                    switch (rank.name.toLowerCase()) {
                                                        case "silver":
                                                            gradientBackground = "linear-gradient(rgb(193, 193, 193) 0%, rgb(142, 142, 142) 100%)";
                                                            break;
                                                        case "gold":
                                                            gradientBackground = "linear-gradient(rgb(221, 156, 72) 0%, rgb(179, 121, 47) 100%)";
                                                            break;
                                                        case "platinum":
                                                            gradientBackground = "linear-gradient(rgb(193, 47, 154) 0%, rgb(138, 26, 110) 100%)";
                                                            break;
                                                        case "bronze":
                                                            gradientBackground = "linear-gradient(rgb(155, 88, 36) 0%, rgb(119, 63, 14) 100%)";
                                                            break;
                                                        default:
                                                            gradientBackground = "linear-gradient(rgb(255, 255, 255) 0%, rgb(200, 200, 200) 100%)";
                                                    }

                                                    return (
                                                        <div
                                                            key={index}
                                                            className="MuiBox-root"
                                                            style={{
                                                                width: "170px",
                                                                height: "230px",
                                                                background: gradientBackground,
                                                                borderRadius: "14px",
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                alignItems: "center",
                                                                justifyContent: "space-between",
                                                                padding: "10px"
                                                            }}
                                                        >
                                                            <div className="MuiBox-root css-1mi3tt8">
                                                                <span
                                                                    style={{
                                                                        boxSizing: "border-box",
                                                                        display: "inline-block",
                                                                        overflow: "hidden",
                                                                        width: "initial",
                                                                        height: "initial",
                                                                        background: "none",
                                                                        opacity: 1,
                                                                        border: 0,
                                                                        margin: 0,
                                                                        padding: 0,
                                                                        position: "relative",
                                                                        maxWidth: "100%"
                                                                    }}
                                                                >
                                                                    <span
                                                                        style={{
                                                                            boxSizing: "border-box",
                                                                            display: "block",
                                                                            width: "initial",
                                                                            height: "initial",
                                                                            background: "none",
                                                                            opacity: 1,
                                                                            border: 0,
                                                                            margin: 0,
                                                                            padding: 0,
                                                                            maxWidth: "100%"
                                                                        }}
                                                                    >
                                                                        <img
                                                                            alt=""
                                                                            aria-hidden="true"
                                                                            src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%27120%27%20height=%27120%27/%3e"
                                                                            style={{
                                                                                display: "block",
                                                                                maxWidth: "100%",
                                                                                width: "initial",
                                                                                height: "initial",
                                                                                background: "none",
                                                                                opacity: 1,
                                                                                border: 0,
                                                                                margin: 0,
                                                                                padding: 0
                                                                            }}
                                                                        />
                                                                    </span>
                                                                    <img
                                                                        alt={rank.name}
                                                                        src={rank.picrack}
                                                                        decoding="async"
                                                                        data-nimg="intrinsic"
                                                                        style={{
                                                                            position: "absolute",
                                                                            inset: 0,
                                                                            boxSizing: "border-box",
                                                                            padding: 0,
                                                                            border: "none",
                                                                            margin: "auto",
                                                                            display: "block",
                                                                            width: 0,
                                                                            height: 0,
                                                                            minWidth: "100%",
                                                                            maxWidth: "100%",
                                                                            minHeight: "40%",
                                                                            maxHeight: "40%"
                                                                        }}
                                                                    />
                                                                    <noscript />
                                                                </span>
                                                                <p className="MuiTypography-root MuiTypography-body1 css-fa6h1x">
                                                                    {rank.name}
                                                                </p>
                                                            </div>
                                                            <div className="MuiBox-root css-1mi3tt8">
                                                                <p className="MuiTypography-root MuiTypography-body1 css-1vtfy49">
                                                                    เร็วๆนี้...
                                                                </p>
                                                            </div>
                                                        </div>
                                                    );
                                                })}



                                        </div>
                                    </div>
                                    <div className="MuiBox-root css-jn3rqv" style={{ display: this.state.tabprofile == 2 ? "" : "none" }}>
                                        <p className="MuiTypography-root MuiTypography-body1 css-1wbloc6">
                                            ตั้งค่ารหัสผ่าน
                                        </p>
                                        <div className="MuiBox-root css-r15qn4">
                                            <p onClick={(e) => {

                                                this.setState({
                                                    tabprofile: 1,
                                                });

                                            }} className="MuiTypography-root MuiTypography-body1 css-flgweo">
                                                &lt;&lt; กลับ
                                            </p>
                                            <form className="MuiBox-root css-1f8hzjo">
                                                <p className="MuiTypography-root MuiTypography-body1 css-1mvgw5v">
                                                    เปลี่ยนรหัสผ่าน
                                                </p>
                                                <div className="MuiFormControl-root css-9tvd8z">
                                                    <div className="MuiFormControl-root MuiTextField-root" style={{ flexDirection: "column !important" }}>
                                                        {/* <label
                                                            className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-outlined MuiFormLabel-colorPrimary Mui-required css-p0rm37"
                                                            htmlFor="old-password"
                                                        >
                                                            รหัสผ่านเก่า
                                                            <span className="MuiFormLabel-asterisk MuiInputLabel-asterisk css-sp68t1">*</span>
                                                        </label> */}
                                                        <div className="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-formControl css-1v4ccyo">
                                                            <input
                                                                id="old-password"
                                                                name="oldPassword"
                                                                placeholder="รหัสผ่านเก่า"
                                                                type="text"
                                                                className="MuiInputBase-input MuiOutlinedInput-input css-1x5jdmq"
                                                                value={oldPassword}
                                                                onChange={this.handleChange}
                                                                required
                                                            />
                                                            <fieldset aria-hidden="false" className="MuiOutlinedInput-notchedOutline css-igs3ac">
                                                                <legend className="css-1ftyaf0">
                                                                    <span>รหัสผ่านเก่า&nbsp;*</span>
                                                                </legend>
                                                            </fieldset>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="MuiFormControl-root css-9tvd8z">
                                                    <div className="MuiFormControl-root MuiTextField-root " style={{ flexDirection: "column  !important" }}>
                                                        {/* <label
                                                            className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-outlined MuiFormLabel-colorPrimary Mui-required css-p0rm37"
                                                            htmlFor="new-password"
                                                        >
                                                            รหัสผ่านใหม่
                                                            <span className="MuiFormLabel-asterisk MuiInputLabel-asterisk css-sp68t1">*</span>
                                                        </label> */}
                                                        <div className="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-formControl css-1v4ccyo">
                                                            <input
                                                                id="new-password"
                                                                name="newPassword"
                                                                placeholder="รหัสผ่านใหม่"
                                                                type="text"
                                                                className="MuiInputBase-input MuiOutlinedInput-input css-1x5jdmq"
                                                                value={newPassword}
                                                                onChange={this.handleChange}
                                                                required
                                                            />
                                                            <fieldset aria-hidden="false" className="MuiOutlinedInput-notchedOutline css-igs3ac">
                                                                <legend className="css-1ftyaf0">
                                                                    <span>รหัสผ่านใหม่&nbsp;*</span>
                                                                </legend>
                                                            </fieldset>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="MuiFormControl-root css-9tvd8z">
                                                    <div className="MuiFormControl-root MuiTextField-root" style={{ flexDirection: "column  !important" }}>
                                                        {/* <label
                                                            className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-outlined MuiFormLabel-colorPrimary Mui-required css-p0rm37"
                                                            htmlFor="confirm-password"
                                                        >
                                                            ยืนยันรหัสผ่านใหม่
                                                            <span className="MuiFormLabel-asterisk MuiInputLabel-asterisk css-sp68t1">*</span>
                                                        </label> */}
                                                        <div className="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-formControl css-1v4ccyo">
                                                            <input
                                                                id="confirm-password"
                                                                name="confirmPassword"
                                                                placeholder="ยืนยันรหัสผ่านใหม่"
                                                                type="text"
                                                                className="MuiInputBase-input MuiOutlinedInput-input css-1x5jdmq"
                                                                value={confirmPassword}
                                                                onChange={this.handleChange}
                                                                required
                                                            />
                                                            <fieldset aria-hidden="false" className="MuiOutlinedInput-notchedOutline css-igs3ac">
                                                                <legend className="css-1ftyaf0">
                                                                    <span>ยืนยันรหัสผ่านใหม่&nbsp;*</span>
                                                                </legend>
                                                            </fieldset>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="MuiFormControl-root css-axodvu">
                                                    <button
                                                        onClick={this.handleSubmit}
                                                        className="MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-8sbcpj"
                                                        tabIndex={0}
                                                        type="submit"
                                                    >
                                                        <p className="MuiTypography-root MuiTypography-body1 css-yqx1uy">
                                                            คลิกเพื่อเปลี่ยนรหัสผ่าน
                                                        </p>
                                                        <span className="MuiTouchRipple-root css-w0pj6f" />
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div tabIndex={0} data-testid="sentinelEnd" />
                </div>

                <div role="presentation" style={{ display: this.state.popupprofile && isMobile ? "" : "none" }} className="MuiDialog-root MuiModal-root css-126xj0f">
                    <div
                        aria-hidden="true"
                        className="MuiBackdrop-root css-919eu4"
                        style={{
                            opacity: 1,
                            transition: "opacity 225ms cubic-bezier(0.4, 0, 0.2, 1)"
                        }}
                    />
                    <div tabIndex={0} data-testid="sentinelStart" />
                    <div
                        className="MuiDialog-container MuiDialog-scrollPaper css-ekeie0"
                        role="presentation"
                        tabIndex={-1}
                        style={{
                            opacity: 1,
                            transition: "opacity 225ms cubic-bezier(0.4, 0, 0.2, 1)"
                        }}
                    >
                        <div
                            className="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation24 MuiDialog-paper MuiDialog-paperScrollPaper MuiDialog-paperWidthLg MuiDialog-paperFullWidth css-1nhstuc"
                            role="dialog"
                            aria-labelledby=":rd:"
                            style={{
                                borderRadius: 30,
                                background: "linear-gradient(rgb(65, 64, 66) 30%, rgb(35, 31, 32) 100%)"
                            }}
                        >
                            <div className="MuiBox-root css-k008qs">
                                <div className="MuiBox-root css-1gz07rm">
                                    <div className="MuiBox-root css-1sjcmme" style={{ display: this.state.tabprofile == 1 ? "" : "none" }}>
                                        <div className="MuiBox-root css-1nifn74">
                                            <p className="MuiTypography-root MuiTypography-body1 css-tjtlq7">
                                                ข้อมูลส่วนตัว
                                            </p>
                                        </div>
                                        <div className="MuiBox-root css-piyqnk">
                                            <div className="MuiBox-root css-1vcfgay">
                                                <div className="MuiBox-root css-0">
                                                    <span
                                                        style={{
                                                            boxSizing: "border-box",
                                                            display: "inline-block",
                                                            overflow: "hidden",
                                                            width: "initial",
                                                            height: "initial",
                                                            background: "none",
                                                            opacity: 1,
                                                            border: 0,
                                                            margin: 0,
                                                            padding: 0,
                                                            position: "relative",
                                                            maxWidth: "100%"
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                boxSizing: "border-box",
                                                                display: "block",
                                                                width: "initial",
                                                                height: "initial",
                                                                background: "none",
                                                                opacity: 1,
                                                                border: 0,
                                                                margin: 0,
                                                                padding: 0,
                                                                maxWidth: "100%"
                                                            }}
                                                        >
                                                            <img
                                                                alt=""
                                                                aria-hidden="true"
                                                                src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2770%27%20height=%2770%27/%3e"
                                                                style={{
                                                                    display: "block",
                                                                    maxWidth: "100%",
                                                                    width: "initial",
                                                                    height: "initial",
                                                                    background: "none",
                                                                    opacity: 1,
                                                                    border: 0,
                                                                    margin: 0,
                                                                    padding: 0
                                                                }}
                                                            />
                                                        </span>
                                                        <img
                                                            alt="BANK LOGO"
                                                            src={accnumber !== null ? accnumberx.img_path : ""}
                                                            decoding="async"
                                                            data-nimg="intrinsic"
                                                            style={{
                                                                position: "absolute",
                                                                inset: 0,
                                                                boxSizing: "border-box",
                                                                padding: 0,
                                                                border: "none",
                                                                margin: "auto",
                                                                display: "block",
                                                                width: 0,
                                                                height: 0,
                                                                minWidth: "100%",
                                                                maxWidth: "100%",
                                                                minHeight: "100%",
                                                                maxHeight: "100%"
                                                            }}
                                                        />
                                                    </span>
                                                </div>
                                                <div className="MuiBox-root css-s8vbhp">
                                                    <p className="MuiTypography-root MuiTypography-body1 css-1bqll34">
                                                        บัญชีธนาคารของท่าน
                                                    </p>
                                                    <p className="MuiTypography-root MuiTypography-body1 css-170qamw" />
                                                    <p className="MuiTypography-root MuiTypography-body1 css-170qamw">
                                                        {accnumber !== null ? accnumberx.fullname_th : ""}
                                                    </p>
                                                    <p className="MuiTypography-root MuiTypography-body1 css-jgkti8">
                                                        {accnumber !== null ? accnumberx.accno.substring(0, 3) + "-" + accnumberx.accno.substring(3, 9) + "-" + accnumberx.accno.substring(9, 12) : ""}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="MuiBox-root css-1yauli6">
                                            <p className="MuiTypography-root MuiTypography-body1 css-881e75">
                                                สิทธิพิเศษสำหรับแต่ละเลเวล
                                            </p>
                                            <p className="MuiTypography-root MuiTypography-body1 css-x76tda">
                                                สิทธิพิเศษและโปรโมชั่นในแต่ละเลเวล จะถูกอัพเดทเพิ่มขึ้นมาเรื่อยๆ
                                                ในอนาคต
                                            </p>
                                        </div>
                                        <div className="MuiBox-root css-1hapwbd">
                                            {this.state.tblrankall
                                                .filter(rank => rank.name.toLowerCase() !== "new") // กรองการ์ดที่ไม่ใช่ "new"
                                                .map((rank, index) => {
                                                    // กำหนดสีพื้นหลังตามชื่อ
                                                    let gradientBackground = "";
                                                    switch (rank.name.toLowerCase()) {
                                                        case "silver":
                                                            gradientBackground = "linear-gradient(rgb(193, 193, 193) 0%, rgb(142, 142, 142) 100%)";
                                                            break;
                                                        case "gold":
                                                            gradientBackground = "linear-gradient(rgb(221, 156, 72) 0%, rgb(179, 121, 47) 100%)";
                                                            break;
                                                        case "platinum":
                                                            gradientBackground = "linear-gradient(rgb(193, 47, 154) 0%, rgb(138, 26, 110) 100%)";
                                                            break;
                                                        case "bronze":
                                                            gradientBackground = "linear-gradient(rgb(155, 88, 36) 0%, rgb(119, 63, 14) 100%)";
                                                            break;
                                                        default:
                                                            gradientBackground = "linear-gradient(rgb(255, 255, 255) 0%, rgb(200, 200, 200) 100%)";
                                                    }

                                                    return (
                                                        <div
                                                            key={index}
                                                            className="MuiBox-root"
                                                            style={{
                                                                height: "230px",
                                                                background: gradientBackground,
                                                                borderRadius: "14px",
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                alignItems: "center",
                                                                justifyContent: "space-between",
                                                                padding: "10px"
                                                            }}
                                                        >
                                                            <div className="MuiBox-root css-1mi3tt8">
                                                                <span
                                                                    style={{
                                                                        boxSizing: "border-box",
                                                                        display: "inline-block",
                                                                        overflow: "hidden",
                                                                        width: "initial",
                                                                        height: "initial",
                                                                        background: "none",
                                                                        opacity: 1,
                                                                        border: 0,
                                                                        margin: 0,
                                                                        padding: 0,
                                                                        position: "relative",
                                                                        maxWidth: "100%"
                                                                    }}
                                                                >
                                                                    <span
                                                                        style={{
                                                                            boxSizing: "border-box",
                                                                            display: "block",
                                                                            width: "initial",
                                                                            height: "initial",
                                                                            background: "none",
                                                                            opacity: 1,
                                                                            border: 0,
                                                                            margin: 0,
                                                                            padding: 0,
                                                                            maxWidth: "100%"
                                                                        }}
                                                                    >
                                                                        <img
                                                                            alt=""
                                                                            aria-hidden="true"
                                                                            src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%27120%27%20height=%27120%27/%3e"
                                                                            style={{
                                                                                display: "block",
                                                                                maxWidth: "100%",
                                                                                width: "initial",
                                                                                height: "initial",
                                                                                background: "none",
                                                                                opacity: 1,
                                                                                border: 0,
                                                                                margin: 0,
                                                                                padding: 0
                                                                            }}
                                                                        />
                                                                    </span>
                                                                    <img
                                                                        alt={rank.name}
                                                                        src={rank.picrack}
                                                                        decoding="async"
                                                                        data-nimg="intrinsic"
                                                                        style={{
                                                                            position: "absolute",
                                                                            inset: 0,
                                                                            boxSizing: "border-box",
                                                                            padding: 0,
                                                                            border: "none",
                                                                            margin: "auto",
                                                                            display: "block",
                                                                            width: 0,
                                                                            height: 0,
                                                                            minWidth: "100%",
                                                                            maxWidth: "100%",
                                                                            minHeight: "40%",
                                                                            maxHeight: "40%"
                                                                        }}
                                                                    />
                                                                    <noscript />
                                                                </span>
                                                                <p className="MuiTypography-root MuiTypography-body1 css-fa6h1x">
                                                                    {rank.name}
                                                                </p>
                                                            </div>
                                                            <div className="MuiBox-root css-1mi3tt8">
                                                                <p className="MuiTypography-root MuiTypography-body1 css-1vtfy49">
                                                                    เร็วๆนี้...
                                                                </p>
                                                            </div>
                                                        </div>
                                                    );
                                                })}

                                        </div>
                                        <div className="MuiBox-root css-1je3yjr">
                                            <div className="MuiBox-root css-1riyxa7">
                                                <button
                                                    onClick={(e) => {

                                                        this.setState({
                                                            tabprofile: 2,
                                                        });

                                                    }}
                                                    className="MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-a4xqmz"
                                                    tabIndex={0}
                                                    type="button"
                                                >
                                                    <p className="MuiTypography-root MuiTypography-body1 css-ji8ket">
                                                        เปลี่ยนรหัสผ่าน
                                                    </p>
                                                    <span className="MuiTouchRipple-root css-w0pj6f" />
                                                </button>
                                            </div>
                                            <div className="MuiBox-root css-eajj09">
                                                <button
                                                    href="/logout"
                                                    className="MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-w966cr"
                                                    tabIndex={0}
                                                    type="button"
                                                >
                                                    <p className="MuiTypography-root MuiTypography-body1 css-ji8ket">
                                                        ออกจากระบบ
                                                    </p>
                                                    <span className="MuiTouchRipple-root css-w0pj6f" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="MuiBox-root css-jn3rqv" style={{ display: this.state.tabprofile == 2 ? "" : "none" }}>
                                        <p className="MuiTypography-root MuiTypography-body1 css-jmvsjj">
                                            ตั้งค่ารหัสผ่าน
                                        </p>
                                        <div className="MuiBox-root css-4iphth">
                                            <p onClick={(e) => {
                                                this.setState({
                                                    tabprofile: 1,
                                                });

                                            }} className="MuiTypography-root MuiTypography-body1 css-flgweo">
                                                &lt;&lt; กลับ
                                            </p>
                                            <form className="MuiBox-root css-1f8hzjo">
                                                <p className="MuiTypography-root MuiTypography-body1 css-17nk34h">
                                                    เปลี่ยนรหัสผ่าน
                                                </p>
                                                <div className="MuiFormControl-root css-ubek3i">
                                                    <div className="MuiFormControl-root MuiTextField-root">

                                                        <div className="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-formControl css-1v4ccyo">
                                                            <input
                                                                id="old-password"
                                                                name="oldPassword"
                                                                placeholder="รหัสผ่านเก่า"
                                                                type="text"
                                                                className="MuiInputBase-input MuiOutlinedInput-input css-1x5jdmq"
                                                                value={oldPassword}
                                                                onChange={this.handleChange}
                                                                required
                                                            />
                                                            <fieldset
                                                                aria-hidden="true"
                                                                className="MuiOutlinedInput-notchedOutline css-igs3ac"
                                                            >
                                                                <legend className="css-1ftyaf0">
                                                                    <span>รหัสผ่านเก่า&nbsp;*</span>
                                                                </legend>
                                                            </fieldset>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="MuiFormControl-root css-ubek3i">
                                                    <div className="MuiFormControl-root MuiTextField-root">

                                                        <div className="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-formControl css-1v4ccyo">
                                                            <input
                                                                id="new-password"
                                                                name="newPassword"
                                                                placeholder="รหัสผ่านใหม่"
                                                                type="text"
                                                                className="MuiInputBase-input MuiOutlinedInput-input css-1x5jdmq"
                                                                value={newPassword}
                                                                onChange={this.handleChange}
                                                                required
                                                            />
                                                            <fieldset
                                                                aria-hidden="true"
                                                                className="MuiOutlinedInput-notchedOutline css-igs3ac"
                                                            >
                                                                <legend className="css-1ftyaf0">
                                                                    <span>รหัสผ่านใหม่&nbsp;*</span>
                                                                </legend>
                                                            </fieldset>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="MuiFormControl-root css-ubek3i">
                                                    <div className="MuiFormControl-root MuiTextField-root">
                                                        <div className="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-formControl css-1v4ccyo">
                                                            <input
                                                                id="confirm-password"
                                                                name="confirmPassword"
                                                                placeholder="ยืนยันรหัสผ่านใหม่"
                                                                type="text"
                                                                className="MuiInputBase-input MuiOutlinedInput-input css-1x5jdmq"
                                                                value={confirmPassword}
                                                                onChange={this.handleChange}
                                                                required
                                                            />
                                                            <fieldset
                                                                aria-hidden="true"
                                                                className="MuiOutlinedInput-notchedOutline css-igs3ac"
                                                            >
                                                                <legend className="css-1ftyaf0">
                                                                    <span>ยืนยันรหัสผ่านใหม่&nbsp;*</span>
                                                                </legend>
                                                            </fieldset>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="MuiFormControl-root css-1uo9vnu">
                                                    <button
                                                        onClick={this.handleSubmit}
                                                        className="MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-3i1t36"
                                                        tabIndex={0}
                                                        type="submit"
                                                    >
                                                        <p className="MuiTypography-root MuiTypography-body1 css-yqx1uy">
                                                            คลิกเพื่อเปลี่ยนรหัสผ่าน
                                                        </p>
                                                        <span className="MuiTouchRipple-root css-w0pj6f" />
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div tabIndex={0} data-testid="sentinelEnd" />
                </div>


                <Popup />


            </>
        );
    }
}

export default Main;
