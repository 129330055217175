import React, { Component } from 'react'; //different
//import './style.css';
import instance from "./axios-instance";
import SweetAlert from 'react-bootstrap-sweetalert';
import jwt_decode from "jwt-decode";
import NavMenu from './navmenu';
import Systems from "./SystemFunction";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

var settings = {
    dots: false, // ซ่อนจุดนำทาง
    infinite: true, // วนลูป slides
    slidesToShow: 3, // แสดง 3 slide ในหน้าจอใหญ่
    slidesToScroll: 1, // เลื่อนทีละ 1 slide
    autoplay: true, // autoplay
    speed: 2000, // ความเร็วในการเลื่อน (ms)
    autoplaySpeed: 3000, // ช่วงเวลาระหว่าง autoplay
    cssEase: "linear", // ความเรียบเนียนของ transition
    centerMode: true, // แสดง slide ตรงกลาง
    centerPadding: "20px", // ระยะห่างซ้าย-ขวาของ slides
    responsive: [
        {
            breakpoint: 1024, // เมื่อหน้าจอเล็กกว่า 1024px
            settings: {
                slidesToShow: 2, // แสดง 2 slide
                centerPadding: "15px", // ระยะห่างลดลง
            },
        },
        {
            breakpoint: 760, // เมื่อหน้าจอเล็กกว่า 760px
            settings: {
                slidesToShow: 1, // แสดง 1 slide
                centerPadding: "10px", // ระยะห่างลดลงอีก
            },
        },
    ],
};

class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tabbannerlist: [],
        }

    }

    componentDidMount() {
        const cacheKey = "tabBannerListCache";
        const cacheTTL = 3 * 60 * 60 * 1000; // 3 ชั่วโมงในมิลลิวินาที
        const cachedData = JSON.parse(localStorage.getItem(cacheKey));
    
        // ตรวจสอบแคช
        if (cachedData && (Date.now() - cachedData.timestamp < cacheTTL)) {
            console.log("Using cached data for tabbannerlist");
            this.setState({ tabbannerlist: cachedData.data });
        } else {
            // ถ้าไม่มีแคชหรือแคชหมดอายุ ให้เรียก API
            instance.get("/api/v1/tabbannerlist?System=" + Systems)
                .then((res) => {
                    console.log("tabbannerlist", res.data);
                    if (res.data.status === 200) {
                        this.setState({ tabbannerlist: res.data.message });
    
                        // บันทึกข้อมูลลงในแคช
                        localStorage.setItem(cacheKey, JSON.stringify({
                            timestamp: Date.now(),
                            data: res.data.message,
                        }));
                    }
                }).catch((err) => {
                    console.error("Error fetching tabbannerlist:", err);
                });
        }
    }
    

    render() {
        const { tabbannerlist } = this.state
        return (
            <>
                <Slider {...settings}>
                    {tabbannerlist.length > 0 ? (
                        tabbannerlist.map((item, index) => (
                            <div>
                                <img
                                    src={item?.path}
                                    alt={"Slide " + index}
                                    style={{ borderRadius: "0.5rem", width: "100%" }}
                                />
                            </div>
                        ))
                    ) : ""}
                </Slider>
            </>

        );
    }
}
export default App;
